import "../App.css";

import { useDispatch, useSelector } from "react-redux";

import { CURRENT_URL } from "../redux/actions/actionTypes";
import Footer from "./Footer";
import NavAppBar from "./NavAppBar";
import ScrollToTop from "../components/ScrollToTop";
import SnackBarAlert from "../components/snackBar/snackBarAlert";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function PageLayout({ children, props }) {
  const snackBarAlert = useSelector((state) => state?.snackBarAlert);
  let location = useLocation();
  const dispatch = useDispatch();
  const validLocation = new RegExp("^/dashboard.*$");

  useEffect(() => {
    dispatch({
      type: CURRENT_URL,
      payload: {
        url: location?.pathname,
      },
    });

    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <div className="App">
        <NavAppBar
          sx={{
            zIndex: 1300,
            backgroundColor: "#ffffff",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        />
        {snackBarAlert?.isAlert && (
          <>
            <SnackBarAlert
              data={snackBarAlert?.data}
              messageObj={snackBarAlert?.messageObj}
              type={snackBarAlert?.type}
            />
          </>
        )}
        <main>
          {children}
          {!validLocation.test(location.pathname) && <Footer />}
        </main>
        <ScrollToTop />
      </div>
    </>
  );
}
