import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function actionProducts(
  state = initialState.actionProducts,
  action
) {
  switch (action.type) {
    case types.ACTION_PRODUCTS:
      return { ...state, ...action.payload };
    case types.DEFAULT_ACTION_PRODUCTS:
      return initialState.actionProducts;
    default:
      return state;
  }
}
