import actionProducts from "./actionProductsReducer";
import apiData from "./apiDataReducer";
import apiProductDetail from "./apiProductDetailReducer";
import authenticate from "./authenticateReducer";
import backdrop from "./backdropReducer";
import basket from "./basketReducer";
import category from "./categoryReducer";
import checkout from "./checkoutReducer";
import { combineReducers } from "redux";
import cookieDialog from "./cookieDialogReducer";
import currentUrl from "./currentUrlReducer";
import dashboard from "./dashboardReducer";
import delivery from "./deliveryReducer";
import dialog from "./dialogReducer";
import displayProducts from "./displayProductsReducer";
import finstat from "./finstatReducer";
import firmInfo from "./firmInfoReducer";
import formFields from "./formFieldsReducer";
import index from "./indexReducer";
import navBarMenu from "./navBarMenuReducer";
import newProducts from "./newProductsReducer";
import numberOfProducts from "./numberOfProductsReducer";
import orders from "./ordersReducer";
import payGateway from "./payGatewayReducer";
import productMenu from "./productMenuReducer";
import productPiece from "./productPieceReducer";
import productTechData from "./productTechDataReducer";
import saleProducts from "./saleProductsReducer";
import search from "./searchReducer";
import sessionInfo from "./sessionInfoReducer";
import slideMenu from "./slideMenuReducer";
import snackBarAlert from "./snackBarAlertReducer";
import testing from "./testingReducer";

const appReducer = combineReducers({
  formFields,
  navBarMenu,
  slideMenu,
  apiData,
  basket,
  apiProductDetail,
  dialog,
  sessionInfo,
  backdrop,
  snackBarAlert,
  category,
  productMenu,
  numberOfProducts,
  search,
  productPiece,
  dashboard,
  firmInfo,
  finstat,
  checkout,
  orders,
  payGateway,
  authenticate,
  testing,
  productTechData,
  cookieDialog,
  displayProducts,
  index,
  delivery,
  currentUrl,
  actionProducts,
  saleProducts,
  newProducts,
});

const rootReducer = (state, action) => {
  // if(action.type===)
  return appReducer(state, action);
};

export default rootReducer;
