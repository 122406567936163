import "./SlideMenuBox.css";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { NAVIGATION_BAR, SLIDE_MENU } from "../../redux/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { HashLink } from "react-router-hash-link";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";
import theme from "../../style/style";
import { useNavigate } from "react-router-dom";

export default function SlideMenuList() {
  const dispatch = useDispatch();
  const displayMenuItems = useSelector((state) => state?.slideMenu?.menuItems);
  const parentItem = useSelector((state) => state?.slideMenu?.parentItem);
  const menuItems = useSelector((state) => state?.productMenu);
  const showBackButton = useSelector(
    (state) => state?.slideMenu?.showBackButton
  );

  const backButton = useSelector((state) => state?.slideMenu?.backButton);

  const navigate = useNavigate();

  const defaultPages = [
    { X_fullName: "O nás", X_URL: "aboutus" },
    { X_fullName: "Produkty", X_URL: "products" },
    { X_fullName: "Kontakt", X_URL: "contact" },
  ];

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -70;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const closeMenu = () => {
    dispatch({
      type: NAVIGATION_BAR,
      payload: {
        slideMenuVisible: false,
      },
    });
    dispatch({
      type: SLIDE_MENU,
      payload: {
        menuItems: menuItems?.items[0]?.items,
        backButton: "",
        showBackButton: "none",
      },
    });
  };

  const findCategory = (url, items, parent) => {
    for (let index = 0; index < items?.length; index++) {
      if (items[index]?.X_URL === url) {
        if (items[index]["items"]) {
          dispatch({
            type: SLIDE_MENU,
            payload: {
              menuItems: items[index]["items"],
              parentItem: parent,
              // TODO EN name
              // backButton: items[index]?.X_fullName,
              backButton: items[index]?.X_fullName_SK,
            },
          });
        } else {
          closeMenu();

          setTimeout(() => {
            navigate("/" + items[index]?.X_URL);
          }, 300);
        }
        break;
      } else {
        findCategory(url, items[index]?.items, items[index]);
      }
    }
  };

  const handleClick = (page) => {
    if (page?.X_fullName === "Produkty" && displayMenuItems !== "") {
      dispatch({
        type: SLIDE_MENU,
        payload: {
          menuItems: displayMenuItems,
          // TODO EN name
          // backButton: page?.X_fullName,
          backButton: page?.X_fullName_SK
            ? page?.X_fullName_SK
            : page?.X_fullName,
          showBackButton: "",
        },
      });
    } else {
      closeMenu();

      setTimeout(() => {
        navigate("/#" + page?.X_URL);
      }, 300);
    }
  };

  const togglePages = (page, index) => {
    findCategory(page?.X_URL, menuItems?.items[0]?.items, menuItems?.items[0]);
  };

  const toggleBack = () => {
    if (backButton === "Produkty") {
      dispatch({
        type: SLIDE_MENU,
        payload: {
          menuItems: menuItems?.items[0]?.items,
          backButton: "",
          showBackButton: "none",
        },
      });
    } else {
      for (const element of menuItems?.items[0]?.items) {
        if (element?.X_fullName_SK === backButton) {
          dispatch({
            type: SLIDE_MENU,
            payload: {
              menuItems: menuItems?.items[0]?.items,
              backButton: "Produkty",
              showBackButton: "",
            },
          });
          break;
        } else {
          findCategory(
            parentItem?.X_URL,
            menuItems?.items[0]?.items,
            menuItems?.items[0]
          );
        }
      }
    }
  };

  return (
    <>
      <Box position={"absolute"} top={0} width={"100%"} key={"slideMenuList"}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            px={"20px"}
            py={"10px"}
          >
            <Box
              key={"back"}
              width={"100%"}
              alignItems={"center"}
              my={"auto"}
              onClick={() => toggleBack()}
              sx={{
                display: showBackButton !== "none" ? "flex" : showBackButton,
                "&:hover": {
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                },
              }}
            >
              <NavigateBeforeIcon />
              <Typography variant="h7" ml={1}>
                {backButton}
              </Typography>
            </Box>
          </Box>
          <IconButton
            onClick={closeMenu}
            sx={{
              mr: 4,
              p: 1.5,
              my: "10px",
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <Divider />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        mt={"68px"}
        sx={{ overflowY: "auto" }}
      >
        {showBackButton !== "none"
          ? displayMenuItems?.map((page, index) => (
              <Box
                key={page.X_fullName}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                pl={"60px"}
                pr={"45px"}
                py={"15px"}
                sx={{
                  "&:hover": {
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  },
                }}
                onClick={() => togglePages(page, index)}
              >
                <Typography
                  variant="h7"
                  sx={{
                    fontWeight: "400",
                  }}
                >
                  {/* TODO EN name */}
                  {/* {page.X_fullName } */}
                  {page.X_fullName_SK}
                </Typography>
                {page["items"] ? <NavigateNextIcon /> : null}
              </Box>
            ))
          : defaultPages.map((page, index) => (
              <>
                {page?.X_fullName === "Produkty" ? (
                  <Box
                    key={index}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    px={"45px"}
                    py={"10px"}
                    sx={{
                      "&:hover": {
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleClick(page)}
                  >
                    {page?.X_fullName}
                    {page?.X_fullName === "Produkty" &&
                      (displayMenuItems[0]?.["items"] ? (
                        <NavigateNextIcon />
                      ) : null)}
                  </Box>
                ) : (
                  <HashLink
                    smooth
                    to={"/#" + page.X_URL}
                    key={page.X_fullName}
                    scroll={scrollWithOffset}
                    className="MenuLink"
                  >
                    <Box
                      key={index}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                      px={"45px"}
                      py={"10px"}
                      sx={{
                        "&:hover": {
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => handleClick(page)}
                    >
                      {page?.X_fullName}
                      {page?.X_fullName === "Produkty" &&
                        (displayMenuItems[0]?.["items"] ? (
                          <NavigateNextIcon />
                        ) : null)}
                    </Box>
                  </HashLink>
                )}
              </>
            ))}
      </Box>
    </>
  );
}
