export const FORM_FIELDS = "FORM_FIELDS";
export const DEFAULT_FORM_FIELDS = "DEFAULT_FORM_FIELDS";

//Navigation Bar
export const NAVIGATION_BAR = "NAVIGATION_BAR";
export const DEFAULT_NAVIGATION_BAR = "DEFAULT_NAVIGATION_BAR";

//Slide Menu
export const SLIDE_MENU = "SLIDE_MENU";
export const DEFAULT_SLIDE_MENU = "DEFAULT_SLIDE_MENU";

//apiData
export const API_DATA = "API_DATA";
export const DEFAULT_API_DATA = "DEFAULT_API_DATA";

//basket
export const BASKET = "BASKET";
export const DEFAULT_BASKET = "DEFAULT_BASKET";

// apiProductDetail
export const API_PRODUCT_DETAIL = "API_PRODUCT_DETAIL";
export const DEFAULT_API_PRODUCT_DETAIL = "DEFAULT_API_PRODUCT_DETAIL";

//dialog
export const DIALOG = "DIALOG";
export const DEFAULT_DIALOG = "DEFAULT_DIALOG";

//sessionInfo
export const SESSION_INFO = "SESSION_INFO";
export const DEFAULT_SESSION_INFO = "DEFAULT_SESSION_INFO";

//backdrop
export const BACKDROP = "BACKDROP";
export const DEFAULT_BACKDROP = "DEFAULT_BACKDROP";

//snackBarAlert
export const SNACKBAR_ALERT = "SNACKBAR_ALERT";
export const DEFAULT_SNACKBAR_ALERT = "DEFAULT_SNACKBAR_ALERT";

//category
export const CATEGORY = "CATEGORY";
export const DEFAULT_CATEGORY = "DEFAULT_CATEGORY";

//productMenu
export const PRODUCT_MENU = "PRODUCT_MENU";
export const DEFAULT_PRODUCT_MENU = "DEFAULT_PRODUCT_MENU";

//numberOfProducts
export const NUMBER_OF_PRODUCTS = "NUMBER_OF_PRODUCTS";
export const DEFAULT_NUMBER_OF_PRODUCTS = "DEFAULT_NUMBER_OF_PRODUCTS";

//search
export const SEARCH = "SEARCH";
export const DEFAULT_SEARCH = "DEFAULT_SEARCH";

//productPiece
export const PRODUCT_PIECE = "PRODUCT_PIECE";
export const DEFAULT_PRODUCT_PIECE = "DEFAULT_PRODUCT_PIECE";

//dashboard
export const DASHBOARD = "DASHBOARD";
export const DEFAULT_DASHBOARD = "DEFAULT_DASHBOARD";

//loginInfo
export const FIRM_INFO = "FIRM_INFO";
export const DEFAULT_FIRM_INFO = "DEFAULT_FIRM_INFO";

//finstat
export const FINSTAT = "FINSTAT";
export const DEFAULT_FINSTAT = "DEFAULT_FINSTAT";

//checkout
export const CHECKOUT = "CHECKOUT";
export const DEFAULT_CHECKOUT = "DEFAULT_CHECKOUT";

// orders
export const ORDERS = "ORDERS";
export const DEFAULT_ORDERS = "DEFAULT_ORDERS";

// payGateway
export const PAYGATEWAY = "PAYGATEWAY";
export const DEFAULT_PAYGATEWAY = "DEFAULT_PAYGATEWAY";

// authenticate
export const AUTHENTICATE = "AUTHENTICATE";
export const DEFAULT_AUTHENTICATE = "DEFAULT_AUTHENTICATE";

// testing
export const TESTING = "TESTING";
export const DEFAULT_TESTING = "DEFAULT_TESTING";

// productTechData
export const PRODUCTTECHDATA = "PRODUCTTECHDATA";
export const DEFAULT_PRODUCTTECHDATA = "DEFAULT_PRODUCTTECHDATA";

// cookieDialog
export const COOKIEDIALOG = "COOKIEDIALOG";
export const DEFAULT_COOKIEDIALOG = "DEFAULT_COOKIEDIALOG";

// displayProducts
export const DISPLAYPRODUCTS = "DISPLAYPRODUCTS";
export const DEFAULT_DISPLAYPRODUCTS = "DEFAULT_DISPLAYPRODUCTS";

// index
export const INDEX = "INDEX";
export const DEFAULT_INDEX = "DEFAULT_INDEX";

// delivery
export const DELIVERY = "DELIVERY";
export const DEFAULT_DELIVERY = "DEFAULT_DELIVERY";

// currentUrl
export const CURRENT_URL = "CURRENT_URL";
export const DEFAULT_CURRENT_URL = "DEFAULT_CURRENT_URL";

// newProducts
export const NEW_PRODUCTS = "NEW_PRODUCTS";
export const DEFAULT_NEW_PRODUCTS = "DEFAULT_NEW_PRODUCTS";

// saleProducts
export const SALE_PRODUCTS = "SALE_PRODUCTS";
export const DEFAULT_SALE_PRODUCTS = "DEFAULT_SALE_PRODUCTS";

// actionProducts
export const ACTION_PRODUCTS = "ACTION_PRODUCTS";
export const DEFAULT_ACTION_PRODUCTS = "DEFAULT_ACTION_PRODUCTS";
