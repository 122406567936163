import "./NavAppBar.css";

import {
  DEV_URL,
  FOOTER_LINKS,
  ROUTE_URLS,
  USEFUL_LINKS,
} from "../config/constants";
import { Divider, Grid, IconButton, Stack, Tooltip } from "@mui/material";

import { ReactComponent as ApplePay } from "../logos/applePay.svg";
import Box from "@mui/material/Box";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import Container from "@mui/material/Container";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { ReactComponent as GooglePay } from "../logos/GooglePay_mark_800_gray.svg";
import { HashLink } from "react-router-hash-link";
import Link from "@mui/material/Link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LogoContainer from "../components/logo/LogoContainer";
import Maestro from "../logos/logo_maestro.gif";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import Mc from "../logos/logo_mc.gif";
import { MegaMenuObj } from "../components/menu/megaMenu_fenixsk";
import React from "react";
import Typography from "@mui/material/Typography";
import Visa from "../logos/logo_visa.gif";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import { MegaMenuObj } from "../config/MenuItems";

// TODO

// const pages = MegaMenuObj.items[0].items;

const pages = MegaMenuObj;

function Copyright() {
  return (
    <Typography
      sx={{ mt: 4 }}
      variant="body2"
      color="text.secondary"
      textAlign={"center"}
    >
      {"Copyright © "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href={ROUTE_URLS.PAGE_LAYOUT} underline="none">
        FENIX SK, s.r.o.
      </Link>
      {" & "}
      <a href="https://antol.pro/">
        <img
          src="https://antol.pro/wp-content/uploads/2024/12/tvorba-web-stranok-kosice-antol2.png"
          style={{ verticalAlign: "text-bottom", width: "18px" }}
          alt="tvorba web stranok"
        />
      </a>
    </Typography>
  );
}

export default function Footer({ sx }) {
  const navigate = useNavigate();
  const usefulLinks = USEFUL_LINKS;
  const footerLinks = FOOTER_LINKS;
  const appBarHeight = useSelector((state) => state?.navBarMenu?.height);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -appBarHeight;

    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const hangleClick = (page) => {
    navigate(page);
  };

  const style = {
    textDecoration: "none",
    color: "black",
  };

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          mt: "auto",
        }}
      >
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            backgroundColor: "#f1f2f2",
          }}
        >
          <Container
            maxWidth="xl"
            sx={{ mt: 2, display: "flex", flexGrow: "1" }}
          >
            <Grid container spacing={3}>
              <Grid item key={"logo"} md={4}>
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    flexGrow: "1",
                  }}
                >
                  <LogoContainer sx={{ maxWidth: "200px" }} />
                </Box>
                <Box sx={{ display: "flex", flexGrow: "1", mt: 1 }}>
                  <Tooltip sx={{ mr: 2 }}>
                    <LocationOnRoundedIcon fontSize="small" />
                  </Tooltip>
                  <Box>
                    <Typography>FENIX SK, s.r.o. </Typography>
                    <Typography> Smaragdová 47 </Typography>
                    <Typography> 040 11 Košice</Typography>
                    <Typography> IČO: 36207888</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexGrow: "1", mt: 1 }}>
                  <Tooltip sx={{ mr: 2 }}>
                    <MailRoundedIcon fontSize="small" />
                  </Tooltip>
                  <Typography>
                    <a href="mailto:obchod@fenixsk.sk" style={style}>
                      obchod@fenixsk.sk
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexGrow: "1", mt: 1 }}>
                  <Tooltip sx={{ mr: 2 }}>
                    <CallRoundedIcon fontSize="small" />
                  </Tooltip>
                  <Typography>
                    <a href="tel:+421 905 396 478" style={style}>
                      +421 905 396 478
                    </a>
                  </Typography>
                </Box>
              </Grid>

              <Grid item key={"links"} md={6}>
                <Box display={"flex"} flexDirection={"row"}>
                  <Box key={"Products"} sx={{ m: 2, mr: 2 }}>
                    <Typography fontWeight={700}>{"Produkty"}</Typography>
                    {pages.map((page) => (
                      <Box key={page?.X_fullName_SK} sx={{ mt: 1 }}>
                        <HashLink
                          smooth
                          to={"/" + page?.X_URL}
                          key={page?.X_URL}
                          scroll={scrollWithOffset}
                          className="FooterLink"
                        >
                          <Typography sx={{ fontSize: 14 }} textAlign={"left"}>
                            {page?.X_fullName_SK}
                          </Typography>
                        </HashLink>
                      </Box>
                    ))}
                  </Box>
                  <Box key={"UsefulLinks"} sx={{ m: 2 }}>
                    <Typography fontWeight={700}>{"Užitočné linky"}</Typography>
                    {usefulLinks.map((page) => (
                      <Box key={page?.label} sx={{ mt: 1 }}>
                        <HashLink
                          smooth
                          to={DEV_URL?.PROD_URL + "/" + page?.route}
                          key={page?.label}
                          scroll={scrollWithOffset}
                          className="FooterLink"
                        >
                          <Typography sx={{ fontSize: 14 }}>
                            {page?.label}
                          </Typography>
                        </HashLink>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Box sx={{ mt: 5, mr: 3 }}>
                <Typography mb={1} fontWeight={500}>
                  Sledujte nás:
                </Typography>
                <Tooltip sx={{ mr: 1 }} title="Facebook">
                  <IconButton
                    size="lamediumrge"
                    onClick={() =>
                      window.open("https://www.facebook.com/fenixsk.sk")
                    }
                    sx={{ color: "black" }}
                  >
                    <FacebookRoundedIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Tooltip sx={{ mr: 1 }} title="LinkedIn">
                  <IconButton
                    size="lamediumrge"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/fenix-sk-s.r.o."
                      )
                    }
                    sx={{ color: "black" }}
                  >
                    <LinkedInIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Container>
          {/* TODO */}
          <Container maxWidth="md" sx={{ mt: 8, textAlign: "center" }}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              Možnosti online platby
            </Typography>
            <Stack
              my={2}
              ml={2}
              direction="row"
              spacing={2}
              flexWrap="wrap"
              useFlexGap
              justifyContent={"center"}
            >
              <Box
                component="img"
                sx={{
                  height: 23,
                  width: 36,
                }}
                alt="Maestro"
                src={Maestro}
              />
              <Box
                component="img"
                sx={{
                  height: 23,
                  width: 35,
                }}
                alt="MasterCard"
                src={Mc}
              />
              <Box
                component="img"
                sx={{
                  height: 22,
                  width: 35,
                }}
                alt="Visa"
                src={Visa}
              />
              <GooglePay width="35" height="23" />
              <ApplePay width="35" height="23" />
            </Stack>
          </Container>
          <Container maxWidth="md" sx={{ mt: 8 }}>
            <Divider sx={{ width: 600, ml: 18 }} orientation="horizontal" />
            <Box sx={{ my: 0.5 }} textAlign="center">
              {footerLinks.map((page) => (
                <Link
                  component="button"
                  onClick={() => hangleClick(page.route)}
                  underline="none"
                  color={"inherit"}
                  key={page.label}
                >
                  <Typography sx={{ fontSize: 12, ml: 4 }}>
                    {page.label}
                  </Typography>
                </Link>
              ))}
            </Box>
            <Divider sx={{ width: 600, ml: 18 }} orientation="horizontal" />

            <Copyright />
          </Container>
        </Box>
      </Box>

      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          mt: "auto",
        }}
      >
        <Box
          component="footer"
          sx={{
            pt: "50px",
            pb: 3,
            px: 2,
            mt: "auto",
            backgroundColor: "#f1f2f2",
          }}
        >
          <Container sx={{ display: "column", flexGrow: "1" }}>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                flexGrow: "1",
              }}
            >
              <LogoContainer sx={{ maxWidth: "200px" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: "1",
                mt: 1,
              }}
            >
              <Tooltip sx={{ mr: 2 }}>
                <LocationOnRoundedIcon fontSize="small" />
              </Tooltip>
              <Box>
                <Typography>FENIX SK, s.r.o. </Typography>
                <Typography> Smaragdová 47 </Typography>
                <Typography> 040 11 Košice</Typography>
                <Typography> IČO: 36207888</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: "1",
                mt: 1,
              }}
            >
              <Tooltip sx={{ mr: 2 }}>
                <MailRoundedIcon fontSize="small" />
              </Tooltip>
              <Typography>
                <a href="mailto:obchod@fenixsk.sk" style={style}>
                  obchod@fenixsk.sk
                </a>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: "1",
                mt: 1,

                mb: 5,
              }}
            >
              <Tooltip sx={{ mr: 2 }}>
                <CallRoundedIcon fontSize="small" />
              </Tooltip>
              <Typography>
                <a href="tel:+421 905 396 478" style={style}>
                  +421 905 396 478
                </a>
              </Typography>
            </Box>
          </Container>
          <Container sx={{ display: "column", flexGrow: "1", mt: 3 }}>
            <Box key={"Products"} sx={{ mt: 4 }}>
              <Typography fontWeight={700}>{"Produkty"}</Typography>
              {pages.map((page) => (
                <Box key={page?.X_fullName_SK} sx={{ mt: 1 }}>
                  <HashLink
                    smooth
                    to={"/" + page?.X_URL}
                    key={page?.X_URL}
                    scroll={scrollWithOffset}
                    className="FooterLink"
                  >
                    <Typography sx={{ fontSize: 14 }}>
                      {page?.X_fullName_SK}
                    </Typography>
                  </HashLink>
                </Box>
              ))}
            </Box>
            <Box key={"UsefulLinks"} sx={{ mt: 4 }}>
              <Typography fontWeight={700}>{"Užitočné linky"}</Typography>
              {usefulLinks.map((page) => (
                <Box key={page?.label} sx={{ mt: 1 }}>
                  <HashLink
                    smooth
                    to={DEV_URL?.PROD_URL + "/" + page?.route}
                    key={page?.label}
                    scroll={scrollWithOffset}
                    className="FooterLink"
                  >
                    <Typography sx={{ fontSize: 14 }}>{page?.label}</Typography>
                  </HashLink>
                </Box>
              ))}
            </Box>
          </Container>
          <Box sx={{ mt: 7 }} textAlign={"center"}>
            <Typography mb={1} fontWeight={500}>
              Sledujte nás:
            </Typography>
            <Tooltip sx={{ mr: 1 }} title="Facebook">
              <IconButton
                size="lamediumrge"
                onClick={() =>
                  window.open("https://www.facebook.com/fenixsk.sk")
                }
                sx={{ color: "black" }}
              >
                <FacebookRoundedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip sx={{ mr: 1 }} title="LinkedIn">
              <IconButton
                size="lamediumrge"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/fenix-sk-s.r.o."
                  )
                }
                sx={{ color: "black" }}
              >
                <LinkedInIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
          {/* TODO */}
          <Container maxWidth="md" sx={{ mt: 8, textAlign: "center" }}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              Možnosti online platby
            </Typography>
            <Stack
              my={2}
              ml={2}
              direction="row"
              spacing={2}
              flexWrap="wrap"
              useFlexGap
              justifyContent={"center"}
            >
              <Box
                component="img"
                sx={{
                  height: 23,
                  width: 36,
                }}
                alt="Maestro"
                src={Maestro}
              />
              <Box
                component="img"
                sx={{
                  height: 23,
                  width: 35,
                }}
                alt="MasterCard"
                src={Mc}
              />
              <Box
                component="img"
                sx={{
                  height: 22,
                  width: 35,
                }}
                alt="Visa"
                src={Visa}
              />
              <GooglePay width="35" height="23" />
              <ApplePay width="35" height="23" />
            </Stack>
          </Container>
          <Container maxWidth="md" sx={{ mt: 5 }}>
            <Divider sx={{ maxwidth: 550 }} orientation="horizontal" />
            <Box sx={{ my: 0.5 }} textAlign="center">
              {footerLinks.map((page) => (
                <Link
                  component="button"
                  onClick={() => hangleClick(page.route)}
                  underline="none"
                  color={"inherit"}
                  key={page.label}
                >
                  <Typography sx={{ fontSize: 12, ml: 4 }}>
                    {page.label}
                  </Typography>
                </Link>
              ))}
            </Box>
            <Divider sx={{ maxwidth: 550 }} orientation="horizontal" />
            <Copyright />
          </Container>
        </Box>
      </Box>
    </>
  );
}
