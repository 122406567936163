import { Box, Typography } from "@mui/material";

export default function SaleTag() {
  return (
    <Box
      sx={{
        borderRadius: 5,
        backgroundColor: "#b94272",
        px: 2,
      }}
    >
      <Typography sx={{ color: "white", textAlign: "center" }}>
        Výpredaj
      </Typography>
    </Box>
  );
}
