import { Box, Typography } from "@mui/material";

export default function NewProductTag() {
  return (
    <Box
      sx={{
        borderRadius: 5,
        backgroundColor: "#00838f",
        px: 2,
      }}
    >
      <Typography sx={{ color: "white", textAlign: "center" }}>
        Novinka
      </Typography>
    </Box>
  );
}
