import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function newProducts(state = initialState.newProducts, action) {
  switch (action.type) {
    case types.NEW_PRODUCTS:
      return { ...state, ...action.payload };
    case types.DEFAULT_NEW_PRODUCTS:
      return initialState.newProducts;
    default:
      return state;
  }
}
