import { Stack } from "@mui/material";

export default function TagArea({ children, props, card, detail }) {
  return (
    <Stack
      sx={{
        position: detail ? "relative" : "absolute",
        top: 24,
        right: 24,
        mb: 2,
      }}
      direction={card ? "column" : "row"}
      spacing={1}
      flexWrap="wrap"
      useFlexGap
      justifyContent={"right"}
    >
      {children}
    </Stack>
  );
}
