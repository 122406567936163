export const MegaMenuObj = [
  {
    id: "HFI0000101",
    parent_id: "null",
    text: "PhoenixContact",
    X_fullName: "PhoenixContact",
    X_fullName_SK: "PhoenixKontakt",
    X_URL: "",
    FULLPATH: "PhoenixContact",
    X_Description: "",
    X_Description_SK: "",
    items: [
      {
        id: "IFI0000101",
        parent_id: "HFI0000101",
        text: "Electronics housing",
        X_fullName: "Electronics housing",
        X_fullName_SK: "Puzdro elektroniky",
        X_URL: "electronics-housing",
        FULLPATH: "PhoenixContact\\Electronics housing",
        X_Description:
          "Packaging as an all-round solution. Versatile electronics housings from Phoenix Contact transform your PCB into a compact device.",
        X_Description_SK:
          "Balenie ako v\u0161estrann\u00e9 rie\u0161enie. V\u0161estrann\u00e9 kryty elektroniky od spolo\u010dnosti Phoenix Contact premenia va\u0161u PCB na kompaktn\u00e9 zariadenie.",
        items: [
          {
            id: "JFI0000101",
            parent_id: "IFI0000101",
            text: "Field enclosure",
            X_fullName: "Field enclosure",
            X_fullName_SK: "Po\u013en\u00fd kryt",
            X_URL: "field-enclosure",
            FULLPATH: "PhoenixContact\\Electronics housing\\Field enclosure",
            X_Description:
              "IP-protected electronics housings provide ideal solutions for use in field applications.",
            X_Description_SK:
              "Kryty elektroniky chr\u00e1nen\u00e9 IP poskytuj\u00fa ide\u00e1lne rie\u0161enia pre pou\u017eitie v oblastn\u00fdch aplik\u00e1ci\u00e1ch.",
            items: [
              {
                id: "LFI0000101",
                parent_id: "JFI0000101",
                text: "DCS display carriers",
                X_fullName: "DCS display carriers",
                X_fullName_SK: "Nosi\u010de displeja DCS",
                X_URL: "dcs-display-carriers",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\DCS display carriers",
                X_Description:
                  "DCS series display carriers protect displays used in the field.",
                X_Description_SK: "",
              },
              {
                id: "NFI0000101",
                parent_id: "JFI0000101",
                text: "ECS outdoor housings",
                X_fullName: "ECS outdoor housings",
                X_fullName_SK: "Vonkaj\u0161ie kryty ECS",
                X_URL: "ecs-outdoor-housings",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\ECS outdoor housings",
                X_Description:
                  "Electronics housings from the ECS series provide maximum protection for electronics in harsh areas of application.",
                X_Description_SK: "",
              },
              {
                id: "PFI0000101",
                parent_id: "JFI0000101",
                text: "Profile housing HC-ALU",
                X_fullName: "Profile housing HC-ALU",
                X_fullName_SK: "Profilov\u00e9 puzdro HC-ALU",
                X_URL: "profile-housing-hc-alu",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\Profile housing HC-ALU",
                X_Description:
                  "HC-ALU aluminum profile housings protect electronics from splash water, as well as thermal and mechanical influences in the field.",
                X_Description_SK: "",
              },
              {
                id: "RFI0000101",
                parent_id: "JFI0000101",
                text: "Handheld housing HCS",
                X_fullName: "Handheld housing HCS",
                X_fullName_SK: "Ru\u010dn\u00e9 puzdro HCS",
                X_URL: "handheld-housing-hcs",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\Handheld housing HCS",
                X_Description:
                  "The electronics housings from the HCS series are ideally suited for mobile operator panels.",
                X_Description_SK: "",
              },
              {
                id: "TFI0000101",
                parent_id: "JFI0000101",
                text: "UCS universal housings",
                X_fullName: "UCS universal housings",
                X_fullName_SK: "Univerz\u00e1lne puzdr\u00e1 UCS",
                X_URL: "usc-universal-housings",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\UCS universal housings",
                X_Description:
                  "Universal housings from the UCS series are the ideal solution for embedded systems.",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "UFI0000101",
            parent_id: "IFI0000101",
            text: "DIN rail housing",
            X_fullName: "DIN rail housing",
            X_fullName_SK: "Puzdro na li\u0161tu DIN",
            X_URL: "din-rail-housing",
            FULLPATH: "PhoenixContact\\Electronics housing\\DIN rail housing",
            X_Description:
              "From basic housings and multifunctional housings to products for Raspberry Pi computers: our DIN rail housings offer optimum solutions for your electronics.",
            X_Description_SK:
              "Od z\u00e1kladn\u00fdch krytov a multifunk\u010dn\u00fdch krytov a\u017e po produkty pre po\u010d\u00edta\u010de Raspberry Pi: na\u0161e kryty na li\u0161tu DIN pon\u00fakaj\u00fa optim\u00e1lne rie\u0161enia pre va\u0161u elektroniku.",
            items: [
              {
                id: "VFI0000101",
                parent_id: "UFI0000101",
                text: "Development kits",
                X_fullName: "Development kits",
                X_fullName_SK: "V\u00fdvojov\u00e9 s\u00fapravy",
                X_URL: "development-kits",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits",
                X_Description:
                  "Mount THT PCB components such as diodes, fuses or resistors on the DIN rail using the development kits for the electronics housings.",
                X_Description_SK:
                  "Namontujte komponenty THT PCB, ako s\u00fa di\u00f3dy, poistky alebo odpory na li\u0161tu DIN pomocou v\u00fdvojov\u00fdch s\u00faprav pre kryty elektroniky.",
                items: [
                  {
                    id: "WFI0000101",
                    parent_id: "VFI0000101",
                    text: "BC development kit",
                    X_fullName: "BC development kit",
                    X_fullName_SK: "V\u00fdvojov\u00e1 s\u00faprava BC",
                    X_URL: "bc-development-kit",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\BC development kit",
                    X_Description:
                      "Integrate your electronics for building automation into modern BC housing.",
                    X_Description_SK:
                      "Integrujte svoju elektroniku pre automatiz\u00e1ciu budov do modern\u00e9ho krytu BC.",
                  },
                  {
                    id: "XFI0000101",
                    parent_id: "VFI0000101",
                    text: "EH development kit",
                    X_fullName: "EH development kit",
                    X_fullName_SK: "V\u00fdvojov\u00e1 s\u00faprava EH",
                    X_URL: "eh-development-kit",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\EH development kit",
                    X_Description:
                      "Develop and design application-specific, compact device solutions with the EH development kit.",
                    X_Description_SK:
                      "Vyv\u00edjajte a navrhujte kompaktn\u00e9 rie\u0161enia zariaden\u00ed \u0161pecifick\u00e9 pre aplik\u00e1ciu pomocou v\u00fdvojovej s\u00fapravy EH.",
                  },
                  {
                    id: "YFI0000101",
                    parent_id: "VFI0000101",
                    text: "ME-IO development kit",
                    X_fullName: "ME-IO development kit",
                    X_fullName_SK: "V\u00fdvojov\u00e1 s\u00faprava ME-IO",
                    X_URL: "me-io-development-kit",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\ME-IO development kit",
                    X_Description:
                      "The development kit from the ME-IO series can be used to develop a control and I/O system with a wide range of signal inputs and outputs.",
                    X_Description_SK:
                      "V\u00fdvojov\u00fd kit zo s\u00e9rie ME-IO je mo\u017en\u00e9 pou\u017ei\u0165 na v\u00fdvoj riadiaceho a I/O syst\u00e9mu so \u0161irokou \u0161k\u00e1lou sign\u00e1lov\u00fdch vstupov a v\u00fdstupov.",
                  },
                  {
                    id: "ZFI0000101",
                    parent_id: "VFI0000101",
                    text: "ME-MAX development kit",
                    X_fullName: "ME-MAX development kit",
                    X_fullName_SK: "V\u00fdvojov\u00e1 s\u00faprava ME-MAX",
                    X_URL: "me-max-development-kit",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\ME-MAX development kit",
                    X_Description:
                      "The ME-MAX development kit is ideal when the electronics need to be configured in a narrow space, but a large assembly area is required on the PCB.",
                    X_Description_SK:
                      "V\u00fdvojov\u00e1 s\u00faprava ME-MAX je ide\u00e1lna, ke\u010f je potrebn\u00e9 konfigurova\u0165 elektroniku v \u00fazkom priestore, ale na DPS je potrebn\u00e1 ve\u013ek\u00e1 mont\u00e1\u017ena plocha.",
                  },
                  {
                    id: "0GI0000101",
                    parent_id: "VFI0000101",
                    text: "ME-PLC development kit",
                    X_fullName: "ME-PLC development kit",
                    X_fullName_SK: "V\u00fdvojov\u00e1 s\u00faprava ME-PLC",
                    X_URL: "me-plc-development-kit",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\ME-PLC development kit",
                    X_Description:
                      "The ME-PLC development kit offers maximum flexibility, lots of space for the electronics, and convenient connection options.",
                    X_Description_SK:
                      "V\u00fdvojov\u00fd kit ME-PLC pon\u00faka maxim\u00e1lnu flexibilitu, ve\u013ea miesta pre elektroniku a pohodln\u00e9 mo\u017enosti pripojenia.",
                  },
                  {
                    id: "1GI0000101",
                    parent_id: "VFI0000101",
                    text: "UM-BASIC development kit",
                    X_fullName: "UM-BASIC development kit",
                    X_fullName_SK: "V\u00fdvojov\u00e1 sada UM-BASIC",
                    X_URL: "um-basic-development-kit",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\UM-BASIC development kit",
                    X_Description:
                      "The UM-BASIC development kit is available for electronics with highly structured electronics components. The profile housing can be mounted quickly and easily by inserting the electronics and snapping on the side parts.",
                    X_Description_SK:
                      "V\u00fdvojov\u00fd kit UM-BASIC je dostupn\u00fd pre elektroniku s vysoko \u0161trukt\u00farovan\u00fdmi elektronick\u00fdmi komponentmi. Profilov\u00e9 puzdro mo\u017eno r\u00fdchlo a jednoducho namontova\u0165 vlo\u017een\u00edm elektroniky a zaklapnut\u00edm bo\u010dn\u00fdch dielov.",
                  },
                  {
                    id: "2GI0000101",
                    parent_id: "VFI0000101",
                    text: "RPI-BC development kit",
                    X_fullName: "RPI-BC development kit",
                    X_fullName_SK: "V\u00fdvojov\u00e1 s\u00faprava RPI-BC",
                    X_URL: "rpi-bc-development-kit",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\RPI-BC development kit",
                    X_Description:
                      "The RPI-BC development kit allows you to build your own minicomputer quickly and easily. The DIN rail housing from Phoenix Contact has been designed specifically to house Raspberry Pi PCBs.",
                    X_Description_SK:
                      "V\u00fdvojov\u00e1 s\u00faprava RPI-BC v\u00e1m umo\u017en\u00ed r\u00fdchlo a jednoducho zostavi\u0165 vlastn\u00fd minipo\u010d\u00edta\u010d. Kryt na li\u0161tu DIN od spolo\u010dnosti Phoenix Contact bol navrhnut\u00fd \u0161peci\u00e1lne na umiestnenie dosiek plo\u0161n\u00fdch spojov Raspberry Pi.",
                  },
                ],
              },
              {
                id: "4GI0000101",
                parent_id: "UFI0000101",
                text: "Modular housing BC",
                X_fullName: "Modular housing BC",
                X_fullName_SK: "Modul\u00e1rne p\u00fazdra BC",
                X_URL: "modular-housing-bc",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Modular housing BC",
                X_Description: "Electronics enclosure for building automation",
                X_Description_SK: "",
              },
              {
                id: "6GI0000101",
                parent_id: "UFI0000101",
                text: "Modular housing ICS",
                X_fullName: "Modular housing ICS",
                X_fullName_SK: "Modul\u00e1rne puzdro ICS",
                X_URL: "modular-housing-ics",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Modular housing ICS",
                X_Description:
                  "ICS modular electronics housings are the basis for IoT devices of tomorrow.",
                X_Description_SK: "",
              },
              {
                id: "8GI0000101",
                parent_id: "UFI0000101",
                text: "Modular housing ME",
                X_fullName: "Modular housing ME",
                X_fullName_SK: "Modul\u00e1rne p\u00fazdra ME",
                X_URL: "modular-housing-me",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Modular housing ME",
                X_Description:
                  "Modular housing system in easy-to-install cup shape",
                X_Description_SK: "",
              },
              {
                id: "AGI0000101",
                parent_id: "UFI0000101",
                text: "Modular housing ME-MAX",
                X_fullName: "Modular housing ME-MAX",
                X_fullName_SK: "Modul\u00e1rne puzdro ME-MAX",
                X_URL: "modular-housing-me-max",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Modular housing ME-MAX",
                X_Description:
                  "Modular housing with plenty of space in compact dimensions",
                X_Description_SK: "",
              },
              {
                id: "CGI0000101",
                parent_id: "UFI0000101",
                text: "Multifunctional housings ME-IO",
                X_fullName: "Multifunctional housings ME-IO",
                X_fullName_SK: "Multifunk\u010dn\u00e9 puzdr\u00e1 ME-IO",
                X_URL: "multifunctional-housings-me-io",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Multifunctional housings ME-IO",
                X_Description:
                  "Compact housing system with high-pos. front connection technology",
                X_Description_SK: "",
              },
              {
                id: "EGI0000101",
                parent_id: "UFI0000101",
                text: "Multifunctional housings ME-PLC",
                X_fullName: "Multifunctional housings ME-PLC",
                X_fullName_SK: "Multifunk\u010dn\u00e9 puzdr\u00e1 ME-PLC",
                X_URL: "multifunctional-housings",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Multifunctional housings ME-PLC",
                X_Description: "The housing system for complex controllers",
                X_Description_SK: "",
              },
              {
                id: "GGI0000101",
                parent_id: "UFI0000101",
                text: "Profile housing UM-BASIC",
                X_fullName: "Profile housing UM-BASIC",
                X_fullName_SK: "Profilov\u00e9 puzdro UM-BASIC",
                X_URL: "um-basic-housing",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Profile housing UM-BASIC",
                X_Description:
                  "Modular press-drawn section housings made from PVC, heat resistant up to 50\u00b0C. For tool-free device mounting.",
                X_Description_SK: "",
              },
              {
                id: "IGI0000101",
                parent_id: "UFI0000101",
                text: "Profile housing UM-PRO",
                X_fullName: "Profile housing UM-PRO",
                X_fullName_SK: "Profilov\u00e9 puzdro UM-PRO",
                X_URL: "profile-housing-um-pro",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Profile housing UM-PRO",
                X_Description:
                  "Flexible housing systems for fast installation and special applications.",
                X_Description_SK: "",
              },
              {
                id: "KGI0000101",
                parent_id: "UFI0000101",
                text: "Profile housing UM-ALU",
                X_fullName: "Profile housing UM-ALU",
                X_fullName_SK: "Profil b\u00fdvania UM-ALU",
                X_URL: "profile-housing-um-alu",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Profile housing UM-ALU",
                X_Description:
                  "Panel mounting bases made from aluminum, for use under thermal or electromagnetic loads.",
                X_Description_SK: "",
              },
              {
                id: "MGI0000101",
                parent_id: "UFI0000101",
                text: "Basic housing EH",
                X_fullName: "Basic housing EH",
                X_fullName_SK: "Z\u00e1kladn\u00e9 b\u00fdvanie EH",
                X_URL: "basic-housing-eh",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Basic housing EH",
                X_Description:
                  "EH: Universal housing system with integrated connection technology",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "NGI0000101",
            parent_id: "IFI0000101",
            text: "HMI components and accessories",
            X_fullName: "HMI components and accessories",
            X_fullName_SK: "Komponenty a pr\u00edslu\u0161enstvo HMI",
            X_URL: "hmi-components-accessories",
            FULLPATH:
              "PhoenixContact\\Electronics housing\\HMI components and accessories",
            X_Description:
              "HMI components and accessories: a wide range of accessories for maximum functionality",
            X_Description_SK:
              "HMI komponenty a pr\u00edslu\u0161enstvo: \u0161irok\u00fd sortiment pr\u00edslu\u0161enstva pre maxim\u00e1lnu funk\u010dnos\u0165",
            items: [
              {
                id: "PGI0000101",
                parent_id: "NGI0000101",
                text: "DIN rail adapter UTA",
                X_fullName: "DIN rail adapter UTA",
                X_fullName_SK: "Adapt\u00e9r na DIN li\u0161tu UTA",
                X_URL: "din-rail-adapter-uta",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\HMI components and accessories\\DIN rail adapter UTA",
                X_Description:
                  "Adapter plate made of plastic for securely fixing devices on standard DIN rails.",
                X_Description_SK: "",
              },
              {
                id: "QGI0000101",
                parent_id: "NGI0000101",
                text: "Displays",
                X_fullName: "Displays",
                X_fullName_SK: "Zobrazuje",
                X_URL: "displays",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\HMI components and accessories\\Displays",
                X_Description:
                  "Displays with screen diagonals of 2.4 to 15 inches for use in mobile and static electronics housings.",
                X_Description_SK:
                  "Displeje s uhloprie\u010dkou 2,4 a\u017e 15 palcov na pou\u017eitie v krytoch mobilnej a statickej elektroniky.",
              },
              {
                id: "RGI0000101",
                parent_id: "NGI0000101",
                text: "Membrane keypads",
                X_fullName: "Membrane keypads",
                X_fullName_SK: "Membr\u00e1nov\u00e9 kl\u00e1vesnice",
                X_URL: "membrane-keypads",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\HMI components and accessories\\Membrane keypads",
                X_Description:
                  "Membrane keypads for time-saving integration into electronics housings.",
                X_Description_SK:
                  "Membr\u00e1nov\u00e9 kl\u00e1vesnice pre \u010dasovo \u00fasporn\u00fa integr\u00e1ciu do krytov elektroniky.",
              },
              {
                id: "SGI0000101",
                parent_id: "NGI0000101",
                text: "Fiber optic",
                X_fullName: "Fiber optic",
                X_fullName_SK: "Optick\u00e9 vl\u00e1kno",
                X_URL: "fiber-optic",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\HMI components and accessories\\Fiber optic",
                X_Description:
                  "Light guides for space-saving implementation of status indicators.",
                X_Description_SK:
                  "Svetlovody pre priestorovo \u00fasporn\u00fa implement\u00e1ciu stavov\u00fdch indik\u00e1torov.",
              },
            ],
          },
        ],
      },
      {
        id: "TGI0000101",
        parent_id: "HFI0000101",
        text: "Electronic switchgear and motor control",
        X_fullName: "Electronic switchgear and motor control",
        X_fullName_SK:
          "Elektronick\u00e9 rozv\u00e1dza\u010de a riadenie motorov",
        X_URL: "slectronic-switchgear-and-motor",
        FULLPATH: "PhoenixContact\\Electronic switchgear and motor control",
        X_Description:
          "Do not take any risks: protect your motors and systems with durable motor controls and switching devices.",
        X_Description_SK:
          "Neriskujte: chr\u00e1\u0148te svoje motory a syst\u00e9my odoln\u00fdmi ovl\u00e1dac\u00edmi prvkami motora a sp\u00ednac\u00edmi zariadeniami.",
        items: [
          {
            id: "UGI0000101",
            parent_id: "TGI0000101",
            text: "Motor starter",
            X_fullName: "Motor starter",
            X_fullName_SK: "Motorov\u00fd sp\u00fa\u0161\u0165a\u010d",
            X_URL: "motor-starter",
            FULLPATH:
              "PhoenixContact\\Electronic switchgear and motor control\\Motor starter",
            X_Description:
              "Hybrid motor starters consisting of direct and reversing starters available with a variety of functions such as emergency stop and motor protection.",
            X_Description_SK:
              "Hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov pozost\u00e1vaj\u00face z priamych a reverzn\u00fdch sp\u00fa\u0161\u0165a\u010dov, ktor\u00e9 s\u00fa k dispoz\u00edcii s r\u00f4znymi funkciami, ako je n\u00fadzov\u00e9 zastavenie a ochrana motora.",
            items: [
              {
                id: "VGI0000101",
                parent_id: "UGI0000101",
                text: "Hybrid motor starters \u2013 Stand-alone",
                X_fullName: "Hybrid motor starters \u2013 Stand-alone",
                X_fullName_SK:
                  "Hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov - samostatn\u00e9",
                X_URL: "hybrid-motor-starters-stand",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Stand-alone",
                X_Description:
                  "Hybrid motor starters consisting of direct and reversing starters available with a variety of functions such as emergency stop and motor protection.",
                X_Description_SK:
                  "Hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov pozost\u00e1vaj\u00face z priamych a reverzn\u00fdch sp\u00fa\u0161\u0165a\u010dov, ktor\u00e9 s\u00fa k dispoz\u00edcii s r\u00f4znymi funkciami, ako je n\u00fadzov\u00e9 zastavenie a ochrana motora.",
                items: [
                  {
                    id: "WGI0000101",
                    parent_id: "VGI0000101",
                    text: "Hybrid motor starters \u2013 Stand-alone",
                    X_fullName: "Hybrid motor starters \u2013 Stand-alone",
                    X_fullName_SK:
                      "Hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov - samostatn\u00e9",
                    X_URL: "hybrid-motor-starters-standalone",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Stand-alone\\Hybrid motor starters \u2013 Stand-alone",
                    X_Description:
                      "Switch motors safely and reliably with compact hybrid motor starters.",
                    X_Description_SK:
                      "Sp\u00ednajte motory bezpe\u010dne a spo\u013eahlivo pomocou kompaktn\u00fdch hybridn\u00fdch motorov\u00fdch \u0161tart\u00e9rov.",
                  },
                  {
                    id: "XGI0000101",
                    parent_id: "VGI0000101",
                    text: "Accessories for hybrid motor starters \u2013 Stand-alon",
                    X_fullName:
                      "Accessories for hybrid motor starters \u2013 Stand-alon",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo pre hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov - Stand-alon",
                    X_URL: "hybrid-motor-starters-acc",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Stand-alone\\Accessories for hybrid motor starters \u2013 Stand-alon",
                    X_Description:
                      "This versatile set of accessories for hybrid motor starters makes handling, service, and installation easier for you.",
                    X_Description_SK:
                      "T\u00e1to v\u0161estrann\u00e1 s\u00faprava pr\u00edslu\u0161enstva pre \u0161tart\u00e9ry hybridn\u00fdch motorov v\u00e1m u\u013eah\u010d\u00ed manipul\u00e1ciu, servis a in\u0161tal\u00e1ciu.",
                  },
                ],
              },
              {
                id: "YGI0000101",
                parent_id: "UGI0000101",
                text: "Hybrid motor starters \u2013 Modular",
                X_fullName: "Hybrid motor starters \u2013 Modular",
                X_fullName_SK:
                  "Hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov - modul\u00e1rne",
                X_URL: "hybrid-motor-starters-mod",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Modular",
                X_Description:
                  "CONTACTRON pro is the new product range from Phoenix Contact that offers simple safety integration and modular extension options. Based entirely on hybrid technology.",
                X_Description_SK:
                  "CONTACTRON pro je nov\u00fd rad produktov od spolo\u010dnosti Phoenix Contact, ktor\u00fd pon\u00faka jednoduch\u00fa integr\u00e1ciu bezpe\u010dnosti a modul\u00e1rne mo\u017enosti roz\u0161\u00edrenia. \u00daplne zalo\u017een\u00e9 na hybridnej technol\u00f3gii.",
                items: [
                  {
                    id: "ZGI0000101",
                    parent_id: "YGI0000101",
                    text: "Hybrid motor starters \u2013 Modular",
                    X_fullName: "Hybrid motor starters \u2013 Modular",
                    X_fullName_SK:
                      "Hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov - modul\u00e1rne",
                    X_URL: "hybrid-motor-starters-modular",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Modular\\Hybrid motor starters \u2013 Modular",
                    X_Description:
                      "CONTACTRON pro is the new product range from Phoenix Contact that offers simple safety integration and modular extension options.",
                    X_Description_SK:
                      "CONTACTRON pro je nov\u00fd rad produktov od spolo\u010dnosti Phoenix Contact, ktor\u00fd pon\u00faka jednoduch\u00fa integr\u00e1ciu bezpe\u010dnosti a modul\u00e1rne mo\u017enosti roz\u0161\u00edrenia.",
                  },
                  {
                    id: "0HI0000101",
                    parent_id: "YGI0000101",
                    text: "Accessories for hybrid motor starters \u2013 Modular",
                    X_fullName:
                      "Accessories for hybrid motor starters \u2013 Modular",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo pre hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov - Modular",
                    X_URL: "hybrid-motor-starters-mod-acc",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Modular\\Accessories for hybrid motor starters \u2013 Modular",
                    X_Description:
                      "This versatile set of accessories for hybrid motor starters makes handling, service, and installation easier for you.",
                    X_Description_SK:
                      "T\u00e1to v\u0161estrann\u00e1 s\u00faprava pr\u00edslu\u0161enstva pre \u0161tart\u00e9ry hybridn\u00fdch motorov v\u00e1m u\u013eah\u010d\u00ed manipul\u00e1ciu, servis a in\u0161tal\u00e1ciu.",
                  },
                ],
              },
              {
                id: "1HI0000101",
                parent_id: "UGI0000101",
                text: "Hybrid motor starters \u2013 Network-capable",
                X_fullName: "Hybrid motor starters \u2013 Network-capable",
                X_fullName_SK:
                  "Hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov - Sie\u0165ovo kompatibiln\u00e9",
                X_URL: "hybrid-motor-starters-net",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Network-capable",
                X_Description:
                  "Integration into fieldbus systems using the INTERFACE system connection. Transfer process data and network devices quickly with the Interface system and the IO-Link versions.",
                X_Description_SK:
                  "Integr\u00e1cia do zbernicov\u00fdch syst\u00e9mov pomocou syst\u00e9mov\u00e9ho pripojenia INTERFACE. R\u00fdchlo pren\u00e1\u0161ajte procesn\u00e9 d\u00e1ta a sie\u0165ov\u00e9 zariadenia pomocou syst\u00e9mu rozhrania a verzi\u00ed IO-Link.",
                items: [
                  {
                    id: "2HI0000101",
                    parent_id: "1HI0000101",
                    text: "Hybrid motor starters \u2013 Network-capable",
                    X_fullName: "Hybrid motor starters \u2013 Network-capable",
                    X_fullName_SK:
                      "Hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov - Sie\u0165ovo kompatibiln\u00e9",
                    X_URL: "hybrid-motor-starters-network",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Network-capable\\Hybrid motor starters \u2013 Network-capable",
                    X_Description:
                      "Integration into fieldbus systems is implemented using the INTERFACE system connection. Corresponding gateways are available for all common fieldbus systems. Transfer your process data easily and network your devices quickly.",
                    X_Description_SK:
                      "Integr\u00e1cia do zbernicov\u00fdch syst\u00e9mov sa realizuje pomocou syst\u00e9mov\u00e9ho pripojenia INTERFACE. Zodpovedaj\u00face br\u00e1ny s\u00fa k dispoz\u00edcii pre v\u0161etky be\u017en\u00e9 syst\u00e9my fieldbus. Jednoducho pren\u00e1\u0161ajte svoje procesn\u00e9 d\u00e1ta a r\u00fdchlo prepojte svoje zariadenia.",
                  },
                  {
                    id: "3HI0000101",
                    parent_id: "1HI0000101",
                    text: "Accessories for hybrid motor starters \u2013 Network-ca",
                    X_fullName:
                      "Accessories for hybrid motor starters \u2013 Network-ca",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo pre hybridn\u00e9 sp\u00fa\u0161\u0165a\u010de motorov - Network-ca",
                    X_URL: "net-hybrid-motor-starters-acc",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Network-capable\\Accessories for hybrid motor starters \u2013 Network-ca",
                    X_Description:
                      "This versatile set of accessories for network-ready hybrid motor starters makes handling, service, and installation easier for you.",
                    X_Description_SK:
                      "T\u00e1to v\u0161estrann\u00e1 s\u00faprava pr\u00edslu\u0161enstva pre \u0161tart\u00e9ry hybridn\u00fdch motorov pripraven\u00fdch na sie\u0165 v\u00e1m u\u013eah\u010d\u00ed manipul\u00e1ciu, servis a in\u0161tal\u00e1ciu.",
                  },
                ],
              },
            ],
          },
          {
            id: "4HI0000101",
            parent_id: "TGI0000101",
            text: "Motor and machine management",
            X_fullName: "Motor and machine management",
            X_fullName_SK: "Riadenie motorov a strojov",
            X_URL: "motor-and-machine-mngmt",
            FULLPATH:
              "PhoenixContact\\Electronic switchgear and motor control\\Motor and machine management",
            X_Description:
              "Motor manager for protecting motors and systems, Electronic machine management for monitoring motors and machines",
            X_Description_SK:
              "Mana\u017e\u00e9r motora na ochranu motorov a syst\u00e9mov, Elektronick\u00fd mana\u017ement strojov na monitorovanie motorov a strojov",
            items: [
              {
                id: "5HI0000101",
                parent_id: "4HI0000101",
                text: "Motor and machine management",
                X_fullName: "Motor and machine management",
                X_fullName_SK: "Riadenie motorov a strojov",
                X_URL: "motor-and-machine-management",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor and machine management\\Motor and machine management",
                X_Description:
                  "The motor manager combines overload and underload detection in a single device. In the event of an emergency, it protects the motor and shuts down the drive. Electronic machine management combines precise power measurement with the display and monito",
                X_Description_SK:
                  "Mana\u017e\u00e9r motora kombinuje detekciu pre\u0165a\u017eenia a nedostato\u010dn\u00e9ho za\u0165a\u017eenia v jednom zariaden\u00ed. V pr\u00edpade n\u00fadze ochr\u00e1ni motor a vypne pohon. Elektronick\u00e9 riadenie stroja kombinuje presn\u00e9 meranie v\u00fdkonu s displejom a monitorom",
              },
              {
                id: "6HI0000101",
                parent_id: "4HI0000101",
                text: "Accessories for motor and machine management",
                X_fullName: "Accessories for motor and machine management",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo pre riadenie motorov a strojov",
                X_URL: "machine-management-accessories",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor and machine management\\Accessories for motor and machine management",
                X_Description:
                  "This versatile set of accessories for motor and machine management makes handling, service, and installation easier for you.",
                X_Description_SK:
                  "T\u00e1to v\u0161estrann\u00e1 s\u00faprava pr\u00edslu\u0161enstva pre riadenie motora a stroja v\u00e1m u\u013eah\u010d\u00ed manipul\u00e1ciu, servis a in\u0161tal\u00e1ciu.",
              },
            ],
          },
          {
            id: "7HI0000101",
            parent_id: "TGI0000101",
            text: "Solid-state contactors",
            X_fullName: "Solid-state contactors",
            X_fullName_SK: "Polovodi\u010dov\u00e9 st\u00fdka\u010de",
            X_URL: "solid-state-contactors-main",
            FULLPATH:
              "PhoenixContact\\Electronic switchgear and motor control\\Solid-state contactors",
            X_Description:
              "Wear-free and frequent switching with robust single or three-phase solid-state contactors.",
            X_Description_SK:
              "\u010cast\u00e9 sp\u00ednanie bez opotrebovania s robustn\u00fdmi jednof\u00e1zov\u00fdmi alebo trojf\u00e1zov\u00fdmi polovodi\u010dov\u00fdmi st\u00fdka\u010dmi.",
            items: [
              {
                id: "8HI0000101",
                parent_id: "7HI0000101",
                text: "Solid-state contactors",
                X_fullName: "Solid-state contactors",
                X_fullName_SK: "Polovodi\u010dov\u00e9 st\u00fdka\u010de",
                X_URL: "solid-state-contactors",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Solid-state contactors\\Solid-state contactors",
                X_Description:
                  "Solid-state contactors as single or three-phase devices, also with reversing function depending on the type. With a practically unlimited service life, resistance to vibrations and harmful substances, they can be used anywhere.",
                X_Description_SK:
                  "Polovodi\u010dov\u00e9 st\u00fdka\u010de ako jednof\u00e1zov\u00e9 alebo trojf\u00e1zov\u00e9 zariadenia, pod\u013ea typu aj s reverznou funkciou. S prakticky neobmedzenou \u017eivotnos\u0165ou, odolnos\u0165ou vo\u010di vibr\u00e1ci\u00e1m a \u0161kodliv\u00fdm l\u00e1tkam ich mo\u017eno pou\u017ei\u0165 kdeko\u013evek.",
              },
              {
                id: "9HI0000101",
                parent_id: "7HI0000101",
                text: "Accessories for solid-state contactors",
                X_fullName: "Accessories for solid-state contactors",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo pre polovodi\u010dov\u00e9 st\u00fdka\u010de",
                X_URL: "solid-state-contactor-acc",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Solid-state contactors\\Accessories for solid-state contactors",
                X_Description:
                  "This versatile set of accessories for solid-state contactors makes handling, service, and installation easier for you.",
                X_Description_SK:
                  "T\u00e1to v\u0161estrann\u00e1 s\u00faprava pr\u00edslu\u0161enstva pre polovodi\u010dov\u00e9 st\u00fdka\u010de v\u00e1m u\u013eah\u010d\u00ed manipul\u00e1ciu, servis a in\u0161tal\u00e1ciu.",
              },
            ],
          },
          {
            id: "BHI0000101",
            parent_id: "TGI0000101",
            text: "Power distribution board",
            X_fullName: "Power distribution board",
            X_fullName_SK: "Rozv\u00e1dza\u010d nap\u00e1jania",
            X_URL: "power-distribution-board",
            FULLPATH:
              "PhoenixContact\\Electronic switchgear and motor control\\Power distribution board",
            X_Description:
              "Not only can you set up motor starters reliably with the power distribution board, you can also implement modular and functional solutions.",
            X_Description_SK: "",
          },
        ],
      },
      {
        id: "CHI0000101",
        parent_id: "HFI0000101",
        text: "Functional Safety",
        X_fullName: "Functional Safety",
        X_fullName_SK: "Funk\u010dn\u00e1 bezpe\u010dnos\u0165",
        X_URL: "functional-safety",
        FULLPATH: "PhoenixContact\\Functional Safety",
        X_Description:
          "For Phoenix Contact, safety does not just mean safe operation, it also means straightforward handling. Comprehensive and tested protection for personnel and machinery.",
        X_Description_SK:
          "Bezpe\u010dnos\u0165 pre Phoenix Contact neznamen\u00e1 len bezpe\u010dn\u00fa prev\u00e1dzku, ale aj jednoduch\u00fa manipul\u00e1ciu. Komplexn\u00e1 a testovan\u00e1 ochrana person\u00e1lu a strojov.",
        items: [
          {
            id: "DHI0000101",
            parent_id: "CHI0000101",
            text: "Safety devices",
            X_fullName: "Safety devices",
            X_fullName_SK: "Bezpe\u010dnostn\u00e9 zariadenia",
            X_URL: "safety-devices",
            FULLPATH: "PhoenixContact\\Functional Safety\\Safety devices",
            X_Description:
              "Versatile safety relay modules with proven technology for various safety functions.",
            X_Description_SK:
              "Univerz\u00e1lne bezpe\u010dnostn\u00e9 rel\u00e9 moduly s osved\u010denou technol\u00f3giou pre r\u00f4zne bezpe\u010dnostn\u00e9 funkcie.",
            items: [
              {
                id: "EHI0000101",
                parent_id: "DHI0000101",
                text: "Safety relays",
                X_fullName: "Safety relays",
                X_fullName_SK: "Bezpe\u010dnostn\u00e9 rel\u00e9",
                X_URL: "safety-relays",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays",
                X_Description:
                  "Safe relays for safety functions, such as emergency stop, light grids or two-hand control devices.",
                X_Description_SK:
                  "Bezpe\u010dn\u00e9 rel\u00e9 pre bezpe\u010dnostn\u00e9 funkcie ako s\u00fa n\u00fadzov\u00e9 zastavenie, \u013eahk\u00e9 mrie\u017eky alebo dve ru\u010dn\u00e9 ovl\u00e1dacie zariadenia.",
                items: [
                  {
                    id: "GHI0000101",
                    parent_id: "EHI0000101",
                    text: "Conventional safety relays",
                    X_fullName: "Conventional safety relays",
                    X_fullName_SK:
                      "Konven\u010dn\u00e9 bezpe\u010dnostn\u00e9 rel\u00e9",
                    X_URL: "conventional-safety-relays",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Conventional safety relays",
                    X_Description:
                      "Classical safety relays for basic functions, such as emergency stop, light grids, and two-hand control devices.",
                    X_Description_SK: "",
                  },
                  {
                    id: "IHI0000101",
                    parent_id: "EHI0000101",
                    text: "Highly compact safety relays",
                    X_fullName: "Highly compact safety relays",
                    X_fullName_SK:
                      "Vysoko kompaktn\u00e9 bezpe\u010dnostn\u00e9 rel\u00e9",
                    X_URL: "highly-compact-safety-relays",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Highly compact safety relays",
                    X_Description:
                      "Highly compact safety relays for basic functions, such as emergency stop, door switches, and light grids.",
                    X_Description_SK: "",
                  },
                  {
                    id: "JHI0000101",
                    parent_id: "EHI0000101",
                    text: "Modular safety relay system",
                    X_fullName: "Modular safety relay system",
                    X_fullName_SK:
                      "Modul\u00e1rny syst\u00e9m bezpe\u010dnostn\u00fdch rel\u00e9",
                    X_URL: "modular-safety-relay",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Modular safety relay system",
                    X_Description:
                      "Adaptable safety system with DIN rail connector, master safety relay, and safety relays for numerous safety functions.",
                    X_Description_SK:
                      "Prisp\u00f4sobite\u013en\u00fd bezpe\u010dnostn\u00fd syst\u00e9m s konektorom na li\u0161tu DIN, hlavn\u00fdm bezpe\u010dnostn\u00fdm rel\u00e9 a bezpe\u010dnostn\u00fdmi rel\u00e9 pre mnoh\u00e9 bezpe\u010dnostn\u00e9 funkcie.",
                    items: [
                      {
                        id: "KHI0000101",
                        parent_id: "JHI0000101",
                        text: "Modular safety relay system",
                        X_fullName: "Modular safety relay system",
                        X_fullName_SK:
                          "Modul\u00e1rny syst\u00e9m bezpe\u010dnostn\u00fdch rel\u00e9",
                        X_URL: "modular-safety-relay-system",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Modular safety relay system\\Modular safety relay system",
                        X_Description:
                          "Adaptable safety system with DIN rail connector, master safety relay, and safety relays for numerous safety functions.",
                        X_Description_SK:
                          "Prisp\u00f4sobite\u013en\u00fd bezpe\u010dnostn\u00fd syst\u00e9m s konektorom na li\u0161tu DIN, hlavn\u00fdm bezpe\u010dnostn\u00fdm rel\u00e9 a bezpe\u010dnostn\u00fdmi rel\u00e9 pre mnoh\u00e9 bezpe\u010dnostn\u00e9 funkcie.",
                      },
                      {
                        id: "LHI0000101",
                        parent_id: "JHI0000101",
                        text: "Accessories for modular safety relay system",
                        X_fullName:
                          "Accessories for modular safety relay system",
                        X_fullName_SK:
                          "Pr\u00edslu\u0161enstvo pre modul\u00e1rny bezpe\u010dnostn\u00fd rel\u00e9ov\u00fd syst\u00e9m",
                        X_URL: "modular-safety-relay-accessories",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Modular safety relay system\\Accessories for modular safety relay system",
                        X_Description:
                          "Accessory list for the adaptable safety relay system with DIN rail connector.",
                        X_Description_SK:
                          "Zoznam pr\u00edslu\u0161enstva pre adaptabiln\u00fd bezpe\u010dnostn\u00fd rel\u00e9ov\u00fd syst\u00e9m s konektorom na li\u0161tu DIN.",
                      },
                    ],
                  },
                ],
              },
              {
                id: "MHI0000101",
                parent_id: "DHI0000101",
                text: "Zero speed and overspeed safety relays",
                X_fullName: "Zero speed and overspeed safety relays",
                X_fullName_SK:
                  "Bezpe\u010dnostn\u00e9 rel\u00e9 nulovej r\u00fdchlosti a prekro\u010denia r\u00fdchlosti",
                X_URL: "speed-safety-relays-main",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safety devices\\Zero speed and overspeed safety relays",
                X_Description:
                  "Safety relay modules for reliably monitoring the speed and downtime of rotating parts in systems and machines.",
                X_Description_SK:
                  "Bezpe\u010dnostn\u00e9 rel\u00e9ov\u00e9 moduly pre spo\u013eahliv\u00e9 sledovanie r\u00fdchlosti a prestojov rotuj\u00facich \u010dast\u00ed v syst\u00e9moch a strojoch.",
                items: [
                  {
                    id: "NHI0000101",
                    parent_id: "MHI0000101",
                    text: "Zero speed and overspeed safety relays",
                    X_fullName: "Zero speed and overspeed safety relays",
                    X_fullName_SK:
                      "Bezpe\u010dnostn\u00e9 rel\u00e9 nulovej r\u00fdchlosti a prekro\u010denia r\u00fdchlosti",
                    X_URL: "speed-safety-relays",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Zero speed and overspeed safety relays\\Zero speed and overspeed safety relays",
                    X_Description:
                      "Safety devices for monitoring the speed and downtime of rotating parts in systems and machines.",
                    X_Description_SK:
                      "Bezpe\u010dnostn\u00e9 zariadenia na sledovanie r\u00fdchlosti a prestojov rotuj\u00facich \u010dast\u00ed v syst\u00e9moch a strojoch.",
                  },
                  {
                    id: "OHI0000101",
                    parent_id: "MHI0000101",
                    text: "Accessories for zero speed and overspeed safety re",
                    X_fullName:
                      "Accessories for zero speed and overspeed safety re",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo pre nulov\u00fa r\u00fdchlos\u0165 a bezpe\u010dnos\u0165 pri prekro\u010den\u00ed r\u00fdchlosti",
                    X_URL: "speed-safety-relays-accessories",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Zero speed and overspeed safety relays\\Accessories for zero speed and overspeed safety re",
                    X_Description:
                      "Accessories for zero-speed and over-speed safety relays.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo pre bezpe\u010dnostn\u00e9 rel\u00e9 nulovej r\u00fdchlosti a prekro\u010denia r\u00fdchlosti.",
                  },
                ],
              },
              {
                id: "PHI0000101",
                parent_id: "DHI0000101",
                text: "Safe coupling relays",
                X_fullName: "Safe coupling relays",
                X_fullName_SK: "Bezpe\u010dn\u00e9 spojovacie rel\u00e9",
                X_URL: "safe-coupling-relays",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays",
                X_Description:
                  "Relays with force-guided contacts for safe signal coupling.",
                X_Description_SK:
                  "Rel\u00e9 so silovo veden\u00fdmi kontaktmi pre bezpe\u010dn\u00e9 spojenie sign\u00e1lov.",
                items: [
                  {
                    id: "QHI0000101",
                    parent_id: "PHI0000101",
                    text: "Classical safe coupling relays",
                    X_fullName: "Classical safe coupling relays",
                    X_fullName_SK:
                      "Klasick\u00e9 bezpe\u010dn\u00e9 spojovacie rel\u00e9",
                    X_URL: "classical-safe-coupling-rel",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Classical safe coupling relays",
                    X_Description:
                      "Coupling relays with force-guided contacts for safe signal coupling.",
                    X_Description_SK:
                      "Spojovacie rel\u00e9 so silovo veden\u00fdmi kontaktmi pre bezpe\u010dn\u00e9 spojenie sign\u00e1lov.",
                    items: [
                      {
                        id: "RHI0000101",
                        parent_id: "QHI0000101",
                        text: "Classical safe coupling relays",
                        X_fullName: "Classical safe coupling relays",
                        X_fullName_SK:
                          "Klasick\u00e9 bezpe\u010dn\u00e9 spojovacie rel\u00e9",
                        X_URL: "classical-safe-coupling-relays",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Classical safe coupling relays\\Classical safe coupling relays",
                        X_Description:
                          "Classical safe coupling relays for safe switching on and off.",
                        X_Description_SK:
                          "Klasick\u00e9 bezpe\u010dn\u00e9 v\u00e4zobn\u00e9 rel\u00e9 pre bezpe\u010dn\u00e9 zap\u00ednanie a vyp\u00ednanie.",
                      },
                      {
                        id: "SHI0000101",
                        parent_id: "QHI0000101",
                        text: "Accessories for classical safe coupling relays",
                        X_fullName:
                          "Accessories for classical safe coupling relays",
                        X_fullName_SK:
                          "Pr\u00edslu\u0161enstvo pre klasick\u00e9 bezpe\u010dn\u00e9 spojovacie rel\u00e9",
                        X_URL: "safe-coupling-relays-accessories",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Classical safe coupling relays\\Accessories for classical safe coupling relays",
                        X_Description:
                          "Accessories for classical safe coupling relays.",
                        X_Description_SK:
                          "Pr\u00edslu\u0161enstvo pre klasick\u00e9 bezpe\u010dn\u00e9 v\u00e4zobn\u00e9 rel\u00e9.",
                      },
                    ],
                  },
                  {
                    id: "THI0000101",
                    parent_id: "PHI0000101",
                    text: "Highly compact, safe coupling relays",
                    X_fullName: "Highly compact, safe coupling relays",
                    X_fullName_SK:
                      "Vysoko kompaktn\u00e9, bezpe\u010dn\u00e9 spojovacie rel\u00e9",
                    X_URL: "compact-safe-coupling-rel",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Highly compact, safe coupling relays",
                    X_Description:
                      "Highly compact, safe coupling relays for electrical isolation and power adaptation.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9, bezpe\u010dn\u00e9 spojovacie rel\u00e9 pre elektrick\u00fa izol\u00e1ciu a prisp\u00f4sobenie nap\u00e1jania.",
                    items: [
                      {
                        id: "UHI0000101",
                        parent_id: "THI0000101",
                        text: "Highly compact, safe coupling relays",
                        X_fullName: "Highly compact, safe coupling relays",
                        X_fullName_SK:
                          "Vysoko kompaktn\u00e9, bezpe\u010dn\u00e9 spojovacie rel\u00e9",
                        X_URL: "compact-safe-coupling-relays",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Highly compact, safe coupling relays\\Highly compact, safe coupling relays",
                        X_Description:
                          "Highly compact, safe coupling relays for safe switching on and off.",
                        X_Description_SK:
                          "Vysoko kompaktn\u00e9, bezpe\u010dn\u00e9 v\u00e4zobn\u00e9 rel\u00e9 pre bezpe\u010dn\u00e9 zap\u00ednanie a vyp\u00ednanie.",
                      },
                      {
                        id: "VHI0000101",
                        parent_id: "THI0000101",
                        text: "Accessories for highly compact, safe coupling rela",
                        X_fullName:
                          "Accessories for highly compact, safe coupling rela",
                        X_fullName_SK:
                          "Pr\u00edslu\u0161enstvo pre vysoko kompaktn\u00e9, bezpe\u010dn\u00e9 spojenie",
                        X_URL: "compact-safe-relays-accessories",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Highly compact, safe coupling relays\\Accessories for highly compact, safe coupling rela",
                        X_Description:
                          "Accessories for highly compact, safe coupling relays.",
                        X_Description_SK:
                          "Pr\u00edslu\u0161enstvo pre vysoko kompaktn\u00e9, bezpe\u010dn\u00e9 v\u00e4zobn\u00e9 rel\u00e9.",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: "WHI0000101",
            parent_id: "CHI0000101",
            text: "Configurable safety modules",
            X_fullName: "Configurable safety modules",
            X_fullName_SK:
              "Konfigurovate\u013en\u00e9 bezpe\u010dnostn\u00e9 moduly",
            X_URL: "configurable-safety-modules-main",
            FULLPATH:
              "PhoenixContact\\Functional Safety\\Configurable safety modules",
            X_Description:
              "Versatile safety modules for the DIN rail. For configuring multiple safety functions with just one device.",
            X_Description_SK:
              "V\u0161estrann\u00e9 bezpe\u010dnostn\u00e9 moduly na DIN li\u0161tu. Na konfigur\u00e1ciu viacer\u00fdch bezpe\u010dnostn\u00fdch funkci\u00ed iba jedn\u00fdm zariaden\u00edm.",
            items: [
              {
                id: "XHI0000101",
                parent_id: "WHI0000101",
                text: "Configurable safety modules",
                X_fullName: "Configurable safety modules",
                X_fullName_SK:
                  "Konfigurovate\u013en\u00e9 bezpe\u010dnostn\u00e9 moduly",
                X_URL: "configurable-safety-modules",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Configurable safety modules\\Configurable safety modules",
                X_Description:
                  "Safety devices with diagnostic options which support modular extension. For monitoring multiple safety functions.",
                X_Description_SK:
                  "Bezpe\u010dnostn\u00e9 zariadenia s diagnostick\u00fdmi mo\u017enos\u0165ami, ktor\u00e9 podporuj\u00fa modul\u00e1rne roz\u0161\u00edrenie. Na monitorovanie viacer\u00fdch bezpe\u010dnostn\u00fdch funkci\u00ed.",
              },
              {
                id: "YHI0000101",
                parent_id: "WHI0000101",
                text: "Accessories for configurable safety modules",
                X_fullName: "Accessories for configurable safety modules",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo pre konfigurovate\u013en\u00e9 bezpe\u010dnostn\u00e9 moduly",
                X_URL: "config-safety-modules-acc",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Configurable safety modules\\Accessories for configurable safety modules",
                X_Description: "Accessories for configurable safety modules",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo pre konfigurovate\u013en\u00e9 bezpe\u010dnostn\u00e9 moduly",
              },
            ],
          },
          {
            id: "ZHI0000101",
            parent_id: "CHI0000101",
            text: "Safe control technology",
            X_fullName: "Safe control technology",
            X_fullName_SK: "Bezpe\u010dn\u00e1 riadiaca technol\u00f3gia",
            X_URL: "safe-control-technology",
            FULLPATH:
              "PhoenixContact\\Functional Safety\\Safe control technology",
            X_Description:
              "Programmable high-performance safety controllers and proxies for integrating functional safety.",
            X_Description_SK:
              "Programovate\u013en\u00e9 vysokov\u00fdkonn\u00e9 bezpe\u010dnostn\u00e9 ovl\u00e1da\u010de a proxy na integr\u00e1ciu funk\u010dnej bezpe\u010dnosti.",
            items: [
              {
                id: "0II0000101",
                parent_id: "ZHI0000101",
                text: "Accessories for safe control technology",
                X_fullName: "Accessories for safe control technology",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo pre bezpe\u010dn\u00fa riadiacu techniku",
                X_URL: "safe-control-tech-accessories",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safe control technology\\Accessories for safe control technology",
                X_Description:
                  "Accessories for the installation and operation of safety controllers and proxies in complex network structures.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo pre in\u0161tal\u00e1ciu a prev\u00e1dzku bezpe\u010dnostn\u00fdch ovl\u00e1da\u010dov a proxy v zlo\u017eit\u00fdch sie\u0165ov\u00fdch \u0161trukt\u00farach.",
              },
              {
                id: "1II0000101",
                parent_id: "ZHI0000101",
                text: "Programming for safety controllers",
                X_fullName: "Programming for safety controllers",
                X_fullName_SK:
                  "Programovanie bezpe\u010dnostn\u00fdch ovl\u00e1da\u010dov",
                X_URL: "programming-safety-controllers",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safe control technology\\Programming for safety controllers",
                X_Description:
                  "Software for configuring and parameterizing programmable safety controllers.",
                X_Description_SK:
                  "Softv\u00e9r na konfigur\u00e1ciu a parametriz\u00e1ciu programovate\u013en\u00fdch bezpe\u010dnostn\u00fdch ovl\u00e1da\u010dov.",
              },
              {
                id: "2II0000101",
                parent_id: "ZHI0000101",
                text: "I/O modules",
                X_fullName: "I/O modules",
                X_fullName_SK: "I/O moduly",
                X_URL: "io-modules",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safe control technology\\I/O modules",
                X_Description:
                  "I/Os for the flexible integration of functional safety at input and output level.",
                X_Description_SK:
                  "I/O pre flexibiln\u00fa integr\u00e1ciu funk\u010dnej bezpe\u010dnosti na vstupnej a v\u00fdstupnej \u00farovni.",
              },
              {
                id: "3II0000101",
                parent_id: "ZHI0000101",
                text: "Safe analog value processing",
                X_fullName: "Safe analog value processing",
                X_fullName_SK:
                  "Bezpe\u010dn\u00e9 spracovanie anal\u00f3gov\u00fdch hodn\u00f4t",
                X_URL: "safe-analog-value-processing",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safe control technology\\Safe analog value processing",
                X_Description:
                  "Software-based, safe analog value processing for safety applications up to SIL 3 and PL e.",
                X_Description_SK:
                  "Softv\u00e9rov\u00e9, bezpe\u010dn\u00e9 spracovanie anal\u00f3gov\u00fdch hodn\u00f4t pre bezpe\u010dnostn\u00e9 aplik\u00e1cie a\u017e do SIL 3 a PL e.",
              },
            ],
          },
          {
            id: "5II0000101",
            parent_id: "CHI0000101",
            text: "Safety switch",
            X_fullName: "Safety switch",
            X_fullName_SK: "Bezpe\u010dnostn\u00fd sp\u00edna\u010d",
            X_URL: "safety-switch",
            FULLPATH: "PhoenixContact\\Functional Safety\\Safety switch",
            X_Description:
              "Non-contact PSRswitch safety switches with RFID transponder technology for reliable safety door and position monitoring",
            X_Description_SK: "",
          },
        ],
      },
      {
        id: "6II0000101",
        parent_id: "HFI0000101",
        text: "HMIs and industrial PCs",
        X_fullName: "HMIs and industrial PCs",
        X_fullName_SK: "HMI a priemyseln\u00e9 po\u010d\u00edta\u010de",
        X_URL: "hmis-and-industrial-pcs",
        FULLPATH: "PhoenixContact\\HMIs and industrial PCs",
        X_Description:
          "Automation starts at the interface between human and machine. Efficient production from the start \u2013 with our HMIs and industrial PCs.",
        X_Description_SK:
          "Automatiz\u00e1cia za\u010d\u00edna na rozhran\u00ed medzi \u010dlovekom a strojom. Efekt\u00edvna v\u00fdroba od za\u010diatku \u2013 s na\u0161imi HMI a priemyseln\u00fdmi PC.",
        items: [
          {
            id: "7II0000101",
            parent_id: "6II0000101",
            text: "Industrial PCs",
            X_fullName: "Industrial PCs",
            X_fullName_SK: "Priemyseln\u00e9 po\u010d\u00edta\u010de",
            X_URL: "industrial-pcs",
            FULLPATH: "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs",
            X_Description:
              "Energy-efficient IPCs for a wide range of applications in all areas of automation.",
            X_Description_SK:
              "Energeticky efekt\u00edvne IPC pre \u0161irok\u00fa \u0161k\u00e1lu aplik\u00e1ci\u00ed vo v\u0161etk\u00fdch oblastiach automatiz\u00e1cie.",
            items: [
              {
                id: "8II0000101",
                parent_id: "7II0000101",
                text: "Box PCs",
                X_fullName: "Box PCs",
                X_fullName_SK: "Skri\u0148ov\u00e9 po\u010d\u00edta\u010de",
                X_URL: "box-pcs-main",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Box PCs",
                X_Description:
                  "Space-saving and high-performance box PCs for use in the control cabinet and terminal box.",
                X_Description_SK:
                  "Priestorovo \u00fasporn\u00e9 a vysoko v\u00fdkonn\u00e9 skri\u0148ov\u00e9 PC na pou\u017eitie v rozv\u00e1dza\u010di a svorkovnici.",
                items: [
                  {
                    id: "9II0000101",
                    parent_id: "8II0000101",
                    text: "Box PCs",
                    X_fullName: "Box PCs",
                    X_fullName_SK: "Skri\u0148ov\u00e9 po\u010d\u00edta\u010de",
                    X_URL: "box-pcs",
                    FULLPATH:
                      "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Box PCs\\Box PCs",
                    X_Description:
                      "Space-saving and high-performance box PCs for use in the control cabinet and terminal box.",
                    X_Description_SK:
                      "Priestorovo \u00fasporn\u00e9 a vysoko v\u00fdkonn\u00e9 skri\u0148ov\u00e9 PC na pou\u017eitie v rozv\u00e1dza\u010di a svorkovnici.",
                  },
                  {
                    id: "AII0000101",
                    parent_id: "8II0000101",
                    text: "Remote monitoring",
                    X_fullName: "Remote monitoring",
                    X_fullName_SK: "Vzdialen\u00e9 monitorovanie",
                    X_URL: "remote-monitoring",
                    FULLPATH:
                      "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Box PCs\\Remote monitoring",
                    X_Description:
                      "Data transmission for distances up to 90 meters - products and solutions for remote operator interfaces.",
                    X_Description_SK:
                      "Prenos d\u00e1t na vzdialenosti do 90 metrov - produkty a rie\u0161enia pre vzdialen\u00e9 oper\u00e1torsk\u00e9 rozhrania.",
                  },
                ],
              },
              {
                id: "BII0000101",
                parent_id: "7II0000101",
                text: "Monitors with touch function",
                X_fullName: "Monitors with touch function",
                X_fullName_SK: "Monitory s dotykovou funkciou",
                X_URL: "monitors-with-touch-function",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Monitors with touch function",
                X_Description:
                  "Monitors with touch function which are suitable for industrial applications - ideal as a remote control panel for industrial PCs.",
                X_Description_SK:
                  "Monitory s dotykovou funkciou, ktor\u00e9 s\u00fa vhodn\u00e9 pre priemyseln\u00e9 aplik\u00e1cie - ide\u00e1lne ako dia\u013ekov\u00fd ovl\u00e1dac\u00ed panel pre priemyseln\u00e9 PC.",
                items: [
                  {
                    id: "CII0000101",
                    parent_id: "BII0000101",
                    text: "Multi-touch monitors",
                    X_fullName: "Multi-touch monitors",
                    X_fullName_SK: "Viacdotykov\u00e9 monitory",
                    X_URL: "multi-touch-monitors",
                    FULLPATH:
                      "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Monitors with touch function\\Multi-touch monitors",
                    X_Description:
                      "Monitors with multi-touch function are the ideal extension to the industrial PC - operation and monitoring without mouse and keyboard.",
                    X_Description_SK:
                      "Monitory s multi-touch funkciou s\u00fa ide\u00e1lnym roz\u0161\u00edren\u00edm priemyseln\u00e9ho PC - obsluha a monitorovanie bez my\u0161i a kl\u00e1vesnice.",
                  },
                ],
              },
              {
                id: "DII0000101",
                parent_id: "7II0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "ipc-accessories",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Accessories",
                X_Description:
                  "Accessories for quick and easy installation and efficient operation of industrial PCs.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo pre r\u00fdchlu a \u013eahk\u00fa in\u0161tal\u00e1ciu a efekt\u00edvnu prev\u00e1dzku priemyseln\u00fdch po\u010d\u00edta\u010dov.",
              },
            ],
          },
          {
            id: "EII0000101",
            parent_id: "6II0000101",
            text: "HMIs",
            X_fullName: "HMIs",
            X_fullName_SK: "HMI",
            X_URL: "hmis",
            FULLPATH: "PhoenixContact\\HMIs and industrial PCs\\HMIs",
            X_Description:
              "Numerous devices \u2013 mobile or stationary \u2013 for operating and monitoring machines and systems.",
            X_Description_SK:
              "Po\u010detn\u00e9 zariadenia - mobiln\u00e9 alebo stacion\u00e1rne - pre prev\u00e1dzkov\u00e9 a monitorovacie stroje a syst\u00e9my.",
            items: [
              {
                id: "FII0000101",
                parent_id: "EII0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "hmi-accessories",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\HMIs\\Accessories",
                X_Description:
                  "Accessories for quick and easy installation and efficient operation of HMI devices.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo pre r\u00fdchlu a \u013eahk\u00fa in\u0161tal\u00e1ciu a efekt\u00edvnu prev\u00e1dzku zariaden\u00ed HMI.",
              },
              {
                id: "GII0000101",
                parent_id: "EII0000101",
                text: "HMIs for Visu+",
                X_fullName: "HMIs for Visu+",
                X_fullName_SK: "HMI pre Visu+",
                X_URL: "hmis-for-visu",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\HMIs\\HMIs for Visu+",
                X_Description:
                  "Touch panels for the visualization of sophisticated applications with the Visu+ software.",
                X_Description_SK:
                  "Dotykov\u00e9 panely pre vizualiz\u00e1ciu sofistikovan\u00fdch aplik\u00e1ci\u00ed so softv\u00e9rom Visu+.",
              },
            ],
          },
        ],
      },
      {
        id: "HII0000101",
        parent_id: "HFI0000101",
        text: "Industrial communication technology",
        X_fullName: "Industrial communication technology",
        X_fullName_SK:
          "Priemyseln\u00e1 komunika\u010dn\u00e1 technol\u00f3gia",
        X_URL: "industrial-communication",
        FULLPATH: "PhoenixContact\\Industrial communication technology",
        X_Description:
          "Our interface devices provide consistent protection for your sensitive data interfaces and so enable you to increase the availability of your systems.",
        X_Description_SK:
          "Na\u0161e rozhrania poskytuj\u00fa konzistentn\u00fa ochranu pre va\u0161e citliv\u00e9 d\u00e1tov\u00e9 rozhrania a umo\u017e\u0148uj\u00fa v\u00e1m tak zv\u00fd\u0161i\u0165 dostupnos\u0165 va\u0161ich syst\u00e9mov.",
        items: [
          {
            id: "III0000101",
            parent_id: "HII0000101",
            text: "Remote communication",
            X_fullName: "Remote communication",
            X_fullName_SK: "Vzdialen\u00e1 komunik\u00e1cia",
            X_URL: "remote-communication",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Remote communication",
            X_Description:
              "Access machines and systems worldwide via telecommunications networks quickly and without interference.",
            X_Description_SK:
              "Pr\u00edstup k strojom a syst\u00e9mom po celom svete prostredn\u00edctvom telekomunika\u010dn\u00fdch siet\u00ed r\u00fdchlo a bez ru\u0161enia.",
            items: [
              {
                id: "JII0000101",
                parent_id: "III0000101",
                text: "Remote maintenance",
                X_fullName: "Remote maintenance",
                X_fullName_SK: "Vzdialen\u00e1 \u00fadr\u017eba",
                X_URL: "remote-maintenance-main",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Remote communication\\Remote maintenance",
                X_Description:
                  "With mGuard technology, Phoenix Contact offers a remote maintenance solution that can be easily integrated into machines and systems.",
                X_Description_SK:
                  "S technol\u00f3giou mGuard pon\u00faka Phoenix Contact rie\u0161enie vzdialenej \u00fadr\u017eby, ktor\u00e9 mo\u017eno jednoducho integrova\u0165 do strojov a syst\u00e9mov.",
                items: [
                  {
                    id: "KII0000101",
                    parent_id: "JII0000101",
                    text: "Remote maintenance",
                    X_fullName: "Remote maintenance",
                    X_fullName_SK: "Vzdialen\u00e1 \u00fadr\u017eba",
                    X_URL: "remote-maintenance",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Remote communication\\Remote maintenance\\Remote maintenance",
                    X_Description:
                      "Solutions from Phoenix Contact for secure remote maintenance of machines and systems.",
                    X_Description_SK:
                      "Rie\u0161enia od Phoenix Contact pre bezpe\u010dn\u00fa vzdialen\u00fa \u00fadr\u017ebu strojov a syst\u00e9mov.",
                  },
                  {
                    id: "MII0000101",
                    parent_id: "JII0000101",
                    text: "Cloud-based remote maintenance",
                    X_fullName: "Cloud-based remote maintenance",
                    X_fullName_SK:
                      "Vzdialen\u00e1 \u00fadr\u017eba na b\u00e1ze cloudu",
                    X_URL: "cloud-based-remote-maintenance",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Remote communication\\Remote maintenance\\Cloud-based remote maintenance",
                    X_Description:
                      "The mGuard Secure Cloud securely connects service personnel and remote maintenance locations via the Internet.",
                    X_Description_SK: "",
                  },
                ],
              },
              {
                id: "OII0000101",
                parent_id: "III0000101",
                text: "Alarm generation",
                X_fullName: "Alarm generation",
                X_fullName_SK: "Generovanie alarmu",
                X_URL: "alarm-generation",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Remote communication\\Alarm generation",
                X_Description:
                  "Industrial devices from Phoenix Contact provide automatic alarm generation for instant remote error detection.",
                X_Description_SK: "",
              },
              {
                id: "QII0000101",
                parent_id: "III0000101",
                text: "Remote control",
                X_fullName: "Remote control",
                X_fullName_SK: "Dia\u013ekov\u00e9 ovl\u00e1danie",
                X_URL: "remote-control",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Remote communication\\Remote control",
                X_Description:
                  "Remote control technology from Phoenix Contact for mobile networks or copper-based solutions.",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "RII0000101",
            parent_id: "HII0000101",
            text: "Industrial Ethernet",
            X_fullName: "Industrial Ethernet",
            X_fullName_SK: "Priemyseln\u00fd Ethernet",
            X_URL: "industrial-ethernet",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Industrial Ethernet",
            X_Description:
              "Design your infrastructure flexibly, quickly, and consistently. Not a problem when you base your automation network on Industrial Ethernet.",
            X_Description_SK:
              "Navrhnite svoju infra\u0161trukt\u00faru flexibilne, r\u00fdchlo a konzistentne. Nie je to probl\u00e9m, ke\u010f svoju automatiza\u010dn\u00fa sie\u0165 zalo\u017e\u00edte na priemyselnom Ethernete.",
            items: [
              {
                id: "SII0000101",
                parent_id: "RII0000101",
                text: "Installation technology for Industrial Ethernet",
                X_fullName: "Installation technology for Industrial Ethernet",
                X_fullName_SK:
                  "Technol\u00f3gia in\u0161tal\u00e1cie pre priemyseln\u00fd Ethernet",
                X_URL: "installation-tech-for-ethernet",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet",
                X_Description:
                  "A wide range of installation materials for setting up your industrial network",
                X_Description_SK:
                  "\u0160irok\u00e1 \u0161k\u00e1la in\u0161tala\u010dn\u00fdch materi\u00e1lov pre nastavenie va\u0161ej priemyselnej siete",
                items: [
                  {
                    id: "TII0000101",
                    parent_id: "SII0000101",
                    text: "Fiber optic installation",
                    X_fullName: "Fiber optic installation",
                    X_fullName_SK:
                      "In\u0161tal\u00e1cia optick\u00fdch vl\u00e1kien",
                    X_URL: "fiber-optic-installation",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\Fiber optic installation",
                    X_Description:
                      "Installation components for fiber optic networks, such as cables, plugs, measuring instruments, and comprehensive accessories.",
                    X_Description_SK:
                      "In\u0161tala\u010dn\u00e9 komponenty pre optick\u00e9 siete, ako s\u00fa k\u00e1ble, z\u00e1str\u010dky, meracie pr\u00edstroje a komplexn\u00e9 pr\u00edslu\u0161enstvo.",
                  },
                  {
                    id: "UII0000101",
                    parent_id: "SII0000101",
                    text: "Copper installation",
                    X_fullName: "Copper installation",
                    X_fullName_SK: "In\u0161tal\u00e1cia z medi",
                    X_URL: "copper-installation",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\Copper installation",
                    X_Description:
                      "Components for copper-based network installation, such as cables, plugs, and network isolators, as well as tools.",
                    X_Description_SK:
                      "Komponenty pre in\u0161tal\u00e1ciu meden\u00fdch siet\u00ed, ako s\u00fa k\u00e1ble, z\u00e1str\u010dky a sie\u0165ov\u00e9 izol\u00e1tory, ako aj n\u00e1stroje.",
                  },
                  {
                    id: "VII0000101",
                    parent_id: "SII0000101",
                    text: "Accessories for industrial Ethernet",
                    X_fullName: "Accessories for industrial Ethernet",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo pre priemyseln\u00fd Ethernet",
                    X_URL: "industrial-ethernet-accessories",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\Accessories for industrial Ethernet",
                    X_Description:
                      "Accessories for Phoenix Contact Ethernet components.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo pre ethernetov\u00e9 komponenty Phoenix Contact.",
                  },
                  {
                    id: "WII0000101",
                    parent_id: "SII0000101",
                    text: "SFP modules",
                    X_fullName: "SFP modules",
                    X_fullName_SK: "Moduly SFP",
                    X_URL: "sfp-modules",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\SFP modules",
                    X_Description:
                      "SFP modules for flexible FO and copper transmission in Fast Ethernet or Gigabit.",
                    X_Description_SK:
                      "SFP moduly pre flexibiln\u00fd FO a meden\u00fd prenos vo Fast Ethernet alebo Gigabit.",
                  },
                  {
                    id: "XII0000101",
                    parent_id: "SII0000101",
                    text: "Patch panels",
                    X_fullName: "Patch panels",
                    X_fullName_SK: "Patch panely",
                    X_URL: "patch-panels",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\Patch panels",
                    X_Description:
                      "Interface modules between field- and control cabinet cabling",
                    X_Description_SK:
                      "Moduly rozhrania medzi kabel\u00e1\u017eou po\u013enej a riadiacej skrine",
                  },
                ],
              },
              {
                id: "ZII0000101",
                parent_id: "RII0000101",
                text: "Network management software",
                X_fullName: "Network management software",
                X_fullName_SK: "Softv\u00e9r na spr\u00e1vu siete",
                X_URL: "network-management-software",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Network management software",
                X_Description:
                  "Powerful software for configuring and monitoring Ethernet networks in automation systems.",
                X_Description_SK: "",
              },
              {
                id: "0JI0000101",
                parent_id: "RII0000101",
                text: "Power over Ethernet",
                X_fullName: "Power over Ethernet",
                X_fullName_SK: "Nap\u00e1janie cez sie\u0165 Ethernet",
                X_URL: "power-over-ethernet",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet",
                X_Description:
                  "You will find our comprehensive range of PoE devices, from PoE injectors, through PoE switches, right through to PoE splitters, here",
                X_Description_SK:
                  "N\u00e1\u0161 komplexn\u00fd sortiment PoE zariaden\u00ed, od PoE injektorov, cez PoE prep\u00edna\u010de, a\u017e po PoE rozbo\u010dova\u010de, n\u00e1jdete tu",
                items: [
                  {
                    id: "1JI0000101",
                    parent_id: "0JI0000101",
                    text: "PoE injectors",
                    X_fullName: "PoE injectors",
                    X_fullName_SK: "Injektory PoE",
                    X_URL: "poe-injectors",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet\\PoE injectors",
                    X_Description:
                      "PoE injectors for easy transmission of data and electricity.",
                    X_Description_SK:
                      "PoE injektory pre jednoduch\u00fd prenos d\u00e1t a elektriny.",
                  },
                  {
                    id: "2JI0000101",
                    parent_id: "0JI0000101",
                    text: "PoE splitter",
                    X_fullName: "PoE splitter",
                    X_fullName_SK: "Rozde\u013eova\u010d PoE",
                    X_URL: "poe-splitter",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet\\PoE splitter",
                    X_Description:
                      "PoE splitters separate data and power locally.",
                    X_Description_SK:
                      "PoE rozbo\u010dova\u010de lok\u00e1lne odde\u013euj\u00fa d\u00e1ta a nap\u00e1janie.",
                  },
                  {
                    id: "3JI0000101",
                    parent_id: "0JI0000101",
                    text: "Unmanaged PoE Switches",
                    X_fullName: "Unmanaged PoE Switches",
                    X_fullName_SK: "Nespravovan\u00e9 prep\u00edna\u010de PoE",
                    X_URL: "unmanaged-poe-switches",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet\\Unmanaged PoE Switches",
                    X_Description:
                      "Unmanaged PoE Switches with extended temperature range, 30 W power budget, full Gigabit ports and jumbo frames for monitoring cameras",
                    X_Description_SK:
                      "Nespravovan\u00e9 PoE prep\u00edna\u010de s roz\u0161\u00edren\u00fdm rozsahom tepl\u00f4t, 30 W energetickou \u00fasporou, pln\u00fdmi gigabitov\u00fdmi portami a jumbo r\u00e1m\u010dekmi pre monitorovanie kamier",
                  },
                  {
                    id: "4JI0000101",
                    parent_id: "0JI0000101",
                    text: "Managed PoE Switches",
                    X_fullName: "Managed PoE Switches",
                    X_fullName_SK: "Spravovan\u00e9 prep\u00edna\u010de PoE",
                    X_URL: "managed-poe-switches",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet\\Managed PoE Switches",
                    X_Description:
                      "Managed PoE Switches in accordance with IEEE 802.3 af/at and bt for the supply and control of several end devices with PoE.",
                    X_Description_SK:
                      "Spravovan\u00e9 PoE prep\u00edna\u010de v s\u00falade s IEEE 802.3 af/at a bt pre nap\u00e1janie a ovl\u00e1danie nieko\u013ek\u00fdch koncov\u00fdch zariaden\u00ed s PoE.",
                  },
                ],
              },
              {
                id: "5JI0000101",
                parent_id: "RII0000101",
                text: "Cyber security",
                X_fullName: "Cyber security",
                X_fullName_SK: "Kybernetick\u00e1 bezpe\u010dnos\u0165",
                X_URL: "cyber-security",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Cyber security",
                X_Description:
                  "Security appliances regulate access to networks and therefore protect the system against harmful influences.",
                X_Description_SK:
                  "Bezpe\u010dnostn\u00e9 zariadenia reguluj\u00fa pr\u00edstup k sie\u0165am a t\u00fdm chr\u00e1nia syst\u00e9m pred \u0161kodliv\u00fdmi vplyvmi.",
                items: [
                  {
                    id: "7JI0000101",
                    parent_id: "5JI0000101",
                    text: "Security routers and firewalls",
                    X_fullName: "Security routers and firewalls",
                    X_fullName_SK:
                      "Bezpe\u010dnostn\u00e9 smerova\u010de a br\u00e1ny firewall",
                    X_URL: "security-routers-and-firewalls",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Cyber security\\Security routers and firewalls",
                    X_Description:
                      "Security appliances from Phoenix Contact offer firewall functions and a secure remote maintenance infrastructure in various designs.",
                    X_Description_SK: "",
                  },
                  {
                    id: "9JI0000101",
                    parent_id: "5JI0000101",
                    text: "Firmware extensions for security routers and firew",
                    X_fullName:
                      "Firmware extensions for security routers and firew",
                    X_fullName_SK:
                      "Roz\u0161\u00edrenia firmv\u00e9ru pre bezpe\u010dnostn\u00e9 smerova\u010de a firew",
                    X_URL: "firmware-extensions",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Cyber security\\Firmware extensions for security routers and firew",
                    X_Description:
                      "Extend mGuard security appliances with additional security functions at any time by means of licenses.",
                    X_Description_SK: "",
                  },
                  {
                    id: "BJI0000101",
                    parent_id: "5JI0000101",
                    text: "Device management software for security routers an",
                    X_fullName:
                      "Device management software for security routers an",
                    X_fullName_SK:
                      "Softv\u00e9r na spr\u00e1vu zariaden\u00ed pre bezpe\u010dnostn\u00e9 smerova\u010de a",
                    X_URL: "security-routers-device-mgmt",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Cyber security\\Device management software for security routers an",
                    X_Description:
                      "You can configure, roll out, and manage all mGuard security appliances from Phoenix Contact centrally with the central device management software.",
                    X_Description_SK: "",
                  },
                ],
              },
              {
                id: "DJI0000101",
                parent_id: "RII0000101",
                text: "Media converters for Ethernet",
                X_fullName: "Media converters for Ethernet",
                X_fullName_SK:
                  "Konvertory m\u00e9di\u00ed pre sie\u0165 Ethernet",
                X_URL: "ethernet-converters",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Media converters for Ethernet",
                X_Description:
                  "Fiber optic media converters for Ethernet in various different versions can be used with polymer fiber, HCS fiber or fiberglass.",
                X_Description_SK: "",
              },
              {
                id: "EJI0000101",
                parent_id: "RII0000101",
                text: "Ethernet switches",
                X_fullName: "Ethernet switches",
                X_fullName_SK: "Prep\u00edna\u010de Ethernet",
                X_URL: "ethernet-switches",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Ethernet switches",
                X_Description:
                  "Here you will find our comprehensive portfolio of Industrial Ethernet Switches: from Unmanaged to Managed Switches and Layer 3 switches.",
                X_Description_SK:
                  "Tu n\u00e1jdete na\u0161e komplexn\u00e9 portf\u00f3lio priemyseln\u00fdch ethernetov\u00fdch prep\u00edna\u010dov: od nespravovan\u00fdch a\u017e po riaden\u00e9 prep\u00edna\u010de a prep\u00edna\u010de L3 vrstvy.",
                items: [
                  {
                    id: "GJI0000101",
                    parent_id: "EJI0000101",
                    text: "Managed switches",
                    X_fullName: "Managed switches",
                    X_fullName_SK: "Spravovan\u00e9 prep\u00edna\u010de",
                    X_URL: "managed-switches",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Ethernet switches\\Managed switches",
                    X_Description:
                      "Managed Switches with Gigabit and Fast Ethernet, PROFINET, EtherNet/IP\u2122, IT functions, PoE and much more.",
                    X_Description_SK: "",
                  },
                  {
                    id: "IJI0000101",
                    parent_id: "EJI0000101",
                    text: "Unmanaged switches",
                    X_fullName: "Unmanaged switches",
                    X_fullName_SK: "Nespravovan\u00e9 prep\u00edna\u010de",
                    X_URL: "unmanaged-switches",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Ethernet switches\\Unmanaged switches",
                    X_Description:
                      "Unmanaged switches in various designs and with a variable number of ports.",
                    X_Description_SK: "",
                  },
                ],
              },
            ],
          },
          {
            id: "JJI0000101",
            parent_id: "HII0000101",
            text: "Industrial Wireless",
            X_fullName: "Industrial Wireless",
            X_fullName_SK: "Priemyseln\u00e9 bezdr\u00f4tov\u00e9 pripojenie",
            X_URL: "industrial-wireless",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Industrial Wireless",
            X_Description:
              "Reliable and efficient data transmission \u2013 without high-maintenance cables. Benefit from the advantages of high-performance wireless technology for your application.",
            X_Description_SK:
              "Spo\u013eahliv\u00fd a efekt\u00edvny prenos d\u00e1t \u2013 bez n\u00e1ro\u010dn\u00fdch k\u00e1blov. Vyu\u017eite v\u00fdhody vysokov\u00fdkonnej bezdr\u00f4tovej technol\u00f3gie pre va\u0161u aplik\u00e1ciu.",
            items: [
              {
                id: "KJI0000101",
                parent_id: "JJI0000101",
                text: "Antennas and cables",
                X_fullName: "Antennas and cables",
                X_fullName_SK: "Ant\u00e9ny a k\u00e1ble",
                X_URL: "antennas-and-cables-main",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Antennas and cables",
                X_Description:
                  "Various antennas, corresponding cables, and accessories complete the range for wireless data communication.",
                X_Description_SK:
                  "Sortiment bezdr\u00f4tovej d\u00e1tovej komunik\u00e1cie dop\u013a\u0148aj\u00fa r\u00f4zne ant\u00e9ny, zodpovedaj\u00face k\u00e1ble a pr\u00edslu\u0161enstvo.",
                items: [
                  {
                    id: "LJI0000101",
                    parent_id: "KJI0000101",
                    text: "Antennas and cables",
                    X_fullName: "Antennas and cables",
                    X_fullName_SK: "Ant\u00e9ny a k\u00e1ble",
                    X_URL: "antennas-and-cables",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Antennas and cables\\Antennas and cables",
                    X_Description:
                      "Antennas and cables for optimally adapting wireless devices to individual requirements.",
                    X_Description_SK:
                      "Ant\u00e9ny a k\u00e1ble na optim\u00e1lne prisp\u00f4sobenie bezdr\u00f4tov\u00fdch zariaden\u00ed individu\u00e1lnym po\u017eiadavk\u00e1m.",
                  },
                  {
                    id: "MJI0000101",
                    parent_id: "KJI0000101",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                    X_URL: "antena-cables-accessories",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Antennas and cables\\Accessories",
                    X_Description:
                      "Accessories for installing antennas and cables for wireless networks.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo na in\u0161tal\u00e1ciu ant\u00e9n a k\u00e1blov pre bezdr\u00f4tov\u00e9 siete.",
                  },
                ],
              },
              {
                id: "NJI0000101",
                parent_id: "JJI0000101",
                text: "Wireless Ethernet",
                X_fullName: "Wireless Ethernet",
                X_fullName_SK: "Bezdr\u00f4tov\u00fd Ethernet",
                X_URL: "wireless-ethernet",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless Ethernet",
                X_Description:
                  "Components for high-performance Ethernet networks via WLAN or Bluetooth.",
                X_Description_SK:
                  "Komponenty pre vysokov\u00fdkonn\u00e9 ethernetov\u00e9 siete cez WLAN alebo Bluetooth.",
                items: [
                  {
                    id: "PJI0000101",
                    parent_id: "NJI0000101",
                    text: "Industrial Bluetooth",
                    X_fullName: "Industrial Bluetooth",
                    X_fullName_SK: "Priemyseln\u00e9 rozhranie Bluetooth",
                    X_URL: "industrial-bluetooth",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless Ethernet\\Industrial Bluetooth",
                    X_Description:
                      "Devices for the Bluetooth-based integration of mobile or interchangeable automation components into Ethernet networks.",
                    X_Description_SK: "",
                  },
                  {
                    id: "RJI0000101",
                    parent_id: "NJI0000101",
                    text: "Industrial WLAN",
                    X_fullName: "Industrial WLAN",
                    X_fullName_SK: "Priemyseln\u00e1 sie\u0165 WLAN",
                    X_URL: "industrial-wlan",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless Ethernet\\Industrial WLAN",
                    X_Description:
                      "Devices for industrial WLAN networks for the integration of mobile or interchangeable automation components in Ethernet networks.",
                    X_Description_SK: "",
                  },
                ],
              },
              {
                id: "SJI0000101",
                parent_id: "JJI0000101",
                text: "Wireless I/O",
                X_fullName: "Wireless I/O",
                X_fullName_SK: "Bezdr\u00f4tov\u00fd vstup/v\u00fdstup",
                X_URL: "wireless-io",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless I/O",
                X_Description:
                  "Wireless I/O devices for the wireless transmission of digital or analog I/O signals.",
                X_Description_SK:
                  "Bezdr\u00f4tov\u00e9 I/O zariadenia na bezdr\u00f4tov\u00fd prenos digit\u00e1lnych alebo anal\u00f3gov\u00fdch I/O sign\u00e1lov.",
                items: [
                  {
                    id: "TJI0000101",
                    parent_id: "SJI0000101",
                    text: "Wireless multiplexer",
                    X_fullName: "Wireless multiplexer",
                    X_fullName_SK: "Bezdr\u00f4tov\u00fd multiplexor",
                    X_URL: "wireless-multiplexer",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless I/O\\Wireless multiplexer",
                    X_Description:
                      "Bluetooth I/O devices for reliable, secure I/O communication to rotating or hard-to-reach system parts.",
                    X_Description_SK:
                      "Bluetooth I/O zariadenia pre spo\u013eahliv\u00fa a bezpe\u010dn\u00fa I/O komunik\u00e1ciu s rotuj\u00facimi alebo \u0165a\u017eko dostupn\u00fdmi \u010das\u0165ami syst\u00e9mu.",
                  },
                  {
                    id: "UJI0000101",
                    parent_id: "SJI0000101",
                    text: "Radioline",
                    X_fullName: "Radioline",
                    X_fullName_SK: "Radioline",
                    X_URL: "radioline",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless I/O\\Radioline",
                    X_Description:
                      "Radioline with Trusted Wireless 2.0 technology for the reliable acquisition and distribution of I/O signals in large industrial systems.",
                    X_Description_SK:
                      "Radioline s technol\u00f3giou Trusted Wireless 2.0 pre spo\u013eahliv\u00e9 z\u00edskavanie a distrib\u00faciu I/O sign\u00e1lov vo ve\u013ek\u00fdch priemyseln\u00fdch syst\u00e9moch.",
                  },
                ],
              },
            ],
          },
          {
            id: "VJI0000101",
            parent_id: "HII0000101",
            text: "Products by protocols",
            X_fullName: "Products by protocols",
            X_fullName_SK: "Produkty pod\u013ea protokolov",
            X_URL: "products-by-protocols",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Products by protocols",
            X_Description:
              "Fieldbus components and systems reduce cabling effort in your systems and save time during planning and installation.",
            X_Description_SK:
              "Komponenty a syst\u00e9my Fieldbus zni\u017euj\u00fa n\u00e1klady na kabel\u00e1\u017e vo va\u0161ich syst\u00e9moch a \u0161etria \u010das pri pl\u00e1novan\u00ed a in\u0161tal\u00e1cii.",
            items: [
              {
                id: "WJI0000101",
                parent_id: "VJI0000101",
                text: "AS-Interface",
                X_fullName: "AS-Interface",
                X_fullName_SK: "AS-Interface",
                X_URL: "as-interface",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\AS-Interface",
                X_Description:
                  "AS-Interface supports quick, easy, and error-free installation.",
                X_Description_SK:
                  "AS-Interface podporuje r\u00fdchlu, jednoduch\u00fa a bezchybn\u00fa in\u0161tal\u00e1ciu.",
                items: [
                  {
                    id: "XJI0000101",
                    parent_id: "WJI0000101",
                    text: "AS-i network components",
                    X_fullName: "AS-i network components",
                    X_fullName_SK: "Sie\u0165ov\u00e9 komponenty AS-i",
                    X_URL: "as-i-network-components",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\AS-Interface\\AS-i network components",
                    X_Description:
                      "Wide range of components for constructing an AS-Interface network.",
                    X_Description_SK:
                      "\u0160irok\u00e1 \u0161k\u00e1la komponentov pre v\u00fdstavbu siete AS-Interface.",
                  },
                ],
              },
              {
                id: "YJI0000101",
                parent_id: "VJI0000101",
                text: "CANopen\u00ae",
                X_fullName: "CANopen\u00ae",
                X_fullName_SK: "CANopen\u00ae",
                X_URL: "canopen",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\CANopen\u00ae",
                X_Description:
                  "The CANopen fieldbus system is particularly suitable for reliable data transmission.",
                X_Description_SK:
                  "Syst\u00e9m CANopen fieldbus je obzvl\u00e1\u0161\u0165 vhodn\u00fd na spo\u013eahliv\u00fd prenos d\u00e1t.",
                items: [
                  {
                    id: "ZJI0000101",
                    parent_id: "YJI0000101",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_fullName_SK: "K\u00e1ble a konektory",
                    X_URL: "canopen-cables-and-connectors",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\CANopen\u00ae\\Cables and connectors",
                    X_Description:
                      "CANopen\u00ae cables and connectors in various lengths and with different degrees of protection, pre-assembled or sold by the meter.",
                    X_Description_SK:
                      "K\u00e1ble a konektory CANopen\u00ae v r\u00f4znych d\u013a\u017ekach a s r\u00f4znym stup\u0148om ochrany, vopred zmontovan\u00e9 alebo pred\u00e1van\u00e9 v metr\u00e1\u017ei.",
                  },
                  {
                    id: "0KI0000101",
                    parent_id: "YJI0000101",
                    text: "Converters and repeaters",
                    X_fullName: "Converters and repeaters",
                    X_fullName_SK: "Konvertory a opakova\u010de",
                    X_URL: "converters-and-repeaters",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\CANopen\u00ae\\Converters and repeaters",
                    X_Description:
                      "Converters and repeaters for CANopen convert copper interfaces to fiber optics, amplify signals, and create segments.",
                    X_Description_SK:
                      "Prevodn\u00edky a opakova\u010de pre CANopen konvertuj\u00fa meden\u00e9 rozhrania na optick\u00e9 vl\u00e1kna, zosil\u0148uj\u00fa sign\u00e1ly a vytv\u00e1raj\u00fa segmenty.",
                  },
                ],
              },
              {
                id: "1KI0000101",
                parent_id: "VJI0000101",
                text: "EtherNet/IP\u2122",
                X_fullName: "EtherNet/IP\u2122",
                X_fullName_SK: "EtherNet/IP\u2122",
                X_URL: "ethernet-ip",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\EtherNet/IP\u2122",
                X_Description:
                  "EtherNet/IP\u2122 creates consistency between office and industrial networks.",
                X_Description_SK:
                  "EtherNet/IP\u2122 vytv\u00e1ra konzistentnos\u0165 medzi kancel\u00e1rskymi a priemyseln\u00fdmi sie\u0165ami.",
                items: [
                  {
                    id: "2KI0000101",
                    parent_id: "1KI0000101",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_fullName_SK: "K\u00e1ble a konektory",
                    X_URL: "ethernet-cables-and-connectors",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\EtherNet/IP\u2122\\Cables and connectors",
                    X_Description:
                      "EtherNet/IP\u2122 cables and connectors in various lengths and with different degrees of protection, pre-assembled or sold by the meter.",
                    X_Description_SK:
                      "K\u00e1ble a konektory EtherNet/IP\u2122 v r\u00f4znych d\u013a\u017ekach a s r\u00f4znym stup\u0148om ochrany, vopred zmontovan\u00e9 alebo pred\u00e1van\u00e9 v metr\u00e1\u017ei.",
                  },
                ],
              },
              {
                id: "3KI0000101",
                parent_id: "VJI0000101",
                text: "HART",
                X_fullName: "HART",
                X_fullName_SK: "HART",
                X_URL: "hart",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART",
                X_Description:
                  "HART and WirelessHART components for communication between sensor and control.",
                X_Description_SK:
                  "Komponenty HART a WirelessHART pre komunik\u00e1ciu medzi sn\u00edma\u010dom a riaden\u00edm.",
                items: [
                  {
                    id: "4KI0000101",
                    parent_id: "3KI0000101",
                    text: "Wired HART",
                    X_fullName: "Wired HART",
                    X_fullName_SK: "K\u00e1blov\u00fd HART",
                    X_URL: "wired-hart",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART\\Wired HART",
                    X_Description:
                      "Wired HART components: multiplexers, isolators, surge protection",
                    X_Description_SK:
                      "Dr\u00f4tov\u00e9 HART komponenty: multiplexory, izol\u00e1tory, prep\u00e4\u0165ov\u00e1 ochrana",
                    items: [
                      {
                        id: "5KI0000101",
                        parent_id: "4KI0000101",
                        text: "Multiplexer for HART signals",
                        X_fullName: "Multiplexer for HART signals",
                        X_fullName_SK: "Multiplexor pre sign\u00e1ly HART",
                        X_URL: "multiplexer-for-hart-signals",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART\\Wired HART\\Multiplexer for HART signals",
                        X_Description:
                          "Multiplexers for the digital connection of HART-compatible field devices to a PC or management system.",
                        X_Description_SK:
                          "Multiplexery na digit\u00e1lne pripojenie prev\u00e1dzkov\u00fdch zariaden\u00ed kompatibiln\u00fdch s protokolom HART k PC alebo riadiacemu syst\u00e9mu.",
                      },
                      {
                        id: "6KI0000101",
                        parent_id: "4KI0000101",
                        text: "Signal conditioner",
                        X_fullName: "Signal conditioner",
                        X_fullName_SK: "Kondicion\u00e9r sign\u00e1lu",
                        X_URL: "hart-signal-conditioner",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART\\Wired HART\\Signal conditioner",
                        X_Description:
                          "HART-transparent and electrically isolated isolating amplifiers for error-free bidirectional data exchange.",
                        X_Description_SK:
                          "HART-transparentn\u00e9 a elektricky izolovan\u00e9 odde\u013eovacie zosil\u0148ova\u010de pre bezchybn\u00fa obojsmern\u00fa v\u00fdmenu d\u00e1t.",
                      },
                      {
                        id: "7KI0000101",
                        parent_id: "4KI0000101",
                        text: "Process indicators and field devices",
                        X_fullName: "Process indicators and field devices",
                        X_fullName_SK:
                          "Procesn\u00e9 indik\u00e1tory a prev\u00e1dzkov\u00e9 zariadenia",
                        X_URL: "process-indicators-and-field-dev",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART\\Wired HART\\Process indicators and field devices",
                        X_Description:
                          "Record temperatures directly in the field, monitor, display, and control process values with HART-capable process indicators and field devices.",
                        X_Description_SK:
                          "Zaznamen\u00e1vajte teploty priamo v ter\u00e9ne, monitorujte, zobrazujte a kontrolujte procesn\u00e9 hodnoty pomocou procesn\u00fdch indik\u00e1torov a prev\u00e1dzkov\u00fdch zariaden\u00ed s podporou HART.",
                      },
                    ],
                  },
                ],
              },
              {
                id: "8KI0000101",
                parent_id: "VJI0000101",
                text: "INTERBUS",
                X_fullName: "INTERBUS",
                X_fullName_SK: "INTERBUS",
                X_URL: "interbus",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS",
                X_Description:
                  "INTERBUS connects all process peripherals to all common controllers.",
                X_Description_SK:
                  "INTERBUS sp\u00e1ja v\u0161etky procesn\u00e9 perif\u00e9rie so v\u0161etk\u00fdmi be\u017en\u00fdmi riadiacimi jednotkami.",
                items: [
                  {
                    id: "9KI0000101",
                    parent_id: "8KI0000101",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_fullName_SK: "K\u00e1ble a konektory",
                    X_URL: "interbus-cables-and-connectors",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\Cables and connectors",
                    X_Description:
                      "Bus system cables and connectors for INTERBUS in various lengths, pre-assembled or sold by the meter.",
                    X_Description_SK:
                      "Zbernicov\u00e9 syst\u00e9mov\u00e9 k\u00e1ble a konektory pre INTERBUS v r\u00f4znych d\u013a\u017ekach, predmontovan\u00e9 alebo pred\u00e1van\u00e9 na metr\u00e1\u017e.",
                  },
                  {
                    id: "AKI0000101",
                    parent_id: "8KI0000101",
                    text: "Converters",
                    X_fullName: "Converters",
                    X_fullName_SK: "Konvertory",
                    X_URL: "converters",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\Converters",
                    X_Description:
                      "Converters for INTERBUS convert wire interfaces to fiber optics.",
                    X_Description_SK:
                      "Konvertory pre INTERBUS premie\u0148aj\u00fa dr\u00f4tov\u00e9 rozhrania na optick\u00e9 vl\u00e1kna.",
                  },
                  {
                    id: "BKI0000101",
                    parent_id: "8KI0000101",
                    text: "Bus modules",
                    X_fullName: "Bus modules",
                    X_fullName_SK: "Moduly zbernice",
                    X_URL: "bus-modules",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\Bus modules",
                    X_Description:
                      "INTERBUS bus couplers connect an I/O station to the INTERBUS network.",
                    X_Description_SK:
                      "Spojovacie \u010dleny zbernice INTERBUS sp\u00e1jaj\u00fa vstupno-v\u00fdstupn\u00fa stanicu so sie\u0165ou INTERBUS.",
                  },
                  {
                    id: "CKI0000101",
                    parent_id: "8KI0000101",
                    text: "INTERBUS master",
                    X_fullName: "INTERBUS master",
                    X_fullName_SK: "INTERBUS master",
                    X_URL: "interbus-master",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\INTERBUS master",
                    X_Description:
                      "The INTERBUS master controls the bus system and monitors the telegrams sent and received within the bus configuration.",
                    X_Description_SK:
                      "Master INTERBUS riadi zbernicov\u00fd syst\u00e9m a kontroluje odosielan\u00e9 a prij\u00edman\u00e9 telegramy v r\u00e1mci konfigur\u00e1cie zbernice.",
                  },
                  {
                    id: "DKI0000101",
                    parent_id: "8KI0000101",
                    text: "INTERBUS slave",
                    X_fullName: "INTERBUS slave",
                    X_fullName_SK: "INTERBUS slave",
                    X_URL: "interbus-slave",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\INTERBUS slave",
                    X_Description:
                      "INTERBUS slaves connect lower-level INTERBUS devices to the higher-level network.",
                    X_Description_SK:
                      "Podriaden\u00e9 zariadenia INTERBUS sp\u00e1jaj\u00fa zariadenia INTERBUS ni\u017e\u0161ej \u00farovne s nadradenou sie\u0165ou.",
                  },
                  {
                    id: "EKI0000101",
                    parent_id: "8KI0000101",
                    text: "PC controller boards",
                    X_fullName: "PC controller boards",
                    X_fullName_SK: "Riadiace dosky PC",
                    X_URL: "controller-boards",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\PC controller boards",
                    X_Description:
                      "PC master/slave controller boards integrate remote bus devices into an INTERBUS system.",
                    X_Description_SK:
                      "Riadiace dosky PC master/slave integruj\u00fa zariadenia vzdialenej zbernice do syst\u00e9mu INTERBUS.",
                  },
                ],
              },
              {
                id: "FKI0000101",
                parent_id: "VJI0000101",
                text: "Modbus",
                X_fullName: "Modbus",
                X_fullName_SK: "Modbus",
                X_URL: "modbus",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\Modbus",
                X_Description:
                  "The versatile Modbus communication protocol is a proven and user-friendly solution.",
                X_Description_SK:
                  "V\u0161estrann\u00fd komunika\u010dn\u00fd protokol Modbus je osved\u010den\u00fdm a u\u017e\u00edvate\u013esky pr\u00edjemn\u00fdm rie\u0161en\u00edm.",
                items: [
                  {
                    id: "GKI0000101",
                    parent_id: "FKI0000101",
                    text: "Signal conditioners with Modbus connection",
                    X_fullName: "Signal conditioners with Modbus connection",
                    X_fullName_SK:
                      "Kondicion\u00e9ry sign\u00e1lu s pripojen\u00edm Modbus",
                    X_URL: "signal-conditioners-Modbus ",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\Modbus\\Signal conditioners with Modbus connection",
                    X_Description:
                      "Signal conditioners and gateways for the direct integration of up to eight analog and digital process values into a Modbus/RTU or Modbus/TCP network.",
                    X_Description_SK:
                      "Ssign\u00e1lov\u00e9 prevodn\u00edky a br\u00e1ny pre priamu integr\u00e1ciu a\u017e \u00f4smich anal\u00f3gov\u00fdch a digit\u00e1lnych procesn\u00fdch hodn\u00f4t do siete Modbus/RTU alebo Modbus/TCP.",
                  },
                ],
              },
              {
                id: "HKI0000101",
                parent_id: "VJI0000101",
                text: "PROFIBUS",
                X_fullName: "PROFIBUS",
                X_fullName_SK: "PROFIBUS",
                X_URL: "profibus",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS",
                X_Description:
                  "PROFIBUS is a worldwide leading field system for the automation of machines and production systems.",
                X_Description_SK:
                  "PROFIBUS je popredn\u00fd svetov\u00fd syst\u00e9m pre automatiz\u00e1ciu strojov a v\u00fdrobn\u00fdch syst\u00e9mov.",
                items: [
                  {
                    id: "IKI0000101",
                    parent_id: "HKI0000101",
                    text: "Gateways and proxies",
                    X_fullName: "Gateways and proxies",
                    X_fullName_SK: "Br\u00e1ny a proxy servery",
                    X_URL: "gateways-proxies",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS\\Gateways and proxies",
                    X_Description:
                      "PROFIBUS proxies are the link between PROFIBUS and PROFINET. Gateways establish connections to other bus systems.",
                    X_Description_SK:
                      "PROFIBUS proxy s\u00fa prepojen\u00edm medzi PROFIBUS a PROFINET. Br\u00e1ny vytv\u00e1raj\u00fa spojenie s in\u00fdmi zbernicov\u00fdmi syst\u00e9mami.",
                  },
                  {
                    id: "JKI0000101",
                    parent_id: "HKI0000101",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_fullName_SK: "K\u00e1ble a konektory",
                    X_URL: "profibus-cables-and-connectors",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS\\Cables and connectors",
                    X_Description:
                      "Bus systems cables and connectors for PROFIBUS in various lengths, pre-assembled or sold by the meter.",
                    X_Description_SK:
                      "Zbernicov\u00e9 syst\u00e9my, k\u00e1ble a konektory pre PROFIBUS v r\u00f4znych d\u013a\u017ekach, predmontovan\u00e9 alebo pred\u00e1van\u00e9 na metre.",
                  },
                  {
                    id: "KKI0000101",
                    parent_id: "HKI0000101",
                    text: "Fieldbus components for PROFIBUS PA",
                    X_fullName: "Fieldbus components for PROFIBUS PA",
                    X_fullName_SK: "Komponenty zbernice pre PROFIBUS PA",
                    X_URL: "fieldbus-components-profibus-pa",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS\\Fieldbus components for PROFIBUS PA",
                    X_Description:
                      "Modular fieldbus components for PROFIBUS PA applications for communication from the process controller to the field devices.",
                    X_Description_SK:
                      "Modul\u00e1rne komponenty fieldbus pre aplik\u00e1cie PROFIBUS PA na komunik\u00e1ciu z procesn\u00e9ho kontrol\u00e9ra do prev\u00e1dzkov\u00fdch zariaden\u00ed.",
                  },
                  {
                    id: "LKI0000101",
                    parent_id: "HKI0000101",
                    text: "Segment coupler",
                    X_fullName: "Segment coupler",
                    X_fullName_SK: "Segmentov\u00e1 spojka",
                    X_URL: "segment-coupler",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS\\Segment coupler",
                    X_Description:
                      "Segment coupler for reliable connection of PROFIBUS PA to PROFIBUS DP networks.",
                    X_Description_SK:
                      "Segmentov\u00e1 spojka pre spo\u013eahliv\u00e9 pripojenie PROFIBUS PA k sie\u0165am PROFIBUS DP.",
                  },
                ],
              },
              {
                id: "MKI0000101",
                parent_id: "VJI0000101",
                text: "PROFINET",
                X_fullName: "PROFINET",
                X_fullName_SK: "PROFINET",
                X_URL: "profinet",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFINET",
                X_Description:
                  "PROFINET is an Ethernet-based fieldbus system and provides consistent data transmission across all levels.",
                X_Description_SK:
                  "PROFINET je syst\u00e9m fieldbus zalo\u017een\u00fd na Ethernete a poskytuje konzistentn\u00fd prenos d\u00e1t na v\u0161etk\u00fdch \u00farovniach.",
                items: [
                  {
                    id: "NKI0000101",
                    parent_id: "MKI0000101",
                    text: "Gateways and proxies",
                    X_fullName: "Gateways and proxies",
                    X_fullName_SK: "Br\u00e1ny a proxy servery",
                    X_URL: "gateways-and-proxies",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFINET\\Gateways and proxies",
                    X_Description:
                      "Proxies integrate existing fieldbus installations into PROFINET. Gateways link fieldbus networks.",
                    X_Description_SK:
                      "Proxy integruj\u00fa existuj\u00face priemyseln\u00e9 in\u0161tal\u00e1cie do PROFINET. Br\u00e1ny sp\u00e1jaj\u00fa siete fieldbus.",
                  },
                  {
                    id: "OKI0000101",
                    parent_id: "MKI0000101",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_fullName_SK: "K\u00e1ble a konektory",
                    X_URL: "profinet-cables-and-connectors",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFINET\\Cables and connectors",
                    X_Description:
                      "PROFINET cables and connectors in various lengths and degrees of protection, pre-assembled or sold by the meter.",
                    X_Description_SK:
                      "K\u00e1ble a konektory PROFINET v r\u00f4znych d\u013a\u017ekach a stup\u0148och ochrany, vopred zmontovan\u00e9 alebo pred\u00e1van\u00e9 na metr\u00e1\u017e.",
                  },
                ],
              },
              {
                id: "PKI0000101",
                parent_id: "VJI0000101",
                text: "Sercos",
                X_fullName: "Sercos",
                X_fullName_SK: "Sercos",
                X_URL: "sercos",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\Sercos",
                X_Description:
                  "Sercos components for fast, synchronous data transmission in automation technology.",
                X_Description_SK:
                  "Komponenty Sercos pre r\u00fdchly, synchr\u00f3nny prenos d\u00e1t v automatiza\u010dnej technike.",
                items: [
                  {
                    id: "QKI0000101",
                    parent_id: "PKI0000101",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_fullName_SK: "K\u00e1ble a konektory",
                    X_URL: "seros-cables-and-connectors",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\Sercos\\Cables and connectors",
                    X_Description:
                      "Sercos cables and connectors in various lengths and degrees of protection, pre-assembled or sold by the meter.",
                    X_Description_SK:
                      "K\u00e1ble a konektory Sercos v r\u00f4znych d\u013a\u017ekach a stup\u0148och ochrany, vopred zmontovan\u00e9 alebo pred\u00e1van\u00e9 v metr\u00e1\u017ei.",
                  },
                ],
              },
              {
                id: "RKI0000101",
                parent_id: "VJI0000101",
                text: "FOUNDATION Fieldbus",
                X_fullName: "FOUNDATION Fieldbus",
                X_fullName_SK: "FOUNDATION Fieldbus",
                X_URL: "foundation-fieldbus",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\FOUNDATION Fieldbus",
                X_Description:
                  "Fieldbus components for the modular configuration of FOUNDATION Fieldbus applications.",
                X_Description_SK:
                  "Komponenty Fieldbus pre modul\u00e1rnu konfigur\u00e1ciu aplik\u00e1ci\u00ed FOUNDATION Fieldbus.",
                items: [
                  {
                    id: "SKI0000101",
                    parent_id: "RKI0000101",
                    text: "Fieldbus distributor boxes",
                    X_fullName: "Fieldbus distributor boxes",
                    X_fullName_SK: "Rozv\u00e1dza\u010de Fieldbus",
                    X_URL: "fieldbus-distributor-boxes",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\FOUNDATION Fieldbus\\Fieldbus distributor boxes",
                    X_Description:
                      "Field distributor boxes in various versions, developed specifically for field device coupler systems.",
                    X_Description_SK:
                      "Krabice prev\u00e1dzkov\u00fdch rozv\u00e1dza\u010dov v r\u00f4znych verzi\u00e1ch, vyvinut\u00e9 \u0161peci\u00e1lne pre spojovacie syst\u00e9my prev\u00e1dzkov\u00fdch zariaden\u00ed.",
                  },
                  {
                    id: "TKI0000101",
                    parent_id: "RKI0000101",
                    text: "Device couplers",
                    X_fullName: "Device couplers",
                    X_fullName_SK: "Spojky zariaden\u00ed",
                    X_URL: "device-couplers",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\FOUNDATION Fieldbus\\Device couplers",
                    X_Description:
                      "Device couplers in various versions for the field and the control cabinet.",
                    X_Description_SK:
                      "Pr\u00edstrojov\u00e9 spojky v r\u00f4znych verzi\u00e1ch pre pole a rozv\u00e1dza\u010d.",
                  },
                  {
                    id: "UKI0000101",
                    parent_id: "RKI0000101",
                    text: "Power supply units",
                    X_fullName: "Power supply units",
                    X_fullName_SK: "Nap\u00e1jacie zdroje",
                    X_URL: "fieldbus-psu",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\FOUNDATION Fieldbus\\Power supply units",
                    X_Description:
                      "Redundant or single-channel power supply units for the DIN rail supply voltage for a FOUNDATION Fieldbus segment.",
                    X_Description_SK:
                      "Redundantn\u00e9 alebo jednokan\u00e1lov\u00e9 nap\u00e1jacie jednotky pre nap\u00e1jacie nap\u00e4tie na DIN li\u0161tu pre segment FOUNDATION Fieldbus.",
                  },
                ],
              },
            ],
          },
          {
            id: "VKI0000101",
            parent_id: "HII0000101",
            text: "Fieldbus communication",
            X_fullName: "Fieldbus communication",
            X_fullName_SK: "Komunik\u00e1cia Fieldbus",
            X_URL: "fieldbus-communication",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Fieldbus communication",
            X_Description:
              "Universal communication with Phoenix Contact interface devices",
            X_Description_SK:
              "Univerz\u00e1lna komunik\u00e1cia so zariadeniami rozhrania Phoenix Contact",
            items: [
              {
                id: "WKI0000101",
                parent_id: "VKI0000101",
                text: "Installation technology",
                X_fullName: "Installation technology",
                X_fullName_SK: "Technol\u00f3gia in\u0161tal\u00e1cie",
                X_URL: "installation-technology",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Installation technology",
                X_Description:
                  "Phoenix Contact installation technology provides you with all the cables, connectors, and tools you need to create fiber-optic and copper fieldbus networks that are suitable for industrial applications.",
                X_Description_SK:
                  "In\u0161tala\u010dn\u00e1 technika Phoenix Contact v\u00e1m poskytuje v\u0161etky k\u00e1ble, konektory a n\u00e1stroje, ktor\u00e9 potrebujete na vytvorenie optick\u00fdch a meden\u00fdch siet\u00ed fieldbus, ktor\u00e9 s\u00fa vhodn\u00e9 pre priemyseln\u00e9 aplik\u00e1cie.",
                items: [
                  {
                    id: "XKI0000101",
                    parent_id: "WKI0000101",
                    text: "Fast connection plugs",
                    X_fullName: "Fast connection plugs",
                    X_fullName_SK: "R\u00fdchle pripojenie z\u00e1str\u010diek",
                    X_URL: "fast-connection-plugs",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Installation technology\\Fast connection plugs",
                    X_Description:
                      "Comprehensive product range for user-friendly cable connection using D-SUB fast connection plugs.",
                    X_Description_SK:
                      "Rozsiahly sortiment produktov pre u\u017e\u00edvate\u013esky pr\u00edvetiv\u00e9 k\u00e1blov\u00e9 pripojenie pomocou r\u00fdchlych konektorov D-SUB.",
                    items: [
                      {
                        id: "YKI0000101",
                        parent_id: "XKI0000101",
                        text: "D-SUB fast connectors",
                        X_fullName: "D-SUB fast connectors",
                        X_fullName_SK: "R\u00fdchle konektory D-SUB",
                        X_URL: "d-sub-fast-connectors",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Installation technology\\Fast connection plugs\\D-SUB fast connectors",
                        X_Description:
                          "Fast connectors in various versions for convenient connection on site.",
                        X_Description_SK:
                          "R\u00fdchle konektory v r\u00f4znych verzi\u00e1ch pre pohodln\u00e9 pripojenie na mieste.",
                      },
                    ],
                  },
                ],
              },
              {
                id: "ZKI0000101",
                parent_id: "VKI0000101",
                text: "Converters and isolators",
                X_fullName: "Converters and isolators",
                X_fullName_SK: "Konvertory a izol\u00e1tory",
                X_URL: "converters-and-isolators",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators",
                X_Description:
                  "Converters enable you to convert various serial interfaces to other standards using a protocol-transparent method.",
                X_Description_SK:
                  "Konvertory v\u00e1m umo\u017e\u0148uj\u00fa konvertova\u0165 r\u00f4zne s\u00e9riov\u00e9 rozhrania na in\u00e9 \u0161tandardy pomocou protokolu transparentnej met\u00f3dy.",
                items: [
                  {
                    id: "0LI0000101",
                    parent_id: "ZKI0000101",
                    text: "Media converter",
                    X_fullName: "Media converter",
                    X_fullName_SK: "Konvertor m\u00e9di\u00ed",
                    X_URL: "media-converter",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Media converter",
                    X_Description:
                      "Media converters enable you to convert your copper-based interfaces to interference-free fiber optics.",
                    X_Description_SK:
                      "Konvertory m\u00e9di\u00ed v\u00e1m umo\u017e\u0148uj\u00fa konvertova\u0165 va\u0161e meden\u00e9 rozhrania na optick\u00e9 vl\u00e1kna bez ru\u0161enia.",
                    items: [
                      {
                        id: "1LI0000101",
                        parent_id: "0LI0000101",
                        text: "Media converters for fieldbuses",
                        X_fullName: "Media converters for fieldbuses",
                        X_fullName_SK:
                          "Konvertory m\u00e9di\u00ed pre po\u013en\u00e9 zbernice",
                        X_URL: "media-converters-for-fieldbuses",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Media converter\\Media converters for fieldbuses",
                        X_Description:
                          "Fiber optic media converters for fieldbuses in various different versions can be used with polymer fiber, HCS fiber or fiberglass.",
                        X_Description_SK:
                          "M\u00e9diov\u00e9 konvertory z optick\u00fdch vl\u00e1kien pre priemyseln\u00e9 zbernice v r\u00f4znych verzi\u00e1ch mo\u017eno pou\u017ei\u0165 s polym\u00e9rov\u00fdm vl\u00e1knom, HCS vl\u00e1knom alebo sklolamin\u00e1tom.",
                      },
                      {
                        id: "2LI0000101",
                        parent_id: "0LI0000101",
                        text: "Serial media converters",
                        X_fullName: "Serial media converters",
                        X_fullName_SK:
                          "S\u00e9riov\u00e9 konvertory m\u00e9di\u00ed",
                        X_URL: "serial-media-converters",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Media converter\\Serial media converters",
                        X_Description:
                          "Fiber optic media converters for serial interfaces in various different versions can be used with polymer fiber, HCS fiber or fiberglass.",
                        X_Description_SK:
                          "M\u00e9diov\u00e9 konvertory z optick\u00fdch vl\u00e1kien pre s\u00e9riov\u00e9 rozhrania v r\u00f4znych verzi\u00e1ch mo\u017eno pou\u017ei\u0165 s polym\u00e9rov\u00fdm vl\u00e1knom, HCS vl\u00e1knom alebo sklolamin\u00e1tom.",
                      },
                    ],
                  },
                  {
                    id: "4LI0000101",
                    parent_id: "ZKI0000101",
                    text: "Interface converters",
                    X_fullName: "Interface converters",
                    X_fullName_SK: "Konvertory rozhrania",
                    X_URL: "interface-converters",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Interface converters",
                    X_Description:
                      "Interface converters, available in different versions, for adapting serial interfaces.",
                    X_Description_SK: "",
                  },
                  {
                    id: "6LI0000101",
                    parent_id: "ZKI0000101",
                    text: "Field device couplers",
                    X_fullName: "Field device couplers",
                    X_fullName_SK: "Spojky po\u013en\u00fdch zariaden\u00ed",
                    X_URL: "field-device-couplers",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Field device couplers",
                    X_Description:
                      "Field device couplers for distributed fieldbus extension.",
                    X_Description_SK: "",
                  },
                ],
              },
              {
                id: "7LI0000101",
                parent_id: "VKI0000101",
                text: "Extenders and repeaters",
                X_fullName: "Extenders and repeaters",
                X_fullName_SK: "Roz\u0161irova\u010de a opakova\u010de",
                X_URL: "extenders-and-repeaters",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Extenders and repeaters",
                X_Description:
                  "Repeaters and extenders increase network performance and extend the number of devices.",
                X_Description_SK:
                  "Opakova\u010de a extendery zvy\u0161uj\u00fa v\u00fdkon siete a roz\u0161iruj\u00fa po\u010det zariaden\u00ed.",
                items: [
                  {
                    id: "8LI0000101",
                    parent_id: "7LI0000101",
                    text: "Repeater",
                    X_fullName: "Repeater",
                    X_fullName_SK: "Opakova\u010d",
                    X_URL: "repeater",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Extenders and repeaters\\Repeater",
                    X_Description:
                      "Repeaters for amplifying signals and organizing segments.",
                    X_Description_SK:
                      "Opakova\u010de na zosilnenie sign\u00e1lov a organiz\u00e1ciu segmentov.",
                  },
                ],
              },
              {
                id: "ALI0000101",
                parent_id: "VKI0000101",
                text: "Gateways",
                X_fullName: "Gateways",
                X_fullName_SK: "Br\u00e1ny",
                X_URL: "gateways",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Gateways",
                X_Description:
                  "Gateways for connecting automation devices with serial interfaces to Ethernet networks.",
                X_Description_SK: "",
              },
            ],
          },
        ],
      },
      {
        id: "BLI0000101",
        parent_id: "HFI0000101",
        text: "Installation and mounting material",
        X_fullName: "Installation and mounting material",
        X_fullName_SK:
          "In\u0161tala\u010dn\u00fd a mont\u00e1\u017eny materi\u00e1l",
        X_URL: "installation-mounting-material",
        FULLPATH: "PhoenixContact\\Installation and mounting material",
        X_Description:
          "Space saving and extremely easy. Our installation and mounting material stands for modern control cabinet technology.",
        X_Description_SK:
          "\u00daspora miesta a ve\u013emi jednoduch\u00e9. N\u00e1\u0161 in\u0161tala\u010dn\u00fd a mont\u00e1\u017eny materi\u00e1l predstavuje modern\u00fa technol\u00f3giu rozv\u00e1dza\u010dov.",
        items: [
          {
            id: "CLI0000101",
            parent_id: "BLI0000101",
            text: "Installation material for the control cabinet",
            X_fullName: "Installation material for the control cabinet",
            X_fullName_SK:
              "In\u0161tala\u010dn\u00fd materi\u00e1l pre rozv\u00e1dza\u010d",
            X_URL: "control-cabinet-installation",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet",
            X_Description:
              "Installation material for the control cabinet simplifies mounting and ensures that connections are fixed securely.",
            X_Description_SK:
              "In\u0161tala\u010dn\u00fd materi\u00e1l pre rozvodn\u00fa skri\u0148u zjednodu\u0161uje mont\u00e1\u017e a zais\u0165uje bezpe\u010dn\u00e9 upevnenie spojov.",
            items: [
              {
                id: "DLI0000101",
                parent_id: "CLI0000101",
                text: "Branch terminals",
                X_fullName: "Branch terminals",
                X_fullName_SK: "Odbo\u010dn\u00e9 svorky",
                X_URL: "branch-terminals",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Branch terminals",
                X_Description:
                  "Branch terminals provide an easy solution for branching flat copper busbars.",
                X_Description_SK:
                  "Odbo\u010dovacie svorky poskytuj\u00fa jednoduch\u00e9 rie\u0161enie pre rozvetvenie ploch\u00fdch meden\u00fdch pr\u00edpojn\u00edc.",
              },
              {
                id: "ELI0000101",
                parent_id: "CLI0000101",
                text: "Supports",
                X_fullName: "Supports",
                X_fullName_SK: "Podpora",
                X_URL: "supports",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Supports",
                X_Description:
                  "Supports for shield connection clamps are simply snapped onto the DIN rail.",
                X_Description_SK:
                  "Podpery pre svorky na pripojenie tienenia sa jednoducho nacvakn\u00fa na li\u0161tu DIN.",
              },
              {
                id: "FLI0000101",
                parent_id: "CLI0000101",
                text: "Power terminal blocks",
                X_fullName: "Power terminal blocks",
                X_fullName_SK: "Nap\u00e1jacie svorkovnice",
                X_URL: "power-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Power terminal blocks",
                X_Description:
                  "Various power terminals are available for busbars in switchgear.",
                X_Description_SK:
                  "Pre pr\u00edpojnice v rozv\u00e1dza\u010di s\u00fa k dispoz\u00edcii r\u00f4zne v\u00fdkonov\u00e9 svorky.",
              },
              {
                id: "GLI0000101",
                parent_id: "CLI0000101",
                text: "Neutral busbars",
                X_fullName: "Neutral busbars",
                X_fullName_SK: "Neutr\u00e1lne pr\u00edpojnice",
                X_URL: "neutral-busbars",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Neutral busbars",
                X_Description:
                  "Neutral busbars, 1000 mm long, are available in two widths: 6 x 6 or 3 x 10 mm.",
                X_Description_SK:
                  "Neutr\u00e1lne pr\u00edpojnice, dlh\u00e9 1 000 mm, s\u00fa k dispoz\u00edcii v dvoch \u0161\u00edrkach: 6 x 6 alebo 3 x 10 mm.",
              },
              {
                id: "HLI0000101",
                parent_id: "CLI0000101",
                text: "Sockets",
                X_fullName: "Sockets",
                X_fullName_SK: "Z\u00e1suvky",
                X_URL: "installation-sockets",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Sockets",
                X_Description:
                  "DIN rail-mountable sockets provide a user-friendly connection for assembly tools, servicing devices, and other equipment.",
                X_Description_SK:
                  "Z\u00e1suvky na mont\u00e1\u017e na DIN li\u0161tu poskytuj\u00fa u\u017e\u00edvate\u013esky pr\u00edvetiv\u00e9 pripojenie pre mont\u00e1\u017ene n\u00e1radie, servisn\u00e9 zariadenia a \u010fal\u0161ie vybavenie.",
              },
              {
                id: "ILI0000101",
                parent_id: "CLI0000101",
                text: "DIN rails",
                X_fullName: "DIN rails",
                X_fullName_SK: "Li\u0161ty DIN",
                X_URL: "din-rails",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\DIN rails",
                X_Description:
                  "Standardized DIN rails provide the basis for a secure hold for DIN rail-mountable components for the control cabinet.",
                X_Description_SK:
                  "\u0160tandardizovan\u00e9 li\u0161ty DIN poskytuj\u00fa z\u00e1klad pre bezpe\u010dn\u00e9 uchytenie komponentov riadiacej skrine na mont\u00e1\u017e na li\u0161tu DIN.",
              },
              {
                id: "JLI0000101",
                parent_id: "CLI0000101",
                text: "Cable ducts",
                X_fullName: "Cable ducts",
                X_fullName_SK: "K\u00e1blov\u00e9 kan\u00e1ly",
                X_URL: "cable-ducts",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Cable ducts",
                X_Description:
                  "Cable ducts enable flexible and easy cable installation without tools.",
                X_Description_SK:
                  "K\u00e1blov\u00e9 kan\u00e1ly umo\u017e\u0148uj\u00fa flexibiln\u00fa a jednoduch\u00fa in\u0161tal\u00e1ciu k\u00e1blov bez n\u00e1radia.",
              },
              {
                id: "KLI0000101",
                parent_id: "CLI0000101",
                text: "Cover profiles",
                X_fullName: "Cover profiles",
                X_fullName_SK: "Obalov\u00e9 profily",
                X_URL: "cover-profiles",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Cover profiles",
                X_Description:
                  "Cover profiles prevent accidental contact with terminal strips or unauthorized actuation of connections.",
                X_Description_SK:
                  "Krycie profily zabra\u0148uj\u00fa n\u00e1hodn\u00e9mu kontaktu so svorkovnicou alebo neopr\u00e1vnenej aktiv\u00e1cii spojov.",
              },
              {
                id: "LLI0000101",
                parent_id: "CLI0000101",
                text: "End clamps",
                X_fullName: "End clamps",
                X_fullName_SK: "Koncov\u00e9 svorky",
                X_URL: "end-clamps",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\End clamps",
                X_Description:
                  "Depending on the application, end clamps are fastened using screws or are simply snapped onto the DIN rail.",
                X_Description_SK:
                  "V z\u00e1vislosti od pou\u017eitia sa koncov\u00e9 svorky upev\u0148uj\u00fa pomocou skrutiek alebo sa jednoducho nacvakn\u00fa na DIN li\u0161tu.",
              },
              {
                id: "MLI0000101",
                parent_id: "CLI0000101",
                text: "Cable bundling",
                X_fullName: "Cable bundling",
                X_fullName_SK: "Zv\u00e4zovanie k\u00e1blov",
                X_URL: "cable-bundling",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Cable bundling",
                X_Description:
                  "Cable binder, cable driller or cable binder base made from plastic or stainless steel.",
                X_Description_SK:
                  "K\u00e1blov\u00fd viaza\u010d, v\u0155ta\u010dka alebo podstavec k\u00e1blov\u00e9ho zv\u00e4zku vyroben\u00fd z plastu alebo nehrdzavej\u00facej ocele.",
              },
            ],
          },
          {
            id: "NLI0000101",
            parent_id: "BLI0000101",
            text: "Grounding and shielding",
            X_fullName: "Grounding and shielding",
            X_fullName_SK: "Uzemnenie a tienenie",
            X_URL: "grounding-and-shielding",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Grounding and shielding",
            X_Description:
              "Appropriate shielding protects systems against electromagnetic interference.",
            X_Description_SK:
              "Vhodn\u00e9 tienenie chr\u00e1ni syst\u00e9my pred elektromagnetick\u00fdm ru\u0161en\u00edm.",
            items: [
              {
                id: "OLI0000101",
                parent_id: "NLI0000101",
                text: "Grounding terminal blocks",
                X_fullName: "Grounding terminal blocks",
                X_fullName_SK: "Uzem\u0148ovacie svorkovnice",
                X_URL: "grounding-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Grounding and shielding\\Grounding terminal blocks",
                X_Description:
                  "Ground terminal blocks and connection terminal blocks for PE distribution via DIN rails and busbars.",
                X_Description_SK:
                  "Uzem\u0148ovacie svorkovnice a pripojovacie svorkovnice pre rozvod PE cez DIN li\u0161ty a pr\u00edpojnice.",
              },
              {
                id: "PLI0000101",
                parent_id: "NLI0000101",
                text: "Shield connection clamps",
                X_fullName: "Shield connection clamps",
                X_fullName_SK: "Svorky na pripojenie \u0161t\u00edtu",
                X_URL: "shield-connection-clamps",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Grounding and shielding\\Shield connection clamps",
                X_Description:
                  "Shield connection clamps are suitable for all standard cable shielding and enable EMC-compliant wiring.",
                X_Description_SK:
                  "Svorky na pripojenie tienenia s\u00fa vhodn\u00e9 pre v\u0161etky \u0161tandardn\u00e9 tienenie k\u00e1blov a umo\u017e\u0148uj\u00fa zapojenie v s\u00falade s EMC.",
              },
            ],
          },
          {
            id: "QLI0000101",
            parent_id: "BLI0000101",
            text: "Cable entry system",
            X_fullName: "Cable entry system",
            X_fullName_SK: "K\u00e1blov\u00fd vstupn\u00fd syst\u00e9m",
            X_URL: "cable-entry-system",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Cable entry system",
            X_Description:
              "Cable entries simplify mounting and ensure the safe routing of cables.",
            X_Description_SK:
              "K\u00e1blov\u00e9 vstupy zjednodu\u0161uj\u00fa mont\u00e1\u017e a zais\u0165uj\u00fa bezpe\u010dn\u00e9 vedenie k\u00e1blov.",
            items: [
              {
                id: "RLI0000101",
                parent_id: "QLI0000101",
                text: "Modular cable entry system",
                X_fullName: "Modular cable entry system",
                X_fullName_SK: "Modul\u00e1rny syst\u00e9m vstupu k\u00e1blov",
                X_URL: "modular-cable-entry-system",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable entry system\\Modular cable entry system",
                X_Description:
                  "The modular entry system for pre-assembled cables provides a simple solution for control cabinet cable feed-throughs.",
                X_Description_SK:
                  "Modul\u00e1rny vstupn\u00fd syst\u00e9m pre vopred zmontovan\u00e9 k\u00e1ble poskytuje jednoduch\u00e9 rie\u0161enie k\u00e1blov\u00fdch priechodiek rozv\u00e1dza\u010da.",
              },
              {
                id: "SLI0000101",
                parent_id: "QLI0000101",
                text: "Cable entry plates",
                X_fullName: "Cable entry plates",
                X_fullName_SK: "K\u00e1blov\u00e9 vstupn\u00e9 dosky",
                X_URL: "cable-entry-plates",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable entry system\\Cable entry plates",
                X_Description:
                  "Metal-reinforced cable entry plates for unassembled cables.",
                X_Description_SK:
                  "Kovov\u00e9 vystu\u017een\u00e9 k\u00e1blov\u00e9 vstupn\u00e9 dosky pre nezmontovan\u00e9 k\u00e1ble.",
              },
            ],
          },
          {
            id: "TLI0000101",
            parent_id: "BLI0000101",
            text: "Sockets and service interfaces",
            X_fullName: "Sockets and service interfaces",
            X_fullName_SK: "Vsuvky a rozhrania slu\u017eieb",
            X_URL: "sockets-and-service-interfaces",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Sockets and service interfaces",
            X_Description:
              "Comprehensive product range for reliable maintenance and testing.",
            X_Description_SK:
              "Komplexn\u00fd sortiment pre spo\u013eahliv\u00fa \u00fadr\u017ebu a testovanie.",
            items: [
              {
                id: "ULI0000101",
                parent_id: "TLI0000101",
                text: "Service sockets",
                X_fullName: "Service sockets",
                X_fullName_SK: "Servisn\u00e9 z\u00e1suvky",
                X_URL: "service-sockets",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Sockets and service interfaces\\Service sockets",
                X_Description:
                  "Service sockets as a compact programming interface in various versions.",
                X_Description_SK:
                  "Servisn\u00e9 z\u00e1suvky ako kompaktn\u00e9 programovacie rozhranie v r\u00f4znych verzi\u00e1ch.",
              },
              {
                id: "VLI0000101",
                parent_id: "TLI0000101",
                text: "Service interfaces",
                X_fullName: "Service interfaces",
                X_fullName_SK: "Rozhrania slu\u017eieb",
                X_URL: "service-interfaces",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Sockets and service interfaces\\Service interfaces",
                X_Description:
                  "Comprehensive product range for reliable maintenance or testing.",
                X_Description_SK:
                  "Komplexn\u00fd sortiment pre spo\u013eahliv\u00fa \u00fadr\u017ebu alebo testovanie.",
              },
              {
                id: "WLI0000101",
                parent_id: "TLI0000101",
                text: "Sockets",
                X_fullName: "Sockets",
                X_fullName_SK: "Z\u00e1suvky",
                X_URL: "sockets",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Sockets and service interfaces\\Sockets",
                X_Description:
                  "Control cabinet sockets with various connector geometries for universal use and numerous additional functions.",
                X_Description_SK:
                  "Z\u00e1suvky riadiacej skrine s r\u00f4znymi geometriami konektorov pre univerz\u00e1lne pou\u017eitie a po\u010detn\u00e9 doplnkov\u00e9 funkcie.",
              },
            ],
          },
          {
            id: "YLI0000101",
            parent_id: "BLI0000101",
            text: "DIN rail mounting frame",
            X_fullName: "DIN rail mounting frame",
            X_fullName_SK: "Mont\u00e1\u017eny r\u00e1m na DIN li\u0161tu",
            X_URL: "din-rail-mounting-frame",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\DIN rail mounting frame",
            X_Description:
              "Plastic and metal DIN rail mounting frames for the space-saving connection of contact inserts directly on the DIN rail.",
            X_Description_SK: "",
          },
          {
            id: "ZLI0000101",
            parent_id: "BLI0000101",
            text: "Cable protection systems",
            X_fullName: "Cable protection systems",
            X_fullName_SK: "Syst\u00e9my ochrany k\u00e1blov",
            X_URL: "cable-protection-systems",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Cable protection systems",
            X_Description:
              "Plastic or metal protection systems for cables and lines.",
            X_Description_SK:
              "Plastov\u00e9 alebo kovov\u00e9 ochrann\u00e9 syst\u00e9my pre k\u00e1ble a vedenia.",
            items: [
              {
                id: "0MI0000101",
                parent_id: "ZLI0000101",
                text: "Protective sleeves",
                X_fullName: "Protective sleeves",
                X_fullName_SK: "Ochrann\u00e9 ruk\u00e1vy",
                X_URL: "protective-sleeves",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable protection systems\\Protective sleeves",
                X_Description:
                  "Protective hoses provide optimum protection for cables against various external influences.",
                X_Description_SK:
                  "Ochrann\u00e9 hadice poskytuj\u00fa optim\u00e1lnu ochranu k\u00e1blov pred r\u00f4znymi vonkaj\u0161\u00edmi vplyvmi.",
              },
              {
                id: "1MI0000101",
                parent_id: "ZLI0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "cable-protection-accessories",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable protection systems\\Accessories",
                X_Description:
                  "Accessories for cable protection systems, for example, protective hose holder, fixing bracket, O-ring.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo pre syst\u00e9my ochrany k\u00e1blov, napr\u00edklad dr\u017eiak ochrannej hadice, upev\u0148ovac\u00ed dr\u017eiak, O-kr\u00fa\u017eok.",
              },
            ],
          },
          {
            id: "2MI0000101",
            parent_id: "BLI0000101",
            text: "Cable glands",
            X_fullName: "Cable glands",
            X_fullName_SK: "K\u00e1blov\u00e9 v\u00fdvodky",
            X_URL: "cable-glands",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Cable glands",
            X_Description:
              "Reliable screw connections seal cables against water and dust.",
            X_Description_SK:
              "Spo\u013eahliv\u00e9 skrutkov\u00e9 spoje utes\u0148uj\u00fa k\u00e1ble proti vode a prachu.",
            items: [
              {
                id: "3MI0000101",
                parent_id: "2MI0000101",
                text: "Standard cable glands",
                X_fullName: "Standard cable glands",
                X_fullName_SK:
                  "\u0160tandardn\u00e9 k\u00e1blov\u00e9 v\u00fdvodky",
                X_URL: "standard-cable-glands",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable glands\\Standard cable glands",
                X_Description:
                  "Cable glands are used for the watertight and dust-proof routing of cables through control cabinet panels.",
                X_Description_SK:
                  "K\u00e1blov\u00e9 v\u00fdvodky sa pou\u017e\u00edvaj\u00fa na vodotesn\u00e9 a prachotesn\u00e9 vedenie k\u00e1blov cez panely rozv\u00e1dza\u010dov.",
              },
              {
                id: "4MI0000101",
                parent_id: "2MI0000101",
                text: "Cable glands for hazardous areas",
                X_fullName: "Cable glands for hazardous areas",
                X_fullName_SK:
                  "K\u00e1blov\u00e9 v\u00fdvodky pre oblasti s nebezpe\u010denstvom v\u00fdbuchu",
                X_URL: "cable-glands-for-hazardous-areas",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable glands\\Cable glands for hazardous areas",
                X_Description: "Seal cables and lines reliably in the ex area.",
                X_Description_SK:
                  "Spo\u013eahlivo utesnite k\u00e1ble a vedenia vo v\u00fdbu\u0161nom prostred\u00ed.",
              },
            ],
          },
          {
            id: "5MI0000101",
            parent_id: "BLI0000101",
            text: "Junction boxes and empty enclosures",
            X_fullName: "Junction boxes and empty enclosures",
            X_fullName_SK: "Spojovacie skrinky a pr\u00e1zdne skrine",
            X_URL: "junction-boxes-and-enclosures",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures",
            X_Description:
              "With CLIPSAFE, you can get your custom control cabinet solution quickly and from a single source.",
            X_Description_SK:
              "S CLIPSAFE m\u00f4\u017eete z\u00edska\u0165 svoje vlastn\u00e9 rie\u0161enie rozv\u00e1dza\u010da r\u00fdchlo a z jedn\u00e9ho zdroja.",
            items: [
              {
                id: "6MI0000101",
                parent_id: "5MI0000101",
                text: "Ex stainless steel empty enclosure",
                X_fullName: "Ex stainless steel empty enclosure",
                X_fullName_SK:
                  "Ex pr\u00e1zdny kryt z nehrdzavej\u00facej ocele",
                X_URL: "stainless-steel-empty-enclosure",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex stainless steel empty enclosure",
                X_Description:
                  "Individually customizable stainless steel enclosures for use in hazardous areas.",
                X_Description_SK:
                  "Individu\u00e1lne prisp\u00f4sobite\u013en\u00e9 kryty z nehrdzavej\u00facej ocele pre pou\u017eitie v nebezpe\u010dn\u00fdch priestoroch.",
                items: [
                  {
                    id: "7MI0000101",
                    parent_id: "6MI0000101",
                    text: "Ex empty enclosure with cover",
                    X_fullName: "Ex empty enclosure with cover",
                    X_fullName_SK: "Ex pr\u00e1zdna skri\u0148a s krytom",
                    X_URL: "ex-stainless-empty-enclosure-cov",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex stainless steel empty enclosure\\Ex empty enclosure with cover",
                    X_Description:
                      "Standardized CLIPSAFE empty enclosure for hazardous areas.",
                    X_Description_SK:
                      "\u0160tandardizovan\u00e9 pr\u00e1zdne puzdro CLIPSAFE pre nebezpe\u010dn\u00e9 priestory.",
                  },
                  {
                    id: "8MI0000101",
                    parent_id: "6MI0000101",
                    text: "Ex empty enclosure with door",
                    X_fullName: "Ex empty enclosure with door",
                    X_fullName_SK: "Ex pr\u00e1zdna skri\u0148a s dverami",
                    X_URL: "ex-stainless-empty-enclosure",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex stainless steel empty enclosure\\Ex empty enclosure with door",
                    X_Description:
                      "Stainless steel empty enclosure with door for safe use in hazardous areas",
                    X_Description_SK:
                      "Pr\u00e1zdny kryt z nehrdzavej\u00facej ocele s dverami pre bezpe\u010dn\u00e9 pou\u017eitie v nebezpe\u010dn\u00fdch priestoroch",
                  },
                ],
              },
              {
                id: "9MI0000101",
                parent_id: "5MI0000101",
                text: "Ex polyester empty enclosure",
                X_fullName: "Ex polyester empty enclosure",
                X_fullName_SK: "Ex polyesterov\u00e1 pr\u00e1zdna skrinka",
                X_URL: "ex-polyester-empty-enclosure",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure",
                X_Description:
                  "With CLIPSAFE polyester Ex enclosures, get your custom control cabinet solution for hazardous areas.",
                X_Description_SK:
                  "S polyesterov\u00fdmi krytmi CLIPSAFE Ex z\u00edskate svoje vlastn\u00e9 rie\u0161enie riadiacej skrine pre nebezpe\u010dn\u00e9 oblasti.",
                items: [
                  {
                    id: "AMI0000101",
                    parent_id: "9MI0000101",
                    text: "Ex empty enclosure with cover",
                    X_fullName: "Ex empty enclosure with cover",
                    X_fullName_SK: "Ex pr\u00e1zdna skri\u0148a s krytom",
                    X_URL: "ex-empty-enclosure-with-cover",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure\\Ex empty enclosure with cover",
                    X_Description:
                      "Polyester empty enclosures with cover for safe use in hazardous areas",
                    X_Description_SK:
                      "Polyesterov\u00e9 pr\u00e1zdne puzdro s krytom pre bezpe\u010dn\u00e9 pou\u017eitie v nebezpe\u010dn\u00fdch priestoroch",
                  },
                  {
                    id: "BMI0000101",
                    parent_id: "9MI0000101",
                    text: "Ex empty enclosure with door",
                    X_fullName: "Ex empty enclosure with door",
                    X_fullName_SK: "Ex pr\u00e1zdna skri\u0148a s dverami",
                    X_URL: "ex-empty-enclosure-with-door",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure\\Ex empty enclosure with door",
                    X_Description:
                      "Polyester empty enclosures with door for safe use in hazardous areas",
                    X_Description_SK:
                      "Polyesterov\u00e9 pr\u00e1zdne kryty s dverami pre bezpe\u010dn\u00e9 pou\u017eitie v nebezpe\u010dn\u00fdch priestoroch",
                  },
                  {
                    id: "CMI0000101",
                    parent_id: "9MI0000101",
                    text: "Ex empty enclosure with two wiring levels",
                    X_fullName: "Ex empty enclosure with two wiring levels",
                    X_fullName_SK:
                      "Ex pr\u00e1zdna skri\u0148a s dvoma \u00farov\u0148ami zapojenia",
                    X_URL: "2-wiringlevels-empty-enclosure",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure\\Ex empty enclosure with two wiring levels",
                    X_Description:
                      "Polyester empty enclosure with two wiring levels for safe use in the ex area",
                    X_Description_SK:
                      "Polyesterov\u00fd pr\u00e1zdny kryt s dvoma \u00farov\u0148ami zapojenia pre bezpe\u010dn\u00e9 pou\u017eitie v prostred\u00ed s nebezpe\u010denstvom v\u00fdbuchu",
                  },
                  {
                    id: "DMI0000101",
                    parent_id: "9MI0000101",
                    text: "Ex empty enclosure, octagonal",
                    X_fullName: "Ex empty enclosure, octagonal",
                    X_fullName_SK:
                      "Ex pr\u00e1zdna skri\u0148a, osemhrann\u00e1",
                    X_URL: "octagonal-enclosure",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure\\Ex empty enclosure, octagonal",
                    X_Description:
                      "Polyester empty enclosure in octagonal design for safe use in the ex area",
                    X_Description_SK:
                      "Polyesterov\u00e1 pr\u00e1zdna skri\u0148a v osemhrannom dizajne pre bezpe\u010dn\u00e9 pou\u017eitie vo v\u00fdbu\u0161nom prostred\u00ed",
                  },
                ],
              },
              {
                id: "EMI0000101",
                parent_id: "5MI0000101",
                text: "Empty polyester enclosure",
                X_fullName: "Empty polyester enclosure",
                X_fullName_SK: "Pr\u00e1zdny polyesterov\u00fd kryt",
                X_URL: "empty-enclosure",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Empty polyester enclosure",
                X_Description:
                  "You will be completely satisfied with our extensive and consistent range of CLIPSAFE polyester enclosures.",
                X_Description_SK:
                  "Budete \u00faplne spokojn\u00ed s na\u0161ou rozsiahlou a konzistentnou \u0161k\u00e1lou krytov polyesterov CLIPSAFE.",
                items: [
                  {
                    id: "FMI0000101",
                    parent_id: "EMI0000101",
                    text: "Empty enclosure with cover",
                    X_fullName: "Empty enclosure with cover",
                    X_fullName_SK: "Pr\u00e1zdna skrinka s krytom",
                    X_URL: "empty-enclosure-cover",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Empty polyester enclosure\\Empty enclosure with cover",
                    X_Description: "Empty polyester enclosure",
                    X_Description_SK: "Pr\u00e1zdny polyesterov\u00fd kryt",
                  },
                  {
                    id: "GMI0000101",
                    parent_id: "EMI0000101",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                    X_URL: "empty-enclosure-accessories",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Empty polyester enclosure\\Accessories",
                    X_Description: "Accessories for polyester empty enclosures",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo pre polyesterov\u00e9 pr\u00e1zdne kryty",
                  },
                ],
              },
            ],
          },
          {
            id: "IMI0000101",
            parent_id: "BLI0000101",
            text: "Cable routing system",
            X_fullName: "Cable routing system",
            X_fullName_SK: "Syst\u00e9m vedenia k\u00e1blov",
            X_URL: "cable-routing-system",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Cable routing system",
            X_Description:
              "Cable and conductor routing for the control cabinet door",
            X_Description_SK: "",
          },
        ],
      },
      {
        id: "JMI0000101",
        parent_id: "HFI0000101",
        text: "Cables and lines",
        X_fullName: "Cables and lines",
        X_fullName_SK: "K\u00e1ble a vedenia",
        X_URL: "cables-and-lines-main",
        FULLPATH: "PhoenixContact\\Cables and lines",
        X_Description:
          "Are you looking for a flexible assembly solution? With the right copper or FO cable, you'll find the perfect solution for your application.",
        X_Description_SK:
          "H\u013ead\u00e1te flexibiln\u00e9 rie\u0161enie mont\u00e1\u017ee? So spr\u00e1vnym meden\u00fdm alebo FO k\u00e1blom n\u00e1jdete dokonal\u00e9 rie\u0161enie pre va\u0161u aplik\u00e1ciu.",
        items: [
          {
            id: "KMI0000101",
            parent_id: "JMI0000101",
            text: "Copper cables",
            X_fullName: "Copper cables",
            X_fullName_SK: "Meden\u00e9 k\u00e1ble",
            X_URL: "copper-cables",
            FULLPATH: "PhoenixContact\\Cables and lines\\Copper cables",
            X_Description:
              "Copper cables are available in different versions for a number of applications.",
            X_Description_SK:
              "Meden\u00e9 k\u00e1ble s\u00fa dostupn\u00e9 v r\u00f4znych verzi\u00e1ch pre mno\u017estvo aplik\u00e1ci\u00ed.",
            items: [
              {
                id: "LMI0000101",
                parent_id: "KMI0000101",
                text: "Assembled cables",
                X_fullName: "Assembled cables",
                X_fullName_SK: "Zmontovan\u00e9 k\u00e1ble",
                X_URL: "assembled-cables-cooper",
                FULLPATH:
                  "PhoenixContact\\Cables and lines\\Copper cables\\Assembled cables",
                X_Description:
                  "Assembled copper cables are available in various lengths and with different connector designs.",
                X_Description_SK:
                  "Osaden\u00e9 meden\u00e9 k\u00e1ble s\u00fa dostupn\u00e9 v r\u00f4znych d\u013a\u017ekach a s r\u00f4znymi dizajnmi konektorov.",
              },
              {
                id: "MMI0000101",
                parent_id: "KMI0000101",
                text: "By the meter",
                X_fullName: "By the meter",
                X_fullName_SK: "Pod\u013ea po\u010dtu metrov",
                X_URL: "cables-by-the-meter",
                FULLPATH:
                  "PhoenixContact\\Cables and lines\\Copper cables\\By the meter",
                X_Description:
                  "Copper cables are available with various numbers of positions and cross sections in variable lengths sold by the meter.",
                X_Description_SK:
                  "Meden\u00e9 k\u00e1ble s\u00fa k dispoz\u00edcii s r\u00f4znym po\u010dtom poz\u00edci\u00ed a prierezov v r\u00f4znych d\u013a\u017ekach pred\u00e1van\u00fdch na metre.",
              },
              {
                id: "NMI0000101",
                parent_id: "KMI0000101",
                text: "Tools and accessories",
                X_fullName: "Tools and accessories",
                X_fullName_SK: "N\u00e1stroje a pr\u00edslu\u0161enstvo",
                X_URL: "tools-and-accessories",
                FULLPATH:
                  "PhoenixContact\\Cables and lines\\Copper cables\\Tools and accessories",
                X_Description:
                  "Tools and accessories for cables, such as torque screwdrivers, nuts, stripping pliers, marking accessories.",
                X_Description_SK:
                  "N\u00e1stroje a pr\u00edslu\u0161enstvo pre k\u00e1ble, ako s\u00fa momentov\u00e9 skrutkova\u010de, matice, odizolovacie klie\u0161te, pr\u00edslu\u0161enstvo na ozna\u010dovanie.",
              },
            ],
          },
          {
            id: "OMI0000101",
            parent_id: "JMI0000101",
            text: "Fiber optic cables",
            X_fullName: "Fiber optic cables",
            X_fullName_SK: "Optick\u00e9 k\u00e1ble",
            X_URL: "fiber-optic-cables",
            FULLPATH: "PhoenixContact\\Cables and lines\\Fiber optic cables",
            X_Description:
              "FO cables for various applications: sold by the meter or pre-assembled.",
            X_Description_SK:
              "Optick\u00e9 k\u00e1ble pre r\u00f4zne aplik\u00e1cie: pred\u00e1van\u00e9 na metre alebo vopred zmontovan\u00e9.",
            items: [
              {
                id: "PMI0000101",
                parent_id: "OMI0000101",
                text: "Assembled cables",
                X_fullName: "Assembled cables",
                X_fullName_SK: "Zmontovan\u00e9 k\u00e1ble",
                X_URL: "assembled-cables-fiber",
                FULLPATH:
                  "PhoenixContact\\Cables and lines\\Fiber optic cables\\Assembled cables",
                X_Description:
                  "Assembled fiber optic cables are available with various connector designs and fiber types.",
                X_Description_SK:
                  "Zostaven\u00e9 k\u00e1ble z optick\u00fdch vl\u00e1kien s\u00fa k dispoz\u00edcii s r\u00f4znymi dizajnmi konektorov a typmi vl\u00e1kien.",
              },
            ],
          },
        ],
      },
      {
        id: "QMI0000101",
        parent_id: "HFI0000101",
        text: "PCB terminal blocks and connectors",
        X_fullName: "PCB terminal blocks and connectors",
        X_fullName_SK:
          "Svorkovnice a konektory na doske plo\u0161n\u00fdch spojov",
        X_URL: "pcb-terminal-blocks-connectors",
        FULLPATH: "PhoenixContact\\PCB terminal blocks and connectors",
        X_Description:
          "Everything is possible with arguably the largest range of PCB connection technology: COMBICON from Phoenix Contact.",
        X_Description_SK:
          "V\u0161etko je mo\u017en\u00e9 s pravdepodobne naj\u0161ir\u0161\u00edm sortimentom spojovacej techniky PCB: COMBICON od Phoenix Contact.",
        items: [
          {
            id: "SMI0000101",
            parent_id: "QMI0000101",
            text: "PCB terminal blocks",
            X_fullName: "PCB terminal blocks",
            X_fullName_SK: "Svorkovnice PCB",
            X_URL: "pcb-terminal-blocks",
            FULLPATH:
              "PhoenixContact\\PCB terminal blocks and connectors\\PCB terminal blocks",
            X_Description:
              "PCB terminal blocks for transmission of signals, data, and power directly to the PCB.",
            X_Description_SK: "",
          },
          {
            id: "UMI0000101",
            parent_id: "QMI0000101",
            text: "PCB connectors",
            X_fullName: "PCB connectors",
            X_fullName_SK: "Konektory PCB",
            X_URL: "pcb-connectors",
            FULLPATH:
              "PhoenixContact\\PCB terminal blocks and connectors\\PCB connectors",
            X_Description:
              "PCB connectors for virtually all device designs of various different sectors and markets.",
            X_Description_SK: "",
          },
          {
            id: "WMI0000101",
            parent_id: "QMI0000101",
            text: "High-current panel feed-through terminal blocks",
            X_fullName: "High-current panel feed-through terminal blocks",
            X_fullName_SK:
              "Priechodov\u00e9 svorkovnice pre vysokopr\u00fadov\u00e9 panely",
            X_URL: "pcb-high-current-terminal-blocks",
            FULLPATH:
              "PhoenixContact\\PCB terminal blocks and connectors\\High-current panel feed-through terminal blocks",
            X_Description:
              "High-current feed-through terminal blocks for transmitting high currents and voltages through the housing wall.",
            X_Description_SK: "",
          },
          {
            id: "YMI0000101",
            parent_id: "QMI0000101",
            text: "Board-to-board connectors",
            X_fullName: "Board-to-board connectors",
            X_fullName_SK: "Konektory medzi doskami",
            X_URL: "board-to-board-connectors",
            FULLPATH:
              "PhoenixContact\\PCB terminal blocks and connectors\\Board-to-board connectors",
            X_Description:
              "FINEPITCH board-to-board connectors provide solutions for signal and data transmission.",
            X_Description_SK: "",
          },
        ],
      },
      {
        id: "ZMI0000101",
        parent_id: "HFI0000101",
        text: "Marking and labeling",
        X_fullName: "Marking and labeling",
        X_fullName_SK: "Ozna\u010dovanie a etiketovanie",
        X_URL: "marking-and-labeling",
        FULLPATH: "PhoenixContact\\Marking and labeling",
        X_Description:
          "The easy way of marking. \u2013 Our solutions for marking and labeling can be tailored to your specific requirements.",
        X_Description_SK:
          "Jednoduch\u00fd sp\u00f4sob ozna\u010dovania. \u2013 Na\u0161e rie\u0161enia pre ozna\u010dovanie a ozna\u010dovanie m\u00f4\u017eu by\u0165 prisp\u00f4soben\u00e9 va\u0161im \u0161pecifick\u00fdm po\u017eiadavk\u00e1m.",
        items: [
          {
            id: "0NI0000101",
            parent_id: "ZMI0000101",
            text: "Printing and marking systems",
            X_fullName: "Printing and marking systems",
            X_fullName_SK: "Tla\u010dov\u00e9 a ozna\u010dovacie syst\u00e9my",
            X_URL: "printing-and-marking-systems",
            FULLPATH:
              "PhoenixContact\\Marking and labeling\\Printing and marking systems",
            X_Description:
              "The printing systems are versatile: they can be used for low and high print volumes and for various different materials and formats.",
            X_Description_SK:
              "Tla\u010dov\u00e9 syst\u00e9my s\u00fa v\u0161estrann\u00e9: mo\u017eno ich pou\u017ei\u0165 pre mal\u00e9 a ve\u013ek\u00e9 objemy tla\u010de a pre r\u00f4zne materi\u00e1ly a form\u00e1ty.",
            items: [
              {
                id: "1NI0000101",
                parent_id: "0NI0000101",
                text: "UV LED printing system",
                X_fullName: "UV LED printing system",
                X_fullName_SK: "UV LED tla\u010dov\u00fd syst\u00e9m",
                X_URL: "uv-led-printing-system",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\UV LED printing system",
                X_Description:
                  "The UV LED printing system labels large quantities quickly and easily, with high-quality results.",
                X_Description_SK:
                  "UV LED tla\u010dov\u00fd syst\u00e9m r\u00fdchlo a jednoducho ozna\u010d\u00ed ve\u013ek\u00e9 mno\u017estv\u00e1 s vysoko kvalitn\u00fdmi v\u00fdsledkami.",
                items: [
                  {
                    id: "2NI0000101",
                    parent_id: "1NI0000101",
                    text: "BLUEMARK",
                    X_fullName: "BLUEMARK",
                    X_fullName_SK: "BLUEMARK",
                    X_URL: "bluemark",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\UV LED printing system\\BLUEMARK",
                    X_Description:
                      "The BLUEMARK CLED high-speed printer prints materials in UniCard format quickly and easily.",
                    X_Description_SK:
                      "Vysokor\u00fdchlostn\u00e1 tla\u010diare\u0148 BLUEMARK CLED tla\u010d\u00ed materi\u00e1ly vo form\u00e1te UniCard r\u00fdchlo a jednoducho.",
                  },
                  {
                    id: "3NI0000101",
                    parent_id: "1NI0000101",
                    text: "Accessories and consumables",
                    X_fullName: "Accessories and consumables",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo a spotrebn\u00fd materi\u00e1l",
                    X_URL: "uv-accessories-and-consumables",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\UV LED printing system\\Accessories and consumables",
                    X_Description:
                      "Accessories and consumables for the high-speed printer, such as fluid cartridges, card chutes, and transport case.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo a spotrebn\u00fd materi\u00e1l pre vysokor\u00fdchlostn\u00fa tla\u010diare\u0148, ako s\u00fa n\u00e1plne tekut\u00edn, z\u00e1sobn\u00edky na karty a prepravn\u00e9 puzdro.",
                  },
                  {
                    id: "4NI0000101",
                    parent_id: "1NI0000101",
                    text: "Marking material for BLUEMARK",
                    X_fullName: "Marking material for BLUEMARK",
                    X_fullName_SK:
                      "Ozna\u010dovac\u00ed materi\u00e1l pre BLUEMARK",
                    X_URL: "marking-material-for-bluemark",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\UV LED printing system\\Marking material for BLUEMARK",
                    X_Description:
                      "UniCard formats and aluminum markers for marking with the BLUEMARK CLED high-speed printer.",
                    X_Description_SK:
                      "Form\u00e1ty UniCard a hlin\u00edkov\u00e9 popisova\u010de na zna\u010denie pomocou vysokor\u00fdchlostnej tla\u010diarne BLUEMARK CLED.",
                  },
                ],
              },
              {
                id: "5NI0000101",
                parent_id: "0NI0000101",
                text: "Plotter and engraving system",
                X_fullName: "Plotter and engraving system",
                X_fullName_SK: "Plotter a grav\u00edrovac\u00ed syst\u00e9m",
                X_URL: "plotter-and-engraving-system",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Plotter and engraving system",
                X_Description:
                  "The plotter with engraving system for markings in challenging environments is very easy to use.",
                X_Description_SK:
                  "Ploter s grav\u00edrovac\u00edm syst\u00e9mom pre zna\u010denie v n\u00e1ro\u010dn\u00fdch prostrediach sa ve\u013emi \u013eahko pou\u017e\u00edva.",
                items: [
                  {
                    id: "6NI0000101",
                    parent_id: "5NI0000101",
                    text: "Plotter for labeling",
                    X_fullName: "Plotter for labeling",
                    X_fullName_SK: "Plotter na ozna\u010dovanie",
                    X_URL: "plotter-for-labeling",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Plotter and engraving system\\Plotter for labeling",
                    X_Description:
                      "The plotter for labeling is operated easily using just a few keys.",
                    X_Description_SK:
                      "Ploter na ozna\u010dovanie sa ovl\u00e1da jednoducho pomocou nieko\u013ek\u00fdch tla\u010didiel.",
                  },
                  {
                    id: "7NI0000101",
                    parent_id: "5NI0000101",
                    text: "Plotter with engraving unit",
                    X_fullName: "Plotter with engraving unit",
                    X_fullName_SK: "Plotter s grav\u00edrovacou jednotkou",
                    X_URL: "plotter-with-engraving-unit",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Plotter and engraving system\\Plotter with engraving unit",
                    X_Description:
                      "The plotter with engraving unit is the ideal marking solution for devices and components subjected to high levels of mechanical and chemical stress.",
                    X_Description_SK:
                      "Ploter s grav\u00edrovacou jednotkou je ide\u00e1lnym rie\u0161en\u00edm na ozna\u010dovanie zariaden\u00ed a komponentov, ktor\u00e9 s\u00fa vystaven\u00e9 vysokej \u00farovni mechanick\u00e9ho a chemick\u00e9ho nam\u00e1hania.",
                  },
                  {
                    id: "8NI0000101",
                    parent_id: "5NI0000101",
                    text: "Accessories and consumables",
                    X_fullName: "Accessories and consumables",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo a spotrebn\u00fd materi\u00e1l",
                    X_URL: "plot-accessories-and-consumables",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Plotter and engraving system\\Accessories and consumables",
                    X_Description:
                      "The range includes exchangeable magazines, plotter pens, and marker pens.",
                    X_Description_SK:
                      "Sortiment zah\u0155\u0148a vymenite\u013en\u00e9 z\u00e1sobn\u00edky, plotrov\u00e9 per\u00e1 a zna\u010dkovacie per\u00e1.",
                  },
                ],
              },
              {
                id: "9NI0000101",
                parent_id: "0NI0000101",
                text: "Thermal transfer printing system",
                X_fullName: "Thermal transfer printing system",
                X_fullName_SK:
                  "Termotransferov\u00fd tla\u010dov\u00fd syst\u00e9m",
                X_URL: "thermal-transfer-printing-system",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Thermal transfer printing system",
                X_Description:
                  "The thermal transfer printing system prints on numerous materials quickly and easily. The device is available individually or as part of a set including notebook.",
                X_Description_SK:
                  "Syst\u00e9m termotransferovej tla\u010de tla\u010d\u00ed na mno\u017estvo materi\u00e1lov r\u00fdchlo a jednoducho. Zariadenie je dostupn\u00e9 samostatne alebo ako s\u00fa\u010das\u0165 setu vr\u00e1tane notebooku.",
                items: [
                  {
                    id: "ANI0000101",
                    parent_id: "9NI0000101",
                    text: "Individual devices",
                    X_fullName: "Individual devices",
                    X_fullName_SK: "Jednotliv\u00e9 zariadenia",
                    X_URL: "individual-devices",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Thermal transfer printing system\\Individual devices",
                    X_Description:
                      "THERMOMARK CARD prints markings in card format, while THERMOMARK ROLL prints markings in roll format.",
                    X_Description_SK:
                      "THERMOMARK CARD tla\u010d\u00ed zna\u010dky vo form\u00e1te karty, zatia\u013e \u010do THERMOMARK ROLL tla\u010d\u00ed zna\u010dky vo form\u00e1te kot\u00fa\u010da.",
                  },
                  {
                    id: "BNI0000101",
                    parent_id: "9NI0000101",
                    text: "Accessories and consumables",
                    X_fullName: "Accessories and consumables",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo a spotrebn\u00fd materi\u00e1l",
                    X_URL: "accessories-and-consumables",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Thermal transfer printing system\\Accessories and consumables",
                    X_Description:
                      "Accessories and consumables for thermal transfer printers, such as magazines, cutters, and ink ribbons.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo a spotrebn\u00fd materi\u00e1l pre termotransferov\u00e9 tla\u010diarne, ako s\u00fa \u010dasopisy, reza\u010dky a farbiace p\u00e1sky.",
                  },
                  {
                    id: "CNI0000101",
                    parent_id: "9NI0000101",
                    text: "Marking material for THERMOMARK ROLL",
                    X_fullName: "Marking material for THERMOMARK ROLL",
                    X_fullName_SK:
                      "Ozna\u010dovac\u00ed materi\u00e1l pre THERMOMARK ROLL",
                    X_URL: "thermomark-roll-marking-material",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Thermal transfer printing system\\Marking material for THERMOMARK ROLL",
                    X_Description:
                      "Material off the roll and continuous media for marking with the THERMOMARK ROLL.",
                    X_Description_SK:
                      "Materi\u00e1l z kot\u00fa\u010da a s\u00favisl\u00e9 m\u00e9dium na zna\u010denie pomocou THERMOMARK ROLL.",
                  },
                ],
              },
              {
                id: "DNI0000101",
                parent_id: "0NI0000101",
                text: "Laser marking system",
                X_fullName: "Laser marking system",
                X_fullName_SK: "Syst\u00e9m laserov\u00e9ho zna\u010denia",
                X_URL: "laser-marking-system",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Laser marking system",
                X_Description:
                  "Fast direct laser marking for challenging industrial identification.",
                X_Description_SK:
                  "R\u00fdchle priame laserov\u00e9 ozna\u010denie pre n\u00e1ro\u010dn\u00fa priemyseln\u00fa identifik\u00e1ciu.",
                items: [
                  {
                    id: "ENI0000101",
                    parent_id: "DNI0000101",
                    text: "Laser marking systems",
                    X_fullName: "Laser marking systems",
                    X_fullName_SK:
                      "Laserov\u00e9 ozna\u010dovacie syst\u00e9my",
                    X_URL: "laser-marking-systems",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Laser marking system\\Laser marking systems",
                    X_Description:
                      "TOPMARK LASER \u2013 the fastest desktop laser marker in its class.",
                    X_Description_SK:
                      "Topark Laser - najr\u00fdchlej\u0161\u00ed stoln\u00fd laserov\u00fd marker vo svojej triede.",
                  },
                  {
                    id: "FNI0000101",
                    parent_id: "DNI0000101",
                    text: "Marking material",
                    X_fullName: "Marking material",
                    X_fullName_SK: "Ozna\u010dovac\u00ed materi\u00e1l",
                    X_URL: "laser-marking-material",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Laser marking system\\Marking material",
                    X_Description:
                      "Marking material for direct laser marking using the TOPMARK LASER.",
                    X_Description_SK:
                      "Zna\u010dkovac\u00ed materi\u00e1l pre priame laserov\u00e9 zna\u010denie pomocou TOPMARK LASER.",
                  },
                  {
                    id: "GNI0000101",
                    parent_id: "DNI0000101",
                    text: "Accessories for the direct laser marking system",
                    X_fullName:
                      "Accessories for the direct laser marking system",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo pre syst\u00e9m priameho laserov\u00e9ho zna\u010denia",
                    X_URL: "laser-marking-system-accessories",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Laser marking system\\Accessories for the direct laser marking system",
                    X_Description:
                      "Carriage, magazines, and notebook for direct laser marking using the TOPMARK LASER.",
                    X_Description_SK:
                      "Carriage, z\u00e1sobn\u00edky a z\u00e1znamn\u00edky pre priame laserov\u00e9 ozna\u010denie pomocou TOPMARK LASER.",
                  },
                ],
              },
              {
                id: "HNI0000101",
                parent_id: "0NI0000101",
                text: "Mobile thermal transfer printing system",
                X_fullName: "Mobile thermal transfer printing system",
                X_fullName_SK:
                  "Mobiln\u00fd termotransferov\u00fd tla\u010dov\u00fd syst\u00e9m",
                X_URL: "mobile-thermal-transfer-printing",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Mobile thermal transfer printing system",
                X_Description:
                  "Thanks to the mobile printing systems, you can print markings right where they are needed",
                X_Description_SK:
                  "V\u010faka mobiln\u00fdm tla\u010dov\u00fdm syst\u00e9mom m\u00f4\u017eete tla\u010di\u0165 zna\u010dky priamo tam, kde s\u00fa potrebn\u00e9",
                items: [
                  {
                    id: "INI0000101",
                    parent_id: "HNI0000101",
                    text: "THERMOMARK PRIME accessories",
                    X_fullName: "THERMOMARK PRIME accessories",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo THERMOMARK PRIME",
                    X_URL: "thermomark-prime-accessories",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Mobile thermal transfer printing system\\THERMOMARK PRIME accessories",
                    X_Description:
                      "Accessories such as power supply unit, replaceable battery, transport case or rucksack facilitate on-site operation of the printer.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo ako nap\u00e1jac\u00ed zdroj, vymenite\u013en\u00e1 bat\u00e9ria, prepravn\u00fd kufr\u00edk alebo ruksak u\u013eah\u010duje obsluhu tla\u010diarne na mieste.",
                  },
                  {
                    id: "JNI0000101",
                    parent_id: "HNI0000101",
                    text: "THERMOFOX accessories",
                    X_fullName: "THERMOFOX accessories",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo THERMOFOX",
                    X_URL: "thermofox-accessories",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Mobile thermal transfer printing system\\THERMOFOX accessories",
                    X_Description:
                      "Whether in a transport case, in the shoulder bag or on the practical belt clip, various accessories enable the device to be transported conveniently.",
                    X_Description_SK:
                      "\u010ci u\u017e v prepravnom kufr\u00edku, v ta\u0161ke cez rameno alebo na praktickej spone na opasok, r\u00f4zne pr\u00edslu\u0161enstvo umo\u017e\u0148uje pohodln\u00fa prepravu zariadenia.",
                  },
                  {
                    id: "KNI0000101",
                    parent_id: "HNI0000101",
                    text: "Marking material for THERMOFOX",
                    X_fullName: "Marking material for THERMOFOX",
                    X_fullName_SK:
                      "Ozna\u010dovac\u00ed materi\u00e1l pre THERMOFOX",
                    X_URL: "marking-material-thermofox",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Mobile thermal transfer printing system\\Marking material for THERMOFOX",
                    X_Description:
                      "The THERMOFOX can be used to mark a variety of materials for terminal, conductor, and equipment marking.",
                    X_Description_SK:
                      "THERMOFOX je mo\u017en\u00e9 pou\u017ei\u0165 na ozna\u010denie r\u00f4znych materi\u00e1lov na ozna\u010denie svoriek, vodi\u010dov a zariaden\u00ed.",
                  },
                ],
              },
            ],
          },
          {
            id: "LNI0000101",
            parent_id: "ZMI0000101",
            text: "Device marking",
            X_fullName: "Device marking",
            X_fullName_SK: "Ozna\u010denie zariadenia",
            X_URL: "device-marking-main",
            FULLPATH: "PhoenixContact\\Marking and labeling\\Device marking",
            X_Description:
              "With various materials and mounting types, the equipment markings cover all requirements.",
            X_Description_SK:
              "Pri r\u00f4znych materi\u00e1loch a typoch mont\u00e1\u017ee pokr\u00fdvaj\u00fa ozna\u010denia zariaden\u00ed v\u0161etky po\u017eiadavky.",
            items: [
              {
                id: "MNI0000101",
                parent_id: "LNI0000101",
                text: "Device marking",
                X_fullName: "Device marking",
                X_fullName_SK: "Ozna\u010denie zariadenia",
                X_URL: "device-marking",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Device marking\\Device marking",
                X_Description:
                  "Self-adhesive device markings feature high adhesive strength and can be labeled quickly and easily.",
                X_Description_SK:
                  "Samolepiace zna\u010dky zariaden\u00ed maj\u00fa vysok\u00fa pri\u013enavos\u0165 a daj\u00fa sa r\u00fdchlo a jednoducho ozna\u010di\u0165.",
              },
              {
                id: "NNI0000101",
                parent_id: "LNI0000101",
                text: "Marker carrier",
                X_fullName: "Marker carrier",
                X_fullName_SK: "Nosi\u010d zna\u010diek",
                X_URL: "marker-carrier",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Device marking\\Marker carrier",
                X_Description:
                  "The plastic labels are used in marker carriers for marking.",
                X_Description_SK:
                  "Plastov\u00e9 \u0161t\u00edtky sa pou\u017e\u00edvaj\u00fa v nosi\u010doch popisova\u010dov na ozna\u010dovanie.",
              },
              {
                id: "ONI0000101",
                parent_id: "LNI0000101",
                text: "Device marking, marked according to customer requi",
                X_fullName:
                  "Device marking, marked according to customer requi",
                X_fullName_SK:
                  "Ozna\u010denie zariadenia, ozna\u010den\u00e9 pod\u013ea po\u017eiadaviek z\u00e1kazn\u00edka",
                X_URL: "marked-according-to-customer",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Device marking\\Device marking, marked according to customer requi",
                X_Description:
                  "Device marking, individually marked according to customer requirements",
                X_Description_SK:
                  "Ozna\u010denie pr\u00edstroja, individu\u00e1lne ozna\u010den\u00e9 pod\u013ea po\u017eiadaviek z\u00e1kazn\u00edka",
              },
            ],
          },
          {
            id: "QNI0000101",
            parent_id: "ZMI0000101",
            text: "Terminal marking",
            X_fullName: "Terminal marking",
            X_fullName_SK: "Ozna\u010denie svoriek",
            X_URL: "terminal-marking",
            FULLPATH: "PhoenixContact\\Marking and labeling\\Terminal marking",
            X_Description:
              "Labeled and unlabeled terminal markings are available in various formats, including roll and sheet form.",
            X_Description_SK: "",
          },
          {
            id: "RNI0000101",
            parent_id: "ZMI0000101",
            text: "Conductor and cable marking",
            X_fullName: "Conductor and cable marking",
            X_fullName_SK: "Ozna\u010denie vodi\u010dov a k\u00e1blov",
            X_URL: "conductor-and-cable-marking-main",
            FULLPATH:
              "PhoenixContact\\Marking and labeling\\Conductor and cable marking",
            X_Description:
              "Wire markings are suitable for every application, whether for fine single wires or heavy-duty cables.",
            X_Description_SK:
              "Ozna\u010denie vodi\u010dov je vhodn\u00e9 pre ka\u017ed\u00fa aplik\u00e1ciu, \u010di u\u017e ide o jemn\u00e9 jednotliv\u00e9 vodi\u010de alebo k\u00e1ble pre ve\u013ek\u00e9 za\u0165a\u017eenie.",
            items: [
              {
                id: "SNI0000101",
                parent_id: "RNI0000101",
                text: "Conductor and cable marking",
                X_fullName: "Conductor and cable marking",
                X_fullName_SK: "Ozna\u010denie vodi\u010dov a k\u00e1blov",
                X_URL: "conductor-and-cable-marking",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Conductor and cable marking\\Conductor and cable marking",
                X_Description:
                  "Wires and cables can be labeled in various ways, for example, using thread-on, slide-on or adhesive markings.",
                X_Description_SK:
                  "Dr\u00f4ty a k\u00e1ble je mo\u017en\u00e9 ozna\u010dova\u0165 r\u00f4znymi sp\u00f4sobmi, napr\u00edklad pomocou navliekac\u00edch, nas\u00favac\u00edch alebo lepiacich zna\u010diek.",
              },
              {
                id: "TNI0000101",
                parent_id: "RNI0000101",
                text: "Conductor and cable marking, marked according to c",
                X_fullName:
                  "Conductor and cable marking, marked according to c",
                X_fullName_SK:
                  "Ozna\u010denie vodi\u010dov a k\u00e1blov, ozna\u010den\u00e9 pod\u013ea c",
                X_URL: "customer-requirement-marking",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Conductor and cable marking\\Conductor and cable marking, marked according to c",
                X_Description:
                  "Wire and cable marking, individually marked according to customer requirements",
                X_Description_SK:
                  "Ozna\u010denie vodi\u010dov a k\u00e1blov, individu\u00e1lne ozna\u010den\u00e9 pod\u013ea po\u017eiadaviek z\u00e1kazn\u00edka",
              },
            ],
          },
          {
            id: "VNI0000101",
            parent_id: "ZMI0000101",
            text: "System marking",
            X_fullName: "System marking",
            X_fullName_SK: "Ozna\u010denie syst\u00e9mu",
            X_URL: "system-marking",
            FULLPATH: "PhoenixContact\\Marking and labeling\\System marking",
            X_Description: "Plant marking for greater safety and orientation.",
            X_Description_SK: "",
          },
          {
            id: "WNI0000101",
            parent_id: "ZMI0000101",
            text: "RFID system for system and device marking",
            X_fullName: "RFID system for system and device marking",
            X_fullName_SK:
              "Syst\u00e9m RFID na ozna\u010dovanie syst\u00e9mov a zariaden\u00ed",
            X_URL: "rfid-for-system-device-marking",
            FULLPATH:
              "PhoenixContact\\Marking and labeling\\RFID system for system and device marking",
            X_Description:
              "Store and read information on marking material with the integrated RFID transponders.",
            X_Description_SK:
              "Ukladajte a \u010d\u00edtajte inform\u00e1cie o zna\u010dkovacom materi\u00e1li pomocou integrovan\u00fdch transpond\u00e9rov RFID.",
            items: [
              {
                id: "XNI0000101",
                parent_id: "WNI0000101",
                text: "Marking material",
                X_fullName: "Marking material",
                X_fullName_SK: "Ozna\u010dovac\u00ed materi\u00e1l",
                X_URL: "marking-material",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\RFID system for system and device marking\\Marking material",
                X_Description:
                  "UniCard marking material, with integrated RFID transponder.",
                X_Description_SK:
                  "Zna\u010dkovac\u00ed materi\u00e1l UniCard, s integrovan\u00fdm RFID transpond\u00e9rom.",
              },
            ],
          },
        ],
      },
      {
        id: "YNI0000101",
        parent_id: "HFI0000101",
        text: "MCR technology",
        X_fullName: "MCR technology",
        X_fullName_SK: "Technol\u00f3gia MCR",
        X_URL: "mcr-technology",
        FULLPATH: "PhoenixContact\\MCR technology",
        X_Description:
          "Measurement and control components from Phoenix Contact offer innovative solutions for the automation of your processes \u2013 from sensor level to control level.",
        X_Description_SK:
          "Komponenty merania a riadenia od spolo\u010dnosti Phoenix Contact pon\u00fakaj\u00fa inovat\u00edvne rie\u0161enia pre automatiz\u00e1ciu va\u0161ich procesov \u2013 od \u00farovne senzorov a\u017e po \u00farove\u0148 riadenia.",
        items: [
          {
            id: "ZNI0000101",
            parent_id: "YNI0000101",
            text: "Signal conditioner",
            X_fullName: "Signal conditioner",
            X_fullName_SK: "Kondicion\u00e9r sign\u00e1lu",
            X_URL: "signal-conditioner",
            FULLPATH: "PhoenixContact\\MCR technology\\Signal conditioner",
            X_Description:
              "Reliable signal transmission, isolation, conversion, filtering, and amplification \u2013 our signal conditioners ensure interference-free transmission of analog signals.",
            X_Description_SK:
              "Spo\u013eahliv\u00fd prenos sign\u00e1lu, izol\u00e1cia, konverzia, filtrovanie a zosilnenie \u2013 na\u0161e prevodn\u00edky sign\u00e1lu zabezpe\u010duj\u00fa prenos anal\u00f3gov\u00fdch sign\u00e1lov bez ru\u0161enia.",
            items: [
              {
                id: "0OI0000101",
                parent_id: "ZNI0000101",
                text: "Highly compact signal conditioners",
                X_fullName: "Highly compact signal conditioners",
                X_fullName_SK:
                  "Vysoko kompaktn\u00e9 prevodn\u00edky sign\u00e1lu",
                X_URL: "highly-compact-sig-conditioners",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners",
                X_Description:
                  "Isolate, convert, filter, and amplify with the highly-compact signal conditioners from the MINI Analog range.",
                X_Description_SK:
                  "Izolujte, konvertujte, filtrujte a zosil\u0148ujte pomocou vysoko kompaktn\u00fdch kondicion\u00e9rov sign\u00e1lu z radu MINI Analog.",
                items: [
                  {
                    id: "1OI0000101",
                    parent_id: "0OI0000101",
                    text: "Active input and output signal conditioners",
                    X_fullName: "Active input and output signal conditioners",
                    X_fullName_SK:
                      "Kondicion\u00e9ry akt\u00edvneho vstupn\u00e9ho a v\u00fdstupn\u00e9ho sign\u00e1lu",
                    X_URL: "active-io-signal-conditioners",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Active input and output signal conditioners",
                    X_Description:
                      "Ultra-compact isolating amplifiers for electrical isolation, conversion, amplification, and filtering of standard analog signals.",
                    X_Description_SK:
                      "Ultra kompaktn\u00e9 odde\u013eovacie zosil\u0148ova\u010de pre elektrick\u00fa izol\u00e1ciu, konverziu, zosilnenie a filtrovanie \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "2OI0000101",
                    parent_id: "0OI0000101",
                    text: "Signal duplicators",
                    X_fullName: "Signal duplicators",
                    X_fullName_SK: "Duplik\u00e1tory sign\u00e1lov",
                    X_URL: "highly-signal-duplicators",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Signal duplicators",
                    X_Description:
                      "Ultra-compact signal duplicators for electrical isolation, conversion, amplification, and filtering of standard analog signals.",
                    X_Description_SK:
                      "Ultra-kompaktn\u00e9 duplik\u00e1tory sign\u00e1lu na elektrick\u00fa izol\u00e1ciu, konverziu, zosilnenie a filtrovanie \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "3OI0000101",
                    parent_id: "0OI0000101",
                    text: "Repeater power supplies",
                    X_fullName: "Repeater power supplies",
                    X_fullName_SK: "Nap\u00e1jacie zdroje opakova\u010da",
                    X_URL: "repeater-power-supplies",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Repeater power supplies",
                    X_Description:
                      "Ultra-compact repeater power supplies for electrical isolation, conversion, amplification, and filtering of standard analog signals.",
                    X_Description_SK:
                      "Ultra-kompaktn\u00e9 opakovacie nap\u00e1jacie zdroje pre elektrick\u00fa izol\u00e1ciu, konverziu, zosilnenie a filtrovanie \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "4OI0000101",
                    parent_id: "0OI0000101",
                    text: "Loop-powered isolators",
                    X_fullName: "Loop-powered isolators",
                    X_fullName_SK:
                      "Izol\u00e1tory nap\u00e1jan\u00e9 slu\u010dkou",
                    X_URL: "loop-powered-isolators",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Loop-powered isolators",
                    X_Description:
                      "Ultra-compact 2-wire passive isolators for electrical isolation and filtering of standard analog signals.",
                    X_Description_SK:
                      "Ultra kompaktn\u00e9 2-vodi\u010dov\u00e9 pas\u00edvne izol\u00e1tory na elektrick\u00fa izol\u00e1ciu a filtrovanie \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "5OI0000101",
                    parent_id: "0OI0000101",
                    text: "Temperature transducers",
                    X_fullName: "Temperature transducers",
                    X_fullName_SK: "Sn\u00edma\u010de teploty",
                    X_URL: "compact-temperature-transducers",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Temperature transducers",
                    X_Description:
                      "Ultra-compact temperature transducers for electrical isolation, conversion, amplification, and filtering of temperature signals to create standard analog signals.",
                    X_Description_SK:
                      "Ultra kompaktn\u00e9 prevodn\u00edky teploty na elektrick\u00fa izol\u00e1ciu, konverziu, zosilnenie a filtrovanie teplotn\u00fdch sign\u00e1lov na vytvorenie \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "6OI0000101",
                    parent_id: "0OI0000101",
                    text: "Frequency transducers",
                    X_fullName: "Frequency transducers",
                    X_fullName_SK: "Frekven\u010dn\u00e9 sn\u00edma\u010de",
                    X_URL: "frequency-transducers",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Frequency transducers",
                    X_Description:
                      "Ultra-compact analog-to-frequency transducer for electrical isolation, amplification, conversion, and filtering of standard signals to create frequencies or PWM signals.",
                    X_Description_SK:
                      "Ultra kompaktn\u00fd anal\u00f3govo-frekven\u010dn\u00fd prevodn\u00edk na elektrick\u00fa izol\u00e1ciu, zosilnenie, konverziu a filtrovanie \u0161tandardn\u00fdch sign\u00e1lov na vytv\u00e1ranie frekvenci\u00ed alebo sign\u00e1lov PWM.",
                  },
                  {
                    id: "7OI0000101",
                    parent_id: "0OI0000101",
                    text: "Potiposition transducers",
                    X_fullName: "Potiposition transducers",
                    X_fullName_SK: "Sn\u00edma\u010de polohy",
                    X_URL: "potiposition-transducers",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Potiposition transducers",
                    X_Description:
                      "Ultra-compact potentiometer measuring transducers for electrical isolation, conversion, amplification, and filtering of potentiometer signals to create standard signals.",
                    X_Description_SK:
                      "Ultra kompaktn\u00e9 potenciometrov\u00e9 meracie prevodn\u00edky na elektrick\u00fa izol\u00e1ciu, konverziu, zosilnenie a filtrovanie sign\u00e1lov potenciometra na vytvorenie \u0161tandardn\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "8OI0000101",
                    parent_id: "0OI0000101",
                    text: "Threshold value switches",
                    X_fullName: "Threshold value switches",
                    X_fullName_SK:
                      "Prep\u00edna\u010de prahov\u00fdch hodn\u00f4t",
                    X_URL: "threshold-value-switches",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Threshold value switches",
                    X_Description:
                      "Ultra-compact threshold value switch for switching analog limit values.",
                    X_Description_SK:
                      "Ultra kompaktn\u00fd prep\u00edna\u010d prahov\u00fdch hodn\u00f4t na prep\u00ednanie anal\u00f3gov\u00fdch limitn\u00fdch hodn\u00f4t.",
                  },
                  {
                    id: "9OI0000101",
                    parent_id: "0OI0000101",
                    text: "Isolation amplifiers",
                    X_fullName: "Isolation amplifiers",
                    X_fullName_SK: "Izola\u010dn\u00e9 zosil\u0148ova\u010de",
                    X_URL: "isolation-amplifiers",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Isolation amplifiers",
                    X_Description:
                      "Ultra-compact isolation amplifier for electrical isolation, amplification, and duplication of proximity sensor signals.",
                    X_Description_SK:
                      "Ultra kompaktn\u00fd izola\u010dn\u00fd zosil\u0148ova\u010d pre elektrick\u00fa izol\u00e1ciu, zosilnenie a duplik\u00e1ciu sign\u00e1lov sn\u00edma\u010da pribl\u00ed\u017eenia.",
                  },
                  {
                    id: "AOI0000101",
                    parent_id: "0OI0000101",
                    text: "System cabling and accessories",
                    X_fullName: "System cabling and accessories",
                    X_fullName_SK:
                      "Syst\u00e9mov\u00e1 kabel\u00e1\u017e a pr\u00edslu\u0161enstvo",
                    X_URL: "system-cabling-and-accessories",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\System cabling and accessories",
                    X_Description:
                      "Accessories for installing and operating signal conditioners, such as DIN rail connectors, system adapters, and multiplexers.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo na in\u0161tal\u00e1ciu a prev\u00e1dzku zariaden\u00ed na \u00fapravu sign\u00e1lu, ako s\u00fa konektory na li\u0161tu DIN, syst\u00e9mov\u00e9 adapt\u00e9ry a multiplexory.",
                  },
                ],
              },
              {
                id: "BOI0000101",
                parent_id: "ZNI0000101",
                text: "Highly compact signal conditioners with plug-in co",
                X_fullName:
                  "Highly compact signal conditioners with plug-in co",
                X_fullName_SK:
                  "Vysoko kompaktn\u00e9 prevodn\u00edky sign\u00e1lu so z\u00e1suvn\u00fdm konektorom",
                X_URL: "compact-signal-conditioners",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co",
                X_Description:
                  "Easier than ever but as slim as before. The highly compact MINI Analog Pro signal conditioners offer the easiest installation and startup in a confined space.",
                X_Description_SK:
                  "Jednoduch\u0161ie ako kedyko\u013evek predt\u00fdm, ale rovnako \u0161t\u00edhle ako predt\u00fdm. Vysoko kompaktn\u00e9 kondicion\u00e9ry sign\u00e1lu MINI Analog Pro pon\u00fakaj\u00fa najjednoduch\u0161iu in\u0161tal\u00e1ciu a spustenie v obmedzenom priestore.",
                items: [
                  {
                    id: "COI0000101",
                    parent_id: "BOI0000101",
                    text: "Active input and output signal conditioners",
                    X_fullName: "Active input and output signal conditioners",
                    X_fullName_SK:
                      "Kondicion\u00e9ry akt\u00edvneho vstupn\u00e9ho a v\u00fdstupn\u00e9ho sign\u00e1lu",
                    X_URL: "active-io-signal-conditioners-w",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Active input and output signal conditioners",
                    X_Description:
                      "Highly compact, active signal conditioners for electrical isolation, conversion, filtering, and amplification of standard analog signals.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9, akt\u00edvne kondicion\u00e9ry sign\u00e1lu na elektrick\u00fa izol\u00e1ciu, konverziu, filtrovanie a zosilnenie \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "DOI0000101",
                    parent_id: "BOI0000101",
                    text: "Repeater power supplies",
                    X_fullName: "Repeater power supplies",
                    X_fullName_SK: "Nap\u00e1jacie zdroje opakova\u010da",
                    X_URL: "plug-repeater-power-supplies",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Repeater power supplies",
                    X_Description:
                      "Highly compact repeater power supplies for electrical isolation, conversion, filtering, and amplification of standard analog signals.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9 nap\u00e1jacie nap\u00e1jacie zdroje pre elektrick\u00fa izol\u00e1ciu, konverziu, filtrovanie a amplifik\u00e1ciu \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "EOI0000101",
                    parent_id: "BOI0000101",
                    text: "Temperature transducer",
                    X_fullName: "Temperature transducer",
                    X_fullName_SK: "Sn\u00edma\u010d teploty",
                    X_URL: "temperature-transducer",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Temperature transducer",
                    X_Description:
                      "Highly compact temperature transducers for electrical isolation, conversion, filtering, and amplification of temperature signals to create standard analog signals.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9 teplotn\u00e9 prevodn\u00edky pre elektrick\u00fa izol\u00e1ciu, konverziu, filtrovanie a zosilnenie teplotn\u00fdch sign\u00e1lov na vytvorenie \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "FOI0000101",
                    parent_id: "BOI0000101",
                    text: "Potiposition transducers",
                    X_fullName: "Potiposition transducers",
                    X_fullName_SK: "Sn\u00edma\u010de polohy",
                    X_URL: "potiposition-transducers-w",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Potiposition transducers",
                    X_Description:
                      "Highly compact potiposition transducers for electrical isolation, conversion, filtering, and amplification of potentiometer signals to create standard analog signals.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9 prevodn\u00edky potenciometra na elektrick\u00fa izol\u00e1ciu, konverziu, filtrovanie a zosilnenie sign\u00e1lov potenciometra na vytvorenie \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "GOI0000101",
                    parent_id: "BOI0000101",
                    text: "System cabling and accessories",
                    X_fullName: "System cabling and accessories",
                    X_fullName_SK:
                      "Syst\u00e9mov\u00e1 kabel\u00e1\u017e a pr\u00edslu\u0161enstvo",
                    X_URL: "system-cabling-accessories",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\System cabling and accessories",
                    X_Description:
                      "System cabling and accessories for installation and operation of signal conditioners such as DIN rail connectors, supply components, marking material, and fault monitoring modules.",
                    X_Description_SK:
                      "Syst\u00e9mov\u00e1 kabel\u00e1\u017e a pr\u00edslu\u0161enstvo na in\u0161tal\u00e1ciu a prev\u00e1dzku zariaden\u00ed na \u00fapravu sign\u00e1lu, ako s\u00fa konektory na DIN li\u0161tu, nap\u00e1jacie komponenty, ozna\u010dovac\u00ed materi\u00e1l a moduly na monitorovanie por\u00fach.",
                  },
                  {
                    id: "HOI0000101",
                    parent_id: "BOI0000101",
                    text: "Loop-powered isolators",
                    X_fullName: "Loop-powered isolators",
                    X_fullName_SK:
                      "Izol\u00e1tory nap\u00e1jan\u00e9 slu\u010dkou",
                    X_URL: "plug-loop-powered-isolators",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Loop-powered isolators",
                    X_Description:
                      "Highly compact 2-way passive isolators for electrical isolation and filtering of analog standardized signals.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9 2-cestn\u00e9 pas\u00edvne izol\u00e1tory na elektrick\u00fa izol\u00e1ciu a filtrovanie anal\u00f3gov\u00fdch \u0161tandardizovan\u00fdch sign\u00e1lov.",
                  },
                  {
                    id: "IOI0000101",
                    parent_id: "BOI0000101",
                    text: "Frequency transducer",
                    X_fullName: "Frequency transducer",
                    X_fullName_SK: "Frekven\u010dn\u00fd sn\u00edma\u010d",
                    X_URL: "frequency-transducer",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Frequency transducer",
                    X_Description:
                      "Highly compact frequency transducers for electrical isolation, amplifying, converting, and filtering of analog standardized signals to frequencies or PWM signals.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9 frekven\u010dn\u00e9 meni\u010de na elektrick\u00fa izol\u00e1ciu, zosilnenie, konverziu a filtrovanie anal\u00f3gov\u00fdch \u0161tandardizovan\u00fdch sign\u00e1lov na frekvencie alebo sign\u00e1ly PWM.",
                  },
                  {
                    id: "JOI0000101",
                    parent_id: "BOI0000101",
                    text: "Threshold value switch",
                    X_fullName: "Threshold value switch",
                    X_fullName_SK: "Prep\u00edna\u010d prahovej hodnoty",
                    X_URL: "threshold-value-switch",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Threshold value switch",
                    X_Description:
                      "Highly compact threshold value switches for switching analog threshold values.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9 sp\u00edna\u010de prahov\u00fdch hodn\u00f4t na prep\u00ednanie anal\u00f3gov\u00fdch prahov\u00fdch hodn\u00f4t.",
                  },
                  {
                    id: "KOI0000101",
                    parent_id: "BOI0000101",
                    text: "Isolation amplifier",
                    X_fullName: "Isolation amplifier",
                    X_fullName_SK: "Izola\u010dn\u00fd zosil\u0148ova\u010d",
                    X_URL: "isolation-amplifier",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Isolation amplifier",
                    X_Description:
                      "Highly compact signal conditioners for electrical isolation, amplifying and duplicating proximity switch signals.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9 kondicion\u00e9ry sign\u00e1lu na elektrick\u00fa izol\u00e1ciu, zosilnenie a duplikovanie sign\u00e1lov bezdotykov\u00fdch sp\u00edna\u010dov.",
                  },
                  {
                    id: "LOI0000101",
                    parent_id: "BOI0000101",
                    text: "Signal duplicators",
                    X_fullName: "Signal duplicators",
                    X_fullName_SK: "Duplik\u00e1tory sign\u00e1lov",
                    X_URL: "highly-signal-plug-duplicators",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Signal duplicators",
                    X_Description:
                      "Highly-compact signal duplicators for electrical isolation, conversion, amplification, and filtering of standard analog signals.",
                    X_Description_SK:
                      "Vysoko kompaktn\u00e9 duplik\u00e1tory sign\u00e1lu na elektrick\u00fa izol\u00e1ciu, konverziu, zosilnenie a filtrovanie \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                  },
                ],
              },
              {
                id: "MOI0000101",
                parent_id: "ZNI0000101",
                text: "Signal conditioners with functional safety",
                X_fullName: "Signal conditioners with functional safety",
                X_fullName_SK:
                  "Kondicion\u00e9ry sign\u00e1lu s funk\u010dnou bezpe\u010dnos\u0165ou",
                X_URL: "safety-signal-conditioners",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety",
                X_Description:
                  "Maximum safety for your machines and systems: MACX signal conditioners are all SIL-certified.",
                X_Description_SK:
                  "Maxim\u00e1lna bezpe\u010dnos\u0165 pre va\u0161e stroje a syst\u00e9my: V\u0161etky prevodn\u00edky sign\u00e1lu MACX maj\u00fa certifik\u00e1ciu SIL.",
                items: [
                  {
                    id: "NOI0000101",
                    parent_id: "MOI0000101",
                    text: "Signal conditioners with SIL functional safety",
                    X_fullName:
                      "Signal conditioners with SIL functional safety",
                    X_fullName_SK:
                      "Kondicion\u00e9ry sign\u00e1lu s funk\u010dnou bezpe\u010dnos\u0165ou SIL",
                    X_URL: "sil-safety-signal-conditioners",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety",
                    X_Description:
                      "Safe isolation, conditioning, filtering, and amplifying with SIL-certified MACX Analog signal conditioners.",
                    X_Description_SK:
                      "Bezpe\u010dn\u00e1 izol\u00e1cia, \u00faprava, filtrovanie a zosilnenie pomocou prevodn\u00edka anal\u00f3gov\u00e9ho sign\u00e1lu MACX s certifik\u00e1ciou SIL.",
                    items: [
                      {
                        id: "OOI0000101",
                        parent_id: "NOI0000101",
                        text: "Active input signal conditioners",
                        X_fullName: "Active input signal conditioners",
                        X_fullName_SK:
                          "Kondicion\u00e9ry akt\u00edvneho vstupn\u00e9ho sign\u00e1lu",
                        X_URL: "active-input-signal-conditioners",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Active input signal conditioners",
                        X_Description:
                          "Active input signal conditioners for the operation of 4-wire measuring transducers for isolating, filtering, amplifying, and converting standard analog signals.",
                        X_Description_SK:
                          "Akt\u00edvne prevodn\u00edky vstupn\u00e9ho sign\u00e1lu na prev\u00e1dzku 4-vodi\u010dov\u00fdch merac\u00edch prevodn\u00edkov na izol\u00e1ciu, filtrovanie, zosil\u0148ovanie a konverziu \u0161tandardn\u00fdch anal\u00f3gov\u00fdch sign\u00e1lov.",
                      },
                      {
                        id: "POI0000101",
                        parent_id: "NOI0000101",
                        text: "Signal duplicators",
                        X_fullName: "Signal duplicators",
                        X_fullName_SK: "Duplik\u00e1tory sign\u00e1lov",
                        X_URL: "signal-duplicators",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Signal duplicators",
                        X_Description:
                          "Repeater power supplies and input isolating amplifiers as signal duplicators for the operation of 2 and 4-wire measuring transducers and mA current sources.",
                        X_Description_SK:
                          "Opakovacie zdroje a vstupn\u00e9 odde\u013eovacie zosil\u0148ova\u010de ako duplik\u00e1tory sign\u00e1lu pre prev\u00e1dzku 2 a 4-vodi\u010dov\u00fdch merac\u00edch prevodn\u00edkov a zdrojov pr\u00fadu mA.",
                      },
                      {
                        id: "QOI0000101",
                        parent_id: "NOI0000101",
                        text: "Repeater power supplies",
                        X_fullName: "Repeater power supplies",
                        X_fullName_SK: "Nap\u00e1jacie zdroje opakova\u010da",
                        X_URL: "sil-repeater-power-supplies",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Repeater power supplies",
                        X_Description:
                          "Repeater power supplies and input isolating amplifiers for the operation of 2 and 4-wire measuring transducers and mA current sources.",
                        X_Description_SK:
                          "Opakovacie zdroje a vstupn\u00e9 odde\u013eovacie zosil\u0148ova\u010de pre prev\u00e1dzku 2 a 4-vodi\u010dov\u00fdch merac\u00edch prevodn\u00edkov a zdrojov pr\u00fadu mA.",
                      },
                      {
                        id: "ROI0000101",
                        parent_id: "NOI0000101",
                        text: "Active output signal conditioners",
                        X_fullName: "Active output signal conditioners",
                        X_fullName_SK:
                          "Kondicion\u00e9ry akt\u00edvneho v\u00fdstupn\u00e9ho sign\u00e1lu",
                        X_URL: "active-output-signal-conditioner",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Active output signal conditioners",
                        X_Description:
                          "Output isolating amplifiers for controlling I/P converters, control valves, and displays.",
                        X_Description_SK:
                          "V\u00fdstupn\u00e9 odde\u013eovacie zosil\u0148ova\u010de na ovl\u00e1danie I/P prevodn\u00edkov, regula\u010dn\u00fdch ventilov a displejov.",
                      },
                      {
                        id: "SOI0000101",
                        parent_id: "NOI0000101",
                        text: "Temperature transducers",
                        X_fullName: "Temperature transducers",
                        X_fullName_SK: "Sn\u00edma\u010de teploty",
                        X_URL: "sil-temperature-transducers",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Temperature transducers",
                        X_Description:
                          "Temperature transducers for operating resistance thermometers, resistance-type sensors, thermocouples, potentiometers, and mV sources.",
                        X_Description_SK:
                          "Prevodn\u00edky teploty pre prev\u00e1dzkov\u00e9 odporov\u00e9 teplomery, odporov\u00e9 sn\u00edma\u010de, termo\u010dl\u00e1nky, potenciometre a mV zdroje.",
                      },
                      {
                        id: "TOI0000101",
                        parent_id: "NOI0000101",
                        text: "Threshold value switches",
                        X_fullName: "Threshold value switches",
                        X_fullName_SK:
                          "Prep\u00edna\u010de prahov\u00fdch hodn\u00f4t",
                        X_URL: "sil-threshold-value-switches",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Threshold value switches",
                        X_Description:
                          "Universal temperature transducers with up to three limit value relays - can be used in combination as a safe limit value relay.",
                        X_Description_SK:
                          "Univerz\u00e1lne prevodn\u00edky teploty a\u017e s tromi rel\u00e9 hrani\u010dnej hodnoty - mo\u017eno pou\u017ei\u0165 v kombin\u00e1cii ako rel\u00e9 bezpe\u010dnej hrani\u010dnej hodnoty.",
                      },
                      {
                        id: "UOI0000101",
                        parent_id: "NOI0000101",
                        text: "Isolation amplifiers",
                        X_fullName: "Isolation amplifiers",
                        X_fullName_SK:
                          "Izola\u010dn\u00e9 zosil\u0148ova\u010de",
                        X_URL: "sil-isolation-amplifiers",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Isolation amplifiers",
                        X_Description:
                          "NAMUR isolation amplifiers for operating proximity sensors and mechanical contacts.",
                        X_Description_SK:
                          "Izola\u010dn\u00e9 zosil\u0148ova\u010de NAMUR na ovl\u00e1danie sn\u00edma\u010dov pribl\u00ed\u017eenia a mechanick\u00fdch kontaktov.",
                      },
                      {
                        id: "VOI0000101",
                        parent_id: "NOI0000101",
                        text: "System cabling and accessories",
                        X_fullName: "System cabling and accessories",
                        X_fullName_SK:
                          "Syst\u00e9mov\u00e1 kabel\u00e1\u017e a pr\u00edslu\u0161enstvo",
                        X_URL: "sil-system-cabling-accessories",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\System cabling and accessories",
                        X_Description:
                          "System cabling and accessories for the installation and operation of signal conditioners, such as DIN rail connectors or power supply modules.",
                        X_Description_SK:
                          "Syst\u00e9mov\u00e1 kabel\u00e1\u017e a pr\u00edslu\u0161enstvo na in\u0161tal\u00e1ciu a prev\u00e1dzku kondicion\u00e9rov sign\u00e1lu, ako s\u00fa konektory na DIN li\u0161tu alebo nap\u00e1jacie moduly.",
                      },
                      {
                        id: "WOI0000101",
                        parent_id: "NOI0000101",
                        text: "Loop-powered isolators",
                        X_fullName: "Loop-powered isolators",
                        X_fullName_SK:
                          "Izol\u00e1tory nap\u00e1jan\u00e9 slu\u010dkou",
                        X_URL: "sil-loop-powered-isolators",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Loop-powered isolators",
                        X_Description:
                          "Single- and dual-channel input loop powered 2-way isolators with increased isolation voltage for the electrical isolation of analog signals.",
                        X_Description_SK:
                          "Jedno- a dvojkan\u00e1lov\u00e9 vstupn\u00e9 slu\u010dky nap\u00e1jan\u00e9 2-cestn\u00e9 izol\u00e1tory so zv\u00fd\u0161en\u00fdm izola\u010dn\u00fdm nap\u00e4t\u00edm pre elektrick\u00fa izol\u00e1ciu anal\u00f3gov\u00fdch sign\u00e1lov.",
                      },
                    ],
                  },
                ],
              },
              {
                id: "XOI0000101",
                parent_id: "ZNI0000101",
                text: "Ex i signal conditioners with functional safety",
                X_fullName: "Ex i signal conditioners with functional safety",
                X_fullName_SK:
                  "Kondicion\u00e9ry sign\u00e1lu Ex i s funk\u010dnou bezpe\u010dnos\u0165ou",
                X_URL: "ex-i-signal-conditioners",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety",
                X_Description:
                  "Maximum safety for your machines and systems: MACX signal conditioners are all SIL-certified.",
                X_Description_SK:
                  "Maxim\u00e1lna bezpe\u010dnos\u0165 pre va\u0161e stroje a syst\u00e9my: V\u0161etky kondicion\u00e9ry sign\u00e1lu MACX maj\u00fa certifik\u00e1ciu SIL.",
                items: [
                  {
                    id: "YOI0000101",
                    parent_id: "XOI0000101",
                    text: "Ex i signal conditioners with SIL functional safet",
                    X_fullName:
                      "Ex i signal conditioners with SIL functional safet",
                    X_fullName_SK:
                      "Kondicion\u00e9ry sign\u00e1lu Ex i s funk\u010dnou bezpe\u010dnos\u0165ou SIL",
                    X_URL: "sil-ex-i-signal-conditioners",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet",
                    X_Description:
                      "Single and two-channel Ex i signal conditioners for intrinsically safe signal circuits in potentially explosive areas.",
                    X_Description_SK:
                      "Jedno a dvojkan\u00e1lov\u00e9 Ex i kondicion\u00e9ry sign\u00e1lu pre iskrovo bezpe\u010dn\u00e9 sign\u00e1lov\u00e9 obvody v potenci\u00e1lne v\u00fdbu\u0161n\u00fdch priestoroch.",
                    items: [
                      {
                        id: "ZOI0000101",
                        parent_id: "YOI0000101",
                        text: "Active input signal conditioners",
                        X_fullName: "Active input signal conditioners",
                        X_fullName_SK:
                          "Kondicion\u00e9ry akt\u00edvneho vstupn\u00e9ho sign\u00e1lu",
                        X_URL: "ex-active-input-signal-con",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Active input signal conditioners",
                        X_Description:
                          "Active input signal conditioners for the operation of intrinsically safe 2-wire and 4-wire measuring transducers and mA current sources installed in the Ex area.",
                        X_Description_SK:
                          "Akt\u00edvne prevodn\u00edky vstupn\u00e9ho sign\u00e1lu pre prev\u00e1dzku iskrovo bezpe\u010dn\u00fdch 2- a 4-vodi\u010dov\u00fdch merac\u00edch prevodn\u00edkov a mA pr\u00fadov\u00fdch zdrojov in\u0161talovan\u00fdch v Ex oblasti.",
                      },
                      {
                        id: "0PI0000101",
                        parent_id: "YOI0000101",
                        text: "Active output signal conditioners",
                        X_fullName: "Active output signal conditioners",
                        X_fullName_SK:
                          "Kondicion\u00e9ry akt\u00edvneho v\u00fdstupn\u00e9ho sign\u00e1lu",
                        X_URL: "ex-active-output-signal-con",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Active output signal conditioners",
                        X_Description:
                          "Active output isolating amplifiers for controlling intrinsically safe I/P converters, control valves, and displays installed in the Ex area.",
                        X_Description_SK:
                          "Akt\u00edvne v\u00fdstupn\u00e9 izola\u010dn\u00e9 zosil\u0148ova\u010de na ovl\u00e1danie iskrovo bezpe\u010dn\u00fdch I/P prevodn\u00edkov, regula\u010dn\u00fdch ventilov a displejov in\u0161talovan\u00fdch v Ex oblasti.",
                      },
                      {
                        id: "1PI0000101",
                        parent_id: "YOI0000101",
                        text: "Repeater power supplies",
                        X_fullName: "Repeater power supplies",
                        X_fullName_SK: "Nap\u00e1jacie zdroje opakova\u010da",
                        X_URL: "ex-repeater-power-supplies",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Repeater power supplies",
                        X_Description:
                          "Repeater power supplies and input isolating amplifiers for the operation of intrinsically safe 2-wire and 4-wire measuring transducers and mA current sources installed in the Ex area.",
                        X_Description_SK:
                          "Opakovacie zdroje a vstupn\u00e9 odde\u013eovacie zosil\u0148ova\u010de pre prev\u00e1dzku iskrovo bezpe\u010dn\u00fdch 2- a 4-vodi\u010dov\u00fdch merac\u00edch prevodn\u00edkov a mA pr\u00fadov\u00fdch zdrojov in\u0161talovan\u00fdch v Ex oblasti.",
                      },
                      {
                        id: "2PI0000101",
                        parent_id: "YOI0000101",
                        text: "Isolation amplifiers",
                        X_fullName: "Isolation amplifiers",
                        X_fullName_SK:
                          "Izola\u010dn\u00e9 zosil\u0148ova\u010de",
                        X_URL: "ex-isolation-amplifiers",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Isolation amplifiers",
                        X_Description:
                          "NAMUR isolation amplifiers for the intrinsically safe operation of proximity sensors and mechanical contacts installed in the Ex area.",
                        X_Description_SK:
                          "Izola\u010dn\u00e9 zosil\u0148ova\u010de NAMUR pre iskrovo bezpe\u010dn\u00fa prev\u00e1dzku sn\u00edma\u010dov pribl\u00ed\u017eenia a mechanick\u00fdch kontaktov in\u0161talovan\u00fdch v v\u00fdbu\u0161nom prostred\u00ed.",
                      },
                      {
                        id: "3PI0000101",
                        parent_id: "YOI0000101",
                        text: "Solenoid drivers",
                        X_fullName: "Solenoid drivers",
                        X_fullName_SK:
                          "Elektromagnetick\u00e9 ovl\u00e1da\u010de",
                        X_URL: "solenoid-drivers",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Solenoid drivers",
                        X_Description:
                          "Solenoid drivers for controlling intrinsically safe solenoid valves, alarm transmitters, and displays installed in the Ex area.",
                        X_Description_SK:
                          "Solenoidov\u00e9 ovl\u00e1da\u010de na ovl\u00e1danie iskrovo bezpe\u010dn\u00fdch solenoidov\u00fdch ventilov, vysiela\u010dov alarmov a displejov in\u0161talovan\u00fdch v Ex oblasti.",
                      },
                      {
                        id: "4PI0000101",
                        parent_id: "YOI0000101",
                        text: "Temperature transducers",
                        X_fullName: "Temperature transducers",
                        X_fullName_SK: "Sn\u00edma\u010de teploty",
                        X_URL: "ex-temperature-transducers",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Temperature transducers",
                        X_Description:
                          "Temperature transducers for the intrinsically safe operation of resistance thermometers, resistance-type sensors, and thermocouples installed in the Ex area.",
                        X_Description_SK:
                          "Prevodn\u00edky teploty pre iskrovo bezpe\u010dn\u00fa prev\u00e1dzku odporov\u00fdch teplomerov, odporov\u00fdch sn\u00edma\u010dov a termo\u010dl\u00e1nkov in\u0161talovan\u00fdch v prostred\u00ed Ex.",
                      },
                      {
                        id: "5PI0000101",
                        parent_id: "YOI0000101",
                        text: "System cabling and accessories",
                        X_fullName: "System cabling and accessories",
                        X_fullName_SK:
                          "Syst\u00e9mov\u00e1 kabel\u00e1\u017e a pr\u00edslu\u0161enstvo",
                        X_URL: "ex-system-cabling-accessories",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\System cabling and accessories",
                        X_Description:
                          "System cabling and accessories for the installation and operation of Ex i signal conditioners, such as DIN rail connectors or power supply modules.",
                        X_Description_SK:
                          "Syst\u00e9mov\u00e1 kabel\u00e1\u017e a pr\u00edslu\u0161enstvo na in\u0161tal\u00e1ciu a prev\u00e1dzku kondicion\u00e9rov sign\u00e1lu Ex i, ako s\u00fa konektory na DIN li\u0161tu alebo nap\u00e1jacie moduly.",
                      },
                      {
                        id: "6PI0000101",
                        parent_id: "YOI0000101",
                        text: "Threshold value switch",
                        X_fullName: "Threshold value switch",
                        X_fullName_SK: "Prep\u00edna\u010d prahovej hodnoty",
                        X_URL: "ex-threshold-value-switch",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Threshold value switch",
                        X_Description:
                          "Universal Ex i temperature transducers with up to three limit value relays. Can be used in combination as safe limit value relays.",
                        X_Description_SK:
                          "Univerz\u00e1lne Ex i prevodn\u00edky teploty a\u017e s tromi rel\u00e9 limitn\u00fdch hodn\u00f4t. M\u00f4\u017eu by\u0165 pou\u017eit\u00e9 v kombin\u00e1cii ako rel\u00e9 s bezpe\u010dn\u00fdmi limitn\u00fdmi hodnotami.",
                      },
                    ],
                  },
                ],
              },
              {
                id: "7PI0000101",
                parent_id: "ZNI0000101",
                text: "System cabling for signal conditioners",
                X_fullName: "System cabling for signal conditioners",
                X_fullName_SK:
                  "Syst\u00e9mov\u00e1 kabel\u00e1\u017e pre zariadenia na \u00fapravu sign\u00e1lu",
                X_URL: "system-cabling-for-conditioners",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\System cabling for signal conditioners",
                X_Description:
                  "Termination Carriers and system adapters from Phoenix Contact for quick, error-free connection of standard DIN rail devices to your automation system.",
                X_Description_SK:
                  "Nosi\u010de koncoviek a syst\u00e9mov\u00e9 adapt\u00e9ry od spolo\u010dnosti Phoenix Contact na r\u00fdchle a bezchybn\u00e9 pripojenie zariaden\u00ed na \u0161tandardn\u00fa li\u0161tu DIN k v\u00e1\u0161mu automatiza\u010dn\u00e9mu syst\u00e9mu.",
                items: [
                  {
                    id: "8PI0000101",
                    parent_id: "7PI0000101",
                    text: "Termination Carriers",
                    X_fullName: "Termination Carriers",
                    X_fullName_SK: "Ukon\u010dovac\u00ed dopravcovia",
                    X_URL: "termination-carriers",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\System cabling for signal conditioners\\Termination Carriers",
                    X_Description:
                      "Termination Carriers are system solutions for the Plug and Play connection of signal isolators to the control level using pre-assembled system cables.",
                    X_Description_SK:
                      "Termination Carriers s\u00fa syst\u00e9mov\u00e9 rie\u0161enia pre Plug and Play pripojenie izol\u00e1torov sign\u00e1lu k riadiacej \u00farovni pomocou vopred zmontovan\u00fdch syst\u00e9mov\u00fdch k\u00e1blov.",
                  },
                  {
                    id: "9PI0000101",
                    parent_id: "7PI0000101",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                    X_URL: "system-cabling-acc",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\System cabling for signal conditioners\\Accessories",
                    X_Description:
                      "Various accessories for the Termination Carrier.",
                    X_Description_SK:
                      "R\u00f4zne pr\u00edslu\u0161enstvo k nosi\u010du ukon\u010denia.",
                  },
                ],
              },
              {
                id: "API0000101",
                parent_id: "ZNI0000101",
                text: "Signal conditioners with bus and network connectio",
                X_fullName:
                  "Signal conditioners with bus and network connectio",
                X_fullName_SK:
                  "Kondicion\u00e9ry sign\u00e1lu so zbernicov\u00fdm a sie\u0165ov\u00fdm pripojen\u00edm",
                X_URL: "signal-conditioners-with-conn",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with bus and network connectio",
                X_Description:
                  "Signal conditioners and gateways for the direct connection of up to eight analog and digital process values to a controller via an industrial network.",
                X_Description_SK:
                  "Kondicion\u00e9ry sign\u00e1lu a br\u00e1ny na priame pripojenie a\u017e \u00f4smich anal\u00f3gov\u00fdch a digit\u00e1lnych procesn\u00fdch hodn\u00f4t k regul\u00e1toru cez priemyseln\u00fa sie\u0165.",
                items: [
                  {
                    id: "BPI0000101",
                    parent_id: "API0000101",
                    text: "Gateways for bus and network connection",
                    X_fullName: "Gateways for bus and network connection",
                    X_fullName_SK: "Br\u00e1ny na pripojenie zbernice a siete",
                    X_URL: "gateways-for-bus-and-network-con",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with bus and network connectio\\Gateways for bus and network connection",
                    X_Description:
                      "Gateways for the direct connection of up to eight analog and digital process values to a controller via an industrial network.",
                    X_Description_SK:
                      "Br\u00e1ny na priame pripojenie a\u017e \u00f4smich anal\u00f3gov\u00fdch a digit\u00e1lnych procesn\u00fdch hodn\u00f4t k riadiacej jednotke cez priemyseln\u00fa sie\u0165.",
                  },
                ],
              },
            ],
          },
          {
            id: "CPI0000101",
            parent_id: "YNI0000101",
            text: "Timer relays",
            X_fullName: "Timer relays",
            X_fullName_SK: "\u010casov\u00e9 rel\u00e9",
            X_URL: "mcr-timer-relays",
            FULLPATH: "PhoenixContact\\MCR technology\\Timer relays",
            X_Description:
              "Ultra-narrow or multifunctional time relays for simple time control applications.",
            X_Description_SK:
              "Ultra-\u00fazke alebo multifunk\u010dn\u00e9 \u010dasov\u00e9 rel\u00e9 pre jednoduch\u00e9 aplik\u00e1cie riadenia \u010dasu.",
            items: [
              {
                id: "DPI0000101",
                parent_id: "CPI0000101",
                text: "Timer relays, 6 mm",
                X_fullName: "Timer relays, 6 mm",
                X_fullName_SK: "\u010casov\u00e9 rel\u00e9, 6 mm",
                X_URL: "timer-relays",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Timer relays\\Timer relays, 6 mm",
                X_Description:
                  "Ultra-narrow timer relays for controllers, each with one time range and one function.",
                X_Description_SK:
                  "Ultra \u00fazke \u010dasov\u00e9 rel\u00e9 pre ovl\u00e1da\u010de, ka\u017ed\u00e9 s jedn\u00fdm \u010dasov\u00fdm rozsahom a jednou funkciou.",
              },
              {
                id: "EPI0000101",
                parent_id: "CPI0000101",
                text: "Compact timer relays",
                X_fullName: "Compact timer relays",
                X_fullName_SK: "Kompaktn\u00e9 \u010dasov\u00e9 rel\u00e9",
                X_URL: "compact-timer-relays",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Timer relays\\Compact timer relays",
                X_Description:
                  "Multi-functional timer relays with compact installation housing.",
                X_Description_SK:
                  "Multifunk\u010dn\u00e9 \u010dasova\u010de rel\u00e9 s kompaktn\u00fdm in\u0161tala\u010dn\u00fdm krytom.",
              },
            ],
          },
          {
            id: "FPI0000101",
            parent_id: "YNI0000101",
            text: "Process indicators and field devices",
            X_fullName: "Process indicators and field devices",
            X_fullName_SK:
              "Procesn\u00e9 indik\u00e1tory a prev\u00e1dzkov\u00e9 zariadenia",
            X_URL: "indicators-and-field-devices",
            FULLPATH:
              "PhoenixContact\\MCR technology\\Process indicators and field devices",
            X_Description:
              "Record temperatures directly in the field, monitor, display, and control process values with process indicators and field devices.",
            X_Description_SK:
              "Rekordn\u00e9 teploty priamo v poli, monitorovanie, zobrazovanie a riadenie procesn\u00fdch hodn\u00f4t pomocou ukazovate\u013eov procesu a po\u013en\u00fdmi zariadeniami.",
            items: [
              {
                id: "GPI0000101",
                parent_id: "FPI0000101",
                text: "Process indicators and accessories",
                X_fullName: "Process indicators and accessories",
                X_fullName_SK:
                  "Procesn\u00e9 indik\u00e1tory a pr\u00edslu\u0161enstvo",
                X_URL: "indicators-accessories",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Process indicators and field devices\\Process indicators and accessories",
                X_Description:
                  "Process indicators for monitoring, displaying, and controlling analog and temperature signals. For control panel installation and field assembly.",
                X_Description_SK:
                  "Indik\u00e1tory procesu na monitorovanie, zobrazovanie a regul\u00e1ciu anal\u00f3gov\u00fdch a teplotn\u00fdch sign\u00e1lov. Pre in\u0161tal\u00e1ciu ovl\u00e1dacieho panela a zostavu po\u013ea.",
              },
              {
                id: "HPI0000101",
                parent_id: "FPI0000101",
                text: "Ex i process indicators and accessories",
                X_fullName: "Ex i process indicators and accessories",
                X_fullName_SK:
                  "Ex i procesn\u00e9 indik\u00e1tory a pr\u00edslu\u0161enstvo",
                X_URL: "process-indicators-accessories",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Process indicators and field devices\\Ex i process indicators and accessories",
                X_Description:
                  "Ex i process indicators for monitoring, displaying, and controlling analog and temperature signals in the ex area. For control panel installation and field assembly.",
                X_Description_SK:
                  "Procesn\u00e9 indik\u00e1tory Ex i na monitorovanie, zobrazovanie a riadenie anal\u00f3gov\u00fdch a teplotn\u00fdch sign\u00e1lov vo v\u00fdbu\u0161nom prostred\u00ed. Na in\u0161tal\u00e1ciu ovl\u00e1dacieho panela a mont\u00e1\u017e na mieste.",
              },
              {
                id: "IPI0000101",
                parent_id: "FPI0000101",
                text: "Sensor-head measuring transducers and accessories",
                X_fullName: "Sensor-head measuring transducers and accessories",
                X_fullName_SK:
                  "Meracie sn\u00edma\u010de a pr\u00edslu\u0161enstvo na hlave sn\u00edma\u010da",
                X_URL: "measuring-transducers-accessory",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Process indicators and field devices\\Sensor-head measuring transducers and accessories",
                X_Description:
                  "Temperature sensor-head measuring transducer for resistance thermometer, thermocouples, resistance and voltage encoder, programmable, loop powered",
                X_Description_SK:
                  "Merac\u00ed prevodn\u00edk s hlavou sn\u00edma\u010da teploty pre odporov\u00fd teplomer, termo\u010dl\u00e1nky, sn\u00edma\u010d odporu a nap\u00e4tia, programovate\u013en\u00fd, nap\u00e1jan\u00fd zo slu\u010dky",
              },
              {
                id: "JPI0000101",
                parent_id: "FPI0000101",
                text: "Loop-powered temperature transducers and accessori",
                X_fullName:
                  "Loop-powered temperature transducers and accessori",
                X_fullName_SK:
                  "Sn\u00edma\u010de teploty nap\u00e1jan\u00e9 slu\u010dkou a pr\u00edslu\u0161enstvo",
                X_URL: "temperature-transducers",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Process indicators and field devices\\Loop-powered temperature transducers and accessori",
                X_Description:
                  "Programmable, output-loop-fed 2-wire measuring transducers with two measuring inputs for resistance thermometers, thermocouples, resistors, and voltage sensors.",
                X_Description_SK:
                  "Programovate\u013en\u00e9 2-vodi\u010dov\u00e9 meracie prevodn\u00edky s v\u00fdstupnou slu\u010dkou s dvomi merac\u00edmi vstupmi pre odporov\u00e9 teplomery, termo\u010dl\u00e1nky, odpory a nap\u00e4\u0165ov\u00e9 sn\u00edma\u010de.",
              },
            ],
          },
        ],
      },
      {
        id: "KPI0000101",
        parent_id: "HFI0000101",
        text: "Monitoring",
        X_fullName: "Monitoring",
        X_fullName_SK: "Monitorovanie",
        X_URL: "monitoring",
        FULLPATH: "PhoenixContact\\Monitoring",
        X_Description:
          "Safeguard your system operation and prevent system failure: with our monitoring products you can detect errors before they become a problem.",
        X_Description_SK:
          "Zabezpe\u010dte prev\u00e1dzku svojho syst\u00e9mu a zabr\u00e1\u0148te zlyhaniu syst\u00e9mu: s na\u0161imi monitorovac\u00edmi produktmi m\u00f4\u017eete odhali\u0165 chyby sk\u00f4r, ako sa stan\u00fa probl\u00e9mom.",
        items: [
          {
            id: "LPI0000101",
            parent_id: "KPI0000101",
            text: "Energy and power measuring",
            X_fullName: "Energy and power measuring",
            X_fullName_SK: "Meranie energie a v\u00fdkonu",
            X_URL: "energy-and-power-measuring",
            FULLPATH: "PhoenixContact\\Monitoring\\Energy and power measuring",
            X_Description:
              "Measuring devices, data loggers, sensors, and meters for energy monitoring in machines and systems.",
            X_Description_SK:
              "Meracie pr\u00edstroje, z\u00e1znamn\u00edky d\u00e1t, senzory a mera\u010de na monitorovanie energie v strojoch a syst\u00e9moch.",
            items: [
              {
                id: "MPI0000101",
                parent_id: "LPI0000101",
                text: "Energy measuring devices and energy meters",
                X_fullName: "Energy measuring devices and energy meters",
                X_fullName_SK:
                  "Zariadenia na meranie energie a mera\u010de energie",
                X_URL: "energy-measuring-devices",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Energy and power measuring\\Energy measuring devices and energy meters",
                X_Description:
                  "EMpro energy meters measure the characteristic electrical data of your systems.",
                X_Description_SK:
                  "Elektromery EMpro meraj\u00fa charakteristick\u00e9 elektrick\u00e9 \u00fadaje va\u0161ich syst\u00e9mov.",
                items: [
                  {
                    id: "NPI0000101",
                    parent_id: "MPI0000101",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                    X_URL: "measuring-devices-accessories",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Energy and power measuring\\Energy measuring devices and energy meters\\Accessories",
                    X_Description:
                      "Modules for extending functions and integrating energy meters into network structures and fieldbus systems.",
                    X_Description_SK:
                      "Moduly pre roz\u0161\u00edrenie funkci\u00ed a integr\u00e1ciu mera\u010dov energie do sie\u0165ov\u00fdch \u0161trukt\u00far a fieldbus syst\u00e9mov.",
                  },
                  {
                    id: "OPI0000101",
                    parent_id: "MPI0000101",
                    text: "Power meter",
                    X_fullName: "Power meter",
                    X_fullName_SK: "Mera\u010d v\u00fdkonu",
                    X_URL: "power-meter",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Energy and power measuring\\Energy measuring devices and energy meters\\Power meter",
                    X_Description:
                      "Digital three-phase energy meters for active power measurement in networks up to 500 V, certified according to the MID directive.",
                    X_Description_SK:
                      "Digit\u00e1lne trojf\u00e1zov\u00e9 elektromery na meranie \u010dinn\u00e9ho v\u00fdkonu v sie\u0165ach do 500 V, certifikovan\u00e9 pod\u013ea smernice MID.",
                  },
                ],
              },
            ],
          },
          {
            id: "PPI0000101",
            parent_id: "KPI0000101",
            text: "Current and voltage measuring",
            X_fullName: "Current and voltage measuring",
            X_fullName_SK: "Meranie pr\u00fadu a nap\u00e4tia",
            X_URL: "current-and-voltage-measuring",
            FULLPATH:
              "PhoenixContact\\Monitoring\\Current and voltage measuring",
            X_Description:
              "Measuring transducers for the acquisition of direct, alternating, and distorted currents and voltages.",
            X_Description_SK:
              "Meracie prevodn\u00edky na sn\u00edmanie jednosmern\u00fdch, striedav\u00fdch a skreslen\u00fdch pr\u00fadov a nap\u00e4t\u00ed.",
            items: [
              {
                id: "QPI0000101",
                parent_id: "PPI0000101",
                text: "Current transducers, AC/DC",
                X_fullName: "Current transducers, AC/DC",
                X_fullName_SK: "Sn\u00edma\u010de pr\u00fadu, AC/DC",
                X_URL: "current-transducers-ac-dc",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC/DC",
                X_Description:
                  "Current transducers in a range of different designs. For converting direct and alternating currents into analog rated currents.",
                X_Description_SK:
                  "Aktu\u00e1lne prevodn\u00edky v rade r\u00f4znych preveden\u00ed. Na konverziu jednosmern\u00fdch a striedav\u00fdch pr\u00fadov na anal\u00f3gov\u00e9 menovit\u00e9 pr\u00fady.",
                items: [
                  {
                    id: "RPI0000101",
                    parent_id: "QPI0000101",
                    text: "Current transducers up to 100 A AC/DC, progr.",
                    X_fullName: "Current transducers up to 100 A AC/DC, progr.",
                    X_fullName_SK:
                      "Sn\u00edma\u010de pr\u00fadu do 100 A AC/DC, progr.",
                    X_URL: "100-a-ac-dc-current-transducers",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC/DC\\Current transducers up to 100 A AC/DC, progr.",
                    X_Description:
                      "Programmable current transducers convert AC/DC currents up to 100 A into standard analog signals.",
                    X_Description_SK:
                      "Programovate\u013en\u00e9 pr\u00fadov\u00e9 prevodn\u00edky konvertuj\u00fa AC/DC pr\u00fady do 100 A na \u0161tandardn\u00e9 anal\u00f3gov\u00e9 sign\u00e1ly.",
                  },
                  {
                    id: "SPI0000101",
                    parent_id: "QPI0000101",
                    text: "Current transducers up to 600 A AC/DC",
                    X_fullName: "Current transducers up to 600 A AC/DC",
                    X_fullName_SK:
                      "Sn\u00edma\u010de pr\u00fadu do 600 A AC/DC",
                    X_URL: "600-a-ac-dc-current-transducers",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC/DC\\Current transducers up to 600 A AC/DC",
                    X_Description:
                      "Current transducers convert currents up to 600 A AC/DC into standard analog signals.",
                    X_Description_SK:
                      "Pr\u00fadov\u00e9 prevodn\u00edky konvertuj\u00fa pr\u00fady a\u017e do 600 A AC/DC na \u0161tandardn\u00e9 anal\u00f3gov\u00e9 sign\u00e1ly.",
                  },
                  {
                    id: "TPI0000101",
                    parent_id: "QPI0000101",
                    text: "Accessories for current transducers",
                    X_fullName: "Accessories for current transducers",
                    X_fullName_SK:
                      "Pr\u00edslu\u0161enstvo pre sn\u00edma\u010de pr\u00fadu",
                    X_URL: "current-transducers-accessories",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC/DC\\Accessories for current transducers",
                    X_Description:
                      "Software adapter cable for programming MCR-T-..., MCR-S-... and MCR-f-... modules",
                    X_Description_SK:
                      "Softv\u00e9rov\u00fd adapt\u00e9rov\u00fd k\u00e1bel na programovanie modulov MCR-T-..., MCR-S-... a MCR-f-...",
                  },
                ],
              },
              {
                id: "UPI0000101",
                parent_id: "PPI0000101",
                text: "Current transducers, AC",
                X_fullName: "Current transducers, AC",
                X_fullName_SK: "Sn\u00edma\u010de pr\u00fadu, AC",
                X_URL: "current-transducers-ac",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC",
                X_Description:
                  "Various types of current transducers for converting alternating currents into analog rated currents.",
                X_Description_SK:
                  "R\u00f4zne typy pr\u00fadov\u00fdch prevodn\u00edkov na konverziu striedav\u00e9ho pr\u00fadu na anal\u00f3gov\u00e9 menovit\u00e9 pr\u00fady.",
                items: [
                  {
                    id: "VPI0000101",
                    parent_id: "UPI0000101",
                    text: "Current transducers up to 12 A AC, sinusoidal",
                    X_fullName: "Current transducers up to 12 A AC, sinusoidal",
                    X_fullName_SK:
                      "Sn\u00edma\u010de pr\u00fadu do 12 A AC, s\u00ednusov\u00e9",
                    X_URL: "current-transducer-sinusoidal",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC\\Current transducers up to 12 A AC, sinusoidal",
                    X_Description:
                      "Current transducers convert sinusoidal alternating currents up to 12 A AC into standard analog signals.",
                    X_Description_SK:
                      "Pr\u00fadov\u00e9 prevodn\u00edky konvertuj\u00fa s\u00ednusov\u00e9 striedav\u00e9 pr\u00fady do 12 A AC na \u0161tandardn\u00e9 anal\u00f3gov\u00e9 sign\u00e1ly.",
                  },
                  {
                    id: "WPI0000101",
                    parent_id: "UPI0000101",
                    text: "Current transducers up to 400 A AC, distorted",
                    X_fullName: "Current transducers up to 400 A AC, distorted",
                    X_fullName_SK:
                      "Sn\u00edma\u010de pr\u00fadu do 400 A AC, skreslen\u00e9",
                    X_URL: "400-a-ac-current-transducers",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC\\Current transducers up to 400 A AC, distorted",
                    X_Description:
                      "Current transducers convert sinusoidal and non-sinusoidal currents up to 400 A AC into standard analog signals.",
                    X_Description_SK:
                      "Pr\u00fadov\u00e9 prevodn\u00edky konvertuj\u00fa s\u00ednusov\u00e9 a nes\u00ednusov\u00e9 pr\u00fady a\u017e do 400 A AC na \u0161tandardn\u00e9 anal\u00f3gov\u00e9 sign\u00e1ly.",
                  },
                ],
              },
              {
                id: "XPI0000101",
                parent_id: "PPI0000101",
                text: "Current protectors up to 16 A AC",
                X_fullName: "Current protectors up to 16 A AC",
                X_fullName_SK:
                  "Pr\u00fadov\u00e9 chr\u00e1ni\u010de do 16 A AC",
                X_URL: "current-protectors-up-to-16-a-ac",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current protectors up to 16 A AC",
                X_Description:
                  "Current protectors for limit value monitoring of sinusoidal alternating currents from 0 to 16 A.",
                X_Description_SK:
                  "Pr\u00fadov\u00e9 chr\u00e1ni\u010de pre sledovanie limitn\u00fdch hodn\u00f4t s\u00ednusov\u00fdch striedav\u00fdch pr\u00fadov od 0 do 16 A.",
              },
              {
                id: "YPI0000101",
                parent_id: "PPI0000101",
                text: "Voltage transducers, AC and DC",
                X_fullName: "Voltage transducers, AC and DC",
                X_fullName_SK: "Sn\u00edma\u010de nap\u00e4tia, AC a DC",
                X_URL: "ac-dc-voltage-transducers",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Voltage transducers, AC and DC",
                X_Description:
                  "Voltage transducers convert AC and DC voltages for measurement into standard analog signals.",
                X_Description_SK:
                  "Nap\u00e4\u0165ov\u00e9 prevodn\u00edky konvertuj\u00fa striedav\u00e9 a jednosmern\u00e9 nap\u00e4tie na meranie na \u0161tandardn\u00e9 anal\u00f3gov\u00e9 sign\u00e1ly.",
              },
              {
                id: "ZPI0000101",
                parent_id: "PPI0000101",
                text: "Current transformer, plug-in",
                X_fullName: "Current transformer, plug-in",
                X_fullName_SK:
                  "Transform\u00e1tor pr\u00fadu, z\u00e1suvn\u00fd",
                X_URL: " plug-in-current-transformer",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformer, plug-in",
                X_Description:
                  "PACT current transformers convert large currents into smaller rated currents.",
                X_Description_SK:
                  "Pr\u00fadov\u00e9 transform\u00e1tory PACT premie\u0148aj\u00fa ve\u013ek\u00e9 pr\u00fady na men\u0161ie menovit\u00e9 pr\u00fady.",
                items: [
                  {
                    id: "0QI0000101",
                    parent_id: "ZPI0000101",
                    text: "Current transformer, plug-in",
                    X_fullName: "Current transformer, plug-in",
                    X_fullName_SK:
                      "Transform\u00e1tor pr\u00fadu, z\u00e1suvn\u00fd",
                    X_URL: "plug-in-current-transformer",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformer, plug-in\\Current transformer, plug-in",
                    X_Description:
                      "Current transformers for converting large currents up to 4000 A into secondary currents of 1 to 5 A.",
                    X_Description_SK:
                      "Pr\u00fadov\u00e9 transform\u00e1tory na premenu ve\u013ek\u00fdch pr\u00fadov do 4000 A na sekund\u00e1rne pr\u00fady 1 a\u017e 5 A.",
                  },
                  {
                    id: "1QI0000101",
                    parent_id: "ZPI0000101",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                    X_URL: "transformer-accessories",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformer, plug-in\\Accessories",
                    X_Description:
                      "Accessories for the installation and operation of current transformers that can and cannot be calibrated.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo na in\u0161tal\u00e1ciu a prev\u00e1dzku pr\u00fadov\u00fdch transform\u00e1torov, ktor\u00e9 m\u00f4\u017eu a nem\u00f4\u017eu by\u0165 kalibrovan\u00e9.",
                  },
                ],
              },
              {
                id: "2QI0000101",
                parent_id: "PPI0000101",
                text: "Current transformers for retrofitting",
                X_fullName: "Current transformers for retrofitting",
                X_fullName_SK:
                  "Transform\u00e1tory pr\u00fadu na moderniz\u00e1ciu",
                X_URL: "retrofitting-current-transformer",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformers for retrofitting",
                X_Description:
                  "Fast installation in a confined space: PACT RCP current transformers for retrofitting can be conveniently mounted where there is not enough space for split core current transformers.",
                X_Description_SK:
                  "R\u00fdchla in\u0161tal\u00e1cia v stiesnenom priestore: Pr\u00fadov\u00e9 transform\u00e1tory PACT RCP pre dodato\u010dn\u00fa mont\u00e1\u017e mo\u017eno pohodlne namontova\u0165 tam, kde nie je dostatok miesta pre pr\u00fadov\u00e9 transform\u00e1tory s delen\u00fdm jadrom.",
                items: [
                  {
                    id: "3QI0000101",
                    parent_id: "2QI0000101",
                    text: "Current transformers for retrofitting",
                    X_fullName: "Current transformers for retrofitting",
                    X_fullName_SK:
                      "Transform\u00e1tory pr\u00fadu na moderniz\u00e1ciu",
                    X_URL: "transformers-for-retrofitting",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformers for retrofitting\\Current transformers for retrofitting",
                    X_Description:
                      "Current transformers for retrofitting enable fast installation that does not require you to remove system parts \u2013 even where there is not enough space for split core current transformers.",
                    X_Description_SK:
                      "Pr\u00fadov\u00e9 transform\u00e1tory pre dodato\u010dn\u00fa mont\u00e1\u017e umo\u017e\u0148uj\u00fa r\u00fdchlu in\u0161tal\u00e1ciu, ktor\u00e1 nevy\u017eaduje odstra\u0148ovanie \u010dast\u00ed syst\u00e9mu \u2013 dokonca aj tam, kde nie je dostatok miesta pre pr\u00fadov\u00e9 transform\u00e1tory s delen\u00fdm jadrom.",
                  },
                  {
                    id: "4QI0000101",
                    parent_id: "2QI0000101",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                    X_URL: "transformer-retro-accessories",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformers for retrofitting\\Accessories",
                    X_Description:
                      "Holding device to secure the Rogowski coil on power rails.",
                    X_Description_SK:
                      "Dr\u017eiak na upevnenie Rogowsk\u00e9ho cievky na nap\u00e1jac\u00edch ko\u013eajniciach.",
                  },
                ],
              },
            ],
          },
          {
            id: "5QI0000101",
            parent_id: "KPI0000101",
            text: "Monitoring and diagnostics",
            X_fullName: "Monitoring and diagnostics",
            X_fullName_SK: "Monitorovanie a diagnostika",
            X_URL: "monitoring-and-diagnostics",
            FULLPATH: "PhoenixContact\\Monitoring\\Monitoring and diagnostics",
            X_Description:
              "Always up to date: log, follow, and monitor the processes of individual modules or entire system parts using our products and solutions for monitoring and diagnostics.",
            X_Description_SK:
              "V\u017edy aktu\u00e1lne: zaznamen\u00e1vajte, sledujte a monitorujte procesy jednotliv\u00fdch modulov alebo cel\u00fdch \u010dast\u00ed syst\u00e9mu pomocou na\u0161ich produktov a rie\u0161en\u00ed pre monitorovanie a diagnostiku.",
            items: [
              {
                id: "7QI0000101",
                parent_id: "5QI0000101",
                text: "Lightning monitoring",
                X_fullName: "Lightning monitoring",
                X_fullName_SK: "Monitorovanie bleskov",
                X_URL: "lightning-monitoring",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Lightning monitoring",
                X_Description:
                  "The LM-S lightning current measuring system can detect, evaluate, and remotely monitor lightning strikes in realtime.",
                X_Description_SK: "",
              },
              {
                id: "8QI0000101",
                parent_id: "5QI0000101",
                text: "Photovoltaic system management",
                X_fullName: "Photovoltaic system management",
                X_fullName_SK: "Riadenie fotovoltaick\u00e9ho syst\u00e9mu",
                X_URL: "photovoltaic-system-management",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Photovoltaic system management",
                X_Description:
                  "Switch off your PV system safely in the event of maintenance or danger and increase efficiency by always being informed about the state of your system.",
                X_Description_SK:
                  "Bezpe\u010dne vypnite svoj FV syst\u00e9m v pr\u00edpade \u00fadr\u017eby alebo nebezpe\u010denstva a zv\u00fd\u0161te efektivitu t\u00fdm, \u017ee budete v\u017edy informovan\u00ed o stave v\u00e1\u0161ho zariadenia.",
                items: [
                  {
                    id: "AQI0000101",
                    parent_id: "8QI0000101",
                    text: "String combiner for photovoltaic systems",
                    X_fullName: "String combiner for photovoltaic systems",
                    X_fullName_SK:
                      "Kombin\u00e1tor re\u0165azcov pre fotovoltaick\u00e9 syst\u00e9my",
                    X_URL: "string-combiner-for-photovoltaic",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Photovoltaic system management\\String combiner for photovoltaic systems",
                    X_Description:
                      "Reliably monitor the performance of your photovoltaic system with string combiner boxes from Phoenix Contact.",
                    X_Description_SK: "",
                  },
                  {
                    id: "CQI0000101",
                    parent_id: "8QI0000101",
                    text: "Photovoltaics string monitoring",
                    X_fullName: "Photovoltaics string monitoring",
                    X_fullName_SK:
                      "Monitorovanie fotovoltaick\u00fdch re\u0165azcov",
                    X_URL: "photovoltaics-string-monitoring",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Photovoltaic system management\\Photovoltaics string monitoring",
                    X_Description:
                      "Two-piece system for string monitoring in photovoltaic systems.",
                    X_Description_SK: "",
                  },
                ],
              },
              {
                id: "DQI0000101",
                parent_id: "5QI0000101",
                text: "Monitoring relays",
                X_fullName: "Monitoring relays",
                X_fullName_SK: "Monitorovacie rel\u00e9",
                X_URL: "monitoring-relays",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays",
                X_Description:
                  "Relays for monitoring currents, voltages, and other power grid parameters.",
                X_Description_SK:
                  "Rel\u00e9 na monitorovanie pr\u00fadov, nap\u00e4t\u00ed a in\u00fdch parametrov elektrickej siete.",
                items: [
                  {
                    id: "EQI0000101",
                    parent_id: "DQI0000101",
                    text: "Compact monitoring relays",
                    X_fullName: "Compact monitoring relays",
                    X_fullName_SK: "Kompaktn\u00e9 monitorovacie rel\u00e9",
                    X_URL: "compact-monitoring-relays",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays",
                    X_Description:
                      "Compact monitoring relays for monitoring current, voltage, and phase parameters.",
                    X_Description_SK:
                      "Kompaktn\u00e9 monitorovacie rel\u00e9 na monitorovac\u00ed pr\u00fad, nap\u00e4tie a f\u00e1zov\u00e9 parametre.",
                    items: [
                      {
                        id: "FQI0000101",
                        parent_id: "EQI0000101",
                        text: "Current monitoring relays",
                        X_fullName: "Current monitoring relays",
                        X_fullName_SK:
                          "Pr\u00fadov\u00e9 monitorovacie rel\u00e9",
                        X_URL: "current-monitoring-relays",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays\\Current monitoring relays",
                        X_Description:
                          "Compact monitoring relays for monitoring single-phase direct and alternating currents from 0 to 10 A.",
                        X_Description_SK:
                          "Kompaktn\u00e9 monitorovacie rel\u00e9 na monitorovanie priamych a striedav\u00fdch pr\u00fadov od 0 do 10 A.",
                      },
                      {
                        id: "GQI0000101",
                        parent_id: "EQI0000101",
                        text: "Voltage monitoring relays",
                        X_fullName: "Voltage monitoring relays",
                        X_fullName_SK:
                          "Rel\u00e9 na monitorovanie nap\u00e4tia",
                        X_URL: "voltage-monitoring-relays",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays\\Voltage monitoring relays",
                        X_Description:
                          "Compact monitoring relays for monitoring DC and AC voltages in single and three-phase networks.",
                        X_Description_SK:
                          "Kompaktn\u00e9 monitorovacie rel\u00e9 na monitorovanie jednosmern\u00fdch a striedav\u00fdch nap\u00e4t\u00ed v jednof\u00e1zov\u00fdch a trojf\u00e1zov\u00fdch sie\u0165ach.",
                      },
                      {
                        id: "HQI0000101",
                        parent_id: "EQI0000101",
                        text: "Phase monitoring relays",
                        X_fullName: "Phase monitoring relays",
                        X_fullName_SK:
                          "F\u00e1zov\u00e9 monitorovacie rel\u00e9",
                        X_URL: "phase-monitoring-relays",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays\\Phase monitoring relays",
                        X_Description:
                          "Compact monitoring relays for phase parameter monitoring in three-phase power grids.",
                        X_Description_SK:
                          "Kompaktn\u00e9 monitorovacie rel\u00e9 pre monitorovanie f\u00e1zov\u00fdch parametrov v trojf\u00e1zov\u00fdch v\u00fdkonov\u00fdch mrie\u017ek\u00e1ch.",
                      },
                      {
                        id: "IQI0000101",
                        parent_id: "EQI0000101",
                        text: "Temperature monitoring relay (thermistor)",
                        X_fullName: "Temperature monitoring relay (thermistor)",
                        X_fullName_SK:
                          "Rel\u00e9 na monitorovanie teploty (termistor)",
                        X_URL: "temperature-monitoring-relay",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays\\Temperature monitoring relay (thermistor)",
                        X_Description:
                          "Compact monitoring relay for monitoring the temperature of motor windings using a thermistor.",
                        X_Description_SK:
                          "Kompaktn\u00e9 monitorovacie rel\u00e9 na sledovanie teploty vinutia motora pomocou termistora.",
                      },
                    ],
                  },
                  {
                    id: "JQI0000101",
                    parent_id: "DQI0000101",
                    text: "Multifunctional monitoring relays",
                    X_fullName: "Multifunctional monitoring relays",
                    X_fullName_SK:
                      "Multifunk\u010dn\u00e9 monitorovacie rel\u00e9",
                    X_URL: "multifunctional-monitoring-relay",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays",
                    X_Description:
                      "Multifunctional monitoring relays for monitoring currents, voltages, and other network parameters.",
                    X_Description_SK:
                      "Multifunk\u010dn\u00e9 monitorovacie rel\u00e9 na monitorovanie pr\u00fadov, nap\u00e4tia a \u010fal\u0161\u00edch sie\u0165ov\u00fdch parametrov.",
                    items: [
                      {
                        id: "KQI0000101",
                        parent_id: "JQI0000101",
                        text: "Current monitoring relays",
                        X_fullName: "Current monitoring relays",
                        X_fullName_SK:
                          "Pr\u00fadov\u00e9 monitorovacie rel\u00e9",
                        X_URL: "multi-current-monitoring-relays",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Current monitoring relays",
                        X_Description:
                          "Compact monitoring relays for monitoring single-phase direct and alternating currents from 0 to 10 A.",
                        X_Description_SK:
                          "Kompaktn\u00e9 monitorovacie rel\u00e9 na monitorovanie jednof\u00e1zov\u00fdch jednosmern\u00fdch a striedav\u00fdch pr\u00fadov od 0 do 10 A.",
                      },
                      {
                        id: "LQI0000101",
                        parent_id: "JQI0000101",
                        text: "Voltage monitoring relays",
                        X_fullName: "Voltage monitoring relays",
                        X_fullName_SK:
                          "Rel\u00e9 na monitorovanie nap\u00e4tia",
                        X_URL: "multi-voltage-monitoring-relays",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Voltage monitoring relays",
                        X_Description:
                          "Multifunctional monitoring relay for monitoring DC and AC voltages in single and three-phase networks.",
                        X_Description_SK:
                          "Multifunk\u010dn\u00e9 monitorovacie rel\u00e9 na monitorovanie jednosmern\u00e9ho a striedav\u00e9ho nap\u00e4tia v jednof\u00e1zov\u00fdch a trojf\u00e1zov\u00fdch sie\u0165ach.",
                      },
                      {
                        id: "MQI0000101",
                        parent_id: "JQI0000101",
                        text: "Phase monitoring relays",
                        X_fullName: "Phase monitoring relays",
                        X_fullName_SK:
                          "F\u00e1zov\u00e9 monitorovacie rel\u00e9",
                        X_URL: "multi-phase-monitoring-relays",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Phase monitoring relays",
                        X_Description:
                          "Relays for phase parameter monitoring of three-phase AC voltages \u2013 with and without neutral conductor.",
                        X_Description_SK:
                          "Rel\u00e9 na monitorovanie f\u00e1zov\u00fdch parametrov trojf\u00e1zov\u00fdch striedav\u00fdch nap\u00e4t\u00ed \u2013 s neutr\u00e1lnym vodi\u010dom a bez neho.",
                      },
                      {
                        id: "NQI0000101",
                        parent_id: "JQI0000101",
                        text: "Load monitoring relays (cos \u03d5)",
                        X_fullName: "Load monitoring relays (cos \u03d5)",
                        X_fullName_SK:
                          "Rel\u00e9 na monitorovanie za\u0165a\u017eenia (cos \u03d5)",
                        X_URL: "load-monitoring-relays",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Load monitoring relays (cos \u03d5)",
                        X_Description:
                          "Monitoring relays for monitoring loads (cos \u03d5) in single and three-phase networks.",
                        X_Description_SK:
                          "Monitorovacie rel\u00e9 pre monitorovanie z\u00e1\u0165a\u017ee (cos \u03d5) v jednof\u00e1zov\u00fdch a trojf\u00e1zov\u00fdch sie\u0165ach.",
                      },
                      {
                        id: "OQI0000101",
                        parent_id: "JQI0000101",
                        text: "Temperature monitoring relays (termistor)",
                        X_fullName: "Temperature monitoring relays (termistor)",
                        X_fullName_SK:
                          "Rel\u00e9 na monitorovanie teploty (termistor)",
                        X_URL: "temperature-monitoring-relays",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Temperature monitoring relays (termistor)",
                        X_Description:
                          "Relays for monitoring motor winding temperatures using PTC according to DIN 44081.",
                        X_Description_SK:
                          "Rel\u00e9 na monitorovanie teploty vinutia motora pomocou PTC pod\u013ea DIN 44081.",
                      },
                      {
                        id: "PQI0000101",
                        parent_id: "JQI0000101",
                        text: "Level monitoring relay",
                        X_fullName: "Level monitoring relay",
                        X_fullName_SK: "Rel\u00e9 monitorovania hladiny",
                        X_URL: "level-monitoring-relay",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Level monitoring relay",
                        X_Description:
                          "Relays for minimum and maximum level monitoring of conductive liquids.",
                        X_Description_SK:
                          "Rel\u00e9 na monitorovanie minim\u00e1lnej a maxim\u00e1lnej hladiny vodiv\u00fdch kvapal\u00edn.",
                      },
                      {
                        id: "QQI0000101",
                        parent_id: "JQI0000101",
                        text: "Accessories for monitoring relays",
                        X_fullName: "Accessories for monitoring relays",
                        X_fullName_SK:
                          "Pr\u00edslu\u0161enstvo pre monitorovacie rel\u00e9",
                        X_URL: "monitoring-relays-accessories",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Accessories for monitoring relays",
                        X_Description:
                          "Accessories for the installation and operation of electronic monitoring relays.",
                        X_Description_SK:
                          "Pr\u00edslu\u0161enstvo pre in\u0161tal\u00e1ciu a prev\u00e1dzku elektronick\u00fdch monitorovac\u00edch rel\u00e9.",
                      },
                    ],
                  },
                ],
              },
              {
                id: "SQI0000101",
                parent_id: "5QI0000101",
                text: "Lamp testing modules",
                X_fullName: "Lamp testing modules",
                X_fullName_SK: "Moduly na testovanie sveteln\u00fdch zdrojov",
                X_URL: "lamp-testing-modules",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Lamp testing modules",
                X_Description:
                  "Modules for testing lamps individually or for testing lamps centrally.",
                X_Description_SK: "",
              },
              {
                id: "UQI0000101",
                parent_id: "5QI0000101",
                text: "Diode modules",
                X_fullName: "Diode modules",
                X_fullName_SK: "Di\u00f3dov\u00e9 moduly",
                X_URL: "diode-modules",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Diode modules",
                X_Description:
                  "Modules for diode circuits which perform a wide range of tasks in electrical systems.",
                X_Description_SK: "",
              },
              {
                id: "VQI0000101",
                parent_id: "5QI0000101",
                text: "Rotor blade monitoring for wind turbine generators",
                X_fullName:
                  "Rotor blade monitoring for wind turbine generators",
                X_fullName_SK:
                  "Monitorovanie lopatiek rotora pre gener\u00e1tory vetern\u00fdch turb\u00edn",
                X_URL: "rotor-blade-monitoring-main",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Rotor blade monitoring for wind turbine generators",
                X_Description:
                  "Rotor blade tension monitoring (RBTM) from Phoenix Contact continuously monitors the vibrations in the rotor blades of a wind turbine generator.",
                X_Description_SK:
                  "Monitorovanie nap\u00e4tia lopatiek rotora (RBTM) od spolo\u010dnosti Phoenix Contact nepretr\u017eite monitoruje vibr\u00e1cie v lopatk\u00e1ch rotora gener\u00e1tora veternej turb\u00edny.",
                items: [
                  {
                    id: "WQI0000101",
                    parent_id: "VQI0000101",
                    text: "Rotor blade monitoring",
                    X_fullName: "Rotor blade monitoring",
                    X_fullName_SK: "Monitorovanie lopatiek rotora",
                    X_URL: "rotor-blade-monitoring",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Rotor blade monitoring for wind turbine generators\\Rotor blade monitoring",
                    X_Description:
                      "Complete solution for continuously monitoring the vibrations in the rotor blades in a wind turbine generator.",
                    X_Description_SK:
                      "Kompletn\u00e9 rie\u0161enie pre nepretr\u017eit\u00e9 monitorovanie vibr\u00e1ci\u00ed v listoch rotora v gener\u00e1tore veternej turb\u00edny.",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "XQI0000101",
        parent_id: "HFI0000101",
        text: "Modular terminal blocks",
        X_fullName: "Modular terminal blocks",
        X_fullName_SK: "Modul\u00e1rne svorkovnice",
        X_URL: "modular-terminal-blocks",
        FULLPATH: "PhoenixContact\\Modular terminal blocks",
        X_Description:
          "Different connection technologies give you greater flexibility \u2013 in both the configuration and design of the control cabinet.",
        X_Description_SK:
          "R\u00f4zne technol\u00f3gie pripojenia v\u00e1m poskytuj\u00fa v\u00e4\u010d\u0161iu flexibilitu \u2013 v konfigur\u00e1cii aj dizajne rozv\u00e1dza\u010da.",
        items: [
          {
            id: "YQI0000101",
            parent_id: "XQI0000101",
            text: "Screw connection",
            X_fullName: "Screw connection",
            X_fullName_SK: "Skrutkov\u00e9 spojenie",
            X_URL: "screw-connection",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Screw connection",
            X_Description:
              "The internationally recognized screw connection meets the most stringent requirements and covers a wide area of application.",
            X_Description_SK:
              "Medzin\u00e1rodne uzn\u00e1van\u00fd skrutkov\u00fd spoj sp\u013a\u0148a najpr\u00edsnej\u0161ie po\u017eiadavky a pokr\u00fdva \u0161irok\u00fa oblas\u0165 pou\u017eitia.",
            items: [
              {
                id: "0RI0000101",
                parent_id: "YQI0000101",
                text: "Component terminal blocks",
                X_fullName: "Component terminal blocks",
                X_fullName_SK: "Komponentn\u00e9 svorkovnice",
                X_URL: "screw-component-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Component terminal blocks",
                X_Description:
                  "Component terminal blocks with LEDs or blocking diodes for individual mounting.",
                X_Description_SK: "",
              },
              {
                id: "2RI0000101",
                parent_id: "YQI0000101",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_fullName_SK: "Priechodkov\u00e9 svorkovnice",
                X_URL: "screw-feed-through-terminal-bl",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with screw connection in various sizes are ideal for both solid and stranded conductors.",
                X_Description_SK: "",
              },
              {
                id: "4RI0000101",
                parent_id: "YQI0000101",
                text: "High-temperature terminal blocks",
                X_fullName: "High-temperature terminal blocks",
                X_fullName_SK: "Vysokoteplotn\u00e9 svorkovnice",
                X_URL: "high-temperature-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\High-temperature terminal blocks",
                X_Description:
                  "Compact high-temperature terminal blocks with screw connection for various cross sections.",
                X_Description_SK: "",
              },
              {
                id: "6RI0000101",
                parent_id: "YQI0000101",
                text: "Hybrid terminal blocks",
                X_fullName: "Hybrid terminal blocks",
                X_fullName_SK: "Hybridn\u00e9 svorkovnice",
                X_URL: "screw-hybrid-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Hybrid terminal blocks",
                X_Description:
                  "Hybrid terminal blocks for various conductor cross sections combine screw connection with spring-cage or fast connection.",
                X_Description_SK: "",
              },
              {
                id: "8RI0000101",
                parent_id: "YQI0000101",
                text: "Sensor/actuator terminal blocks",
                X_fullName: "Sensor/actuator terminal blocks",
                X_fullName_SK:
                  "Svorkovnice senzorov/ak\u010dn\u00fdch \u010dlenov",
                X_URL: "screw-sensor-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Sensor/actuator terminal blocks",
                X_Description:
                  "Sensor/actuator terminal blocks with screw connection in various lengths, with or without LED.",
                X_Description_SK: "",
              },
              {
                id: "ARI0000101",
                parent_id: "YQI0000101",
                text: "Installation terminal blocks",
                X_fullName: "Installation terminal blocks",
                X_fullName_SK: "In\u0161tala\u010dn\u00e9 svorkovnice",
                X_URL: "installation-terminal-bl-main",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Installation terminal blocks",
                X_Description:
                  "Installation terminal blocks with screw connection in various sizes and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "CRI0000101",
                parent_id: "YQI0000101",
                text: "Multi-conductor terminal blocks",
                X_fullName: "Multi-conductor terminal blocks",
                X_fullName_SK: "Viacvodi\u010dov\u00e9 svorkovnice",
                X_URL: "screw-multi-conductor-terminal",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Multi-conductor terminal blocks",
                X_Description:
                  "Multi-conductor terminal blocks with screw connection for three or four conductors with various conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "ERI0000101",
                parent_id: "YQI0000101",
                text: "Multi-level terminal blocks",
                X_fullName: "Multi-level terminal blocks",
                X_fullName_SK: "Viac\u00farov\u0148ov\u00e9 svorkovnice",
                X_URL: "screw-multi-level-terminal-bl",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Multi-level terminal blocks",
                X_Description:
                  "Double- or three-level terminal blocks with offset levels for various conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "GRI0000101",
                parent_id: "YQI0000101",
                text: "Test disconnect terminal blocks",
                X_fullName: "Test disconnect terminal blocks",
                X_fullName_SK: "Testovacie odpojovacie svorkovnice",
                X_URL: "screw-test-disconnect-terminal",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Test disconnect terminal blocks",
                X_Description:
                  "Test disconnect terminal blocks and plugs with screw connection in various sizes.",
                X_Description_SK: "",
              },
              {
                id: "IRI0000101",
                parent_id: "YQI0000101",
                text: "Mini and micro terminal blocks",
                X_fullName: "Mini and micro terminal blocks",
                X_fullName_SK: "Mini a mikro svorkovnice",
                X_URL: "mini-micro-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Mini and micro terminal blocks",
                X_Description:
                  "Mini and micro terminal blocks with a connection cross section of 1.5 mm\u00b2 permit convenient wiring in a confined space.",
                X_Description_SK: "",
              },
              {
                id: "KRI0000101",
                parent_id: "YQI0000101",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_fullName_SK: "Uzem\u0148ovacie svorkovnice",
                X_URL: "screw-ground-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with screw connection in various sizes and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "MRI0000101",
                parent_id: "YQI0000101",
                text: "Fuse terminal blocks",
                X_fullName: "Fuse terminal blocks",
                X_fullName_SK: "Poistkov\u00e9 svorkovnice",
                X_URL: "screw-fuse-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Fuse terminal blocks",
                X_Description:
                  "Fuse terminal blocks with screw connection in various designs. Versions with LED complete the product range.",
                X_Description_SK: "",
              },
              {
                id: "ORI0000101",
                parent_id: "YQI0000101",
                text: "Plug-in terminal blocks",
                X_fullName: "Plug-in terminal blocks",
                X_fullName_SK: "Z\u00e1suvn\u00e9 svorkovnice",
                X_URL: "screw-plug-in-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Plug-in terminal blocks",
                X_Description:
                  "Plug-in terminal blocks with screw connection in various designs and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "QRI0000101",
                parent_id: "YQI0000101",
                text: "Disconnect and knife disconnect terminal blocks",
                X_fullName: "Disconnect and knife disconnect terminal blocks",
                X_fullName_SK:
                  "Odpojovacie a no\u017eov\u00e9 odpojovacie svorkovnice",
                X_URL: " knife-disconnect-terminal-block",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Disconnect and knife disconnect terminal blocks",
                X_Description:
                  "Disconnect and knife disconnect terminal blocks with universal disconnect zone for accommodating isolating or function plugs.",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "RRI0000101",
            parent_id: "XQI0000101",
            text: "Push-in connection",
            X_fullName: "Push-in connection",
            X_fullName_SK: "Pripojenie Push-in",
            X_URL: "push-in-connection",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Push-in connection",
            X_Description:
              "The low insertion forces of the Push-in connection terminal blocks enable the conductors to be inserted easily and directly.",
            X_Description_SK:
              "N\u00edzke sily zas\u00favania pripojovac\u00edch svoriek Push-in umo\u017e\u0148uj\u00fa jednoduch\u00e9 a priame zas\u00favanie vodi\u010dov.",
            items: [
              {
                id: "TRI0000101",
                parent_id: "RRI0000101",
                text: "Component terminal blocks",
                X_fullName: "Component terminal blocks",
                X_fullName_SK: "Komponentn\u00e9 svorkovnice",
                X_URL: "push-component-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Component terminal blocks",
                X_Description:
                  "Component terminal blocks with LEDs or blocking diodes for individual mounting.",
                X_Description_SK: "",
              },
              {
                id: "VRI0000101",
                parent_id: "RRI0000101",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_fullName_SK: "Priechodkov\u00e9 svorkovnice",
                X_URL: "push-feed-through-terminal-bl",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with push-in connection in various sizes are ideal for both solid and stranded conductors.",
                X_Description_SK: "",
              },
              {
                id: "XRI0000101",
                parent_id: "RRI0000101",
                text: "Sensor/actuator terminal blocks",
                X_fullName: "Sensor/actuator terminal blocks",
                X_fullName_SK:
                  "Svorkovnice senzorov/ak\u010dn\u00fdch \u010dlenov",
                X_URL: "push-sensor-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Sensor/actuator terminal blocks",
                X_Description:
                  "Sensor/actuator terminal blocks with push-in connection in various lengths, with or without LED.",
                X_Description_SK: "",
              },
              {
                id: "ZRI0000101",
                parent_id: "RRI0000101",
                text: "Installation terminal blocks",
                X_fullName: "Installation terminal blocks",
                X_fullName_SK: "In\u0161tala\u010dn\u00e9 svorkovnice",
                X_URL: "installation-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Installation terminal blocks",
                X_Description:
                  "Installation terminal blocks with push-in connection in various sizes and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "1SI0000101",
                parent_id: "RRI0000101",
                text: "Multi-conductor terminal blocks",
                X_fullName: "Multi-conductor terminal blocks",
                X_fullName_SK: "Viacvodi\u010dov\u00e9 svorkovnice",
                X_URL: "push-multi-conductor-terminal",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Multi-conductor terminal blocks",
                X_Description:
                  "Multi-conductor terminal blocks with push-in connection for three or four conductors with various conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "3SI0000101",
                parent_id: "RRI0000101",
                text: "Multi-level terminal blocks",
                X_fullName: "Multi-level terminal blocks",
                X_fullName_SK: "Viac\u00farov\u0148ov\u00e9 svorkovnice",
                X_URL: "push-multi-level-terminal-bl",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Multi-level terminal blocks",
                X_Description:
                  "Double- or three-level terminal blocks with offset levels for various conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "5SI0000101",
                parent_id: "RRI0000101",
                text: "Test disconnect terminal blocks",
                X_fullName: "Test disconnect terminal blocks",
                X_fullName_SK: "Testovacie odpojovacie svorkovnice",
                X_URL: "push-test-disconnect-terminal",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Test disconnect terminal blocks",
                X_Description:
                  "Test disconnect terminal blocks and plugs with push-in connection in various sizes.",
                X_Description_SK: "",
              },
              {
                id: "7SI0000101",
                parent_id: "RRI0000101",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_fullName_SK: "Uzem\u0148ovacie svorkovnice",
                X_URL: "push-ground-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with push-in connection in various sizes and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "9SI0000101",
                parent_id: "RRI0000101",
                text: "Fuse terminal blocks",
                X_fullName: "Fuse terminal blocks",
                X_fullName_SK: "Poistkov\u00e9 svorkovnice",
                X_URL: "push-fuse-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Fuse terminal blocks",
                X_Description:
                  "Fuse terminal blocks with push-in connection in various designs. Versions with LED complete the product range.",
                X_Description_SK: "",
              },
              {
                id: "BSI0000101",
                parent_id: "RRI0000101",
                text: "Plug-in terminal blocks",
                X_fullName: "Plug-in terminal blocks",
                X_fullName_SK: "Z\u00e1suvn\u00e9 svorkovnice",
                X_URL: "push-plug-in-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Plug-in terminal blocks",
                X_Description:
                  "Plug-in terminal blocks with push-in connection in various designs and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "DSI0000101",
                parent_id: "RRI0000101",
                text: "Disconnect and knife disconnect terminal blocks",
                X_fullName: "Disconnect and knife disconnect terminal blocks",
                X_fullName_SK:
                  "Odpojovacie a no\u017eov\u00e9 odpojovacie svorkovnice",
                X_URL: "push-disconnect-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Disconnect and knife disconnect terminal blocks",
                X_Description:
                  "Disconnect and knife disconnect terminal blocks with universal disconnect zone for accommodating isolating or function plugs.",
                X_Description_SK: "",
              },
              {
                id: "ESI0000101",
                parent_id: "RRI0000101",
                text: "Mini and micro terminal blocks",
                X_fullName: "Mini and micro terminal blocks",
                X_fullName_SK: "Mini a mikro svorkovnice",
                X_URL: "terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Mini and micro terminal blocks",
                X_Description:
                  "Miniature and micro terminal blocks with limited space requirements for all applications",
                X_Description_SK:
                  "Miniat\u00farne a mikro svorkovnice s obmedzen\u00fdmi priestorov\u00fdmi po\u017eiadavkami pre v\u0161etky aplik\u00e1cie",
                items: [
                  {
                    id: "FSI0000101",
                    parent_id: "ESI0000101",
                    text: "Mini terminal blocks",
                    X_fullName: "Mini terminal blocks",
                    X_fullName_SK: "Mini svorkovnice",
                    X_URL: "mini-terminal-blocks",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Mini and micro terminal blocks\\Mini terminal blocks",
                    X_Description:
                      "Miniature terminal blocks with a connection cross section of 1.5 mm\u00b2 permit convenient wiring in the most confined spaces.",
                    X_Description_SK:
                      "Miniat\u00farne svorkovnice s pripojovac\u00edm prierezom 1,5 mm\u00b2 umo\u017e\u0148uj\u00fa pohodln\u00e9 zapojenie do najstiesnenej\u0161\u00edch priestorov.",
                  },
                  {
                    id: "GSI0000101",
                    parent_id: "ESI0000101",
                    text: "Micro terminal blocks",
                    X_fullName: "Micro terminal blocks",
                    X_fullName_SK: "Mikro svorkovnice",
                    X_URL: "micro-terminal-blocks",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Mini and micro terminal blocks\\Micro terminal blocks",
                    X_Description:
                      "Miniature and micro terminal blocks from Phoenix Contact provide convenient wiring and potential distribution in the most confined spaces.",
                    X_Description_SK:
                      "Miniat\u00farne a mikro svorkovnice od spolo\u010dnosti Phoenix Contact poskytuj\u00fa pohodln\u00e9 zapojenie a distrib\u00faciu potenci\u00e1lu v najstiesnenej\u0161\u00edch priestoroch.",
                  },
                ],
              },
              {
                id: "ISI0000101",
                parent_id: "RRI0000101",
                text: "High-current terminal blocks",
                X_fullName: "High-current terminal blocks",
                X_fullName_SK: "Vysokopr\u00fadov\u00e9 svorkovnice",
                X_URL: "high-current-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\High-current terminal blocks",
                X_Description:
                  "PTPOWER high-current spring-cage terminal blocks for large conductor cross sections up to 150 mm\u00b2.",
                X_Description_SK: "",
              },
              {
                id: "JSI0000101",
                parent_id: "RRI0000101",
                text: "Marshalling terminals and potential distributors",
                X_fullName: "Marshalling terminals and potential distributors",
                X_fullName_SK:
                  "Zbern\u00e9 termin\u00e1ly a potenci\u00e1lni distrib\u00fatori",
                X_URL: "marshalling-terminals-main",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling terminals and potential distributors",
                X_Description:
                  "Marshal signals safely with an innovative color coding system and up to 20% less space required.",
                X_Description_SK:
                  "Zria\u010fova\u010d signalizuje bezpe\u010dne s inovat\u00edvnym syst\u00e9mom farebn\u00e9ho k\u00f3dovania a a\u017e o 20 % men\u0161ou potrebou miesta.",
                items: [
                  {
                    id: "KSI0000101",
                    parent_id: "JSI0000101",
                    text: "Marshalling terminals",
                    X_fullName: "Marshalling terminals",
                    X_fullName_SK: "Zbern\u00e9 termin\u00e1ly",
                    X_URL: "marshalling-terminals",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling terminals and potential distributors\\Marshalling terminals",
                    X_Description:
                      "PTRV marshalling terminals - compact, safe, and intuitive signal marshalling.",
                    X_Description_SK:
                      "Zora\u010fovacie termin\u00e1ly PTRV - kompaktn\u00e9, bezpe\u010dn\u00e9 a intuit\u00edvne zora\u010fovanie sign\u00e1lov.",
                  },
                  {
                    id: "LSI0000101",
                    parent_id: "JSI0000101",
                    text: "Potential distributors",
                    X_fullName: "Potential distributors",
                    X_fullName_SK: "Potenci\u00e1l distrib\u00fatorov",
                    X_URL: "push-potential-distributors",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling terminals and potential distributors\\Potential distributors",
                    X_Description:
                      "Potential distributors for distributing up to 32 connections.",
                    X_Description_SK:
                      "Potenci\u00e1lni distrib\u00fatori na distrib\u00faciu a\u017e 32 pripojen\u00ed.",
                  },
                ],
              },
              {
                id: "NSI0000101",
                parent_id: "RRI0000101",
                text: "Hybrid terminal blocks",
                X_fullName: "Hybrid terminal blocks",
                X_fullName_SK: "Hybridn\u00e9 svorkovnice",
                X_URL: "push-hybrid-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Hybrid terminal blocks",
                X_Description:
                  "Hybrid terminal blocks from Phoenix Contact combine two connection technologies in a single terminal block: push-in and screw connection.",
                X_Description_SK: "",
              },
              {
                id: "OSI0000101",
                parent_id: "RRI0000101",
                text: "Marshalling patchboards",
                X_fullName: "Marshalling patchboards",
                X_fullName_SK: "Marshalling patchboards",
                X_URL: "marshalling-patchboards-main",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling patchboards",
                X_Description:
                  "Marshal signals reliably with an innovative color control system with up to 20% more packing density.",
                X_Description_SK:
                  "Patchboardy spo\u013eahlivo signalizuj\u00fa v\u010faka inovat\u00edvnemu syst\u00e9mu kontroly farieb s a\u017e o 20 % vy\u0161\u0161ou hustotou balenia.",
                items: [
                  {
                    id: "PSI0000101",
                    parent_id: "OSI0000101",
                    text: "Marshalling patchboards",
                    X_fullName: "Marshalling patchboards",
                    X_fullName_SK: "Marshalling patchboards",
                    X_URL: "marshalling-patchboards",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling patchboards\\Marshalling patchboards",
                    X_Description:
                      "Pre-assembled standard marshalling patchboards for the highest signal density during marshalling in automation applications.",
                    X_Description_SK:
                      "Vopred zostaven\u00e9 \u0161tandardn\u00e9 zora\u010fovacie patchboardy pre najvy\u0161\u0161iu hustotu sign\u00e1lu po\u010das zora\u010fovania v automatiza\u010dn\u00fdch aplik\u00e1ci\u00e1ch.",
                  },
                  {
                    id: "QSI0000101",
                    parent_id: "OSI0000101",
                    text: 'Marshalling patchboards for 19" racks',
                    X_fullName: 'Marshalling patchboards for 19" racks',
                    X_fullName_SK: 'Patchboardy pre 19" racky',
                    X_URL: "19-marshalling-patchboards",
                    FULLPATH:
                      'PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling patchboards\\Marshalling patchboards for 19" racks',
                    X_Description:
                      'Pre-assembled marshalling patchboards for 19" racks for space-saving, clearly arranged, and error-free wiring.',
                    X_Description_SK:
                      'Predmontovan\u00e9 zara\u010fovacie patchboardy pre 19" racky pre priestorovo \u00fasporn\u00e9, preh\u013eadn\u00e9 a bezchybn\u00e9 zapojenie.',
                  },
                ],
              },
            ],
          },
          {
            id: "RSI0000101",
            parent_id: "XQI0000101",
            text: "Spring-cage connection",
            X_fullName: "Spring-cage connection",
            X_fullName_SK: "Pripojenie pru\u017einovej klietky",
            X_URL: "spring-cage-connection",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Spring-cage connection",
            X_Description:
              "The compact spring-cage connection offers a reliable, constant connection when subjected to strong vibrations.",
            X_Description_SK:
              "Kompaktn\u00e9 spojenie pru\u017eina-klietka pon\u00faka spo\u013eahliv\u00e9 a kon\u0161tantn\u00e9 spojenie, ke\u010f je vystaven\u00e9 siln\u00fdm vibr\u00e1ci\u00e1m.",
            items: [
              {
                id: "TSI0000101",
                parent_id: "RSI0000101",
                text: "Component terminal blocks",
                X_fullName: "Component terminal blocks",
                X_fullName_SK: "Komponentn\u00e9 svorkovnice",
                X_URL: "component-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Component terminal blocks",
                X_Description:
                  "Component terminal blocks with LEDs or blocking diodes for individual mounting.",
                X_Description_SK: "",
              },
              {
                id: "VSI0000101",
                parent_id: "RSI0000101",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_fullName_SK: "Priechodkov\u00e9 svorkovnice",
                X_URL: "feed-through-terminal-bl",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with spring-cage connection in various sizes are ideal for both solid and stranded conductors.",
                X_Description_SK: "",
              },
              {
                id: "XSI0000101",
                parent_id: "RSI0000101",
                text: "Sensor/actuator terminal blocks",
                X_fullName: "Sensor/actuator terminal blocks",
                X_fullName_SK:
                  "Svorkovnice senzorov/ak\u010dn\u00fdch \u010dlenov",
                X_URL: "sensor-actuator-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Sensor/actuator terminal blocks",
                X_Description:
                  "Sensor/actuator terminal blocks with spring-cage connection in various lengths, with or without LED.",
                X_Description_SK: "",
              },
              {
                id: "ZSI0000101",
                parent_id: "RSI0000101",
                text: "Multi-conductor terminal blocks",
                X_fullName: "Multi-conductor terminal blocks",
                X_fullName_SK: "Viacvodi\u010dov\u00e9 svorkovnice",
                X_URL: "multi-conductor-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Multi-conductor terminal blocks",
                X_Description:
                  "Multi-conductor terminal blocks with spring-cage connection for three or four conductors with various conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "1TI0000101",
                parent_id: "RSI0000101",
                text: "Multi-level terminal blocks",
                X_fullName: "Multi-level terminal blocks",
                X_fullName_SK: "Viac\u00farov\u0148ov\u00e9 svorkovnice",
                X_URL: "multi-level-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Multi-level terminal blocks",
                X_Description:
                  "Double- or three-level terminal blocks with offset levels for various conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "3TI0000101",
                parent_id: "RSI0000101",
                text: "Test disconnect terminal blocks",
                X_fullName: "Test disconnect terminal blocks",
                X_fullName_SK: "Testovacie odpojovacie svorkovnice",
                X_URL: "test-disconnect-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Test disconnect terminal blocks",
                X_Description:
                  "Test disconnect terminal blocks and plugs with spring-cage connection in various sizes.",
                X_Description_SK: "",
              },
              {
                id: "5TI0000101",
                parent_id: "RSI0000101",
                text: "Mini and micro terminal blocks",
                X_fullName: "Mini and micro terminal blocks",
                X_fullName_SK: "Mini a mikro svorkovnice",
                X_URL: "mini-and-micro-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Mini and micro terminal blocks",
                X_Description:
                  "Mini and micro terminal blocks with a connection cross section of 1.5 mm\u00b2 permit convenient wiring in a confined space.",
                X_Description_SK: "",
              },
              {
                id: "7TI0000101",
                parent_id: "RSI0000101",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_fullName_SK: "Uzem\u0148ovacie svorkovnice",
                X_URL: "spring-ground-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with spring-cage connection in various sizes and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "9TI0000101",
                parent_id: "RSI0000101",
                text: "Fuse terminal blocks",
                X_fullName: "Fuse terminal blocks",
                X_fullName_SK: "Poistkov\u00e9 svorkovnice",
                X_URL: "fuse-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Fuse terminal blocks",
                X_Description:
                  "Fuse terminal blocks with spring-cage connection in various designs. Versions with LED complete the product range.",
                X_Description_SK: "",
              },
              {
                id: "BTI0000101",
                parent_id: "RSI0000101",
                text: "Plug-in terminal blocks",
                X_fullName: "Plug-in terminal blocks",
                X_fullName_SK: "Z\u00e1suvn\u00e9 svorkovnice",
                X_URL: "plug-in-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Plug-in terminal blocks",
                X_Description:
                  "Plug-in terminal blocks with spring-cage connection in various designs and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "DTI0000101",
                parent_id: "RSI0000101",
                text: "Disconnect and knife disconnect terminal blocks",
                X_fullName: "Disconnect and knife disconnect terminal blocks",
                X_fullName_SK:
                  "Odpojovacie a no\u017eov\u00e9 odpojovacie svorkovnice",
                X_URL: "disconnect-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Disconnect and knife disconnect terminal blocks",
                X_Description:
                  "Disconnect and knife disconnect terminal blocks with universal disconnect zone for accommodating isolating or function plugs.",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "ETI0000101",
            parent_id: "XQI0000101",
            text: "Fast connection",
            X_fullName: "Fast connection",
            X_fullName_SK: "R\u00fdchle pripojenie",
            X_URL: "fast-connection",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Fast connection",
            X_Description:
              "The patented insulation displacement contact allows for a quick connection without conductor pretreatment.",
            X_Description_SK:
              "Patentovan\u00fd izola\u010dn\u00fd kontakt umo\u017e\u0148uje r\u00fdchle pripojenie bez predbe\u017enej \u00fapravy vodi\u010da.",
            items: [
              {
                id: "GTI0000101",
                parent_id: "ETI0000101",
                text: "Component terminal blocks",
                X_fullName: "Component terminal blocks",
                X_fullName_SK: "Komponentn\u00e9 svorkovnice",
                X_URL: "fast-component-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Component terminal blocks",
                X_Description:
                  "Component terminal blocks with LEDs or blocking diodes for individual mounting.",
                X_Description_SK: "",
              },
              {
                id: "ITI0000101",
                parent_id: "ETI0000101",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_fullName_SK: "Priechodkov\u00e9 svorkovnice",
                X_URL: "fast-feed-through-terminal-bl",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with fast connection in various sizes are ideal for both solid and stranded conductors.",
                X_Description_SK: "",
              },
              {
                id: "KTI0000101",
                parent_id: "ETI0000101",
                text: "Hybrid terminal blocks",
                X_fullName: "Hybrid terminal blocks",
                X_fullName_SK: "Hybridn\u00e9 svorkovnice",
                X_URL: "hybrid-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Hybrid terminal blocks",
                X_Description:
                  "Hybrid terminal blocks for various conductor cross sections combine fast connection with spring-cage or fast connection.",
                X_Description_SK: "",
              },
              {
                id: "MTI0000101",
                parent_id: "ETI0000101",
                text: "Multi-conductor terminal blocks",
                X_fullName: "Multi-conductor terminal blocks",
                X_fullName_SK: "Viacvodi\u010dov\u00e9 svorkovnice",
                X_URL: "fast-multi-conductor-terminal",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Multi-conductor terminal blocks",
                X_Description:
                  "Multi-conductor terminal blocks with fast connection for three or four conductors with various conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "OTI0000101",
                parent_id: "ETI0000101",
                text: "Multi-level terminal blocks",
                X_fullName: "Multi-level terminal blocks",
                X_fullName_SK: "Viac\u00farov\u0148ov\u00e9 svorkovnice",
                X_URL: "fast-multi-level-terminal-bl",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Multi-level terminal blocks",
                X_Description:
                  "Double- or three-level terminal blocks with offset levels for various conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "QTI0000101",
                parent_id: "ETI0000101",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_fullName_SK: "Uzem\u0148ovacie svorkovnice",
                X_URL: "fast-ground-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with fast connection in various sizes and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "STI0000101",
                parent_id: "ETI0000101",
                text: "Fuse terminal blocks",
                X_fullName: "Fuse terminal blocks",
                X_fullName_SK: "Poistkov\u00e9 svorkovnice",
                X_URL: "fast-fuse-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Fuse terminal blocks",
                X_Description:
                  "Fuse terminal blocks with fast connection in various designs. Versions with LED complete the product range.",
                X_Description_SK: "",
              },
              {
                id: "UTI0000101",
                parent_id: "ETI0000101",
                text: "Plug-in terminal blocks",
                X_fullName: "Plug-in terminal blocks",
                X_fullName_SK: "Z\u00e1suvn\u00e9 svorkovnice",
                X_URL: "fast-plug-in-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Plug-in terminal blocks",
                X_Description:
                  "Plug-in terminal blocks with fast connection in various designs and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "WTI0000101",
                parent_id: "ETI0000101",
                text: "Disconnect and knife disconnect terminal blocks",
                X_fullName: "Disconnect and knife disconnect terminal blocks",
                X_fullName_SK:
                  "Odpojovacie a no\u017eov\u00e9 odpojovacie svorkovnice",
                X_URL: "knife-disconnect-terminal-block",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Disconnect and knife disconnect terminal blocks",
                X_Description:
                  "Disconnect and knife disconnect terminal blocks with universal disconnect zone for accommodating isolating or function plugs.",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "XTI0000101",
            parent_id: "XQI0000101",
            text: "Bolt connection",
            X_fullName: "Bolt connection",
            X_fullName_SK: "Skrutkov\u00e9 spojenie",
            X_URL: "bolt-connection",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Bolt connection",
            X_Description:
              "Connect all types of conductors safely with long-term stability: multi-conductor connection with several cable lugs per bolt.",
            X_Description_SK:
              "Spojte v\u0161etky typy vodi\u010dov bezpe\u010dne s dlhodobou stabilitou: viacvodi\u010dov\u00e9 spojenie s nieko\u013ek\u00fdmi k\u00e1blov\u00fdmi okami na skrutku.",
            items: [
              {
                id: "ZTI0000101",
                parent_id: "XTI0000101",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_fullName_SK: "Priechodkov\u00e9 svorkovnice",
                X_URL: "feed-through-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Bolt connection\\Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with bolt connection in various sizes are ideal for both solid and stranded conductors.",
                X_Description_SK: "",
              },
              {
                id: "1UI0000101",
                parent_id: "XTI0000101",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_fullName_SK: "Uzem\u0148ovacie svorkovnice",
                X_URL: "ground-terminal-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Bolt connection\\Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with bolt connection in various sizes and for a range of conductor cross sections.",
                X_Description_SK: "",
              },
              {
                id: "3UI0000101",
                parent_id: "XTI0000101",
                text: "Test disconnect terminal blocks",
                X_fullName: "Test disconnect terminal blocks",
                X_fullName_SK: "Testovacie odpojovacie svorkovnice",
                X_URL: "bolt-test-disconnect-terminal",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Bolt connection\\Test disconnect terminal blocks",
                X_Description:
                  "Test disconnect terminal blocks and plugs with bolt connection in various sizes.",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "4UI0000101",
            parent_id: "XQI0000101",
            text: "Special connection",
            X_fullName: "Special connection",
            X_fullName_SK: "\u0160peci\u00e1lne pripojenie",
            X_URL: "special-connection",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Special connection",
            X_Description:
              "Special connection technologies for special industrial requirements.",
            X_Description_SK:
              "\u0160peci\u00e1lne spojovacie technol\u00f3gie pre \u0161peci\u00e1lne priemyseln\u00e9 po\u017eiadavky.",
            items: [
              {
                id: "5UI0000101",
                parent_id: "4UI0000101",
                text: "Slip-on plug-in connection",
                X_fullName: "Slip-on plug-in connection",
                X_fullName_SK:
                  "N\u00e1suvn\u00e9 z\u00e1str\u010dkov\u00e9 pripojenie",
                X_URL: "slip-on-plug-in-connection",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Special connection\\Slip-on plug-in connection",
                X_Description:
                  "Single- or double-level special terminal blocks with front slip-on plug-in connection. Can also be combined with screw connection.",
                X_Description_SK:
                  "Jedno- alebo dvoj\u00farov\u0148ov\u00e9 \u0161peci\u00e1lne svorkovnice s predn\u00fdm n\u00e1str\u010dn\u00fdm pripojen\u00edm. Mo\u017en\u00e9 kombinova\u0165 aj so skrutkov\u00fdm spojom.",
              },
            ],
          },
          {
            id: "6UI0000101",
            parent_id: "XQI0000101",
            text: "Accessories",
            X_fullName: "Accessories",
            X_fullName_SK: "Pr\u00edslu\u0161enstvo",
            X_URL: "mtb-accessories",
            FULLPATH: "PhoenixContact\\Modular terminal blocks\\Accessories",
            X_Description:
              "Accessories for modular terminal blocks, for example, bridges, covers, and test accessories.",
            X_Description_SK:
              "Pr\u00edslu\u0161enstvo pre modul\u00e1rne svorkovnice, napr\u00edklad most\u00edky, kryty a testovacie pr\u00edslu\u0161enstvo.",
          },
          {
            id: "7UI0000101",
            parent_id: "XQI0000101",
            text: "Terminal blocks for protection and control technol",
            X_fullName: "Terminal blocks for protection and control technol",
            X_fullName_SK: "Svorkovnice pre ochrann\u00e9 a riadiace technol",
            X_URL: "protection-terminal-blocks",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol",
            X_Description:
              "Terminal blocks for the special requirements of protection and control technology.",
            X_Description_SK:
              "Svorkovnice pre \u0161peci\u00e1lne po\u017eiadavky ochrannej a riadiacej techniky.",
            items: [
              {
                id: "8UI0000101",
                parent_id: "7UI0000101",
                text: "Plug-in test system",
                X_fullName: "Plug-in test system",
                X_fullName_SK: "Z\u00e1suvn\u00fd testovac\u00ed syst\u00e9m",
                X_URL: "plug-in-test-system",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system",
                X_Description:
                  "The FAME test system simplifies the regular testing of power switchgear.",
                X_Description_SK:
                  "Testovac\u00ed syst\u00e9m FAME zjednodu\u0161uje pravideln\u00e9 testovanie v\u00fdkonov\u00fdch rozv\u00e1dza\u010dov.",
                items: [
                  {
                    id: "9UI0000101",
                    parent_id: "8UI0000101",
                    text: "Test disconnect system with operating plug and tra",
                    X_fullName:
                      "Test disconnect system with operating plug and tra",
                    X_fullName_SK:
                      "Sk\u00fa\u0161obn\u00e9 odpojenie syst\u00e9mu s prev\u00e1dzkovou z\u00e1str\u010dkou a tra",
                    X_URL: "operating-plug-disconnect-system",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra",
                    X_Description:
                      "With FAME 1 you can combine testing processes in individual blocks.",
                    X_Description_SK:
                      "S FAME 1 m\u00f4\u017eete kombinova\u0165 testovacie procesy v jednotliv\u00fdch blokoch.",
                    items: [
                      {
                        id: "AUI0000101",
                        parent_id: "9UI0000101",
                        text: "Test plugs",
                        X_fullName: "Test plugs",
                        X_fullName_SK: "Testovacie z\u00e1str\u010dky",
                        X_URL: "test-plugs-tra",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra\\Test plugs",
                        X_Description:
                          "Test plugs for safe and easy, manual testing operations.",
                        X_Description_SK:
                          "Testovacie z\u00e1str\u010dky pre bezpe\u010dn\u00e9 a jednoduch\u00e9 manu\u00e1lne testovanie.",
                      },
                      {
                        id: "BUI0000101",
                        parent_id: "9UI0000101",
                        text: "Power plug",
                        X_fullName: "Power plug",
                        X_fullName_SK: "Nap\u00e1jacia z\u00e1str\u010dka",
                        X_URL: "power-plug",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra\\Power plug",
                        X_Description:
                          "Power plugs for the plug-in test system with power plug.",
                        X_Description_SK:
                          "Nap\u00e1jacie z\u00e1str\u010dky pre z\u00e1suvn\u00fd testovac\u00ed syst\u00e9m s nap\u00e1jacou z\u00e1str\u010dkou.",
                      },
                      {
                        id: "CUI0000101",
                        parent_id: "9UI0000101",
                        text: "Test terminal strips",
                        X_fullName: "Test terminal strips",
                        X_fullName_SK: "Testovacie svorkovnice",
                        X_URL: "test-terminal-strips-tra",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra\\Test terminal strips",
                        X_Description:
                          "Test terminal strips for the control cabinet panel for safe and easy, manual testing operations.",
                        X_Description_SK:
                          "Testovacie svorkovnice pre panel rozv\u00e1dza\u010da pre bezpe\u010dn\u00e9 a jednoduch\u00e9 manu\u00e1lne testovanie.",
                      },
                      {
                        id: "DUI0000101",
                        parent_id: "9UI0000101",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                        X_URL: "disconnect-sys-with-accessories",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra\\Accessories",
                        X_Description:
                          "Accessories for the FAME plug-in test system for safe and easy manual testing operations.",
                        X_Description_SK:
                          "Pr\u00edslu\u0161enstvo pre z\u00e1suvn\u00fd testovac\u00ed syst\u00e9m FAME pre bezpe\u010dn\u00e9 a jednoduch\u00e9 manu\u00e1lne testovanie.",
                      },
                    ],
                  },
                  {
                    id: "EUI0000101",
                    parent_id: "8UI0000101",
                    text: "Test disconnect system without operating plug and ",
                    X_fullName:
                      "Test disconnect system without operating plug and ",
                    X_fullName_SK:
                      "Test odpojenia syst\u00e9mu bez prev\u00e1dzkovej z\u00e1str\u010dky a ",
                    X_URL: "test-disconnect-system",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and ",
                    X_Description:
                      "With FAME 2 you can combine complex testing processes in just one block.",
                    X_Description_SK:
                      "S FAME 2 m\u00f4\u017eete kombinova\u0165 komplexn\u00e9 testovacie procesy v jednom bloku.",
                    items: [
                      {
                        id: "FUI0000101",
                        parent_id: "EUI0000101",
                        text: "Test terminal strips",
                        X_fullName: "Test terminal strips",
                        X_fullName_SK: "Testovacie svorkovnice",
                        X_URL: "test-terminal-strips",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test terminal strips",
                        X_Description:
                          "Test terminal strips for the control cabinet panel for safe and easy manual testing.",
                        X_Description_SK:
                          "Testovacie svorkovnice pre panel rozv\u00e1dza\u010da pre bezpe\u010dn\u00e9 a jednoduch\u00e9 manu\u00e1lne testovanie.",
                      },
                      {
                        id: "GUI0000101",
                        parent_id: "EUI0000101",
                        text: "Test terminal strips according to VDE",
                        X_fullName: "Test terminal strips according to VDE",
                        X_fullName_SK:
                          "Sk\u00fa\u0161obn\u00e9 svorkovnice pod\u013ea VDE",
                        X_URL: "test-terminal-strips-vde",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test terminal strips according to VDE",
                        X_Description:
                          "Test terminal strips according to VDE for safe and easy manual testing.",
                        X_Description_SK:
                          "Testovacie svorkovnice pod\u013ea VDE pre bezpe\u010dn\u00e9 a jednoduch\u00e9 manu\u00e1lne testovanie.",
                      },
                      {
                        id: "HUI0000101",
                        parent_id: "EUI0000101",
                        text: "Test plugs according to VDE",
                        X_fullName: "Test plugs according to VDE",
                        X_fullName_SK:
                          "Sk\u00fa\u0161obn\u00e9 z\u00e1str\u010dky pod\u013ea VDE",
                        X_URL: "test-plugs-vde",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test plugs according to VDE",
                        X_Description:
                          "Test plugs according to VDE for safe and easy manual testing.",
                        X_Description_SK:
                          "Testovacie z\u00e1str\u010dky pod\u013ea VDE pre bezpe\u010dn\u00e9 a jednoduch\u00e9 manu\u00e1lne testovanie.",
                      },
                      {
                        id: "IUI0000101",
                        parent_id: "EUI0000101",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                        X_URL: "disconnect-sys-without-acc",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Accessories",
                        X_Description:
                          "Accessories for the FAME plug-in test system for safe and easy manual testing.",
                        X_Description_SK:
                          "Pr\u00edslu\u0161enstvo pre z\u00e1suvn\u00fd testovac\u00ed syst\u00e9m FAME pre bezpe\u010dn\u00e9 a jednoduch\u00e9 manu\u00e1lne testovanie.",
                      },
                    ],
                  },
                  {
                    id: "JUI0000101",
                    parent_id: "8UI0000101",
                    text: "Test disconnect system without operating plug and ",
                    X_fullName:
                      "Test disconnect system without operating plug and ",
                    X_fullName_SK:
                      "Test odpojenia syst\u00e9mu bez prev\u00e1dzkovej z\u00e1str\u010dky a ",
                    X_URL: "disconnect-system-without-plug",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and ",
                    X_Description:
                      "With FAME 3 you can combine complex testing processes into individual blocks.",
                    X_Description_SK:
                      "S FAME 3 m\u00f4\u017eete kombinova\u0165 komplexn\u00e9 testovacie procesy do jednotliv\u00fdch blokov.",
                    items: [
                      {
                        id: "KUI0000101",
                        parent_id: "JUI0000101",
                        text: "Test terminal strip",
                        X_fullName: "Test terminal strip",
                        X_fullName_SK: "Testovacia svorkovnica",
                        X_URL: "test-terminal-strip",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test terminal strip",
                        X_Description:
                          "Test disconnect socket for the control cabinet panel. For safe, service-friendly testing.",
                        X_Description_SK:
                          "Otestujte odp\u00e1jaciu z\u00e1suvku pre panel ovl\u00e1dacej skrine. Pre bezpe\u010dn\u00e9 a servisne nen\u00e1ro\u010dn\u00e9 testovanie.",
                      },
                      {
                        id: "LUI0000101",
                        parent_id: "JUI0000101",
                        text: "Test plugs",
                        X_fullName: "Test plugs",
                        X_fullName_SK: "Testovacie z\u00e1str\u010dky",
                        X_URL: "test-plugs",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test plugs",
                        X_Description:
                          "Test plugs for safe, service-friendly testing.",
                        X_Description_SK:
                          "Testovacie z\u00e1str\u010dky pre bezpe\u010dn\u00e9 a servisne nen\u00e1ro\u010dn\u00e9 testovanie.",
                      },
                      {
                        id: "MUI0000101",
                        parent_id: "JUI0000101",
                        text: "Service connectors",
                        X_fullName: "Service connectors",
                        X_fullName_SK: "Servisn\u00e9 konektory",
                        X_URL: "service-connectors",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Service connectors",
                        X_Description:
                          "Service plugs for special measuring and switching tasks.",
                        X_Description_SK:
                          "Servisn\u00e9 z\u00e1str\u010dky pre \u0161peci\u00e1lne meracie a sp\u00ednacie \u00falohy.",
                      },
                      {
                        id: "NUI0000101",
                        parent_id: "JUI0000101",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                        X_URL: "disconnect-system-accessories",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Accessories",
                        X_Description:
                          "Accessories for the FAME test disconnect system. For safe, service-friendly testing.",
                        X_Description_SK:
                          "Pr\u00edslu\u0161enstvo pre syst\u00e9m odpojenia testu FAME. Pre bezpe\u010dn\u00e9 a servisne nen\u00e1ro\u010dn\u00e9 testovanie.",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: "OUI0000101",
            parent_id: "XQI0000101",
            text: "Distributor blocks",
            X_fullName: "Distributor blocks",
            X_fullName_SK: "Bloky distrib\u00fatorov",
            X_URL: "distributor-blocks",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Distributor blocks",
            X_Description:
              "You can now save even more time and space during installation with the ready-to-connect distribution blocks in Push-in and screw connection technology.",
            X_Description_SK:
              "Teraz m\u00f4\u017eete u\u0161etri\u0165 e\u0161te viac \u010dasu a miesta pri in\u0161tal\u00e1cii s rozvodn\u00fdmi blokmi pripraven\u00fdmi na pripojenie v technol\u00f3gii Push-in a skrutkov\u00fdch spojov.",
            items: [
              {
                id: "PUI0000101",
                parent_id: "OUI0000101",
                text: "Push-in distribution blocks for DIN rail mounting",
                X_fullName: "Push-in distribution blocks for DIN rail mounting",
                X_fullName_SK:
                  "Z\u00e1suvn\u00e9 rozvodn\u00e9 bloky na mont\u00e1\u017e na DIN li\u0161tu",
                X_URL: "push-in-distribution-blocks-din",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\Push-in distribution blocks for DIN rail mounting",
                X_Description:
                  "Distribution blocks for mounting on NS 15 and NS 35 DIN rails and space-saving transverse mounting on NS 35.",
                X_Description_SK:
                  "Rozvodn\u00e9 bloky pre mont\u00e1\u017e na DIN li\u0161tu NS 15 a NS 35 a priestorovo nen\u00e1ro\u010dn\u00fa prie\u010dnu mont\u00e1\u017e na NS 35.",
              },
              {
                id: "QUI0000101",
                parent_id: "OUI0000101",
                text: "Push-in distribution blocks for adhesive mounting",
                X_fullName: "Push-in distribution blocks for adhesive mounting",
                X_fullName_SK:
                  "Z\u00e1suvn\u00e9 rozde\u013eovacie bloky na lepiacu mont\u00e1\u017e",
                X_URL: "push-in-adhesive-mounting",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\Push-in distribution blocks for adhesive mounting",
                X_Description:
                  "Distribution blocks with adhesive pads enable tool-free mounting.",
                X_Description_SK:
                  "Rozvodn\u00e9 bloky s lepiacimi podlo\u017ekami umo\u017e\u0148uj\u00fa mont\u00e1\u017e bez pou\u017eitia n\u00e1radia.",
              },
              {
                id: "RUI0000101",
                parent_id: "OUI0000101",
                text: "Push-in distribution blocks",
                X_fullName: "Push-in distribution blocks",
                X_fullName_SK: "Rozvodn\u00e9 bloky Push-in",
                X_URL: "push-in-dir-distribution-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\Push-in distribution blocks",
                X_Description:
                  "The Push-in distributor base blocks can be self-assembled using mounting accessories for direct or DIN rail mounting.",
                X_Description_SK:
                  "Z\u00e1kladn\u00e9 bloky rozv\u00e1dza\u010da Push-in je mo\u017en\u00e9 zmontova\u0165 svojpomocne pomocou mont\u00e1\u017eneho pr\u00edslu\u0161enstva pre priamu mont\u00e1\u017e alebo mont\u00e1\u017e na li\u0161tu DIN.",
              },
              {
                id: "SUI0000101",
                parent_id: "OUI0000101",
                text: "DIN rail and direct mounting adapters for Push-in ",
                X_fullName:
                  "DIN rail and direct mounting adapters for Push-in ",
                X_fullName_SK:
                  "Adapt\u00e9ry na DIN li\u0161tu a priamu mont\u00e1\u017e pre Push-in ",
                X_URL: "din-rail-and-mounting-push-in",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\DIN rail and direct mounting adapters for Push-in ",
                X_Description:
                  "The Push-in distributor base blocks can be self-assembled using mounting accessories for direct or DIN rail mounting.",
                X_Description_SK:
                  "Z\u00e1kladn\u00e9 bloky rozv\u00e1dza\u010da Push-in je mo\u017en\u00e9 zmontova\u0165 svojpomocne pomocou mont\u00e1\u017eneho pr\u00edslu\u0161enstva pre priamu mont\u00e1\u017e alebo mont\u00e1\u017e na li\u0161tu DIN.",
              },
              {
                id: "TUI0000101",
                parent_id: "OUI0000101",
                text: "Push-in distribution blocks",
                X_fullName: "Push-in distribution blocks",
                X_fullName_SK: "Rozvodn\u00e9 bloky Push-in",
                X_URL: "push-in-distribution-blocks",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\Push-in distribution blocks",
                X_Description:
                  "Space-saving potential distributor terminals with terminal points for different conductor cross-section ranges.",
                X_Description_SK:
                  "Priestorovo \u00fasporn\u00e9 svorky rozde\u013eova\u010da potenci\u00e1lu s pripojovac\u00edmi bodmi pre r\u00f4zne rozsahy prierezov vodi\u010dov.",
              },
            ],
          },
        ],
      },
      {
        id: "UUI0000101",
        parent_id: "HFI0000101",
        text: "Relay modules",
        X_fullName: "Relay modules",
        X_fullName_SK: "Rel\u00e9ov\u00e9 moduly",
        X_URL: "relay-modules",
        FULLPATH: "PhoenixContact\\Relay modules",
        X_Description:
          "Whether switching, isolating or monitoring. Whatever the function you require \u2013 we offer custom relay modules.",
        X_Description_SK:
          "\u010ci u\u017e sp\u00ednanie, odde\u013eovanie alebo monitorovanie. Bez oh\u013eadu na funkciu, ktor\u00fa po\u017eadujete \u2013 pon\u00fakame vlastn\u00e9 rel\u00e9ov\u00e9 moduly.",
        items: [
          {
            id: "VUI0000101",
            parent_id: "UUI0000101",
            text: "Electromechanical and solid-state relay modules",
            X_fullName: "Electromechanical and solid-state relay modules",
            X_fullName_SK:
              "Elektromechanick\u00e9 a polovodi\u010dov\u00e9 rel\u00e9ov\u00e9 moduly",
            X_URL: "electromechanical-relay-modules",
            FULLPATH:
              "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules",
            X_Description:
              "Use the relays to switch, isolate, amplify, and multiply signals.",
            X_Description_SK:
              "Pomocou rel\u00e9 m\u00f4\u017eete prep\u00edna\u0165, izolova\u0165, zosil\u0148ova\u0165 a n\u00e1sobi\u0165 sign\u00e1ly.",
            items: [
              {
                id: "WUI0000101",
                parent_id: "VUI0000101",
                text: "Highly compact relay modules",
                X_fullName: "Highly compact relay modules",
                X_fullName_SK: "Vysoko kompaktn\u00e9 rel\u00e9ov\u00e9 moduly",
                X_URL: "highly-compact-relay-modules",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules",
                X_Description:
                  "High-performance interface between control and field level with all the advantages of proven modular terminal block technology.",
                X_Description_SK:
                  "Vysokov\u00fdkonn\u00e9 rozhranie medzi riaden\u00edm a \u00farov\u0148ou po\u013ea so v\u0161etk\u00fdmi v\u00fdhodami osved\u010denej technol\u00f3gie modul\u00e1rnych svorkovn\u00edc.",
                items: [
                  {
                    id: "XUI0000101",
                    parent_id: "WUI0000101",
                    text: "Complete modules",
                    X_fullName: "Complete modules",
                    X_fullName_SK: "Kompletn\u00e9 moduly",
                    X_URL: "highly-complete-modules",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules\\Complete modules",
                    X_Description:
                      "Complete modules can be used universally and consist of a basic terminal block and plug-in electromechanical relay.",
                    X_Description_SK:
                      "Kompletn\u00e9 moduly s\u00fa univerz\u00e1lne pou\u017eite\u013en\u00e9 a pozost\u00e1vaj\u00fa zo z\u00e1kladnej svorkovnice a z\u00e1suvn\u00e9ho elektromechanick\u00e9ho rel\u00e9.",
                  },
                  {
                    id: "YUI0000101",
                    parent_id: "WUI0000101",
                    text: "Single relays",
                    X_fullName: "Single relays",
                    X_fullName_SK: "Jednotliv\u00e9 rel\u00e9",
                    X_URL: "highly-single-relays",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules\\Single relays",
                    X_Description:
                      "Single relays in the modular system can be ordered individually and custom assembled.",
                    X_Description_SK:
                      "Jednotliv\u00e9 rel\u00e9 v modul\u00e1rnom syst\u00e9me je mo\u017en\u00e9 objedna\u0165 jednotlivo a zostavi\u0165 na mieru.",
                  },
                  {
                    id: "ZUI0000101",
                    parent_id: "WUI0000101",
                    text: "Base",
                    X_fullName: "Base",
                    X_fullName_SK: "Z\u00e1klad\u0148a",
                    X_URL: "base",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules\\Base",
                    X_Description:
                      "Relay bases with screw connection or also with spring-cage connection for miniature power relays.",
                    X_Description_SK:
                      "Rel\u00e9ov\u00e9 p\u00e4tky so skrutkov\u00fdm pripojen\u00edm alebo tie\u017e s pru\u017einov\u00fdm pripojen\u00edm pre miniat\u00farne v\u00fdkonov\u00e9 rel\u00e9.",
                  },
                  {
                    id: "0VI0000101",
                    parent_id: "WUI0000101",
                    text: "Accessories and marking",
                    X_fullName: "Accessories and marking",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo a zna\u010denie",
                    X_URL: "highly-accessories-and-marking",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules\\Accessories and marking",
                    X_Description:
                      "The accessories range from relay retaining brackets and marking labels to loop bridges in various colors.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo siaha od dr\u017eiakov rel\u00e9 a ozna\u010dovac\u00edch \u0161t\u00edtkov a\u017e po slu\u010dkov\u00e9 most\u00edky v r\u00f4znych farb\u00e1ch.",
                  },
                ],
              },
              {
                id: "1VI0000101",
                parent_id: "VUI0000101",
                text: "Universal industrial relay system",
                X_fullName: "Universal industrial relay system",
                X_fullName_SK:
                  "Univerz\u00e1lny priemyseln\u00fd rel\u00e9ov\u00fd syst\u00e9m",
                X_URL: "universal-industrial-relay-sys",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system",
                X_Description:
                  "The industrial relay system consists of either complete modules or a modular system.",
                X_Description_SK:
                  "Priemyseln\u00fd rel\u00e9ov\u00fd syst\u00e9m pozost\u00e1va bu\u010f z kompletn\u00fdch modulov alebo modul\u00e1rneho syst\u00e9mu.",
                items: [
                  {
                    id: "2VI0000101",
                    parent_id: "1VI0000101",
                    text: "Complete modules",
                    X_fullName: "Complete modules",
                    X_fullName_SK: "Kompletn\u00e9 moduly",
                    X_URL: "universal-complete-modules",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system\\Complete modules",
                    X_Description:
                      "Complete modules can be used universally and consist of a basic terminal block and plug-in electromechanical relay.",
                    X_Description_SK:
                      "Kompletn\u00e9 moduly s\u00fa univerz\u00e1lne pou\u017eite\u013en\u00e9 a pozost\u00e1vaj\u00fa zo z\u00e1kladnej svorkovnice a z\u00e1suvn\u00e9ho elektromechanick\u00e9ho rel\u00e9.",
                  },
                  {
                    id: "3VI0000101",
                    parent_id: "1VI0000101",
                    text: "Single relays",
                    X_fullName: "Single relays",
                    X_fullName_SK: "Jednotliv\u00e9 rel\u00e9",
                    X_URL: "universal-single-relays",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system\\Single relays",
                    X_Description:
                      "Single relays in the modular system can be ordered individually and custom assembled.",
                    X_Description_SK:
                      "Jednotliv\u00e9 rel\u00e9 v modul\u00e1rnom syst\u00e9me je mo\u017en\u00e9 objedna\u0165 jednotlivo a zostavi\u0165 na mieru.",
                  },
                  {
                    id: "4VI0000101",
                    parent_id: "1VI0000101",
                    text: "Base",
                    X_fullName: "Base",
                    X_fullName_SK: "Z\u00e1klad\u0148a",
                    X_URL: "universal-base",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system\\Base",
                    X_Description:
                      "Relay bases with screw connection or also with spring-cage connection for miniature power relays.",
                    X_Description_SK:
                      "Rel\u00e9ov\u00e9 p\u00e4tky so skrutkov\u00fdm pripojen\u00edm alebo tie\u017e s pru\u017einov\u00fdm pripojen\u00edm pre miniat\u00farne v\u00fdkonov\u00e9 rel\u00e9.",
                  },
                  {
                    id: "5VI0000101",
                    parent_id: "1VI0000101",
                    text: "Accessories and marking",
                    X_fullName: "Accessories and marking",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo a zna\u010denie",
                    X_URL: "accessories-and-marking",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system\\Accessories and marking",
                    X_Description:
                      "Wide range of accessories and marking material. Including, for example, bridges, zack marker strips, and special function modules.",
                    X_Description_SK:
                      "\u0160irok\u00fd sortiment pr\u00edslu\u0161enstva a ozna\u010dovacieho materi\u00e1lu. Vr\u00e1tane napr\u00edklad most\u00edkov, zna\u010dkovac\u00edch p\u00e1sikov zack a \u0161peci\u00e1lnych funk\u010dn\u00fdch modulov.",
                  },
                ],
              },
              {
                id: "6VI0000101",
                parent_id: "VUI0000101",
                text: "Multi-channel relay modules",
                X_fullName: "Multi-channel relay modules",
                X_fullName_SK:
                  "Viackan\u00e1lov\u00e9 rel\u00e9ov\u00e9 moduly",
                X_URL: "multi-channel-relay-modules",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Multi-channel relay modules",
                X_Description:
                  "Multi-channel relay modules are designed as 4, 8, and 16-channel interfaces.",
                X_Description_SK:
                  "Viackan\u00e1lov\u00e9 rel\u00e9ov\u00e9 moduly s\u00fa navrhnut\u00e9 ako 4, 8 a 16-kan\u00e1lov\u00e9 rozhrania.",
              },
              {
                id: "7VI0000101",
                parent_id: "VUI0000101",
                text: "Relay modules with soldered-in relays",
                X_fullName: "Relay modules with soldered-in relays",
                X_fullName_SK:
                  "Rel\u00e9ov\u00e9 moduly so zap\u00e1jan\u00fdmi rel\u00e9",
                X_URL: "soldered-in-relays-modules",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules with soldered-in relays",
                X_Description:
                  "The modular Interface system contains a wide range of interface modules that can be combined flexibly.",
                X_Description_SK:
                  "Modul\u00e1rny syst\u00e9m rozhrania obsahuje \u0161irok\u00fa \u0161k\u00e1lu modulov rozhrania, ktor\u00e9 je mo\u017en\u00e9 flexibilne kombinova\u0165.",
              },
              {
                id: "8VI0000101",
                parent_id: "VUI0000101",
                text: "Relay modules in modular terminal block design",
                X_fullName: "Relay modules in modular terminal block design",
                X_fullName_SK:
                  "Rel\u00e9ov\u00e9 moduly v modul\u00e1rnom preveden\u00ed svorkovnice",
                X_URL: "relay-in-modular-terminal-block",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules in modular terminal block design",
                X_Description:
                  "High-performance interfaces for binary signal processing in 6.2 mm terminal block format.",
                X_Description_SK:
                  "Vysokov\u00fdkonn\u00e9 rozhrania na spracovanie bin\u00e1rnych sign\u00e1lov vo form\u00e1te 6,2 mm svorkovnice.",
              },
              {
                id: "9VI0000101",
                parent_id: "VUI0000101",
                text: "Relay modules for potentially explosive areas",
                X_fullName: "Relay modules for potentially explosive areas",
                X_fullName_SK:
                  "Rel\u00e9ov\u00e9 moduly pre oblasti s nebezpe\u010denstvom v\u00fdbuchu",
                X_URL: "explosive-areas-relay-modules",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules for potentially explosive areas",
                X_Description:
                  "The relay modules are suitable for use in zone 2 potentially explosive areas.",
                X_Description_SK:
                  "Rel\u00e9ov\u00e9 moduly s\u00fa vhodn\u00e9 na pou\u017eitie v z\u00f3ne 2 s nebezpe\u010denstvom v\u00fdbuchu.",
                items: [
                  {
                    id: "AVI0000101",
                    parent_id: "9VI0000101",
                    text: "Complete modules",
                    X_fullName: "Complete modules",
                    X_fullName_SK: "Kompletn\u00e9 moduly",
                    X_URL: "complete-modules",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules for potentially explosive areas\\Complete modules",
                    X_Description:
                      "The modules can be used universally and consist of a basic terminal block and plug-in relay.",
                    X_Description_SK:
                      "Moduly s\u00fa univerz\u00e1lne pou\u017eite\u013en\u00e9 a pozost\u00e1vaj\u00fa zo z\u00e1kladnej svorkovnice a z\u00e1suvn\u00e9ho rel\u00e9.",
                  },
                  {
                    id: "BVI0000101",
                    parent_id: "9VI0000101",
                    text: "Single relays",
                    X_fullName: "Single relays",
                    X_fullName_SK: "Jednotliv\u00e9 rel\u00e9",
                    X_URL: "single-relays",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules for potentially explosive areas\\Single relays",
                    X_Description:
                      "Single relays in the modular system can be ordered individually and custom assembled.",
                    X_Description_SK:
                      "Jednotliv\u00e9 rel\u00e9 v modul\u00e1rnom syst\u00e9me je mo\u017en\u00e9 objedna\u0165 jednotlivo a zostavi\u0165 na mieru.",
                  },
                  {
                    id: "CVI0000101",
                    parent_id: "9VI0000101",
                    text: "Accessories and marking",
                    X_fullName: "Accessories and marking",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo a zna\u010denie",
                    X_URL: "relay-accessories-and-marking",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules for potentially explosive areas\\Accessories and marking",
                    X_Description:
                      "The accessories range from relay retaining brackets and marking labels to loop bridges in various colors.",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo siaha od dr\u017eiakov rel\u00e9 a ozna\u010dovac\u00edch \u0161t\u00edtkov a\u017e po slu\u010dkov\u00e9 most\u00edky v r\u00f4znych farb\u00e1ch.",
                  },
                ],
              },
              {
                id: "DVI0000101",
                parent_id: "VUI0000101",
                text: "Force-guided coupling relays",
                X_fullName: "Force-guided coupling relays",
                X_fullName_SK: "Spojovacie rel\u00e9 riaden\u00e9 silou",
                X_URL: "force-guided-relays",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Force-guided coupling relays",
                X_Description:
                  "Coupling relays with force-guided contacts for universal use.",
                X_Description_SK:
                  "Spojovacie rel\u00e9 so silovo veden\u00fdmi kontaktmi pre univerz\u00e1lne pou\u017eitie.",
                items: [
                  {
                    id: "EVI0000101",
                    parent_id: "DVI0000101",
                    text: "Positively driven coupling relays",
                    X_fullName: "Positively driven coupling relays",
                    X_fullName_SK:
                      "Pozit\u00edvne riaden\u00e9 spojovacie rel\u00e9",
                    X_URL: "positively-driven-relay",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Force-guided coupling relays\\Positively driven coupling relays",
                    X_Description:
                      "Forcibly guided coupling relays for universal use.",
                    X_Description_SK:
                      "N\u00fatene veden\u00e9 v\u00e4zobn\u00e9 rel\u00e9 pre univerz\u00e1lne pou\u017eitie.",
                  },
                ],
              },
            ],
          },
          {
            id: "GVI0000101",
            parent_id: "UUI0000101",
            text: "Relay modules with lockable manual actuation",
            X_fullName: "Relay modules with lockable manual actuation",
            X_fullName_SK:
              "Rel\u00e9ov\u00e9 moduly s uzamykate\u013en\u00fdm manu\u00e1lnym ovl\u00e1dan\u00edm",
            X_URL: "relay-modules-lockable",
            FULLPATH:
              "PhoenixContact\\Relay modules\\Relay modules with lockable manual actuation",
            X_Description:
              "The PLC-INTERFACE relay system is the high-performance interface between the controller and system peripherals.",
            X_Description_SK: "",
          },
        ],
      },
      {
        id: "HVI0000101",
        parent_id: "HFI0000101",
        text: "Protective devices",
        X_fullName: "Protective devices",
        X_fullName_SK: "Ochrann\u00e9 zariadenia",
        X_URL: "protective-devices",
        FULLPATH: "PhoenixContact\\Protective devices",
        X_Description:
          "Do not take any chances: protective devices provide optimum protection for your cables, systems, and devices.",
        X_Description_SK:
          "Neriskujte: ochrann\u00e9 zariadenia poskytuj\u00fa optim\u00e1lnu ochranu pre va\u0161e k\u00e1ble, syst\u00e9my a zariadenia.",
        items: [
          {
            id: "IVI0000101",
            parent_id: "HVI0000101",
            text: "Device circuit breakers",
            X_fullName: "Device circuit breakers",
            X_fullName_SK: "Vyp\u00edna\u010de zariaden\u00ed",
            X_URL: "device-circuit-breakers-main",
            FULLPATH:
              "PhoenixContact\\Protective devices\\Device circuit breakers",
            X_Description:
              "Device circuit breakers ensure a high level of system availability thanks to the selective protection of operating equipment.",
            X_Description_SK:
              "Pr\u00edstrojov\u00e9 isti\u010de zabezpe\u010duj\u00fa vysok\u00fa dostupnos\u0165 syst\u00e9mu v\u010faka selekt\u00edvnej ochrane prev\u00e1dzkov\u00fdch zariaden\u00ed.",
            items: [
              {
                id: "JVI0000101",
                parent_id: "IVI0000101",
                text: "Electronic device circuit breakers",
                X_fullName: "Electronic device circuit breakers",
                X_fullName_SK: "isti\u010de elektronick\u00fdch zariaden\u00ed",
                X_URL: "device-circuit-breakers",
                FULLPATH:
                  "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers",
                X_Description:
                  "Discover our product line of single-channel and multi-channel electronic circuit breakers.",
                X_Description_SK:
                  "Objavte n\u00e1\u0161 produktov\u00fd rad jednokan\u00e1lov\u00fdch a viackan\u00e1lov\u00fdch elektronick\u00fdch isti\u010dov.",
                items: [
                  {
                    id: "KVI0000101",
                    parent_id: "JVI0000101",
                    text: "Single-channel electronic device circuit breaker",
                    X_fullName:
                      "Single-channel electronic device circuit breaker",
                    X_fullName_SK:
                      "Jednokan\u00e1lov\u00fd isti\u010d elektronick\u00fdch zariaden\u00ed",
                    X_URL: "single-channel-circuit-break",
                    FULLPATH:
                      "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Single-channel electronic device circuit breaker",
                    X_Description:
                      "Organize your system protection precisely in accordance with your needs with single-channel circuit breakers",
                    X_Description_SK:
                      "Zorganizujte si ochranu syst\u00e9mu presne pod\u013ea svojich potrieb pomocou jednokan\u00e1lov\u00fdch isti\u010dov",
                    items: [
                      {
                        id: "LVI0000101",
                        parent_id: "KVI0000101",
                        text: "Single-channel electronic device circuit breaker",
                        X_fullName:
                          "Single-channel electronic device circuit breaker",
                        X_fullName_SK:
                          "Jednokan\u00e1lov\u00fd isti\u010d elektronick\u00fdch zariaden\u00ed",
                        X_URL: "single-channel-circuit-breaker",
                        FULLPATH:
                          "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Single-channel electronic device circuit breaker\\Single-channel electronic device circuit breaker",
                        X_Description:
                          "Organize your system protection precisely in accordance with your needs with single-channel circuit breakers.",
                        X_Description_SK:
                          "Zorganizujte si ochranu syst\u00e9mu presne pod\u013ea svojich potrieb pomocou jednokan\u00e1lov\u00fdch isti\u010dov.",
                      },
                      {
                        id: "MVI0000101",
                        parent_id: "KVI0000101",
                        text: "Accessories for device circuit breakers",
                        X_fullName: "Accessories for device circuit breakers",
                        X_fullName_SK:
                          "Pr\u00edslu\u0161enstvo pre isti\u010de zariaden\u00ed",
                        X_URL: "circuit-breakers-accessories ",
                        FULLPATH:
                          "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Single-channel electronic device circuit breaker\\Accessories for device circuit breakers",
                        X_Description:
                          "Accessories and additional products for the entire range of device circuit breakers.",
                        X_Description_SK:
                          "Pr\u00edslu\u0161enstvo a doplnkov\u00e9 produkty k cel\u00e9mu sortimentu pr\u00edstrojov\u00fdch isti\u010dov.",
                      },
                    ],
                  },
                  {
                    id: "NVI0000101",
                    parent_id: "JVI0000101",
                    text: "Multi-channel electronic circuit breakers",
                    X_fullName: "Multi-channel electronic circuit breakers",
                    X_fullName_SK:
                      "Viackan\u00e1lov\u00e9 elektronick\u00e9 isti\u010de",
                    X_URL: "multi-channel-circuit-break",
                    FULLPATH:
                      "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Multi-channel electronic circuit breakers",
                    X_Description:
                      "The multi-channel circuit breakers can be adjusted individually for each channel and provide a functional, space-saving solution for every application.",
                    X_Description_SK:
                      "Viackan\u00e1lov\u00e9 isti\u010de je mo\u017en\u00e9 nastavi\u0165 individu\u00e1lne pre ka\u017ed\u00fd kan\u00e1l a poskytuj\u00fa funk\u010dn\u00e9 a priestorovo \u00fasporn\u00e9 rie\u0161enie pre ka\u017ed\u00fa aplik\u00e1ciu.",
                    items: [
                      {
                        id: "OVI0000101",
                        parent_id: "NVI0000101",
                        text: "Multi-channel electronic device circuit breakers",
                        X_fullName:
                          "Multi-channel electronic device circuit breakers",
                        X_fullName_SK:
                          "Viackan\u00e1lov\u00e9 isti\u010de elektronick\u00fdch zariaden\u00ed",
                        X_URL: "multi-channel-circuit-breakers",
                        FULLPATH:
                          "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Multi-channel electronic circuit breakers\\Multi-channel electronic device circuit breakers",
                        X_Description:
                          "Multi-channel circuit breakers can be adjusted individually for each channel and provide a functional, space-saving solution for every application.",
                        X_Description_SK:
                          "Viackan\u00e1lov\u00e9 isti\u010de mo\u017eno nastavi\u0165 individu\u00e1lne pre ka\u017ed\u00fd kan\u00e1l a poskytuj\u00fa funk\u010dn\u00e9, priestor \u0161etriace rie\u0161enie pre ka\u017ed\u00fa aplik\u00e1ciu.",
                      },
                    ],
                  },
                ],
              },
              {
                id: "PVI0000101",
                parent_id: "IVI0000101",
                text: "Thermal Circuit Breaker",
                X_fullName: "Thermal Circuit Breaker",
                X_fullName_SK: "Tepeln\u00fd isti\u010d",
                X_URL: "thermal-circuit-breaker",
                FULLPATH:
                  "PhoenixContact\\Protective devices\\Device circuit breakers\\Thermal Circuit Breaker",
                X_Description:
                  "Thermal device circuit breakers shut down connected loads safely in the event of overload.",
                X_Description_SK:
                  "Isti\u010de tepeln\u00e9ho zariadenia bezpe\u010dne vypn\u00fa pripojen\u00e9 z\u00e1\u0165a\u017ee v pr\u00edpade pre\u0165a\u017eenia.",
                items: [
                  {
                    id: "QVI0000101",
                    parent_id: "PVI0000101",
                    text: "Thermal device circuit breakers",
                    X_fullName: "Thermal device circuit breakers",
                    X_fullName_SK: "Isti\u010de tepeln\u00fdch zariaden\u00ed",
                    X_URL: "thermal-device-circuit-breakers",
                    FULLPATH:
                      "PhoenixContact\\Protective devices\\Device circuit breakers\\Thermal Circuit Breaker\\Thermal device circuit breakers",
                    X_Description:
                      "Thermal device circuit breakers protect individual pieces of equipment against overload",
                    X_Description_SK:
                      "Isti\u010de tepeln\u00fdch zariaden\u00ed chr\u00e1nia jednotliv\u00e9 \u010dasti zariadenia pred pre\u0165a\u017een\u00edm",
                  },
                ],
              },
              {
                id: "RVI0000101",
                parent_id: "IVI0000101",
                text: "Thermal Magnetic Circuit Breaker",
                X_fullName: "Thermal Magnetic Circuit Breaker",
                X_fullName_SK: "Tepeln\u00fd magnetick\u00fd isti\u010d",
                X_URL: "thermal-magnetic-circuit-breaker",
                FULLPATH:
                  "PhoenixContact\\Protective devices\\Device circuit breakers\\Thermal Magnetic Circuit Breaker",
                X_Description:
                  "Thermomagnetic device circuit breakers shut down connected loads safely in the event of overload or short circuit.",
                X_Description_SK:
                  "Isti\u010de termomagnetick\u00fdch zariaden\u00ed bezpe\u010dne vyp\u00ednaj\u00fa pripojen\u00e9 z\u00e1\u0165a\u017ee v pr\u00edpade pre\u0165a\u017eenia alebo skratu.",
                items: [
                  {
                    id: "SVI0000101",
                    parent_id: "RVI0000101",
                    text: "Thermomagnetic circuit breakers",
                    X_fullName: "Thermomagnetic circuit breakers",
                    X_fullName_SK: "Termomagnetick\u00e9 isti\u010de",
                    X_URL: "thermomagnetic-circuit-breakers",
                    FULLPATH:
                      "PhoenixContact\\Protective devices\\Device circuit breakers\\Thermal Magnetic Circuit Breaker\\Thermomagnetic circuit breakers",
                    X_Description:
                      "Thermomagnetic device circuit breakers protect individual pieces of equipment against overload and short circuit.",
                    X_Description_SK:
                      "Isti\u010de termomagnetick\u00fdch zariaden\u00ed chr\u00e1nia jednotliv\u00e9 \u010dasti zariadenia pred pre\u0165a\u017een\u00edm a skratom.",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "TVI0000101",
        parent_id: "HFI0000101",
        text: "Sensor/actuator cabling",
        X_fullName: "Sensor/actuator cabling",
        X_fullName_SK:
          "Kabel\u00e1\u017e senzorov/ak\u010dn\u00fdch \u010dlenov",
        X_URL: "sensor-actuator-cabling",
        FULLPATH: "PhoenixContact\\Sensor/actuator cabling",
        X_Description:
          "Connect your sensors and actuators quickly and safely in the field.",
        X_Description_SK:
          "Pripojte svoje senzory a ak\u010dn\u00e9 \u010dleny r\u00fdchlo a bezpe\u010dne v ter\u00e9ne.",
        items: [
          {
            id: "UVI0000101",
            parent_id: "TVI0000101",
            text: "Assembled cables",
            X_fullName: "Assembled cables",
            X_fullName_SK: "Zmontovan\u00e9 k\u00e1ble",
            X_URL: "assembled-cables",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Assembled cables",
            X_Description:
              "Connect sensors and actuators quickly and easily in the field using assembled cables.",
            X_Description_SK:
              "Pripojte sn\u00edma\u010de a ak\u010dn\u00e9 \u010dleny r\u00fdchlo a jednoducho v ter\u00e9ne pomocou zmontovan\u00fdch k\u00e1blov.",
            items: [
              {
                id: "VVI0000101",
                parent_id: "UVI0000101",
                text: "Cable with circular connectors",
                X_fullName: "Cable with circular connectors",
                X_fullName_SK: "K\u00e1bel s okr\u00fahlimi konektormi",
                X_URL: "cable-with-circular-connectors",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Assembled cables\\Cable with circular connectors",
                X_Description:
                  'Cables with M5 to M12 plug-in connectors available in 1/2" and 7/8", as well as in various versions.',
                X_Description_SK:
                  'K\u00e1ble so z\u00e1suvn\u00fdmi konektormi M5 a\u017e M12 dostupn\u00e9 v 1/2" a 7/8", ako aj v r\u00f4znych verzi\u00e1ch.',
              },
              {
                id: "WVI0000101",
                parent_id: "UVI0000101",
                text: "Cables with valve connectors",
                X_fullName: "Cables with valve connectors",
                X_fullName_SK: "K\u00e1ble s konektormi ventilov",
                X_URL: "cables-with-valve-connectors",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Assembled cables\\Cables with valve connectors",
                X_Description:
                  "Cables with valve connectors are available in numerous designs and protective circuits.",
                X_Description_SK:
                  "K\u00e1ble s ventilov\u00fdmi konektormi s\u00fa dostupn\u00e9 v mnoh\u00fdch prevedeniach a ochrann\u00fdch obvodoch.",
              },
            ],
          },
          {
            id: "XVI0000101",
            parent_id: "TVI0000101",
            text: "Connectors",
            X_fullName: "Connectors",
            X_fullName_SK: "Konektory",
            X_URL: "sensor-connectors-main",
            FULLPATH: "PhoenixContact\\Sensor/actuator cabling\\Connectors",
            X_Description:
              "Connectors that can be assembled enable you to reliably design your cabling in the field.",
            X_Description_SK:
              "Konektory, ktor\u00e9 je mo\u017en\u00e9 zostavi\u0165, v\u00e1m umo\u017enia spo\u013eahlivo navrhn\u00fa\u0165 va\u0161u kabel\u00e1\u017e v ter\u00e9ne.",
            items: [
              {
                id: "YVI0000101",
                parent_id: "XVI0000101",
                text: "Connectors",
                X_fullName: "Connectors",
                X_fullName_SK: "Konektory",
                X_URL: "sensor-connectors",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Connectors\\Connectors",
                X_Description:
                  "Connectors with variable connection methods are available for a wide range of applications.",
                X_Description_SK:
                  "Konektory s variabiln\u00fdmi sp\u00f4sobmi pripojenia s\u00fa dostupn\u00e9 pre \u0161irok\u00fa \u0161k\u00e1lu aplik\u00e1ci\u00ed.",
              },
              {
                id: "ZVI0000101",
                parent_id: "XVI0000101",
                text: "Valve connectors",
                X_fullName: "Valve connectors",
                X_fullName_SK: "Konektory ventilov",
                X_URL: "valve-connectors",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Connectors\\Valve connectors",
                X_Description:
                  "Valve connectors are available in numerous designs and protective circuits.",
                X_Description_SK:
                  "Ventilov\u00e9 konektory s\u00fa dostupn\u00e9 v mnoh\u00fdch prevedeniach a ochrann\u00fdch obvodoch.",
              },
            ],
          },
          {
            id: "1WI0000101",
            parent_id: "TVI0000101",
            text: "Device connectors",
            X_fullName: "Device connectors",
            X_fullName_SK: "Konektory zariadenia",
            X_URL: "device-connectors",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Device connectors",
            X_Description:
              "Device connectors for all common applications, even with a high number of positions.",
            X_Description_SK: "",
          },
          {
            id: "2WI0000101",
            parent_id: "TVI0000101",
            text: "Distributor boxes",
            X_fullName: "Distributor boxes",
            X_fullName_SK: "Distribu\u010dn\u00e9 boxy",
            X_URL: "distributor-boxes",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Distributor boxes",
            X_Description:
              "Distributor boxes bundle and distribute signals quickly and clearly in the field.",
            X_Description_SK:
              "Rozv\u00e1dza\u010de r\u00fdchlo a zrete\u013ene sp\u00e1jaj\u00fa a distribuuj\u00fa sign\u00e1ly v ter\u00e9ne.",
            items: [
              {
                id: "3WI0000101",
                parent_id: "2WI0000101",
                text: "Distributor boxes with M5 to M12 slots",
                X_fullName: "Distributor boxes with M5 to M12 slots",
                X_fullName_SK: "Rozv\u00e1dza\u010de so slotmi M5 a\u017e M12",
                X_URL: "m5-to-m12-distributor-boxes",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Distributor boxes\\Distributor boxes with M5 to M12 slots",
                X_Description:
                  "Distributor boxes with M5 to M12 slots for quick and easy signal bundling.",
                X_Description_SK:
                  "Rozv\u00e1dza\u010de so slotmi M5 a\u017e M12 pre r\u00fdchle a jednoduch\u00e9 viazanie sign\u00e1lu.",
              },
              {
                id: "4WI0000101",
                parent_id: "2WI0000101",
                text: "Connector hoods",
                X_fullName: "Connector hoods",
                X_fullName_SK: "Krytky konektorov",
                X_URL: "connector-hoods",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Distributor boxes\\Connector hoods",
                X_Description:
                  "Corresponding connector hoods with and without master cable are available for headers.",
                X_Description_SK:
                  "Pre zbera\u010de s\u00fa k dispoz\u00edcii zodpovedaj\u00face kryty konektorov s hlavn\u00fdm k\u00e1blom a bez neho.",
              },
            ],
          },
          {
            id: "5WI0000101",
            parent_id: "TVI0000101",
            text: "Distributors and adapters",
            X_fullName: "Distributors and adapters",
            X_fullName_SK: "Distrib\u00fatori a adapt\u00e9ry",
            X_URL: "distributors-and-adapters",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Distributors and adapters",
            X_Description:
              "Distributors and adapters enable a flexible cabling design.",
            X_Description_SK:
              "Rozde\u013eova\u010de a adapt\u00e9ry umo\u017e\u0148uj\u00fa flexibiln\u00fd dizajn kabel\u00e1\u017ee.",
            items: [
              {
                id: "6WI0000101",
                parent_id: "5WI0000101",
                text: "Distributors",
                X_fullName: "Distributors",
                X_fullName_SK: "Distrib\u00fatori",
                X_URL: "distributors",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Distributors and adapters\\Distributors",
                X_Description:
                  "Distributors are available in various different designs with or without cable.",
                X_Description_SK:
                  "Rozv\u00e1dza\u010de s\u00fa dostupn\u00e9 v r\u00f4znych prevedeniach s k\u00e1blom alebo bez k\u00e1bla.",
              },
              {
                id: "7WI0000101",
                parent_id: "5WI0000101",
                text: "Adapter",
                X_fullName: "Adapter",
                X_fullName_SK: "Adapt\u00e9r",
                X_URL: "adapter",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Distributors and adapters\\Adapter",
                X_Description:
                  "The product range includes valve plugs as well as metric adapters.",
                X_Description_SK:
                  "Sortiment zah\u0155\u0148a ventilov\u00e9 z\u00e1tky ako aj metrick\u00e9 adapt\u00e9ry.",
              },
            ],
          },
          {
            id: "9WI0000101",
            parent_id: "TVI0000101",
            text: "By the meter",
            X_fullName: "By the meter",
            X_fullName_SK: "Pod\u013ea po\u010dtu metrov",
            X_URL: "by-the-meter",
            FULLPATH: "PhoenixContact\\Sensor/actuator cabling\\By the meter",
            X_Description:
              "The range includes sensor and valve plug cables, as well as master cables for distributor boxes.",
            X_Description_SK: "",
          },
          {
            id: "AWI0000101",
            parent_id: "TVI0000101",
            text: "Accessories",
            X_fullName: "Accessories",
            X_fullName_SK: "Pr\u00edslu\u0161enstvo",
            X_URL: "sensor-accessories",
            FULLPATH: "PhoenixContact\\Sensor/actuator cabling\\Accessories",
            X_Description:
              "Accessories for sensor/actuator cabling, e.g., assembly tool, sealing elements, and marking material.",
            X_Description_SK:
              "Pr\u00edslu\u0161enstvo pre kabel\u00e1\u017e sn\u00edma\u010da/aktora, napr. mont\u00e1\u017eny n\u00e1stroj, tesniace prvky a ozna\u010dovac\u00ed materi\u00e1l.",
          },
          {
            id: "BWI0000101",
            parent_id: "TVI0000101",
            text: "Cabling for North American requirements",
            X_fullName: "Cabling for North American requirements",
            X_fullName_SK:
              "Kabel\u00e1\u017e pre severoamerick\u00e9 po\u017eiadavky",
            X_URL: "cabling-for-na-requirements",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Cabling for North American requirements",
            X_Description:
              "Cabling for the North American market enables a high degree of flexibility thanks to the short delivery times.",
            X_Description_SK:
              "Kabel\u00e1\u017e pre severoamerick\u00fd trh umo\u017e\u0148uje vysok\u00fd stupe\u0148 flexibility v\u010faka kr\u00e1tkym dodac\u00edm lehot\u00e1m.",
            items: [
              {
                id: "CWI0000101",
                parent_id: "BWI0000101",
                text: "Sensor/actuator cables",
                X_fullName: "Sensor/actuator cables",
                X_fullName_SK:
                  "K\u00e1ble senzorov/ak\u010dn\u00fdch \u010dlenov",
                X_URL: "sensor-actuator-cables",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for North American requirements\\Sensor/actuator cables",
                X_Description:
                  "Signal cables with M8 and M12 plug-in connectors with AWG cross-sections.",
                X_Description_SK:
                  "Sign\u00e1lne k\u00e1ble so z\u00e1suvn\u00fdmi konektormi M8 a M12 s prierezmi AWG.",
              },
              {
                id: "DWI0000101",
                parent_id: "BWI0000101",
                text: "Cabling for explosion-protected areas",
                X_fullName: "Cabling for explosion-protected areas",
                X_fullName_SK:
                  "Kabel\u00e1\u017e pre oblasti chr\u00e1nen\u00e9 pred v\u00fdbuchom",
                X_URL: "explosion-protected-cabling",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for North American requirements\\Cabling for explosion-protected areas",
                X_Description:
                  "M12 cabling for potentially explosive areas, with locking clip.",
                X_Description_SK:
                  "M12 kabel\u00e1\u017e pre potenci\u00e1lne v\u00fdbu\u0161n\u00e9 priestory, s uzamykacou sponou.",
              },
            ],
          },
          {
            id: "EWI0000101",
            parent_id: "TVI0000101",
            text: "Cabling for special applications",
            X_fullName: "Cabling for special applications",
            X_fullName_SK:
              "Kabel\u00e1\u017e pre \u0161peci\u00e1lne aplik\u00e1cie",
            X_URL: "cabling-for-applications",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications",
            X_Description:
              "Cabling products for demanding industries or special applications.",
            X_Description_SK:
              "Kabel\u00e1\u017e v\u00fdrobkov pre n\u00e1ro\u010dn\u00e9 odvetvia alebo \u0161peci\u00e1lne aplik\u00e1cie.",
            items: [
              {
                id: "FWI0000101",
                parent_id: "EWI0000101",
                text: "Cabling for the food industry",
                X_fullName: "Cabling for the food industry",
                X_fullName_SK:
                  "Kabel\u00e1\u017e pre potravin\u00e1rsky priemysel",
                X_URL: "food-industry-cabling",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for the food industry",
                X_Description:
                  "Cabling for the food industry is corrosion resistant and features a special design.",
                X_Description_SK:
                  "Kabel\u00e1\u017e pre potravin\u00e1rsky priemysel je odoln\u00e1 vo\u010di kor\u00f3zii a m\u00e1 \u0161peci\u00e1lny dizajn.",
                items: [
                  {
                    id: "GWI0000101",
                    parent_id: "FWI0000101",
                    text: "Connectors",
                    X_fullName: "Connectors",
                    X_fullName_SK: "Konektory",
                    X_URL: "cabling-connectors",
                    FULLPATH:
                      "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for the food industry\\Connectors",
                    X_Description:
                      "Connectors for the food industry are made from particularly resistant materials.",
                    X_Description_SK:
                      "Konektory pre potravin\u00e1rsky priemysel s\u00fa vyroben\u00e9 z obzvl\u00e1\u0161\u0165 odoln\u00fdch materi\u00e1lov.",
                  },
                ],
              },
              {
                id: "HWI0000101",
                parent_id: "EWI0000101",
                text: "Cabling for outdoor applications",
                X_fullName: "Cabling for outdoor applications",
                X_fullName_SK:
                  "Kabel\u00e1\u017e pre vonkaj\u0161ie aplik\u00e1cie",
                X_URL: "cabling-for-outdoor-applications",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for outdoor applications",
                X_Description:
                  "Cabling for outdoor applications is made from robust and resistant materials.",
                X_Description_SK:
                  "Kabel\u00e1\u017e pre vonkaj\u0161ie pou\u017eitie je vyroben\u00e1 z robustn\u00fdch a odoln\u00fdch materi\u00e1lov.",
                items: [
                  {
                    id: "IWI0000101",
                    parent_id: "HWI0000101",
                    text: "Cables for mobile hydraulics",
                    X_fullName: "Cables for mobile hydraulics",
                    X_fullName_SK: "K\u00e1ble pre mobiln\u00fa hydrauliku",
                    X_URL: "mobile-cables-hydraulics",
                    FULLPATH:
                      "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for outdoor applications\\Cables for mobile hydraulics",
                    X_Description:
                      "Cables with valve, Deutsch, and Superseal connectors for mobile hydraulics.",
                    X_Description_SK:
                      "K\u00e1ble s ventilov\u00fdmi, Deutsch a Superseal konektormi pre mobiln\u00fa hydrauliku.",
                  },
                ],
              },
              {
                id: "JWI0000101",
                parent_id: "EWI0000101",
                text: "Cabling for robots and drag chains",
                X_fullName: "Cabling for robots and drag chains",
                X_fullName_SK:
                  "Kabel\u00e1\u017e pre roboty a vle\u010dn\u00e9 re\u0165aze",
                X_URL: "cabling-and-drag-chains",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for robots and drag chains",
                X_Description:
                  "Cabling for robots and drag chains is a reliable solution in areas that are particularly subject to stress.",
                X_Description_SK:
                  "Kabel\u00e1\u017e pre roboty a vle\u010dn\u00e9 re\u0165aze je spo\u013eahliv\u00fdm rie\u0161en\u00edm v oblastiach, ktor\u00e9 s\u00fa obzvl\u00e1\u0161\u0165 nam\u00e1han\u00e9.",
                items: [
                  {
                    id: "KWI0000101",
                    parent_id: "JWI0000101",
                    text: "Cable",
                    X_fullName: "Cable",
                    X_fullName_SK: "K\u00e1bel",
                    X_URL: "sensor-cabling-cable",
                    FULLPATH:
                      "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for robots and drag chains\\Cable",
                    X_Description:
                      "Highly flexible assembled cables for robots and drag chains.",
                    X_Description_SK:
                      "Vysoko flexibiln\u00e9 zostaven\u00e9 k\u00e1ble pre roboty a vle\u010dn\u00e9 re\u0165aze.",
                  },
                ],
              },
              {
                id: "MWI0000101",
                parent_id: "EWI0000101",
                text: "Cables for the railway industry",
                X_fullName: "Cables for the railway industry",
                X_fullName_SK:
                  "K\u00e1ble pre \u017eelezni\u010dn\u00fd priemysel",
                X_URL: "cables-for-the-railway-industry",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cables for the railway industry",
                X_Description:
                  "Assembled cables with M12 connectors for signals and data.",
                X_Description_SK: "",
              },
              {
                id: "NWI0000101",
                parent_id: "EWI0000101",
                text: "M12 cabling for power applications",
                X_fullName: "M12 cabling for power applications",
                X_fullName_SK:
                  "Kabel\u00e1\u017e M12 pre v\u00fdkonov\u00e9 aplik\u00e1cie",
                X_URL: "m12-cabling-power-applications",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\M12 cabling for power applications",
                X_Description:
                  "Maximum power transmission while keeping the size to a minimum.",
                X_Description_SK:
                  "Maxim\u00e1lny prenos sily pri zachovan\u00ed ve\u013ekosti na minime.",
                items: [
                  {
                    id: "OWI0000101",
                    parent_id: "NWI0000101",
                    text: "Distributor and distributor boxes",
                    X_fullName: "Distributor and distributor boxes",
                    X_fullName_SK:
                      "Distribu\u010dn\u00e9 a rozde\u013eovacie skrinky",
                    X_URL: "cabling-distributor-boxes",
                    FULLPATH:
                      "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\M12 cabling for power applications\\Distributor and distributor boxes",
                    X_Description:
                      "M12 power distributor boxes, also with diagnostic functions, and Y-distributors.",
                    X_Description_SK:
                      "Rozv\u00e1dza\u010de M12 aj s diagnostick\u00fdmi funkciami a Y-rozv\u00e1dza\u010de.",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "PWI0000101",
        parent_id: "HFI0000101",
        text: "Software",
        X_fullName: "Software",
        X_fullName_SK: "Softv\u00e9r",
        X_URL: "software",
        FULLPATH: "PhoenixContact\\Software",
        X_Description:
          "Tailor made and user friendly: software from Phoenix Contact is your efficient and multifunctional tool for all automation tasks.",
        X_Description_SK:
          "Na mieru \u0161it\u00fd a u\u017e\u00edvate\u013esky pr\u00edvetiv\u00fd: softv\u00e9r od Phoenix Contact je v\u00e1\u0161 efekt\u00edvny a multifunk\u010dn\u00fd n\u00e1stroj pre v\u0161etky automatiza\u010dn\u00e9 \u00falohy.",
        items: [
          {
            id: "QWI0000101",
            parent_id: "PWI0000101",
            text: "Function blocks",
            X_fullName: "Function blocks",
            X_fullName_SK: "Funk\u010dn\u00e9 bloky",
            X_URL: "function-blocks",
            FULLPATH: "PhoenixContact\\Software\\Function blocks",
            X_Description:
              "Function blocks that can be used to integrate complex functions flexibly and cost-effectively in a control program.",
            X_Description_SK:
              "Funk\u010dn\u00e9 bloky, ktor\u00e9 mo\u017eno pou\u017ei\u0165 na flexibiln\u00fa a cenovo v\u00fdhodn\u00fa integr\u00e1ciu komplexn\u00fdch funkci\u00ed do riadiaceho programu.",
            items: [
              {
                id: "RWI0000101",
                parent_id: "QWI0000101",
                text: "Water and Wastewater Treatment",
                X_fullName: "Water and Wastewater Treatment",
                X_fullName_SK: "\u010cistenie vody a odpadov\u00fdch v\u00f4d",
                X_URL: "water-and-wastewater-treatment",
                FULLPATH:
                  "PhoenixContact\\Software\\Function blocks\\Water and Wastewater Treatment",
                X_Description:
                  "Function block library for wastewater treatment and drinking water treatment enables easy integration of measurement and control technology.",
                X_Description_SK:
                  "Kni\u017enica funk\u010dn\u00fdch blokov pre \u010distenie odpadov\u00fdch v\u00f4d a \u00fapravu pitnej vody umo\u017e\u0148uje jednoduch\u00fa integr\u00e1ciu meracej a regula\u010dnej techniky.",
                items: [
                  {
                    id: "SWI0000101",
                    parent_id: "RWI0000101",
                    text: "Function blocks for Water and Wastewater Treatment",
                    X_fullName:
                      "Function blocks for Water and Wastewater Treatment",
                    X_fullName_SK:
                      "Funk\u010dn\u00e9 bloky pre \u010distenie vody a odpadov\u00fdch v\u00f4d",
                    X_URL: "function-blocks-water-treatment",
                    FULLPATH:
                      "PhoenixContact\\Software\\Function blocks\\Water and Wastewater Treatment\\Function blocks for Water and Wastewater Treatment",
                    X_Description:
                      "Function block library for process automation in wastewater treatment and drinking water treatment.",
                    X_Description_SK:
                      "Kni\u017enica funk\u010dn\u00fdch blokov pre automatiz\u00e1ciu procesov v \u010disten\u00ed odpadov\u00fdch v\u00f4d a \u00faprave pitnej vody.",
                  },
                ],
              },
            ],
          },
          {
            id: "UWI0000101",
            parent_id: "PWI0000101",
            text: "Configuration, monitoring, and diagnostics",
            X_fullName: "Configuration, monitoring, and diagnostics",
            X_fullName_SK: "Konfigur\u00e1cia, monitorovanie a diagnostika",
            X_URL: "config-monitor-diagnostics",
            FULLPATH:
              "PhoenixContact\\Software\\Configuration, monitoring, and diagnostics",
            X_Description:
              "Software tools for easy configuration, preventive monitoring, and fast diagnostics in the event of an error.",
            X_Description_SK: "",
          },
          {
            id: "WWI0000101",
            parent_id: "PWI0000101",
            text: "Planning and configuration",
            X_fullName: "Planning and configuration",
            X_fullName_SK: "Pl\u00e1novanie a konfigur\u00e1cia",
            X_URL: "planning-and-configuration",
            FULLPATH: "PhoenixContact\\Software\\Planning and configuration",
            X_Description:
              "Software tools for planning and configuring automation stations and markings.",
            X_Description_SK: "",
          },
          {
            id: "YWI0000101",
            parent_id: "PWI0000101",
            text: "Programming",
            X_fullName: "Programming",
            X_fullName_SK: "Programovanie",
            X_URL: "programming",
            FULLPATH: "PhoenixContact\\Software\\Programming",
            X_Description:
              "Software for the efficient programming of Phoenix Contact controllers in every class.",
            X_Description_SK: "",
          },
          {
            id: "ZWI0000101",
            parent_id: "PWI0000101",
            text: "Drivers and interfaces",
            X_fullName: "Drivers and interfaces",
            X_fullName_SK: "Ovl\u00e1da\u010de a rozhrania",
            X_URL: "drivers-and-interfaces",
            FULLPATH: "PhoenixContact\\Software\\Drivers and interfaces",
            X_Description:
              "Open, standardized interfaces such as OPC or SNMP offer flexible integration in numerous higher-level systems.",
            X_Description_SK:
              "Otvoren\u00e9, \u0161tandardizovan\u00e9 rozhrania ako OPC alebo SNMP pon\u00fakaj\u00fa flexibiln\u00fa integr\u00e1ciu do mnoh\u00fdch syst\u00e9mov vy\u0161\u0161ej \u00farovne.",
            items: [
              {
                id: "0XI0000101",
                parent_id: "ZWI0000101",
                text: "OPC communication interface",
                X_fullName: "OPC communication interface",
                X_fullName_SK: "Komunika\u010dn\u00e9 rozhranie OPC",
                X_URL: "opc-communication-interface",
                FULLPATH:
                  "PhoenixContact\\Software\\Drivers and interfaces\\OPC communication interface",
                X_Description:
                  "Products for standardized connection of your controller solution to all higher-level software systems via OPC.",
                X_Description_SK:
                  "Produkty pre \u0161tandardizovan\u00e9 pripojenie v\u00e1\u0161ho riadiaceho rie\u0161enia ku v\u0161etk\u00fdm softv\u00e9rov\u00fdm syst\u00e9mom vy\u0161\u0161ej \u00farovne cez OPC.",
              },
            ],
          },
          {
            id: "2XI0000101",
            parent_id: "PWI0000101",
            text: "Visualization",
            X_fullName: "Visualization",
            X_fullName_SK: "Vizualiz\u00e1cia",
            X_URL: "visualization",
            FULLPATH: "PhoenixContact\\Software\\Visualization",
            X_Description:
              "Software for a wide range of visualization tasks in automation systems. Ideal for HMI devices and industrial PCs.",
            X_Description_SK: "",
          },
          {
            id: "3XI0000101",
            parent_id: "PWI0000101",
            text: "Software for E-Mobility",
            X_fullName: "Software for E-Mobility",
            X_fullName_SK: "Softv\u00e9r pre elektronick\u00fa mobilitu",
            X_URL: "software-for-e-mobility",
            FULLPATH: "PhoenixContact\\Software\\Software for E-Mobility",
            X_Description:
              "Software for controlling or monitoring individual charging stations or entire charging parks.",
            X_Description_SK:
              "Softv\u00e9r na ovl\u00e1danie alebo monitorovanie jednotliv\u00fdch nab\u00edjac\u00edch stan\u00edc alebo cel\u00fdch nab\u00edjac\u00edch parkov\u00edsk.",
            items: [
              {
                id: "5XI0000101",
                parent_id: "3XI0000101",
                text: "Software suite for charging park management",
                X_fullName: "Software suite for charging park management",
                X_fullName_SK:
                  "Softv\u00e9rov\u00fd bal\u00edk na spr\u00e1vu nab\u00edjac\u00edch parkov",
                X_URL: "charging-park-management",
                FULLPATH:
                  "PhoenixContact\\Software\\Software for E-Mobility\\Software suite for charging park management",
                X_Description:
                  "The EV Charging Suite combines all of the functions required for intelligent charging park management in a single software package.",
                X_Description_SK: "",
              },
            ],
          },
        ],
      },
      {
        id: "6XI0000101",
        parent_id: "HFI0000101",
        text: "Connectors",
        X_fullName: "Connectors",
        X_fullName_SK: "Konektory",
        X_URL: "connectors",
        FULLPATH: "PhoenixContact\\Connectors",
        X_Description:
          "We not only offer a comprehensive selection of connectors, but also outstanding quality, expertise, and design-in support.",
        X_Description_SK:
          "Pon\u00fakame nielen komplexn\u00fd v\u00fdber konektorov, ale aj vynikaj\u00facu kvalitu, odbornos\u0165 a podporu dizajnu.",
        items: [
          {
            id: "7XI0000101",
            parent_id: "6XI0000101",
            text: '19" plug-in card blocks and socket strips',
            X_fullName: '19" plug-in card blocks and socket strips',
            X_fullName_SK:
              '19" bloky z\u00e1suvn\u00fdch kariet a z\u00e1suvkov\u00e9 li\u0161ty',
            X_URL: "card-blocks-and-socket-strips-19",
            FULLPATH:
              'PhoenixContact\\Connectors\\19" plug-in card blocks and socket strips',
            X_Description:
              'Connection elements for use in the control cabinet or in 19" racks.',
            X_Description_SK:
              'Pripojovacie prvky pre pou\u017eitie v rozv\u00e1dza\u010di alebo v 19" rackoch.',
            items: [
              {
                id: "8XI0000101",
                parent_id: "7XI0000101",
                text: '19" socket strips',
                X_fullName: '19" socket strips',
                X_fullName_SK: '19" z\u00e1suvkov\u00e9 li\u0161ty',
                X_URL: "19-socket-strips",
                FULLPATH:
                  'PhoenixContact\\Connectors\\19" plug-in card blocks and socket strips\\19" socket strips',
                X_Description:
                  "Socket strips with screw or spring-cage connection in various designs.",
                X_Description_SK:
                  "Z\u00e1suvkov\u00e9 li\u0161ty so skrutkov\u00fdm alebo pru\u017einov\u00fdm spojen\u00edm v r\u00f4znych prevedeniach.",
              },
            ],
          },
          {
            id: "9XI0000101",
            parent_id: "6XI0000101",
            text: "Data connectors",
            X_fullName: "Data connectors",
            X_fullName_SK: "D\u00e1tov\u00e9 konektory",
            X_URL: "data-connectors",
            FULLPATH: "PhoenixContact\\Connectors\\Data connectors",
            X_Description:
              "Common, standardized bus systems in industrial environments for high data transmission speeds.",
            X_Description_SK:
              "Be\u017en\u00e9, \u0161tandardizovan\u00e9 zbernicov\u00e9 syst\u00e9my v priemyselnom prostred\u00ed pre vysok\u00e9 r\u00fdchlosti prenosu d\u00e1t.",
            items: [
              {
                id: "AXI0000101",
                parent_id: "9XI0000101",
                text: "Copper-based data connectors",
                X_fullName: "Copper-based data connectors",
                X_fullName_SK: "D\u00e1tov\u00e9 konektory na b\u00e1ze medi",
                X_URL: "copper-based-data-connectors",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Data connectors\\Copper-based data connectors",
                X_Description:
                  "Safe and reliable data transmission up to 10 Gbps by means of copper-based data connectors.",
                X_Description_SK:
                  "Bezpe\u010dn\u00fd a spo\u013eahliv\u00fd prenos d\u00e1t a\u017e do 10 Gbps pomocou d\u00e1tov\u00fdch konektorov na b\u00e1ze medi.",
                items: [
                  {
                    id: "BXI0000101",
                    parent_id: "AXI0000101",
                    text: "Connectors",
                    X_fullName: "Connectors",
                    X_fullName_SK: "Konektory",
                    X_URL: "cooper-connectors",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Data connectors\\Copper-based data connectors\\Connectors",
                    X_Description:
                      "The extensive product range from Phoenix Contact offers numerous options for copper-based cabling.",
                    X_Description_SK:
                      "Rozsiahly sortiment od spolo\u010dnosti Phoenix Contact pon\u00faka mno\u017estvo mo\u017enost\u00ed pre kabel\u00e1\u017e na b\u00e1ze medi.",
                  },
                  {
                    id: "CXI0000101",
                    parent_id: "AXI0000101",
                    text: "Device connections",
                    X_fullName: "Device connections",
                    X_fullName_SK: "Pripojenia zariaden\u00ed",
                    X_URL: "device-connections",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Data connectors\\Copper-based data connectors\\Device connections",
                    X_Description:
                      "Versatile panel feed-throughs are available for use in devices or control cabinets.",
                    X_Description_SK:
                      "Na pou\u017eitie v zariadeniach alebo ovl\u00e1dac\u00edch skriniach s\u00fa k dispoz\u00edcii v\u0161estrann\u00e9 panelov\u00e9 priechodky.",
                  },
                ],
              },
              {
                id: "DXI0000101",
                parent_id: "9XI0000101",
                text: "Fiber optic-based data connectors",
                X_fullName: "Fiber optic-based data connectors",
                X_fullName_SK:
                  "D\u00e1tov\u00e9 konektory na b\u00e1ze optick\u00fdch vl\u00e1kien",
                X_URL: "fiber-data-connectors",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Data connectors\\Fiber optic-based data connectors",
                X_Description:
                  "Fast and safe data transmission up to 40 Gbps by means of fiber optic-based data connectors.",
                X_Description_SK:
                  "R\u00fdchly a bezpe\u010dn\u00fd prenos \u00fadajov do 40 Gbps pomocou d\u00e1tov\u00fdch konektorov zalo\u017een\u00fdch na optick\u00fdch vl\u00e1knach.",
                items: [
                  {
                    id: "EXI0000101",
                    parent_id: "DXI0000101",
                    text: "Patch cables and lines",
                    X_fullName: "Patch cables and lines",
                    X_fullName_SK: "Patch k\u00e1ble a vedenia",
                    X_URL: "cables-and-lines",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Data connectors\\Fiber optic-based data connectors\\Patch cables and lines",
                    X_Description:
                      "The choice is yours: POF for short transmission paths, PCF for medium distances or GOF for long transmission paths.",
                    X_Description_SK:
                      "V\u00fdber je na v\u00e1s: POF pre kr\u00e1tke prenosov\u00e9 cesty, PCF pre stredn\u00e9 vzdialenosti alebo GOF pre dlh\u00e9 prenosov\u00e9 cesty.",
                  },
                  {
                    id: "FXI0000101",
                    parent_id: "DXI0000101",
                    text: "Load center",
                    X_fullName: "Load center",
                    X_fullName_SK: "N\u00e1kladov\u00e9 stredisko",
                    X_URL: "load-center",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Data connectors\\Fiber optic-based data connectors\\Load center",
                    X_Description:
                      'Distributors for DIN rails or 19" racks support efficient connection solutions.',
                    X_Description_SK:
                      'Rozv\u00e1dza\u010de pre DIN li\u0161ty alebo 19" stojany podporuj\u00fa efekt\u00edvne rie\u0161enia pripojenia.',
                  },
                ],
              },
              {
                id: "GXI0000101",
                parent_id: "9XI0000101",
                text: "Copper-based data connectors",
                X_fullName: "Copper-based data connectors",
                X_fullName_SK: "D\u00e1tov\u00e9 konektory na b\u00e1ze medi",
                X_URL: "copper-data-connectors",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Data connectors\\Copper-based data connectors",
                X_Description:
                  "Copper-based data connectors for data rates up to 10 Gbps are available with various connection methods.",
                X_Description_SK:
                  "D\u00e1tov\u00e9 konektory na b\u00e1ze medi pre prenosov\u00fa r\u00fdchlos\u0165 a\u017e 10 Gbps s\u00fa dostupn\u00e9 s r\u00f4znymi sp\u00f4sobmi pripojenia.",
              },
              {
                id: "HXI0000101",
                parent_id: "9XI0000101",
                text: "Fiber optic-based data connectors",
                X_fullName: "Fiber optic-based data connectors",
                X_fullName_SK:
                  "D\u00e1tov\u00e9 konektory na b\u00e1ze optick\u00fdch vl\u00e1kien",
                X_URL: "fiber-optic-data-connectors",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Data connectors\\Fiber optic-based data connectors",
                X_Description:
                  "Fiber optic-based data connectors for data rates up to 40 Gbps are available for all common fiber types.",
                X_Description_SK:
                  "Pre v\u0161etky be\u017en\u00e9 typy vl\u00e1kien s\u00fa k dispoz\u00edcii d\u00e1tov\u00e9 konektory na b\u00e1ze optick\u00fdch vl\u00e1kien pre prenosov\u00fa r\u00fdchlos\u0165 a\u017e 40 Gbps.",
              },
            ],
          },
          {
            id: "IXI0000101",
            parent_id: "6XI0000101",
            text: "Photovoltaic connectors",
            X_fullName: "Photovoltaic connectors",
            X_fullName_SK: "Fotovoltaick\u00e9 konektory",
            X_URL: "photovoltaic-connectors",
            FULLPATH: "PhoenixContact\\Connectors\\Photovoltaic connectors",
            X_Description:
              "Quick and easy cabling of PV panels and inverters on site.",
            X_Description_SK:
              "R\u00fdchla a \u013eahk\u00e1 kabel\u00e1\u017e PV panelov a meni\u010dov na mieste.",
            items: [
              {
                id: "KXI0000101",
                parent_id: "IXI0000101",
                text: "AC connection technology",
                X_fullName: "AC connection technology",
                X_fullName_SK:
                  "Technol\u00f3gia pripojenia na striedav\u00fd pr\u00fad",
                X_URL: "ac-connection-technology",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Photovoltaic connectors\\AC connection technology",
                X_Description:
                  "Phoenix Contact offers an extensive connection range for every performance class for the AC-side installation of photovoltaic systems.",
                X_Description_SK: "",
              },
              {
                id: "LXI0000101",
                parent_id: "IXI0000101",
                text: "DC connection technology",
                X_fullName: "DC connection technology",
                X_fullName_SK:
                  "Technol\u00f3gia pripojenia jednosmern\u00e9ho pr\u00fadu",
                X_URL: "dc-connection-technology-main",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Photovoltaic connectors\\DC connection technology",
                X_Description:
                  "Panel feed-throughs, connectors, and comprehensive accessories \u2013 innovative DC connection technology from a single source.",
                X_Description_SK:
                  "Panelov\u00e9 priechodky, konektory a komplexn\u00e9 pr\u00edslu\u0161enstvo \u2013 inovat\u00edvna technol\u00f3gia DC pripojenia z jedn\u00e9ho zdroja.",
                items: [
                  {
                    id: "MXI0000101",
                    parent_id: "LXI0000101",
                    text: "Connection technology for PV panels",
                    X_fullName: "Connection technology for PV panels",
                    X_fullName_SK:
                      "Technol\u00f3gia pripojenia pre fotovoltick\u00e9 panely",
                    X_URL: "pv-panels-connection-technology",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Photovoltaic connectors\\DC connection technology\\Connection technology for PV panels",
                    X_Description:
                      "The PCB terminal blocks from Phoenix Contact are designed for a rated current of 41 A.",
                    X_Description_SK:
                      "Svorkovnice PCB od Phoenix Contact s\u00fa navrhnut\u00e9 pre menovit\u00fd pr\u00fad 41 A.",
                  },
                  {
                    id: "NXI0000101",
                    parent_id: "LXI0000101",
                    text: "DC connection technology",
                    X_fullName: "DC connection technology",
                    X_fullName_SK:
                      "Technol\u00f3gia pripojenia jednosmern\u00e9ho pr\u00fadu",
                    X_URL: "dc-connection-technology",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Photovoltaic connectors\\DC connection technology\\DC connection technology",
                    X_Description:
                      "Panel feed-throughs, connectors, and comprehensive accessories: innovative DC connection technology from a single source.",
                    X_Description_SK:
                      "Panelov\u00e9 priechodky, konektory a komplexn\u00e9 pr\u00edslu\u0161enstvo: inovat\u00edvna technol\u00f3gia DC pripojenia z jedn\u00e9ho zdroja.",
                  },
                ],
              },
            ],
          },
          {
            id: "OXI0000101",
            parent_id: "6XI0000101",
            text: "Circular connectors",
            X_fullName: "Circular connectors",
            X_fullName_SK: "Okr\u00fahle konektory",
            X_URL: "circular-connectors",
            FULLPATH: "PhoenixContact\\Connectors\\Circular connectors",
            X_Description:
              "Versatile circular connectors with variable connection technologies \u2013 for industrial applications.",
            X_Description_SK:
              "V\u0161estrann\u00e9 kruhov\u00e9 konektory s variabiln\u00fdmi technol\u00f3giami pripojenia \u2013 pre priemyseln\u00e9 aplik\u00e1cie.",
            items: [
              {
                id: "PXI0000101",
                parent_id: "OXI0000101",
                text: "Signal connectors",
                X_fullName: "Signal connectors",
                X_fullName_SK: "Sign\u00e1lne konektory",
                X_URL: "signal-connectors",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Circular connectors\\Signal connectors",
                X_Description:
                  "We offer a variety of connectors for signal transmission in sizes M5 to M23.",
                X_Description_SK:
                  "Pon\u00fakame r\u00f4zne konektory na prenos sign\u00e1lu vo ve\u013ekostiach M5 a\u017e M23.",
                items: [
                  {
                    id: "QXI0000101",
                    parent_id: "PXI0000101",
                    text: "Circular connectors for signals",
                    X_fullName: "Circular connectors for signals",
                    X_fullName_SK: "Okr\u00fahle konektory pre sign\u00e1ly",
                    X_URL: "circular-connectors-for-signals",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Signal connectors\\Circular connectors for signals",
                    X_Description:
                      "M5 to M23 signal connectors \u2013 a large variety of sizes and housing designs enable the creation of consistent solutions for the device and cable side.",
                    X_Description_SK:
                      "Sign\u00e1lov\u00e9 konektory M5 a\u017e M23 \u2013 \u0161irok\u00e1 \u0161k\u00e1la ve\u013ekost\u00ed a dizajnov krytov umo\u017e\u0148uje vytvorenie konzistentn\u00fdch rie\u0161en\u00ed na strane zariadenia a k\u00e1blov.",
                  },
                  {
                    id: "RXI0000101",
                    parent_id: "PXI0000101",
                    text: "Circular connectors for signals (M23 PRO series)",
                    X_fullName:
                      "Circular connectors for signals (M23 PRO series)",
                    X_fullName_SK:
                      "Okr\u00fahle konektory pre sign\u00e1ly (s\u00e9ria M23 PRO)",
                    X_URL: "circular-connectors-signals-pro",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Signal connectors\\Circular connectors for signals (M23 PRO series)",
                    X_Description:
                      "M23 circular connectors from the PRO series offer solutions for signal transmission.",
                    X_Description_SK:
                      "Kruhov\u00e9 konektory M23 zo s\u00e9rie PRO pon\u00fakaj\u00fa rie\u0161enia pre prenos sign\u00e1lu.",
                  },
                ],
              },
              {
                id: "SXI0000101",
                parent_id: "OXI0000101",
                text: "Data connectors",
                X_fullName: "Data connectors",
                X_fullName_SK: "D\u00e1tov\u00e9 konektory",
                X_URL: "circular-data-connectors",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Circular connectors\\Data connectors",
                X_Description:
                  "For fast and reliable data communication, we offer shielded circular connectors in sizes M8 and M12 for all common fieldbuses and networks.",
                X_Description_SK:
                  "Pre r\u00fdchlu a spo\u013eahliv\u00fa d\u00e1tov\u00fa komunik\u00e1ciu pon\u00fakame tienen\u00e9 kruhov\u00e9 konektory vo ve\u013ekostiach M8 a M12 pre v\u0161etky be\u017en\u00e9 priemyseln\u00e9 zbernice a siete.",
                items: [
                  {
                    id: "TXI0000101",
                    parent_id: "SXI0000101",
                    text: "Circular connectors for data",
                    X_fullName: "Circular connectors for data",
                    X_fullName_SK: "Okr\u00fahle konektory pre d\u00e1ta",
                    X_URL: "circular-connectors-for-data",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Data connectors\\Circular connectors for data",
                    X_Description:
                      "M8 and M12 data connectors - cost-effectively integrate device connectors into your fieldbus and network applications",
                    X_Description_SK:
                      "D\u00e1tov\u00e9 konektory M8 a M12 \u2013 cenovo v\u00fdhodn\u00e1 integr\u00e1cia konektorov zariaden\u00ed do va\u0161ich fieldbusov\u00fdch a sie\u0165ov\u00fdch aplik\u00e1ci\u00ed",
                  },
                ],
              },
              {
                id: "UXI0000101",
                parent_id: "OXI0000101",
                text: "Power connectors",
                X_fullName: "Power connectors",
                X_fullName_SK: "Nap\u00e1jacie konektory",
                X_URL: "power-connectors",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Circular connectors\\Power connectors",
                X_Description:
                  "Use the power connectors to connect compact devices and electrical drives.",
                X_Description_SK:
                  "Na pripojenie kompaktn\u00fdch zariaden\u00ed a elektrick\u00fdch pohonov pou\u017eite nap\u00e1jacie konektory.",
                items: [
                  {
                    id: "VXI0000101",
                    parent_id: "UXI0000101",
                    text: "Circular connectors for power",
                    X_fullName: "Circular connectors for power",
                    X_fullName_SK: "Okr\u00fahle konektory pre nap\u00e1janie",
                    X_URL: "circular-connectors-for-power",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Power connectors\\Circular connectors for power",
                    X_Description:
                      "M12 to M58 power connectors \u2013 always the right choice for the easy connection of electrical drives and devices up to 150 A/630 V",
                    X_Description_SK:
                      "Nap\u00e1jacie konektory M12 a\u017e M58 \u2013 v\u017edy spr\u00e1vna vo\u013eba pre jednoduch\u00e9 pripojenie elektrick\u00fdch pohonov a zariaden\u00ed do 150 A/630 V",
                  },
                  {
                    id: "WXI0000101",
                    parent_id: "UXI0000101",
                    text: "Circular connectors for power (M23 PRO series)",
                    X_fullName:
                      "Circular connectors for power (M23 PRO series)",
                    X_fullName_SK:
                      "Okr\u00fahle konektory pre nap\u00e1janie (s\u00e9ria M23 PRO)",
                    X_URL: "circular-connectors-power-pro",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Power connectors\\Circular connectors for power (M23 PRO series)",
                    X_Description:
                      "M23 circular connectors from the PRO series offer solutions for power transmission.",
                    X_Description_SK:
                      "Kruhov\u00e9 konektory M23 zo s\u00e9rie PRO pon\u00fakaj\u00fa rie\u0161enia pre prenos sily.",
                  },
                ],
              },
              {
                id: "YXI0000101",
                parent_id: "OXI0000101",
                text: "Hybrid connectors",
                X_fullName: "Hybrid connectors",
                X_fullName_SK: "Hybridn\u00e9 konektory",
                X_URL: "hybrid-connectors",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Circular connectors\\Hybrid connectors",
                X_Description:
                  "Hybrid connectors for transmitting signals, data, and power in a single connector",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "ZXI0000101",
            parent_id: "6XI0000101",
            text: "Heavy-duty connectors",
            X_fullName: "Heavy-duty connectors",
            X_fullName_SK: "Konektory s vysokou odolnos\u0165ou",
            X_URL: "heavy-duty-connectors",
            FULLPATH: "PhoenixContact\\Connectors\\Heavy-duty connectors",
            X_Description:
              "Robust connectors \u2013 for quick startup and servicing in extreme conditions.",
            X_Description_SK:
              "Robustn\u00e9 konektory \u2013 pre r\u00fdchle spustenie a servis v extr\u00e9mnych podmienkach.",
            items: [
              {
                id: "0YI0000101",
                parent_id: "ZXI0000101",
                text: "Contact inserts and contacts",
                X_fullName: "Contact inserts and contacts",
                X_fullName_SK: "Kontaktn\u00e9 vlo\u017eky a kontakty",
                X_URL: "contact-inserts-and-contacts",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts",
                X_Description:
                  "Contact inserts and terminal adapters are designed to suit the individual application.",
                X_Description_SK:
                  "Kontaktn\u00e9 vlo\u017eky a koncov\u00e9 adapt\u00e9ry s\u00fa navrhnut\u00e9 tak, aby vyhovovali individu\u00e1lnej aplik\u00e1cii.",
                items: [
                  {
                    id: "1YI0000101",
                    parent_id: "0YI0000101",
                    text: "Contact inserts with fixed number of positions",
                    X_fullName:
                      "Contact inserts with fixed number of positions",
                    X_fullName_SK:
                      "Kontaktn\u00e9 vlo\u017eky s pevn\u00fdm po\u010dtom pol\u00f4h",
                    X_URL: "fixed-positions-contact-inserts",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contact inserts with fixed number of positions",
                    X_Description:
                      "Contact inserts with a fixed number of positions in different contact sizes and a wide range of connection technologies.",
                    X_Description_SK:
                      "Kontaktn\u00e9 vlo\u017eky s pevn\u00fdm po\u010dtom poz\u00edci\u00ed v r\u00f4znych ve\u013ekostiach kontaktov a \u0161irokou \u0161k\u00e1lou spojovac\u00edch technol\u00f3gi\u00ed.",
                  },
                  {
                    id: "2YI0000101",
                    parent_id: "0YI0000101",
                    text: "Modular contact inserts",
                    X_fullName: "Modular contact inserts",
                    X_fullName_SK: "Modul\u00e1rne kontaktn\u00e9 vlo\u017eky",
                    X_URL: "modular-contact-inserts",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Modular contact inserts",
                    X_Description:
                      "Modular contact inserts for the individual configuration of contact inserts, even with different media.",
                    X_Description_SK:
                      "Modul\u00e1rne kontaktn\u00e9 vlo\u017eky pre individu\u00e1lnu konfigur\u00e1ciu kontaktn\u00fdch vlo\u017eiek aj s r\u00f4znymi m\u00e9diami.",
                  },
                  {
                    id: "3YI0000101",
                    parent_id: "0YI0000101",
                    text: "Contacts",
                    X_fullName: "Contacts",
                    X_fullName_SK: "Kontakty",
                    X_URL: "contacts",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts",
                    X_Description:
                      "Crimp contacts in various different versions for a wide range of requirements.",
                    X_Description_SK:
                      "Krimpovacie kontakty v r\u00f4znych verzi\u00e1ch pre \u0161irok\u00fa \u0161k\u00e1lu po\u017eiadaviek.",
                    items: [
                      {
                        id: "4YI0000101",
                        parent_id: "3YI0000101",
                        text: "Turned contacts",
                        X_fullName: "Turned contacts",
                        X_fullName_SK: "Oto\u010den\u00e9 kontakty",
                        X_URL: "turned-contacts",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Turned contacts",
                        X_Description:
                          "Turned contacts in various different versions for optimum crimp connection.",
                        X_Description_SK:
                          "Oto\u010den\u00e9 kontakty v r\u00f4znych verzi\u00e1ch pre optim\u00e1lne krimpovacie spojenie.",
                      },
                      {
                        id: "5YI0000101",
                        parent_id: "3YI0000101",
                        text: "Rolled contacts",
                        X_fullName: "Rolled contacts",
                        X_fullName_SK: "Valcovan\u00e9 kontakty",
                        X_URL: "rolled-contacts",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Rolled contacts",
                        X_Description:
                          "Rolled contacts in various different versions for optimum crimp connection.",
                        X_Description_SK:
                          "Valcovan\u00e9 kontakty v r\u00f4znych verzi\u00e1ch pre optim\u00e1lne krimpovacie spojenie.",
                      },
                      {
                        id: "6YI0000101",
                        parent_id: "3YI0000101",
                        text: "FO contacts",
                        X_fullName: "FO contacts",
                        X_fullName_SK: "Kontakty FO",
                        X_URL: "fo-contacts",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\FO contacts",
                        X_Description:
                          "Fiber optic contacts for heavy-duty connectors.",
                        X_Description_SK:
                          "Kontakty z optick\u00fdch vl\u00e1kien pre vysokov\u00fdkonn\u00e9 konektory.",
                      },
                      {
                        id: "7YI0000101",
                        parent_id: "3YI0000101",
                        text: "Pneumatics contacts",
                        X_fullName: "Pneumatics contacts",
                        X_fullName_SK: "Pneumatick\u00e9 kontakty",
                        X_URL: "pneumatics-contacts",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Pneumatics contacts",
                        X_Description:
                          "Pneumatics pins for heavy-duty connectors.",
                        X_Description_SK:
                          "Pneumatick\u00e9 kol\u00edky pre vysokov\u00fdkonn\u00e9 konektory.",
                      },
                      {
                        id: "8YI0000101",
                        parent_id: "3YI0000101",
                        text: "Shield connection contacts",
                        X_fullName: "Shield connection contacts",
                        X_fullName_SK: "Kontakty na pripojenie \u0161t\u00edtu",
                        X_URL: "shield-connection-contacts",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Shield connection contacts",
                        X_Description:
                          "Shield connection contacts for shielded cables to contact carriers.",
                        X_Description_SK:
                          "Tienen\u00e9 pripojovacie kontakty pre tienen\u00e9 k\u00e1ble k nosi\u010dom kontaktov.",
                      },
                      {
                        id: "9YI0000101",
                        parent_id: "3YI0000101",
                        text: "Thermostatic contacts",
                        X_fullName: "Thermostatic contacts",
                        X_fullName_SK: "Termostatick\u00e9 kontakty",
                        X_URL: "thermostatic-contacts",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Thermostatic contacts",
                        X_Description:
                          "Thermal contacts for heavy-duty connectors.",
                        X_Description_SK:
                          "Tepeln\u00e9 kontakty pre vysokov\u00fdkonn\u00e9 konektory.",
                      },
                    ],
                  },
                ],
              },
              {
                id: "AYI0000101",
                parent_id: "ZXI0000101",
                text: "Housing",
                X_fullName: "Housing",
                X_fullName_SK: "P\u00fazdra elektroniky",
                X_URL: "housing",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing",
                X_Description:
                  "Robust and reliable housings for use in harsh, aggressive conditions.",
                X_Description_SK:
                  "Robustn\u00e9 a spo\u013eahliv\u00e9 kryty na pou\u017eitie v drsn\u00fdch, agres\u00edvnych podmienkach.",
                items: [
                  {
                    id: "BYI0000101",
                    parent_id: "AYI0000101",
                    text: "EVO housings",
                    X_fullName: "EVO housings",
                    X_fullName_SK: "Puzdr\u00e1 EVO",
                    X_URL: "evo-housings",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\EVO housings",
                    X_Description:
                      "Save costs with our innovative range of heavy-duty connectors.",
                    X_Description_SK:
                      "U\u0161etrite n\u00e1klady s na\u0161\u00edm inovat\u00edvnym radom vysokov\u00fdkonn\u00fdch konektorov.",
                    items: [
                      {
                        id: "CYI0000101",
                        parent_id: "BYI0000101",
                        text: "EVO housing",
                        X_fullName: "EVO housing",
                        X_fullName_SK: "Puzdro EVO",
                        X_URL: "evo-housing",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\EVO housings\\EVO housing",
                        X_Description:
                          "Innovative EVO housing with bayonet locking in B6 to B24 sizes with single and double locking latch.",
                        X_Description_SK:
                          "Inovat\u00edvne puzdro EVO s bajonetov\u00fdm uz\u00e1verom vo ve\u013ekostiach B6 a\u017e B24 s jednoduchou a dvojitou uzamykacou z\u00e1padkou.",
                      },
                      {
                        id: "DYI0000101",
                        parent_id: "BYI0000101",
                        text: "EVO screw connections",
                        X_fullName: "EVO screw connections",
                        X_fullName_SK: "Skrutkov\u00e9 spoje EVO",
                        X_URL: "evo-screw-connections",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\EVO housings\\EVO screw connections",
                        X_Description:
                          "Plastic metric screw connections for EVO housing in sizes M20 to M40.",
                        X_Description_SK:
                          "Plastov\u00e9 metrick\u00e9 skrutkov\u00e9 pripojenia pre puzdro EVO vo ve\u013ekostiach M20 a\u017e M40.",
                      },
                    ],
                  },
                  {
                    id: "FYI0000101",
                    parent_id: "AYI0000101",
                    text: "ADVANCE housing",
                    X_fullName: "ADVANCE housing",
                    X_fullName_SK: "P\u00fazdra elektroniky ADVANCE",
                    X_URL: "advance-housing",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\ADVANCE housing",
                    X_Description:
                      "Robust housing with screw or bayonet locking in various designs for use without a panel mounting base.",
                    X_Description_SK: "",
                  },
                  {
                    id: "HYI0000101",
                    parent_id: "AYI0000101",
                    text: "STANDARD housing",
                    X_fullName: "STANDARD housing",
                    X_fullName_SK: "P\u00fazdra elektroniky STANDARD",
                    X_URL: "standard-housing",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\STANDARD housing",
                    X_Description:
                      "Robust housing with locking latch in various sizes for high mechanical loads.",
                    X_Description_SK: "",
                  },
                ],
              },
              {
                id: "JYI0000101",
                parent_id: "ZXI0000101",
                text: "Connector sets",
                X_fullName: "Connector sets",
                X_fullName_SK: "Sady konektorov",
                X_URL: "connector-sets",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Heavy-duty connectors\\Connector sets",
                X_Description:
                  "User-friendly connection for quick, tool-free connection of multi-position cables.",
                X_Description_SK: "",
              },
              {
                id: "KYI0000101",
                parent_id: "ZXI0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "connector-accessories",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Heavy-duty connectors\\Accessories",
                X_Description:
                  "Comprehensive accessories and replacement parts for extended mounting options and repairs.",
                X_Description_SK:
                  "Komplexn\u00e9 pr\u00edslu\u0161enstvo a n\u00e1hradn\u00e9 diely pre roz\u0161\u00edren\u00e9 mo\u017enosti mont\u00e1\u017ee a opravy.",
              },
            ],
          },
          {
            id: "LYI0000101",
            parent_id: "6XI0000101",
            text: "Modular rectangular connectors",
            X_fullName: "Modular rectangular connectors",
            X_fullName_SK:
              "Modul\u00e1rne obd\u013a\u017enikov\u00e9 konektory",
            X_URL: "modular-rectangular-connectors",
            FULLPATH:
              "PhoenixContact\\Connectors\\Modular rectangular connectors",
            X_Description:
              "Compact connection with modular rectangular connectors \u2013 for use in devices, terminal boxes, and control cabinets",
            X_Description_SK:
              "Kompaktn\u00e9 pripojenie s modul\u00e1rnymi pravouhl\u00fdmi konektormi \u2013 pre pou\u017eitie v zariadeniach, svorkovnicov\u00fdch skrink\u00e1ch a ovl\u00e1dac\u00edch skriniach",
            items: [
              {
                id: "MYI0000101",
                parent_id: "LYI0000101",
                text: "Sleeve housings",
                X_fullName: "Sleeve housings",
                X_fullName_SK: "Puzdr\u00e1 ruk\u00e1vov",
                X_URL: "sleeve-housings",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Sleeve housings",
                X_Description:
                  "Sleeve housings made of plastic or cast aluminium for space-saving installation right on the housing wall.",
                X_Description_SK:
                  "Puzdr\u00e1 z plastu alebo hlin\u00edkovej zliatiny pre priestorovo \u00fasporn\u00fa in\u0161tal\u00e1ciu priamo na stenu puzdra.",
              },
              {
                id: "NYI0000101",
                parent_id: "LYI0000101",
                text: "Panel mounting base and coupling housing",
                X_fullName: "Panel mounting base and coupling housing",
                X_fullName_SK:
                  "Z\u00e1klad\u0148a pre mont\u00e1\u017e na panel a puzdro spojky",
                X_URL: "panel-mounting-base",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Panel mounting base and coupling housing",
                X_Description:
                  "Panel mounting bases and coupling housings allow you to implement plug-in connections on the device wall or free-hanging connections.",
                X_Description_SK:
                  "Panelov\u00e9 mont\u00e1\u017ene podstavce a kryty spojok v\u00e1m umo\u017e\u0148uj\u00fa realizova\u0165 z\u00e1suvn\u00e9 spojenia na stenu zariadenia alebo vo\u013ene visiace spojenia.",
              },
              {
                id: "OYI0000101",
                parent_id: "LYI0000101",
                text: "Contact inserts and contacts",
                X_fullName: "Contact inserts and contacts",
                X_fullName_SK: "Kontaktn\u00e9 vlo\u017eky a kontakty",
                X_URL: "mod-contact-inserts-and-contacts",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Contact inserts and contacts",
                X_Description:
                  "Thanks to modular contact inserts, it is possible to transmit signals, data and power in individual combinations in one connector.",
                X_Description_SK:
                  "V\u010faka modul\u00e1rnym kontaktn\u00fdm vlo\u017ek\u00e1m je mo\u017en\u00e9 pren\u00e1\u0161a\u0165 sign\u00e1ly, d\u00e1ta a nap\u00e1janie v jednotliv\u00fdch kombin\u00e1ci\u00e1ch v jednom konektore.",
              },
              {
                id: "PYI0000101",
                parent_id: "LYI0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "rectangular-conn-accessories",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Accessories",
                X_Description:
                  "Cable glands, adapter plates, protective covers and tools complete the modular connector range.",
                X_Description_SK:
                  "K\u00e1blov\u00e9 v\u00fdvodky, adapt\u00e9rov\u00e9 dosky, ochrann\u00e9 kryty a n\u00e1stroje dop\u013a\u0148aj\u00fa rad modul\u00e1rnych konektorov.",
              },
              {
                id: "RYI0000101",
                parent_id: "LYI0000101",
                text: "Hybrid connectors for motor connection",
                X_fullName: "Hybrid connectors for motor connection",
                X_fullName_SK: "Hybridn\u00e9 konektory na pripojenie motora",
                X_URL: "hybrid-conn-motor-connection",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Hybrid connectors for motor connection",
                X_Description:
                  "Hybrid connectors for quick, tool-free connection of motors.",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "SYI0000101",
            parent_id: "6XI0000101",
            text: "Installation systems",
            X_fullName: "Installation systems",
            X_fullName_SK: "In\u0161tala\u010dn\u00e9 syst\u00e9my",
            X_URL: "installation-systems",
            FULLPATH: "PhoenixContact\\Connectors\\Installation systems",
            X_Description:
              "A compact connector system with fast connection technology \u2013 for easy and secure installation.",
            X_Description_SK:
              "Kompaktn\u00fd konektorov\u00fd syst\u00e9m s technol\u00f3giou r\u00fdchleho pripojenia \u2013 pre jednoduch\u00fa a bezpe\u010dn\u00fa in\u0161tal\u00e1ciu.",
            items: [
              {
                id: "TYI0000101",
                parent_id: "SYI0000101",
                text: "QPD installation system",
                X_fullName: "QPD installation system",
                X_fullName_SK: "In\u0161tala\u010dn\u00fd syst\u00e9m QPD",
                X_URL: "qpd-installation-system",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Installation systems\\QPD installation system",
                X_Description:
                  "QPD installation system with shock-proof IDC fast connection technology.",
                X_Description_SK:
                  "In\u0161tala\u010dn\u00fd syst\u00e9m QPD s technol\u00f3giou r\u00fdchleho pripojenia IDC odolnou vo\u010di n\u00e1razom.",
              },
              {
                id: "UYI0000101",
                parent_id: "SYI0000101",
                text: "Accessories for QPD installation system",
                X_fullName: "Accessories for QPD installation system",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo pre in\u0161tala\u010dn\u00fd syst\u00e9m QPD",
                X_URL: "qpd-system-accessories",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Installation systems\\Accessories for QPD installation system",
                X_Description:
                  "Mounting and sealing material for the QPD installation system with IDC insulation displacement connection.",
                X_Description_SK:
                  "Mont\u00e1\u017eny a tesniaci materi\u00e1l pre in\u0161tala\u010dn\u00fd syst\u00e9m QPD so z\u00e1rezom IDC.",
              },
              {
                id: "VYI0000101",
                parent_id: "SYI0000101",
                text: "IPD installation system",
                X_fullName: "IPD installation system",
                X_fullName_SK: "In\u0161tala\u010dn\u00fd syst\u00e9m IPD",
                X_URL: "ipd-installation-system",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Installation systems\\IPD installation system",
                X_Description:
                  "IPD installation system panel feed-throughs with easy and time-saving Push-in connection",
                X_Description_SK:
                  "Panelov\u00e9 priechodky in\u0161tala\u010dn\u00e9ho syst\u00e9mu IPD s jednoduch\u00fdm a \u010dasovo \u00fasporn\u00fdm pripojen\u00edm Push-in",
              },
              {
                id: "WYI0000101",
                parent_id: "SYI0000101",
                text: "Accessories IPD installation system",
                X_fullName: "Accessories IPD installation system",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo In\u0161tala\u010dn\u00fd syst\u00e9m IPD",
                X_URL: "ipd-system-accessories",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Installation systems\\Accessories IPD installation system",
                X_Description: "",
                X_Description_SK: "",
              },
            ],
          },
        ],
      },
      {
        id: "XYI0000101",
        parent_id: "HFI0000101",
        text: "PLCs and I/O systems",
        X_fullName: "PLCs and I/O systems",
        X_fullName_SK: "PLC a I/O syst\u00e9my",
        X_URL: "plcs-and-io-systems",
        FULLPATH: "PhoenixContact\\PLCs and I/O systems",
        X_Description:
          "All small-scale controllers, PLCs, and I/O systems for any automation task.",
        X_Description_SK:
          "V\u0161etky mal\u00e9 riadiace jednotky, PLC a I/O syst\u00e9my pre ak\u00fako\u013evek automatiza\u010dn\u00fa \u00falohu.",
        items: [
          {
            id: "YYI0000101",
            parent_id: "XYI0000101",
            text: "Programmable logic controllers",
            X_fullName: "Programmable logic controllers",
            X_fullName_SK:
              "Programovate\u013en\u00e9 logick\u00e9 regul\u00e1tory",
            X_URL: "programmable-logic-controllers",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers",
            X_Description:
              "PLCs in all performance classes, software PLC, and PLC programming software",
            X_Description_SK:
              "PLC vo v\u0161etk\u00fdch v\u00fdkonnostn\u00fdch triedach, softv\u00e9rov\u00e9 PLC a programovac\u00ed softv\u00e9r PLC",
            items: [
              {
                id: "ZYI0000101",
                parent_id: "YYI0000101",
                text: "Axiocontrols",
                X_fullName: "Axiocontrols",
                X_fullName_SK: "Axiocontrols",
                X_URL: "axiocontrols",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers\\Axiocontrols",
                X_Description:
                  "Axiocontrol controllers with IEC 61131 programming for the Axioline F I/O system",
                X_Description_SK:
                  "Regul\u00e1tory Axiocontrol s programovan\u00edm IEC 61131 pre syst\u00e9m Axioline F I/O",
              },
              {
                id: "0ZI0000101",
                parent_id: "YYI0000101",
                text: "Inline controllers",
                X_fullName: "Inline controllers",
                X_fullName_SK: "Radov\u00e9 ovl\u00e1da\u010de",
                X_URL: "inline-controllers",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers\\Inline controllers",
                X_Description:
                  "Inline controllers with IEC 61131 programming for the Inline I/O system",
                X_Description_SK:
                  "Inline regul\u00e1tory s programovan\u00edm IEC 61131 pre Inline I/O syst\u00e9m",
              },
              {
                id: "1ZI0000101",
                parent_id: "YYI0000101",
                text: "High-performance PLCs",
                X_fullName: "High-performance PLCs",
                X_fullName_SK: "Vysoko v\u00fdkonn\u00e9 PLC",
                X_URL: "high-performance-plcs",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers\\High-performance PLCs",
                X_Description:
                  "High-performance PLCs for PROFINET and Modbus/TCP",
                X_Description_SK:
                  "Vysokov\u00fdkonn\u00e9 PLC pre PROFINET a Modbus/TCP",
              },
              {
                id: "2ZI0000101",
                parent_id: "YYI0000101",
                text: "Software PLC",
                X_fullName: "Software PLC",
                X_fullName_SK: "Softv\u00e9r PLC",
                X_URL: "software-plc",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers\\Software PLC",
                X_Description: "Software PLCS for use on IPCs and PCs",
                X_Description_SK:
                  "Softv\u00e9rov\u00e9 PLCS pre pou\u017eitie na IPC a PC",
              },
            ],
          },
          {
            id: "3ZI0000101",
            parent_id: "XYI0000101",
            text: "Programmable logic relay system",
            X_fullName: "Programmable logic relay system",
            X_fullName_SK:
              "Programovate\u013en\u00fd logick\u00fd rel\u00e9ov\u00fd syst\u00e9m",
            X_URL: "programmable-logic-relay-system",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system",
            X_Description:
              "Carry out smaller automation tasks with the programmable logic relay system from Phoenix Contact.",
            X_Description_SK:
              "Vykon\u00e1vajte men\u0161ie automatiza\u010dn\u00e9 \u00falohy so syst\u00e9mom programovate\u013en\u00fdch logick\u00fdch rel\u00e9 od spolo\u010dnosti Phoenix Contact.",
            items: [
              {
                id: "4ZI0000101",
                parent_id: "3ZI0000101",
                text: "Logic modules",
                X_fullName: "Logic modules",
                X_fullName_SK: "Logick\u00e9 moduly",
                X_URL: "logic-modules",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system\\Logic modules",
                X_Description:
                  "Smaller automation tasks can be carried out with the programmable logic modules.",
                X_Description_SK:
                  "Men\u0161ie automatiza\u010dn\u00e9 \u00falohy je mo\u017en\u00e9 vykon\u00e1va\u0165 pomocou programovate\u013en\u00fdch logick\u00fdch modulov.",
              },
              {
                id: "5ZI0000101",
                parent_id: "3ZI0000101",
                text: "Compatible relay modules",
                X_fullName: "Compatible relay modules",
                X_fullName_SK: "Kompatibiln\u00e9 rel\u00e9ov\u00e9 moduly",
                X_URL: "compatible-relay-modules",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system\\Compatible relay modules",
                X_Description:
                  "Compatible relays for the programmable logic module.",
                X_Description_SK:
                  "Kompatibiln\u00e9 rel\u00e9 pre programovate\u013en\u00fd logick\u00fd modul.",
              },
              {
                id: "6ZI0000101",
                parent_id: "3ZI0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "logic-relay-accessories",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system\\Accessories",
                X_Description:
                  "Numerous accessories for the programmable logic relay system.",
                X_Description_SK:
                  "Po\u010detn\u00e9 pr\u00edslu\u0161enstvo pre syst\u00e9m programovate\u013en\u00fdch logick\u00fdch rel\u00e9.",
              },
              {
                id: "7ZI0000101",
                parent_id: "3ZI0000101",
                text: "Compatible analog modules",
                X_fullName: "Compatible analog modules",
                X_fullName_SK: "Kompatibiln\u00e9 anal\u00f3gov\u00e9 moduly",
                X_URL: "compatible-analog-modules",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system\\Compatible analog modules",
                X_Description:
                  "Modules for processing analog input and output signals.",
                X_Description_SK:
                  "Moduly na spracovanie anal\u00f3gov\u00fdch vstupn\u00fdch a v\u00fdstupn\u00fdch sign\u00e1lov.",
              },
            ],
          },
          {
            id: "8ZI0000101",
            parent_id: "XYI0000101",
            text: "PLCs for building infrastructure",
            X_fullName: "PLCs for building infrastructure",
            X_fullName_SK: "PLC pre infra\u0161trukt\u00faru budov",
            X_URL: "plcs-for-building-infrastructure",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\PLCs for building infrastructure",
            X_Description:
              "Automate different subsections in building infrastructure, data centers, and distributed properties using the ILC 2050 BI Inline controller.",
            X_Description_SK:
              "Automatizujte r\u00f4zne podsekcie v infra\u0161trukt\u00fare budov, d\u00e1tov\u00fdch centr\u00e1ch a distribuovan\u00fdch nehnute\u013enostiach pomocou kontrol\u00e9ra ILC 2050 BI Inline.",
            items: [
              {
                id: "9ZI0000101",
                parent_id: "8ZI0000101",
                text: "PLCs and licenses for building infrastructure",
                X_fullName: "PLCs and licenses for building infrastructure",
                X_fullName_SK:
                  "PLC a licencie pre infra\u0161trukt\u00faru budov",
                X_URL: "building-infrastructure-plcs-lic",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\PLCs for building infrastructure\\PLCs and licenses for building infrastructure",
                X_Description:
                  "ILC 2050 BI Inline controller and licenses for automating different utilities in building infrastructure",
                X_Description_SK:
                  "ILC 2050 BI Inline radi\u010d a licencie na automatiz\u00e1ciu r\u00f4znych n\u00e1strojov v infra\u0161trukt\u00fare budov",
              },
            ],
          },
          {
            id: "AZI0000101",
            parent_id: "XYI0000101",
            text: "I/O systems for the control cabinet (IP20)",
            X_fullName: "I/O systems for the control cabinet (IP20)",
            X_fullName_SK: "I/O syst\u00e9my pre rozv\u00e1dza\u010d (IP20)",
            X_URL: "io-sys-for-the-control-cabinet",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)",
            X_Description:
              "I/O devices for installation in the control cabinet with a modular or block design",
            X_Description_SK:
              "I/O zariadenia na in\u0161tal\u00e1ciu do rozv\u00e1dza\u010da s modul\u00e1rnym alebo blokov\u00fdm dizajnom",
            items: [
              {
                id: "BZI0000101",
                parent_id: "AZI0000101",
                text: "Axioline F \u2013 the block-based I/O system",
                X_fullName: "Axioline F \u2013 the block-based I/O system",
                X_fullName_SK: "Axioline F - blokov\u00fd I/O syst\u00e9m",
                X_URL: "axioline-f-the-block-based-io",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\Axioline F \u2013 the block-based I/O system",
                X_Description:
                  "The fast, easy, and robust I/O system with high signal density",
                X_Description_SK:
                  "R\u00fdchly, jednoduch\u00fd a robustn\u00fd I/O syst\u00e9m s vysokou hustotou sign\u00e1lu",
                items: [
                  {
                    id: "CZI0000101",
                    parent_id: "BZI0000101",
                    text: "Accessories for Axioline\u00a0F",
                    X_fullName: "Accessories for Axioline\u00a0F",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo pre Axioline F",
                    X_URL: "accessories-for-axioline-f",
                    FULLPATH:
                      "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\Axioline F \u2013 the block-based I/O system\\Accessories for Axioline\u00a0F",
                    X_Description:
                      "Accessories for installing and operating the Axioline F I/O system",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo pre in\u0161tal\u00e1ciu a prev\u00e1dzku syst\u00e9mu Axioline F I/O",
                  },
                ],
              },
              {
                id: "DZI0000101",
                parent_id: "AZI0000101",
                text: "Inline \u2013 the highly modular I/O system",
                X_fullName: "Inline \u2013 the highly modular I/O system",
                X_fullName_SK: "Inline - vysoko modul\u00e1rny I/O syst\u00e9m",
                X_URL: "inline-io-system",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\Inline \u2013 the highly modular I/O system",
                X_Description:
                  "Inline \u2013 the particularly flexible I/O system with fine granularity",
                X_Description_SK:
                  "Inline - obzvl\u00e1\u0161\u0165 flexibiln\u00fd I/O syst\u00e9m s jemnou granularitou",
                items: [
                  {
                    id: "EZI0000101",
                    parent_id: "DZI0000101",
                    text: "Accessories for Inline",
                    X_fullName: "Accessories for Inline",
                    X_fullName_SK: "Pr\u00edslu\u0161enstvo pre inline",
                    X_URL: "accessories-for-inline",
                    FULLPATH:
                      "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\Inline \u2013 the highly modular I/O system\\Accessories for Inline",
                    X_Description:
                      "Accessories for the installation and operation of the Inline I/O system",
                    X_Description_SK:
                      "Pr\u00edslu\u0161enstvo pre in\u0161tal\u00e1ciu a prev\u00e1dzku Inline I/O syst\u00e9mu",
                  },
                ],
              },
              {
                id: "FZI0000101",
                parent_id: "AZI0000101",
                text: "I/O systems for field installation (IP65/IP67)",
                X_fullName: "I/O systems for field installation (IP65/IP67)",
                X_fullName_SK:
                  "I/O syst\u00e9my na in\u0161tal\u00e1ciu v ter\u00e9ne (IP65/IP67)",
                X_URL: "io-systems",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\I/O systems for field installation (IP65/IP67)",
                X_Description:
                  "I/O devices for installation directly in the field with a modular or block design",
                X_Description_SK:
                  "I/O zariadenia pre in\u0161tal\u00e1ciu priamo v prev\u00e1dzke s modul\u00e1rnym alebo blokov\u00fdm dizajnom",
                items: [
                  {
                    id: "GZI0000101",
                    parent_id: "FZI0000101",
                    text: "Axioline\u00a0E \u2013 the block-based modular I/O system",
                    X_fullName:
                      "Axioline\u00a0E \u2013 the block-based modular I/O system",
                    X_fullName_SK:
                      "Axioline E - blokov\u00fd modul\u00e1rny I/O syst\u00e9m",
                    X_URL: "axioline-e",
                    FULLPATH:
                      "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\I/O systems for field installation (IP65/IP67)\\Axioline\u00a0E \u2013 the block-based modular I/O system",
                    X_Description:
                      "Axioline E \u2013 the fast, easy, and robust I/O system",
                    X_Description_SK:
                      "Axioline E \u2013 r\u00fdchly, jednoduch\u00fd a robustn\u00fd I/O syst\u00e9m",
                    items: [
                      {
                        id: "HZI0000101",
                        parent_id: "GZI0000101",
                        text: "Accessories for Axioline\u00a0E",
                        X_fullName: "Accessories for Axioline\u00a0E",
                        X_fullName_SK: "Pr\u00edslu\u0161enstvo pre Axioline E",
                        X_URL: "axioline-e-accessories",
                        FULLPATH:
                          "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\I/O systems for field installation (IP65/IP67)\\Axioline\u00a0E \u2013 the block-based modular I/O system\\Accessories for Axioline\u00a0E",
                        X_Description:
                          "Accessories for the installation and operation of the Axioline E I/O system.",
                        X_Description_SK:
                          "Pr\u00edslu\u0161enstvo pre in\u0161tal\u00e1ciu a prev\u00e1dzku syst\u00e9mu Axioline E I/O.",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: "IZI0000101",
            parent_id: "XYI0000101",
            text: "Components for SPS and I/O systems",
            X_fullName: "Components for SPS and I/O systems",
            X_fullName_SK: "Komponenty pre syst\u00e9my SPS a I/O",
            X_URL: "sps-io-components",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\Components for SPS and I/O systems",
            X_Description:
              "Components for PLC and I/O systems, as well as accessories for automation.",
            X_Description_SK:
              "Komponenty pre syst\u00e9my PLC a I/O, ako aj pr\u00edslu\u0161enstvo pre automatiz\u00e1ciu.",
          },
          {
            id: "JZI0000101",
            parent_id: "XYI0000101",
            text: "PLCnext Control devices",
            X_fullName: "PLCnext Control devices",
            X_fullName_SK: "PLCnext Riadiace zariadenia",
            X_URL: "plcnext-control-devices",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\PLCnext Control devices",
            X_Description:
              "Open PLCs with PLCnext Technology for high-level language programming, PLC programming software, and apps in the PLCnext Store.",
            X_Description_SK:
              "Otv\u00e1rajte PLC pomocou technol\u00f3gie PLCnext pre programovanie jazykov na vysokej \u00farovni, programovac\u00ed softv\u00e9r PLC a aplik\u00e1cie v obchode PLCnext Store.",
            items: [
              {
                id: "KZI0000101",
                parent_id: "JZI0000101",
                text: "PLCnext Technology components",
                X_fullName: "PLCnext Technology components",
                X_fullName_SK: "Komponenty technol\u00f3gie PLCnext",
                X_URL: "plcnext-technology-components",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\PLCnext Control devices\\PLCnext Technology components",
                X_Description:
                  "Components for the new PLCnext Technology open control platform",
                X_Description_SK:
                  "Komponenty pre nov\u00fa otvoren\u00fa riadiacu platformu PLCnext Technology",
              },
            ],
          },
        ],
      },
      {
        id: "LZI0000101",
        parent_id: "HFI0000101",
        text: "Power supply units and UPS",
        X_fullName: "Power supply units and UPS",
        X_fullName_SK: "Nap\u00e1jacie zdroje a UPS",
        X_URL: "power-supply-units-and-ups",
        FULLPATH: "PhoenixContact\\Power supply units and UPS",
        X_Description:
          "Do you want to run your system without interruption? Then you can fully rely on our power supplies to do the job.",
        X_Description_SK:
          "Chcete spusti\u0165 syst\u00e9m bez preru\u0161enia? Potom sa m\u00f4\u017eete plne spo\u013eahn\u00fa\u0165 na na\u0161e nap\u00e1jacie zdroje.",
        items: [
          {
            id: "MZI0000101",
            parent_id: "LZI0000101",
            text: "Redundancy modules",
            X_fullName: "Redundancy modules",
            X_fullName_SK: "Redundantn\u00e9 moduly",
            X_URL: "redundancy-modules",
            FULLPATH:
              "PhoenixContact\\Power supply units and UPS\\Redundancy modules",
            X_Description:
              "Decoupling, monitoring, and controlling redundancy modules. For maximized availability of power supplies operated in parallel.",
            X_Description_SK:
              "Odde\u013eovanie, monitorovanie a riadenie redundantn\u00fdch modulov. Pre maxim\u00e1lnu dostupnos\u0165 paralelne prev\u00e1dzkovan\u00fdch nap\u00e1jac\u00edch zdrojov.",
            items: [
              {
                id: "NZI0000101",
                parent_id: "MZI0000101",
                text: "Active redundancy modules",
                X_fullName: "Active redundancy modules",
                X_fullName_SK: "Moduly akt\u00edvnej redundancie",
                X_URL: "active-redundancy-modules",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Active redundancy modules",
                X_Description:
                  "ORING modules with ACB technology provide the best possible protection for power supplies \u2014 for superior system availability.",
                X_Description_SK:
                  "Moduly ORING s technol\u00f3giou ACB poskytuj\u00fa najlep\u0161iu mo\u017en\u00fa ochranu nap\u00e1jac\u00edch zdrojov \u2014 pre vynikaj\u00facu dostupnos\u0165 syst\u00e9mu.",
                items: [
                  {
                    id: "OZI0000101",
                    parent_id: "NZI0000101",
                    text: "QUINT ORING",
                    X_fullName: "QUINT ORING",
                    X_fullName_SK: "QUINT ORING",
                    X_URL: "qunit-oring",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Active redundancy modules\\QUINT ORING",
                    X_Description:
                      "QUINT ORING modules with ACB technology double the service life of your redundant power supply units.",
                    X_Description_SK:
                      "Moduly QUINT ORING s technol\u00f3giou ACB zdvojn\u00e1sobia \u017eivotnos\u0165 va\u0161ich redundantn\u00fdch nap\u00e1jac\u00edch jednotiek.",
                  },
                ],
              },
              {
                id: "PZI0000101",
                parent_id: "MZI0000101",
                text: "Diode modules",
                X_fullName: "Diode modules",
                X_fullName_SK: "Di\u00f3dov\u00e9 moduly",
                X_URL: "ups-diode-modules",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Diode modules",
                X_Description:
                  "Diode modules decouple power supplies that are operated in parallel: a short circuit no longer affects the load.",
                X_Description_SK:
                  "Di\u00f3dov\u00e9 moduly odde\u013euj\u00fa paralelne prev\u00e1dzkovan\u00e9 nap\u00e1jacie zdroje: skrat u\u017e neovplyv\u0148uje z\u00e1\u0165a\u017e.",
                items: [
                  {
                    id: "QZI0000101",
                    parent_id: "PZI0000101",
                    text: "QUINT DIODE",
                    X_fullName: "QUINT DIODE",
                    X_fullName_SK: "QUINT DI\u00d3DA",
                    X_URL: "qunit-diode",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Diode modules\\QUINT DIODE",
                    X_Description:
                      "QUINT DIODE diode modules feature full redundancy up to the consumer and increase system availability even under demanding ambient conditions.",
                    X_Description_SK:
                      "Di\u00f3dov\u00e9 moduly QUINT DIODE sa vyzna\u010duj\u00fa plnou redundanciou a\u017e po spotrebite\u013ea a zvy\u0161uj\u00fa dostupnos\u0165 syst\u00e9mu aj v n\u00e1ro\u010dn\u00fdch okolit\u00fdch podmienkach.",
                  },
                  {
                    id: "RZI0000101",
                    parent_id: "PZI0000101",
                    text: "TRIO DIODE",
                    X_fullName: "TRIO DIODE",
                    X_fullName_SK: "TRIO DI\u00d3DA",
                    X_URL: "trio-diode",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Diode modules\\TRIO DIODE",
                    X_Description:
                      "TRIO DIODE redundancy modules permanently monitor redundancy from the output voltage to the redundancy module.",
                    X_Description_SK:
                      "Redundantn\u00e9 moduly TRIO DIODE permanentne monitoruj\u00fa redundanciu od v\u00fdstupn\u00e9ho nap\u00e4tia po redundantn\u00fd modul.",
                  },
                  {
                    id: "SZI0000101",
                    parent_id: "PZI0000101",
                    text: "STEP / UNO DIODE",
                    X_fullName: "STEP / UNO DIODE",
                    X_fullName_SK: "STEP / UNO DIODOV\u00dd MODUL",
                    X_URL: "step-uno-diode",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Diode modules\\STEP / UNO DIODE",
                    X_Description:
                      "STEP / UNO DIODE narrow diode modules decouple power supply units that are connected in parallel and provide 100% redundancy.",
                    X_Description_SK:
                      "\u00dazke di\u00f3dov\u00e9 moduly STEP / UNO DIODE odde\u013euj\u00fa paralelne zapojen\u00e9 nap\u00e1jacie jednotky a poskytuj\u00fa 100% redundanciu.",
                  },
                ],
              },
            ],
          },
          {
            id: "TZI0000101",
            parent_id: "LZI0000101",
            text: "Power supply units",
            X_fullName: "Power supply units",
            X_fullName_SK: "Nap\u00e1jacie zdroje",
            X_URL: "power-supply-units",
            FULLPATH:
              "PhoenixContact\\Power supply units and UPS\\Power supply units",
            X_Description:
              "Maximize the availability of your systems with high-quality power supplies featuring leading technology, optimized for superior functionality and performance.",
            X_Description_SK:
              "Maximalizujte dostupnos\u0165 svojich syst\u00e9mov pomocou vysokokvalitn\u00fdch nap\u00e1jac\u00edch zdrojov s poprednou technol\u00f3giou, optimalizovan\u00fdch pre vynikaj\u00facu funk\u010dnos\u0165 a v\u00fdkon.",
            items: [
              {
                id: "UZI0000101",
                parent_id: "TZI0000101",
                text: "Power supplies with maximum functionality",
                X_fullName: "Power supplies with maximum functionality",
                X_fullName_SK:
                  "Nap\u00e1jacie zdroje s maxim\u00e1lnou funk\u010dnos\u0165ou",
                X_URL: "ps-with-maximum-functionality",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with maximum functionality",
                X_Description:
                  "The QUINT POWER power supplies with SFB Technology feature the highest level of functionality for maximum system availability.",
                X_Description_SK:
                  "Nap\u00e1jacie zdroje QUINT POWER s technol\u00f3giou SFB sa vyzna\u010duj\u00fa najvy\u0161\u0161ou \u00farov\u0148ou funk\u010dnosti pre maxim\u00e1lnu dostupnos\u0165 syst\u00e9mu.",
                items: [
                  {
                    id: "VZI0000101",
                    parent_id: "UZI0000101",
                    text: "QUINT POWER",
                    X_fullName: "QUINT POWER",
                    X_fullName_SK: "QUINT POWER",
                    X_URL: "psu-qunit-power",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with maximum functionality\\QUINT POWER",
                    X_Description:
                      "QUINT POWER with SFB technology and an NFC interface increases the availability of systems and can be adapted on an individual basis.",
                    X_Description_SK:
                      "QUINT POWER s technol\u00f3giou SFB a rozhran\u00edm NFC zvy\u0161uje dostupnos\u0165 syst\u00e9mov a je mo\u017en\u00e9 ho individu\u00e1lne prisp\u00f4sobi\u0165.",
                  },
                  {
                    id: "WZI0000101",
                    parent_id: "UZI0000101",
                    text: "QUINT POWER for extreme conditions",
                    X_fullName: "QUINT POWER for extreme conditions",
                    X_fullName_SK: "QUINT POWER pre extr\u00e9mne podmienky",
                    X_URL: "qunit-power-extreme-conditions",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with maximum functionality\\QUINT POWER for extreme conditions",
                    X_Description:
                      "QUINT POWER with protective coating for extreme environmental conditions, such as 100% humidity.",
                    X_Description_SK:
                      "QUINT POWER s ochrann\u00fdm n\u00e1terom pre extr\u00e9mne podmienky prostredia, ako je 100% vlhkos\u0165.",
                  },
                ],
              },
              {
                id: "XZI0000101",
                parent_id: "TZI0000101",
                text: "Power supplies with standard functionality",
                X_fullName: "Power supplies with standard functionality",
                X_fullName_SK:
                  "Nap\u00e1jacie zdroje so \u0161tandardn\u00fdmi funkciami",
                X_URL: "standard-power-supplies",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with standard functionality",
                X_Description:
                  "The TRIO POWER power supplies with standard functionality are particularly robust and therefore ideal for use in machine building.",
                X_Description_SK:
                  "Nap\u00e1jacie zdroje TRIO POWER so \u0161tandardnou funkcionalitou s\u00fa obzvl\u00e1\u0161\u0165 robustn\u00e9 a preto ide\u00e1lne pre pou\u017eitie v stroj\u00e1rstve.",
                items: [
                  {
                    id: "YZI0000101",
                    parent_id: "XZI0000101",
                    text: "TRIO POWER with screw connection",
                    X_fullName: "TRIO POWER with screw connection",
                    X_fullName_SK:
                      "TRIO POWER so skrutkov\u00fdm pripojen\u00edm",
                    X_URL: "trio-power-with-screw-connection",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with standard functionality\\TRIO POWER with screw connection",
                    X_Description:
                      "The functions and the space-saving design of the robust power supplies are tailored to machine building requirements.",
                    X_Description_SK:
                      "Funkcie a priestorovo \u00fasporn\u00fd dizajn robustn\u00fdch nap\u00e1jac\u00edch zdrojov s\u00fa prisp\u00f4soben\u00e9 po\u017eiadavk\u00e1m stroj\u00e1rskej v\u00fdroby.",
                  },
                  {
                    id: "ZZI0000101",
                    parent_id: "XZI0000101",
                    text: "TRIO POWER with push-in connection",
                    X_fullName: "TRIO POWER with push-in connection",
                    X_fullName_SK: "TRIO POWER s pripojen\u00edm push-in",
                    X_URL: "push-in-trio-power",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with standard functionality\\TRIO POWER with push-in connection",
                    X_Description:
                      "The TRIO POWER range of power supplies with push-in connection. Extremely robust and therefore perfectly suited for machine building.",
                    X_Description_SK:
                      "Rad nap\u00e1jac\u00edch zdrojov TRIO POWER s n\u00e1str\u010dn\u00fdm pripojen\u00edm. Extr\u00e9mne robustn\u00fd, a preto sa dokonale hod\u00ed na v\u00fdrobu strojov.",
                  },
                  {
                    id: "00J0000101",
                    parent_id: "XZI0000101",
                    text: "TRIO CROSS POWER",
                    X_fullName: "TRIO CROSS POWER",
                    X_fullName_SK: "TRIO CROSS POWER",
                    X_URL: "trio-cross-power",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with standard functionality\\TRIO CROSS POWER",
                    X_Description:
                      "The power supply for the CrossPowerSystem power distribution board is perfectly suited for use in machine building.",
                    X_Description_SK:
                      "Nap\u00e1jac\u00ed zdroj pre rozvodn\u00fa dosku CrossPowerSystem sa dokonale hod\u00ed na pou\u017eitie v stroj\u00e1rstve.",
                  },
                ],
              },
              {
                id: "10J0000101",
                parent_id: "TZI0000101",
                text: "Power supply units for installation distributors",
                X_fullName: "Power supply units for installation distributors",
                X_fullName_SK:
                  "Nap\u00e1jacie zdroje pre in\u0161tala\u010dn\u00e9 rozv\u00e1dza\u010de",
                X_URL: "psu-for-installation-distributor",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supply units for installation distributors",
                X_Description:
                  "With their flat design, STEP POWER power supplies are particularly suitable for building automation.",
                X_Description_SK:
                  "Nap\u00e1jacie zdroje STEP POWER s\u00fa svoj\u00edm ploch\u00fdm dizajnom vhodn\u00e9 najm\u00e4 pre automatiz\u00e1ciu budov.",
                items: [
                  {
                    id: "20J0000101",
                    parent_id: "10J0000101",
                    text: "STEP POWER",
                    X_fullName: "STEP POWER",
                    X_fullName_SK: "STEP POWER",
                    X_URL: "step-power",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supply units for installation distributors\\STEP POWER",
                    X_Description:
                      "STEP POWER features a flat design and offers maximum energy efficiency \u2013 this makes it ideal for use in building automation.",
                    X_Description_SK:
                      "STEP POWER m\u00e1 ploch\u00fd dizajn a pon\u00faka maxim\u00e1lnu energetick\u00fa \u00fa\u010dinnos\u0165 \u2013 v\u010faka tomu je ide\u00e1lny na pou\u017eitie v automatiz\u00e1cii budov.",
                  },
                ],
              },
              {
                id: "30J0000101",
                parent_id: "TZI0000101",
                text: "Power supplies with basic functionality",
                X_fullName: "Power supplies with basic functionality",
                X_fullName_SK:
                  "Nap\u00e1jacie zdroje so z\u00e1kladn\u00fdmi funkciami",
                X_URL: "psu-with-basic-functionality",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with basic functionality",
                X_Description:
                  "Power supplies with basic functionality are the perfect solution for compact control boxes.",
                X_Description_SK:
                  "Nap\u00e1jacie zdroje so z\u00e1kladnou funkcionalitou s\u00fa dokonal\u00fdm rie\u0161en\u00edm pre kompaktn\u00e9 rozv\u00e1dza\u010de.",
                items: [
                  {
                    id: "40J0000101",
                    parent_id: "30J0000101",
                    text: "UNO POWER",
                    X_fullName: "UNO POWER",
                    X_fullName_SK: "UNO POWER",
                    X_URL: "psu-uno-power",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with basic functionality\\UNO POWER",
                    X_Description:
                      "UNO POWER power supply units offer the ideal solution for loads up to 240 W in compact control boxes.",
                    X_Description_SK:
                      "Nap\u00e1jacie zdroje UNO POWER pon\u00fakaj\u00fa ide\u00e1lne rie\u0161enie pre z\u00e1\u0165a\u017ee do 240 W v kompaktn\u00fdch rozv\u00e1dza\u010doch.",
                  },
                ],
              },
            ],
          },
          {
            id: "50J0000101",
            parent_id: "LZI0000101",
            text: "DC/DC converters",
            X_fullName: "DC/DC converters",
            X_fullName_SK: "DC/DC meni\u010de",
            X_URL: "dc-dc-converters",
            FULLPATH:
              "PhoenixContact\\Power supply units and UPS\\DC/DC converters",
            X_Description:
              "DC/DC converters alter the voltage level, regenerate the voltage at the end of long cables, or enable the creation of independent supply systems.",
            X_Description_SK:
              "DC/DC meni\u010de menia \u00farove\u0148 nap\u00e4tia, regeneruj\u00fa nap\u00e4tie na konci dlh\u00fdch k\u00e1blov alebo umo\u017e\u0148uj\u00fa vytvorenie nez\u00e1visl\u00fdch nap\u00e1jac\u00edch syst\u00e9mov.",
            items: [
              {
                id: "60J0000101",
                parent_id: "50J0000101",
                text: "QUINT POWER",
                X_fullName: "QUINT POWER",
                X_fullName_SK: "QUINT POWER",
                X_URL: "qunit-power",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\DC/DC converters\\QUINT POWER",
                X_Description:
                  "QUINT DC/DC converters trip standard circuit breakers quickly and reliably \u2013 thanks to SFB technology.",
                X_Description_SK:
                  "DC/DC meni\u010de QUINT vyp\u00ednaj\u00fa \u0161tandardn\u00e9 isti\u010de r\u00fdchlo a spo\u013eahlivo \u2013 v\u010faka technol\u00f3gii SFB.",
              },
              {
                id: "70J0000101",
                parent_id: "50J0000101",
                text: "MINI POWER",
                X_fullName: "MINI POWER",
                X_fullName_SK: "MINI POWER",
                X_URL: "mini-power",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\DC/DC converters\\MINI POWER",
                X_Description:
                  "MINI DC/DC converters support conversion to various voltage levels.",
                X_Description_SK:
                  "MINI DC/DC meni\u010de podporuj\u00fa konverziu na r\u00f4zne nap\u00e4\u0165ov\u00e9 \u00farovne.",
              },
              {
                id: "80J0000101",
                parent_id: "50J0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "dc-dc-accessories",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\DC/DC converters\\Accessories",
                X_Description:
                  "The AC power terminal block supplies MINI DC/DC converters with rectified and filtered voltage.",
                X_Description_SK:
                  "AC nap\u00e1jacia svorkovnica nap\u00e1ja MINI DC/DC meni\u010de usmernen\u00fdm a filtrovan\u00fdm nap\u00e4t\u00edm.",
              },
              {
                id: "90J0000101",
                parent_id: "50J0000101",
                text: "UNO POWER",
                X_fullName: "UNO POWER",
                X_fullName_SK: "UNO POWER",
                X_URL: "uno-power",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\DC/DC converters\\UNO POWER",
                X_Description:
                  "UNO POWER DC/DC converters supply the control cabinet directly from the photovoltaic system.",
                X_Description_SK:
                  "DC/DC meni\u010de UNO POWER nap\u00e1jaj\u00fa rozv\u00e1dza\u010d priamo z fotovoltaick\u00e9ho syst\u00e9mu.",
              },
            ],
          },
          {
            id: "A0J0000101",
            parent_id: "LZI0000101",
            text: "Uninterruptible power supply units",
            X_fullName: "Uninterruptible power supply units",
            X_fullName_SK:
              "Zdroje nepreru\u0161ite\u013en\u00e9ho nap\u00e1jania",
            X_URL: "uninterruptible-psu",
            FULLPATH:
              "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units",
            X_Description:
              "Uninterruptible power supplies for DC and AC loads ensure optimum system availability with tailor-made solutions.",
            X_Description_SK:
              "Nepreru\u0161ite\u013en\u00e9 zdroje nap\u00e1jania pre jednosmern\u00e9 a striedav\u00e9 z\u00e1\u0165a\u017ee zais\u0165uj\u00fa optim\u00e1lnu dostupnos\u0165 syst\u00e9mu s rie\u0161eniami \u0161it\u00fdmi na mieru.",
            items: [
              {
                id: "B0J0000101",
                parent_id: "A0J0000101",
                text: "Buffer modules",
                X_fullName: "Buffer modules",
                X_fullName_SK: "Vyrovn\u00e1vacie moduly",
                X_URL: "buffer-modules",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\Buffer modules",
                X_Description:
                  "Buffer modules store energy in capacitors and bridge mains failures lasting several seconds.",
                X_Description_SK:
                  "Vyrovn\u00e1vacie moduly uchov\u00e1vaj\u00fa energiu v kondenz\u00e1toroch a premos\u0165uj\u00fa v\u00fdpadky siete trvaj\u00face nieko\u013eko sek\u00fand.",
                items: [
                  {
                    id: "C0J0000101",
                    parent_id: "B0J0000101",
                    text: "QUINT BUFFER",
                    X_fullName: "QUINT BUFFER",
                    X_fullName_SK: "QUINT BUFFER",
                    X_URL: "qunit-buffer",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\Buffer modules\\QUINT BUFFER",
                    X_Description:
                      "The QUINT BUFFER buffer module works with maintenance-free capacitors in the case of failures lasting several seconds.",
                    X_Description_SK:
                      "Z\u00e1lo\u017en\u00fd modul QUINT BUFFER pracuje s bez\u00fadr\u017ebov\u00fdmi kondenz\u00e1tormi v pr\u00edpade por\u00fach trvaj\u00facich nieko\u013eko sek\u00fand.",
                  },
                  {
                    id: "D0J0000101",
                    parent_id: "B0J0000101",
                    text: "QUINT CAP",
                    X_fullName: "QUINT CAP",
                    X_fullName_SK: "QUINT CAP",
                    X_URL: "qunit-cap",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\Buffer modules\\QUINT CAP",
                    X_Description:
                      "QUINT CAP buffer modules bridge failures lasting up to 30 seconds.",
                    X_Description_SK:
                      "Vyrovn\u00e1vacie moduly QUINT CAP premos\u0165uj\u00fa zlyhania trvaj\u00face a\u017e 30 sek\u00fand.",
                  },
                ],
              },
              {
                id: "E0J0000101",
                parent_id: "A0J0000101",
                text: "USV for DC applications",
                X_fullName: "USV for DC applications",
                X_fullName_SK: "USV pre aplik\u00e1cie DC",
                X_URL: "usv-dc",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications",
                X_Description:
                  "Uninterruptible power supplies for DC applications continue to deliver power even in the event of mains failure. Select your appropriate UPS solution.",
                X_Description_SK:
                  "Nepreru\u0161ite\u013en\u00e9 nap\u00e1jacie zdroje pre DC aplik\u00e1cie na\u010falej dod\u00e1vaj\u00fa energiu aj v pr\u00edpade v\u00fdpadku siete. Vyberte si vhodn\u00e9 rie\u0161enie UPS.",
                items: [
                  {
                    id: "F0J0000101",
                    parent_id: "E0J0000101",
                    text: "DC UPS with IQ technology",
                    X_fullName: "DC UPS with IQ technology",
                    X_fullName_SK: "DC UPS s technol\u00f3giou IQ",
                    X_URL: "dc-ups-iq",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC UPS with IQ technology",
                    X_Description:
                      "The intelligent DC UPS with IQ Technology ensures superior system availability. Monitor and optimize your energy storage with the QUINT DC UPS.",
                    X_Description_SK:
                      "Inteligentn\u00fd DC UPS s technol\u00f3giou IQ zais\u0165uje vynikaj\u00facu dostupnos\u0165 syst\u00e9mu. Monitorujte a optimalizujte svoje ukladanie energie pomocou Quint DC UPS.",
                    items: [
                      {
                        id: "G0J0000101",
                        parent_id: "F0J0000101",
                        text: "QUINT DC-UPS",
                        X_fullName: "QUINT DC-UPS",
                        X_fullName_SK: "QUINT DC-UPS",
                        X_URL: "qunit-dc-ups",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC UPS with IQ technology\\QUINT DC-UPS",
                        X_Description:
                          "QUINT DC-UPS, the uninterruptible power supply unit for DC applications, ensures maximum system availability.",
                        X_Description_SK:
                          "QUINT DC-UPS, nepreru\u0161ite\u013en\u00fd zdroj nap\u00e1jania pre DC aplik\u00e1cie, zais\u0165uje maxim\u00e1lnu dostupnos\u0165 syst\u00e9mu.",
                      },
                    ],
                  },
                  {
                    id: "H0J0000101",
                    parent_id: "E0J0000101",
                    text: "DC-USV module with integrated power supply unit",
                    X_fullName:
                      "DC-USV module with integrated power supply unit",
                    X_fullName_SK:
                      "Modul DC-USV s integrovanou nap\u00e1jacou jednotkou",
                    X_URL: "dc-usv-with-power-supply-unit",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-USV module with integrated power supply unit",
                    X_Description:
                      "UPS with integrated power supply, in an ultra-slim design, combines a power supply and electronic switch-over unit.",
                    X_Description_SK:
                      "UPS s integrovan\u00fdm nap\u00e1jac\u00edm zdrojom v ultratenkom preveden\u00ed kombinuje nap\u00e1jac\u00ed zdroj a elektronick\u00fa prep\u00ednaciu jednotku.",
                    items: [
                      {
                        id: "I0J0000101",
                        parent_id: "H0J0000101",
                        text: "MINI UPS",
                        X_fullName: "MINI UPS",
                        X_fullName_SK: "MINI UPS",
                        X_URL: "mini-ups",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-USV module with integrated power supply unit\\MINI UPS",
                        X_Description:
                          "The highly compact MINI UPS combines a power supply unit and electronic switchover unit in the same housing.",
                        X_Description_SK:
                          "Vysoko kompaktn\u00e9 mini UPS kombinuje nap\u00e1jaciu jednotku a elektronick\u00fa sp\u00ednaciu jednotku v rovnakom kryte.",
                      },
                      {
                        id: "J0J0000101",
                        parent_id: "H0J0000101",
                        text: "TRIO UPS",
                        X_fullName: "TRIO UPS",
                        X_fullName_SK: "TRIO UPS",
                        X_URL: "trio-ups",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-USV module with integrated power supply unit\\TRIO UPS",
                        X_Description:
                          "TRIO UPS has been developed specifically for supplying industrial PCs in standard machine production.",
                        X_Description_SK:
                          "TRIO UPS bol vyvinut\u00fd \u0161peci\u00e1lne pre nap\u00e1janie priemyseln\u00fdch PC v \u0161tandardnej strojovej v\u00fdrobe.",
                      },
                    ],
                  },
                  {
                    id: "K0J0000101",
                    parent_id: "E0J0000101",
                    text: "DC-UPS with integrated energy storage",
                    X_fullName: "DC-UPS with integrated energy storage",
                    X_fullName_SK:
                      "DC-UPS s integrovan\u00fdm ukladan\u00edm energie",
                    X_URL: "dc-ups-with-energy-storage",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-UPS with integrated energy storage",
                    X_Description:
                      "The compact DC UPS with integrated energy storage are particularly space-saving and easy to retrofit. Select your appropriate UPS solution.",
                    X_Description_SK:
                      "Kompaktn\u00e9 DC UPS s integrovan\u00fdm z\u00e1sobn\u00edkom energie s\u00fa obzvl\u00e1\u0161\u0165 priestorovo \u00fasporn\u00e9 a jednoducho sa daj\u00fa dodato\u010dne namontova\u0165. Vyberte si vhodn\u00e9 rie\u0161enie UPS.",
                    items: [
                      {
                        id: "L0J0000101",
                        parent_id: "K0J0000101",
                        text: "QUINT UPS",
                        X_fullName: "QUINT UPS",
                        X_fullName_SK: "QUINT UPS",
                        X_URL: "qunit-ups",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-UPS with integrated energy storage\\QUINT UPS",
                        X_Description:
                          "QUINT UPS with IQ Technology and integrated energy storage maximizes the availability for load currents up to 10 A.",
                        X_Description_SK:
                          "UPS QUINT s technol\u00f3giou IQ a integrovan\u00fdm z\u00e1sobn\u00edkom energie maximalizuje dostupnos\u0165 pre z\u00e1\u0165a\u017eov\u00e9 pr\u00fady a\u017e do 10 A.",
                      },
                      {
                        id: "M0J0000101",
                        parent_id: "K0J0000101",
                        text: "UNO UPS",
                        X_fullName: "UNO UPS",
                        X_fullName_SK: "UNO UPS",
                        X_URL: "uno-ups",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-UPS with integrated energy storage\\UNO UPS",
                        X_Description:
                          "The UNO UPS uninterruptible power supply works with integrated lead AGM power storage.",
                        X_Description_SK:
                          "Nepreru\u0161ite\u013en\u00fd zdroj nap\u00e1jania UPS UNO pracuje s integrovan\u00fdm oloven\u00fdm z\u00e1sobn\u00edkom energie AGM.",
                      },
                    ],
                  },
                ],
              },
              {
                id: "N0J0000101",
                parent_id: "A0J0000101",
                text: "USV for AC applications",
                X_fullName: "USV for AC applications",
                X_fullName_SK: "USV pre aplik\u00e1cie AC",
                X_URL: "usvfor-ac-applications",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications",
                X_Description:
                  "Uninterruptible power supplies for AC applications continue to deliver power even in the event of mains failure.",
                X_Description_SK:
                  "Nepreru\u0161ite\u013en\u00e9 nap\u00e1jacie zdroje pre AC aplik\u00e1cie na\u010falej dod\u00e1vaj\u00fa energiu aj v pr\u00edpade v\u00fdpadku siete.",
                items: [
                  {
                    id: "O0J0000101",
                    parent_id: "N0J0000101",
                    text: "AC UPS with IQ technology",
                    X_fullName: "AC UPS with IQ technology",
                    X_fullName_SK: "AC UPS s technol\u00f3giou IQ",
                    X_URL: "ac-ups-with-iq-technology",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC UPS with IQ technology",
                    X_Description:
                      "The intelligent AC UPS with IQ Technology ensures superior system availability. Monitor and optimize your energy storage with the QUINT AC UPS.",
                    X_Description_SK:
                      "Inteligentn\u00fd AC UPS s technol\u00f3giou IQ zais\u0165uje vynikaj\u00facu dostupnos\u0165 syst\u00e9mu. Monitorujte a optimalizujte svoje skladovanie energie pomocou UPS QUINT AC.",
                    items: [
                      {
                        id: "P0J0000101",
                        parent_id: "O0J0000101",
                        text: "QUINT AC-UPS",
                        X_fullName: "QUINT AC-UPS",
                        X_fullName_SK: "QUINT AC-UPS",
                        X_URL: "qunit-ac-ups",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC UPS with IQ technology\\QUINT AC-UPS",
                        X_Description:
                          "QUINT AC-UPS, the uninterruptible power supply unit for AC applications, ensures maximum system availability.",
                        X_Description_SK:
                          "QUINT AC-UPS, nepreru\u0161ite\u013en\u00fd zdroj nap\u00e1jania pre AC aplik\u00e1cie, zais\u0165uje maxim\u00e1lnu dostupnos\u0165 syst\u00e9mu.",
                      },
                      {
                        id: "Q0J0000101",
                        parent_id: "O0J0000101",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                        X_URL: "ac-ups-accessories",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC UPS with IQ technology\\Accessories",
                        X_Description:
                          "Free configuration software, a variety of data cables, and power storage mounting kits are available for QUINT UPS.",
                        X_Description_SK:
                          "Pre UPS QUINT je k dispoz\u00edcii bezplatn\u00fd konfigura\u010dn\u00fd softv\u00e9r, mno\u017estvo d\u00e1tov\u00fdch k\u00e1blov a mont\u00e1\u017ene s\u00fapravy na ukladanie energie.",
                      },
                    ],
                  },
                  {
                    id: "R0J0000101",
                    parent_id: "N0J0000101",
                    text: "AC-UPS with integrated energy storage",
                    X_fullName: "AC-UPS with integrated energy storage",
                    X_fullName_SK:
                      "AC-UPS s integrovan\u00fdm ukladan\u00edm energie",
                    X_URL: "ac-ups-with-energy-storage",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC-UPS with integrated energy storage",
                    X_Description:
                      "The TRIO AC UPS with integrated energy storage delivers a pure sine curve at the output and supplies your AC applications without interruption.",
                    X_Description_SK:
                      "UPS TRIO AC s integrovan\u00fdm z\u00e1sobn\u00edkom energie poskytuje \u010dist\u00fd s\u00ednusov\u00fd priebeh na v\u00fdstupe a nap\u00e1ja va\u0161e AC aplik\u00e1cie bez preru\u0161enia.",
                    items: [
                      {
                        id: "S0J0000101",
                        parent_id: "R0J0000101",
                        text: "TRIO AC-UPS",
                        X_fullName: "TRIO AC-UPS",
                        X_fullName_SK: "TRIO AC-UPS",
                        X_URL: "trio-ac-ups",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC-UPS with integrated energy storage\\TRIO AC-UPS",
                        X_Description:
                          "TRIO AC-UPS for the DIN rail supply AC loads reliably.",
                        X_Description_SK:
                          "TRIO AC-UPS pre nap\u00e1janie na DIN li\u0161tu AC spo\u013eahlivo za\u0165a\u017euje.",
                      },
                    ],
                  },
                  {
                    id: "T0J0000101",
                    parent_id: "N0J0000101",
                    text: "Energy storage",
                    X_fullName: "Energy storage",
                    X_fullName_SK: "Ukladanie energie",
                    X_URL: "energy-storage",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\Energy storage",
                    X_Description:
                      "Different energy storage technologies are characterized by their long service lives or the fact they involve no maintenance.",
                    X_Description_SK:
                      "R\u00f4zne technol\u00f3gie skladovania energie sa vyzna\u010duj\u00fa dlhou \u017eivotnos\u0165ou alebo t\u00fdm, \u017ee nevy\u017eaduj\u00fa \u017eiadnu \u00fadr\u017ebu.",
                    items: [
                      {
                        id: "U0J0000101",
                        parent_id: "T0J0000101",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                        X_URL: "energy-storage-accessories",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\Energy storage\\Accessories",
                        X_Description:
                          "Mounting and DIN rail adapters plus flat-type plug-in fuses are available as accessories.",
                        X_Description_SK:
                          "Ako pr\u00edslu\u0161enstvo s\u00fa k dispoz\u00edcii mont\u00e1\u017ene adapt\u00e9ry a adapt\u00e9ry na li\u0161tu DIN plus ploch\u00e9 z\u00e1suvn\u00e9 poistky.",
                      },
                      {
                        id: "V0J0000101",
                        parent_id: "T0J0000101",
                        text: "Energy storage for QUINT UPS with IQ Technology",
                        X_fullName:
                          "Energy storage for QUINT UPS with IQ Technology",
                        X_fullName_SK:
                          "Ukladanie energie pre UPS QUINT s technol\u00f3giou IQ",
                        X_URL: "iq-qunit-energy-storage",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\Energy storage\\Energy storage for QUINT UPS with IQ Technology",
                        X_Description:
                          "Power storage devices for combination with QUINT UPS with IQ technology.",
                        X_Description_SK:
                          "Z\u00e1sobn\u00edky energie pre kombin\u00e1ciu s UPS QUINT s technol\u00f3giou IQ.",
                      },
                      {
                        id: "W0J0000101",
                        parent_id: "T0J0000101",
                        text: "Energy storage for TRIO UPS, MINI UPS and STEP UPS",
                        X_fullName:
                          "Energy storage for TRIO UPS, MINI UPS and STEP UPS",
                        X_fullName_SK:
                          "Skladovanie energie pre TRIO UPS, MINI UPS a STEP UPS",
                        X_URL: "ups-energy-storage",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\Energy storage\\Energy storage for TRIO UPS, MINI UPS and STEP UPS",
                        X_Description:
                          "Power storage devices for combination with TRIO UPS, MINI UPS or STEP UPS.",
                        X_Description_SK:
                          "Z\u00e1sobn\u00edky energie pre kombin\u00e1ciu s TRIO UPS, MINI UPS alebo STEP UPS.",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "X0J0000101",
        parent_id: "HFI0000101",
        text: "System cabling for controllers",
        X_fullName: "System cabling for controllers",
        X_fullName_SK:
          "Syst\u00e9mov\u00e1 kabel\u00e1\u017e pre riadiace jednotky",
        X_URL: "system-cabling-for-controllers",
        FULLPATH: "PhoenixContact\\System cabling for controllers",
        X_Description:
          "Quick, clear, error-free: the perfect fit for wiring single wires to your control unit at field level.",
        X_Description_SK:
          "R\u00fdchle, jasn\u00e9, bezchybn\u00e9: perfektne sa hod\u00ed na zapojenie jednotliv\u00fdch vodi\u010dov do va\u0161ej riadiacej jednotky na \u00farovni po\u013ea.",
        items: [
          {
            id: "Y0J0000101",
            parent_id: "X0J0000101",
            text: "Controller-specific system cabling",
            X_fullName: "Controller-specific system cabling",
            X_fullName_SK:
              "\u0160pecifick\u00e1 syst\u00e9mov\u00e1 kabel\u00e1\u017e riadiacej jednotky",
            X_URL: "controller-specific-sys-cabling",
            FULLPATH:
              "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling",
            X_Description:
              "VARIOFACE system cabling connects the field level to the controller - easy and error-free, thanks to plug-in connections.",
            X_Description_SK:
              "Syst\u00e9mov\u00e1 kabel\u00e1\u017e VARIOFACE sp\u00e1ja \u00farove\u0148 po\u013ea s kontrol\u00e9rom - jednoducho a bezchybne v\u010faka z\u00e1suvn\u00fdm pripojeniam.",
            items: [
              {
                id: "Z0J0000101",
                parent_id: "Y0J0000101",
                text: "Front adapters",
                X_fullName: "Front adapters",
                X_fullName_SK: "Predn\u00e9 adapt\u00e9ry",
                X_URL: "front-adapters",
                FULLPATH:
                  "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling\\Front adapters",
                X_Description:
                  "Front adapters are available for various controllers. They are connected via system cables.",
                X_Description_SK:
                  "Predn\u00e9 adapt\u00e9ry s\u00fa k dispoz\u00edcii pre r\u00f4zne ovl\u00e1da\u010de. S\u00fa prepojen\u00e9 syst\u00e9mov\u00fdmi k\u00e1blami.",
              },
              {
                id: "01J0000101",
                parent_id: "Y0J0000101",
                text: "Cable",
                X_fullName: "Cable",
                X_fullName_SK: "K\u00e1bel",
                X_URL: "cable",
                FULLPATH:
                  "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling\\Cable",
                X_Description:
                  "System cables for the connection between the controller and the interface module. The cables are available in variable lengths.",
                X_Description_SK:
                  "Syst\u00e9mov\u00e9 k\u00e1ble na prepojenie medzi ovl\u00e1da\u010dom a modulom rozhrania. K\u00e1ble s\u00fa dostupn\u00e9 v r\u00f4znych d\u013a\u017ekach.",
              },
              {
                id: "11J0000101",
                parent_id: "Y0J0000101",
                text: "Modules",
                X_fullName: "Modules",
                X_fullName_SK: "Moduly",
                X_URL: "modules",
                FULLPATH:
                  "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling\\Modules",
                X_Description:
                  "Modules are used in combination with the corresponding front adapters and are connected via system cables.",
                X_Description_SK:
                  "Moduly sa pou\u017e\u00edvaj\u00fa v kombin\u00e1cii s pr\u00edslu\u0161n\u00fdmi predn\u00fdmi adapt\u00e9rmi a prip\u00e1jaj\u00fa sa pomocou syst\u00e9mov\u00fdch k\u00e1blov.",
              },
              {
                id: "21J0000101",
                parent_id: "Y0J0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "system-cab-accessories",
                FULLPATH:
                  "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling\\Accessories",
                X_Description:
                  "Accessories, fuses, and locking clips are available.",
                X_Description_SK:
                  "K dispoz\u00edcii je pr\u00edslu\u0161enstvo, poistky a uzamykacie spony.",
              },
            ],
          },
          {
            id: "41J0000101",
            parent_id: "X0J0000101",
            text: "Universal cables",
            X_fullName: "Universal cables",
            X_fullName_SK: "Univerz\u00e1lne k\u00e1ble",
            X_URL: "universal-cables",
            FULLPATH:
              "PhoenixContact\\System cabling for controllers\\Universal cables",
            X_Description:
              "Universal cables for 1:1 connection have FLK (flat-ribbon cable) connectors or D-SUB connectors.",
            X_Description_SK: "",
          },
          {
            id: "61J0000101",
            parent_id: "X0J0000101",
            text: "Universal modules",
            X_fullName: "Universal modules",
            X_fullName_SK: "Univerz\u00e1lne moduly",
            X_URL: "universal-modules",
            FULLPATH:
              "PhoenixContact\\System cabling for controllers\\Universal modules",
            X_Description:
              "Universal termination boards with a compact design convert high-position connectors to various connection terminal blocks.",
            X_Description_SK: "",
          },
          {
            id: "81J0000101",
            parent_id: "X0J0000101",
            text: "Potential distributors",
            X_fullName: "Potential distributors",
            X_fullName_SK: "Potenci\u00e1l distrib\u00fatorov",
            X_URL: "potential-distributors",
            FULLPATH:
              "PhoenixContact\\System cabling for controllers\\Potential distributors",
            X_Description:
              "Potential distributors, with screw or spring-cage connection, provide several potential levels.",
            X_Description_SK: "",
          },
        ],
      },
      {
        id: "91J0000101",
        parent_id: "HFI0000101",
        text: "Surge protection and interference filters",
        X_fullName: "Surge protection and interference filters",
        X_fullName_SK: "Prep\u00e4\u0165ov\u00e1 ochrana a filtre ru\u0161enia",
        X_URL: "surge-protection-and-filters",
        FULLPATH: "PhoenixContact\\Surge protection and interference filters",
        X_Description:
          "Ensure that operation is not interrupted. Our surge protective devices and interference filters provide effective protection for your systems.",
        X_Description_SK:
          "Uistite sa, \u017ee prev\u00e1dzka nie je preru\u0161en\u00e1. Na\u0161e zariadenia na ochranu proti prep\u00e4tiu a ru\u0161iv\u00e9 filtre poskytuj\u00fa \u00fa\u010dinn\u00fa ochranu va\u0161im syst\u00e9mom.",
        items: [
          {
            id: "A1J0000101",
            parent_id: "91J0000101",
            text: "Interference filters",
            X_fullName: "Interference filters",
            X_fullName_SK: "Interferen\u010dn\u00e9 filtre",
            X_URL: "interference-filters",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Interference filters",
            X_Description:
              "Interference filters limit unwanted voltage peaks and smooth the voltage signal.",
            X_Description_SK:
              "Interferen\u010dn\u00e9 filtre obmedzuj\u00fa ne\u017eiaduce nap\u00e4\u0165ov\u00e9 \u0161pi\u010dky a vyhladzuj\u00fa nap\u00e4\u0165ov\u00fd sign\u00e1l.",
            items: [
              {
                id: "B1J0000101",
                parent_id: "A1J0000101",
                text: "Filter",
                X_fullName: "Filter",
                X_fullName_SK: "Filter",
                X_URL: "filter",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Interference filters\\Filter",
                X_Description:
                  "Interference filters limit both symmetrical and asymmetrical interference voltages.",
                X_Description_SK:
                  "Ru\u0161iv\u00e9 filtre obmedzuj\u00fa symetrick\u00e9 aj asymetrick\u00e9 ru\u0161iv\u00e9 nap\u00e4tia.",
              },
              {
                id: "C1J0000101",
                parent_id: "A1J0000101",
                text: "Filters with surge protection type 3",
                X_fullName: "Filters with surge protection type 3",
                X_fullName_SK: "Filtre s prep\u00e4\u0165ovou ochranou typu 3",
                X_URL: "surge-protection-type-3-filters",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Interference filters\\Filters with surge protection type 3",
                X_Description:
                  "Interference filters with integrated surge protection protect against transients and high-frequency interference.",
                X_Description_SK:
                  "Ru\u0161iv\u00e9 filtre s integrovanou prep\u00e4\u0165ovou ochranou chr\u00e1nia pred prechodov\u00fdmi javmi a vysokofrekven\u010dn\u00fdm ru\u0161en\u00edm.",
              },
            ],
          },
          {
            id: "D1J0000101",
            parent_id: "91J0000101",
            text: "Surge protection for IT",
            X_fullName: "Surge protection for IT",
            X_fullName_SK: "Prep\u00e4\u0165ov\u00e1 ochrana pre IT",
            X_URL: "surge-protection",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Surge protection for IT",
            X_Description:
              "Appropriately combined surge protection provides low attenuation and, at the same time, high bandwidth for data transmission.",
            X_Description_SK:
              "Vhodne kombinovan\u00e1 prep\u00e4\u0165ov\u00e1 ochrana poskytuje n\u00edzky \u00fatlm a z\u00e1rove\u0148 ve\u013ek\u00fa \u0161\u00edrku p\u00e1sma pre prenos d\u00e1t.",
            items: [
              {
                id: "E1J0000101",
                parent_id: "D1J0000101",
                text: "Bus systems",
                X_fullName: "Bus systems",
                X_fullName_SK: "Syst\u00e9my zbern\u00edc",
                X_URL: "bus-systems",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for IT\\Bus systems",
                X_Description:
                  "Universal surge protection with no influence on signals for information and data technology.",
                X_Description_SK:
                  "Univerz\u00e1lna ochrana proti prep\u00e4tiu bez vplyvu na sign\u00e1ly pre informa\u010dn\u00e9 a d\u00e1tov\u00e9 technol\u00f3gie.",
              },
              {
                id: "F1J0000101",
                parent_id: "D1J0000101",
                text: "Telecommunications",
                X_fullName: "Telecommunications",
                X_fullName_SK: "Telekomunik\u00e1cie",
                X_URL: "telecommunications",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for IT\\Telecommunications",
                X_Description:
                  "Surge protective devices with circuits specifically designed to meet the requirements of digital and analog telecommunications.",
                X_Description_SK:
                  "Prep\u00e4\u0165ov\u00e9 ochrany s obvodmi \u0161peci\u00e1lne navrhnut\u00fdmi tak, aby sp\u013a\u0148ali po\u017eiadavky digit\u00e1lnych a anal\u00f3gov\u00fdch telekomunik\u00e1ci\u00ed.",
              },
              {
                id: "G1J0000101",
                parent_id: "D1J0000101",
                text: "Surge protection accessories for information techn",
                X_fullName:
                  "Surge protection accessories for information techn",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo na ochranu proti prep\u00e4tiu pre informa\u010dn\u00e9 techn",
                X_URL: "surge-protection-accessories",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for IT\\Surge protection accessories for information techn",
                X_Description:
                  "Accessories and additional products for all surge protective devices in the field of information technology.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo a doplnkov\u00e9 produkty pre v\u0161etky prep\u00e4\u0165ov\u00e9 ochrany v oblasti informa\u010dn\u00fdch technol\u00f3gi\u00ed.",
              },
            ],
          },
          {
            id: "H1J0000101",
            parent_id: "91J0000101",
            text: "Surge protection for measurement and control techn",
            X_fullName: "Surge protection for measurement and control techn",
            X_fullName_SK:
              "Prep\u00e4\u0165ov\u00e1 ochrana pre meracie a regula\u010dn\u00e9 techn.",
            X_URL: "surge-protection-measurement",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn",
            X_Description:
              "Surge protective devices for MCR technology combine a high discharge capacity with components that respond quickly.",
            X_Description_SK:
              "Prep\u00e4\u0165ov\u00e9 ochrann\u00e9 zariadenia pre technol\u00f3giu MCR kombinuj\u00fa vysok\u00fa vyb\u00edjaciu kapacitu s komponentmi, ktor\u00e9 r\u00fdchlo reaguj\u00fa.",
            items: [
              {
                id: "I1J0000101",
                parent_id: "H1J0000101",
                text: "Surge protection accessories for MCR technology",
                X_fullName: "Surge protection accessories for MCR technology",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo na ochranu proti prep\u00e4tiu pre technol\u00f3giu MCR",
                X_URL: "mcr-surge-protection",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Surge protection accessories for MCR technology",
                X_Description:
                  "Accessories and additional products for all surge protective devices in the field of MCR technology.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo a doplnkov\u00e9 produkty pre v\u0161etky prep\u00e4\u0165ov\u00e9 ochrany v oblasti MCR techniky.",
              },
              {
                id: "K1J0000101",
                parent_id: "H1J0000101",
                text: "Current loops and analog signals",
                X_fullName: "Current loops and analog signals",
                X_fullName_SK:
                  "Pr\u00fadov\u00e9 slu\u010dky a anal\u00f3gov\u00e9 sign\u00e1ly",
                X_URL: "current-loops-and-analog-signals",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Current loops and analog signals",
                X_Description:
                  "Protect current loops and analog signals with the narrowest surge protection starting from 3.5 mm.",
                X_Description_SK: "",
              },
              {
                id: "M1J0000101",
                parent_id: "H1J0000101",
                text: "Digital signals and switching contacts",
                X_fullName: "Digital signals and switching contacts",
                X_fullName_SK:
                  "Digit\u00e1lne sign\u00e1ly a sp\u00ednacie kontakty",
                X_URL: "signals-switching-contacts",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Digital signals and switching contacts",
                X_Description:
                  "Protect your digital signals and switch contacts against surge voltages with TERMITRAB complete with an overall width starting from 3.5 mm.",
                X_Description_SK: "",
              },
              {
                id: "O1J0000101",
                parent_id: "H1J0000101",
                text: "Resistance-dependent measurements",
                X_fullName: "Resistance-dependent measurements",
                X_fullName_SK: "Merania z\u00e1visl\u00e9 od odporu",
                X_URL: "resistance-dependent-measurement",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Resistance-dependent measurements",
                X_Description:
                  "TERMITRAB complete provides protection against surge voltages for resistance-dependent measurements, such as Pt 100 temperature measurements.",
                X_Description_SK: "",
              },
              {
                id: "Q1J0000101",
                parent_id: "H1J0000101",
                text: "Potentially explosive applications",
                X_fullName: "Potentially explosive applications",
                X_fullName_SK:
                  "Potenci\u00e1lne v\u00fdbu\u0161n\u00e9 aplik\u00e1cie",
                X_URL: "explosive-applications",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Potentially explosive applications",
                X_Description:
                  "Intrinsically safe and pressure-encapsulated protective devices ensure reliable signals in the Ex area.",
                X_Description_SK: "",
              },
              {
                id: "S1J0000101",
                parent_id: "H1J0000101",
                text: "Single-stage protective devices",
                X_fullName: "Single-stage protective devices",
                X_fullName_SK:
                  "Jednostup\u0148ov\u00e9 ochrann\u00e9 zariadenia",
                X_URL: "single-stage-protective-devices",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Single-stage protective devices",
                X_Description:
                  "Single-stage protective devices provide additional protection for long cables in the field.",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "T1J0000101",
            parent_id: "91J0000101",
            text: "Surge protection for transceiver systems",
            X_fullName: "Surge protection for transceiver systems",
            X_fullName_SK:
              "Prep\u00e4\u0165ov\u00e1 ochrana pre syst\u00e9my vysiela\u010dov",
            X_URL: "surge-protection-for-transceiver",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Surge protection for transceiver systems",
            X_Description:
              "Coaxial arresters are powerful, do not reduce signal quality, and are suitable for all standard transmission equipment.",
            X_Description_SK:
              "Koaxi\u00e1lne zvodi\u010de s\u00fa v\u00fdkonn\u00e9, nezni\u017euj\u00fa kvalitu sign\u00e1lu a s\u00fa vhodn\u00e9 pre v\u0161etky \u0161tandardn\u00e9 prenosov\u00e9 zariadenia.",
            items: [
              {
                id: "U1J0000101",
                parent_id: "T1J0000101",
                text: "TV and radio systems",
                X_fullName: "TV and radio systems",
                X_fullName_SK: "Telev\u00edzne a rozhlasov\u00e9 syst\u00e9my",
                X_URL: "tvand-radio-systems",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for transceiver systems\\TV and radio systems",
                X_Description:
                  "Surge protective devices with coaxial connection protect TV and radio systems against damage.",
                X_Description_SK:
                  "Prep\u00e4\u0165ov\u00e9 ochrany s koaxi\u00e1lnym pripojen\u00edm chr\u00e1nia TV a r\u00e1diov\u00e9 syst\u00e9my pred po\u0161koden\u00edm.",
              },
              {
                id: "V1J0000101",
                parent_id: "T1J0000101",
                text: "Transceiver technology",
                X_fullName: "Transceiver technology",
                X_fullName_SK: "Technol\u00f3gia vysiela\u010da",
                X_URL: "transceiver-technology",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for transceiver systems\\Transceiver technology",
                X_Description:
                  "Powerful protection for the systems particularly at risk in transceiver technology.",
                X_Description_SK:
                  "V\u00fdkonn\u00e1 ochrana pre syst\u00e9my obzvl\u00e1\u0161\u0165 ohrozen\u00e9 v technol\u00f3gii transceiverov.",
              },
              {
                id: "W1J0000101",
                parent_id: "T1J0000101",
                text: "Surge protection accessories for transceiver syste",
                X_fullName:
                  "Surge protection accessories for transceiver syste",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo na ochranu proti prep\u00e4tiu pre syst\u00e9m vysiela\u010da",
                X_URL: "transceiver-surge-protection",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for transceiver systems\\Surge protection accessories for transceiver syste",
                X_Description:
                  "Accessories and additional products for surge protection for transceiver systems.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo a doplnkov\u00e9 produkty pre prep\u00e4\u0165ov\u00fa ochranu pre syst\u00e9my transceiverov.",
              },
            ],
          },
          {
            id: "X1J0000101",
            parent_id: "91J0000101",
            text: "Surge protection for power supply units",
            X_fullName: "Surge protection for power supply units",
            X_fullName_SK:
              "Prep\u00e4\u0165ov\u00e1 ochrana pre nap\u00e1jacie zdroje",
            X_URL: "psu-surge-protection",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units",
            X_Description:
              "Surge protection for power supply units is suitable for all applications, from the feed-in through to the device.",
            X_Description_SK:
              "Prep\u00e4\u0165ov\u00e1 ochrana pre nap\u00e1jacie zdroje je vhodn\u00e1 pre v\u0161etky aplik\u00e1cie, od nap\u00e1jania a\u017e po zariadenie.",
            items: [
              {
                id: "Y1J0000101",
                parent_id: "X1J0000101",
                text: "Accessories for surge protection for the power sup",
                X_fullName:
                  "Accessories for surge protection for the power sup",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo na ochranu proti prep\u00e4tiu pre nap\u00e1jac\u00ed zdroj",
                X_URL: "surge-protection-psu-accessories",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Accessories for surge protection for the power sup",
                X_Description:
                  "Accessories and additional products for all surge protective devices in the field of power supply.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo a doplnkov\u00e9 produkty pre v\u0161etky prep\u00e4\u0165ov\u00e9 ochrany v oblasti nap\u00e1jania.",
              },
              {
                id: "02J0000101",
                parent_id: "X1J0000101",
                text: "Combination type 1/2 protective device",
                X_fullName: "Combination type 1/2 protective device",
                X_fullName_SK:
                  "Kombinovan\u00e9 ochrann\u00e9 zariadenie typu 1/2",
                X_URL: "1-2-protective-device",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Combination type 1/2 protective device",
                X_Description:
                  "Combination type 1/2 protective device \u2013 powerful protection for systems and buildings with increased risk of lightning strike.",
                X_Description_SK: "",
              },
              {
                id: "22J0000101",
                parent_id: "X1J0000101",
                text: "Type 1+2 protective device combination",
                X_fullName: "Type 1+2 protective device combination",
                X_fullName_SK:
                  "Kombin\u00e1cia ochrann\u00fdch zariaden\u00ed typu 1+2",
                X_URL: "protective-device-combination",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Type 1+2 protective device combination",
                X_Description:
                  "The combination of type 1 + 2 lightning and surge protective devices provides maximum performance with minimum space.",
                X_Description_SK: "",
              },
              {
                id: "42J0000101",
                parent_id: "X1J0000101",
                text: "Type 2 surge arrester",
                X_fullName: "Type 2 surge arrester",
                X_fullName_SK: "Zvodi\u010d prep\u00e4tia typu 2",
                X_URL: "type-2-surge-arrester",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Type 2 surge arrester",
                X_Description:
                  "Type 2 surge protective devices for industrial and building installations protect systems, machinery, and devices.",
                X_Description_SK: "",
              },
              {
                id: "62J0000101",
                parent_id: "X1J0000101",
                text: "Type 3 device protection",
                X_fullName: "Type 3 device protection",
                X_fullName_SK: "Ochrana zariadenia typu 3",
                X_URL: "type-3-device-protection",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Type 3 device protection",
                X_Description:
                  "High-performance type 3 device protection for protecting sensitive end devices",
                X_Description_SK: "",
              },
              {
                id: "72J0000101",
                parent_id: "X1J0000101",
                text: "Spark gap",
                X_fullName: "Spark gap",
                X_fullName_SK: "Iskrovisko",
                X_URL: "spark-gap",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Spark gap",
                X_Description:
                  "The FLT-ISG\u2026 isolating spark gap for protecting insulating flanges on pipelines and indirect equipotential bonding",
                X_Description_SK:
                  "Izola\u010dn\u00e9 iskrisko FLT-ISG\u2026 na ochranu izola\u010dn\u00fdch pr\u00edrub na potrubiach a nepriame vyrovnanie potenci\u00e1lov",
                items: [
                  {
                    id: "82J0000101",
                    parent_id: "72J0000101",
                    text: "Isolating spark gap and accessories",
                    X_fullName: "Isolating spark gap and accessories",
                    X_fullName_SK:
                      "Izola\u010dn\u00e9 iskrisko a pr\u00edslu\u0161enstvo",
                    X_URL: "isolating-spark-gap-accessories",
                    FULLPATH:
                      "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Spark gap\\Isolating spark gap and accessories",
                    X_Description:
                      "Isolating spark gap for protecting insulating flanges on pipelines and corresponding accessories",
                    X_Description_SK:
                      "Izola\u010dn\u00e9 iskrisko na ochranu izola\u010dn\u00fdch pr\u00edrub na potrubiach a pr\u00edslu\u0161n\u00e9 pr\u00edslu\u0161enstvo",
                  },
                ],
              },
            ],
          },
          {
            id: "92J0000101",
            parent_id: "91J0000101",
            text: "Test device for surge protective devices",
            X_fullName: "Test device for surge protective devices",
            X_fullName_SK:
              "Sk\u00fa\u0161obn\u00e9 zariadenie pre prep\u00e4\u0165ov\u00e9 ochrann\u00e9 zariadenia",
            X_URL: "test-device-for-surge-protective",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Test device for surge protective devices",
            X_Description:
              "The portable test device checks all the relevant components of pluggable surge protective devices.",
            X_Description_SK:
              "Prenosn\u00e9 testovacie zariadenie kontroluje v\u0161etky pr\u00edslu\u0161n\u00e9 komponenty z\u00e1suvn\u00fdch prep\u00e4\u0165ov\u00fdch ochrann\u00fdch zariaden\u00ed.",
            items: [
              {
                id: "A2J0000101",
                parent_id: "92J0000101",
                text: "Test device, test sockets, and accessories",
                X_fullName: "Test device, test sockets, and accessories",
                X_fullName_SK:
                  "Testovacie zariadenie, testovacie z\u00e1suvky a pr\u00edslu\u0161enstvo",
                X_URL: "test-device-and-accessories",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Test device for surge protective devices\\Test device, test sockets, and accessories",
                X_Description:
                  "The CHECKMASTER provides complete electrical testing for plug-in surge protective devices.",
                X_Description_SK:
                  "CHECKMASTER poskytuje kompletn\u00e9 elektrick\u00e9 testovanie z\u00e1suvn\u00fdch prep\u00e4\u0165ov\u00fdch ochrann\u00fdch zariaden\u00ed.",
              },
            ],
          },
          {
            id: "B2J0000101",
            parent_id: "91J0000101",
            text: "Set solutions",
            X_fullName: "Set solutions",
            X_fullName_SK: "Nastavenie rie\u0161en\u00ed",
            X_URL: "set-solutions",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Set solutions",
            X_Description:
              "Set solutions, for buildings or photovoltaic systems, provide comprehensive protection against damage caused by surge voltages.",
            X_Description_SK:
              "Setov\u00e9 rie\u0161enia, pre budovy alebo fotovoltaick\u00e9 syst\u00e9my, poskytuj\u00fa komplexn\u00fa ochranu pred po\u0161koden\u00edm sp\u00f4soben\u00fdm prep\u00e4t\u00edm.",
            items: [
              {
                id: "C2J0000101",
                parent_id: "B2J0000101",
                text: "Building sets",
                X_fullName: "Building sets",
                X_fullName_SK: "Stavebn\u00e9 s\u00fapravy",
                X_URL: "building-sets",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Set solutions\\Building sets",
                X_Description:
                  "Building sets protect electrical installations, household appliances, phones, and consumer electronics against damage caused by surge voltages.",
                X_Description_SK:
                  "Stavebnice chr\u00e1nia elektroin\u0161tal\u00e1ciu, dom\u00e1ce spotrebi\u010de, telef\u00f3ny a spotrebn\u00fa elektroniku pred po\u0161koden\u00edm sp\u00f4soben\u00fdm prep\u00e4t\u00edm.",
              },
            ],
          },
          {
            id: "4OJ0000101",
            parent_id: "91J0000101",
            text: "Surage protection for photovoltaic systems",
            X_fullName: "Surage protection for photovoltaic systems",
            X_fullName_SK:
              "Ochrana proti prep\u00e4tiu pre fotovoltaick\u00e9 syst\u00e9my",
            X_URL: "photovoltaic-surge-protection",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Surage protection for photovoltaic systems",
            X_Description: "",
            X_Description_SK: "",
          },
        ],
      },
      {
        id: "D2J0000101",
        parent_id: "HFI0000101",
        text: "Tools",
        X_fullName: "Tools",
        X_fullName_SK: "N\u00e1stroje",
        X_URL: "tools",
        FULLPATH: "PhoenixContact\\Tools",
        X_Description:
          "Using the right tool is key to achieving optimum results. Our products are all about quality, precision, and durability.",
        X_Description_SK:
          "Pou\u017e\u00edvanie spr\u00e1vneho n\u00e1stroja je k\u013e\u00fa\u010dom k dosiahnutiu optim\u00e1lnych v\u00fdsledkov. Na\u0161e v\u00fdrobky s\u00fa o kvalite, presnosti a trvanlivosti.",
        items: [
          {
            id: "E2J0000101",
            parent_id: "D2J0000101",
            text: "Automatic devices",
            X_fullName: "Automatic devices",
            X_fullName_SK: "Automatick\u00e9 zariadenia",
            X_URL: "automatic-devices",
            FULLPATH: "PhoenixContact\\Tools\\Automatic devices",
            X_Description:
              "Comprehensive range of innovative automatic tools for flexible and cost-effective conductor processing.",
            X_Description_SK:
              "Rozsiahly rad inovat\u00edvnych automatick\u00fdch n\u00e1strojov pre flexibiln\u00e9 a cenovo v\u00fdhodn\u00e9 spracovanie vodi\u010dov.",
            items: [
              {
                id: "G2J0000101",
                parent_id: "E2J0000101",
                text: "Stripping machines",
                X_fullName: "Stripping machines",
                X_fullName_SK: "Stroje na odizolovanie",
                X_URL: "stripping-machines",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Stripping machines",
                X_Description:
                  "Compact stripping machine for conductors and cables with standard or special insulation.",
                X_Description_SK: "",
              },
              {
                id: "I2J0000101",
                parent_id: "E2J0000101",
                text: "Crimping machines",
                X_fullName: "Crimping machines",
                X_fullName_SK: "Lisovacie stroje",
                X_URL: "crimping-machines",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Crimping machines",
                X_Description:
                  "CF 500 portable crimping machine for processing ferrules, cable lugs, and contacts.",
                X_Description_SK: "",
              },
              {
                id: "K2J0000101",
                parent_id: "E2J0000101",
                text: "Cutting machines",
                X_fullName: "Cutting machines",
                X_fullName_SK: "Rezacie stroje",
                X_URL: "cutting-machines",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Cutting machines",
                X_Description:
                  "The CUTFOX 10 compact cutting machine cuts solid and stranded conductors up to 10 mm\u00b2 to length with millimeter precision.",
                X_Description_SK: "",
              },
              {
                id: "M2J0000101",
                parent_id: "E2J0000101",
                text: "Stripping/crimping machine",
                X_fullName: "Stripping/crimping machine",
                X_fullName_SK: "Odizolovac\u00ed/\u0161kripac\u00ed stroj",
                X_URL: "stripping-crimping-machine",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Stripping/crimping machine",
                X_Description:
                  "Portable or stationary stripping and crimping machines process ferrules \u2013 supplied loose or taped.",
                X_Description_SK: "",
              },
              {
                id: "N2J0000101",
                parent_id: "E2J0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "automatic-devices-accessories",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Accessories",
                X_Description:
                  "Accessories for automatic devices, for example, protective cover, spare knife, replacement locator, conversion kit.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo pre automatick\u00e9 zariadenia, napr\u00edklad ochrann\u00fd kryt, n\u00e1hradn\u00fd n\u00f4\u017e, n\u00e1hradn\u00fd lokaliz\u00e1tor, konverzn\u00e1 s\u00faprava.",
              },
            ],
          },
          {
            id: "O2J0000101",
            parent_id: "D2J0000101",
            text: "Hand tools",
            X_fullName: "Hand tools",
            X_fullName_SK: "Ru\u010dn\u00e9 n\u00e1radie",
            X_URL: "hand-tools",
            FULLPATH: "PhoenixContact\\Tools\\Hand tools",
            X_Description:
              "The TOOL fox range offers processing and measuring tools for all applications.",
            X_Description_SK:
              "Rad TOOL fox pon\u00faka n\u00e1stroje na spracovanie a meranie pre v\u0161etky aplik\u00e1cie.",
            items: [
              {
                id: "Q2J0000101",
                parent_id: "O2J0000101",
                text: "Stripping tool",
                X_fullName: "Stripping tool",
                X_fullName_SK: "N\u00e1stroj na odizolovanie",
                X_URL: "stripping-tool",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Stripping tool",
                X_Description:
                  "Stripping tools for various types of insulation and conductor sizes. Also with automatic adjustment mechanism.",
                X_Description_SK: "",
              },
              {
                id: "R2J0000101",
                parent_id: "O2J0000101",
                text: "Crimping tool",
                X_fullName: "Crimping tool",
                X_fullName_SK: "Lisovac\u00ed n\u00e1stroj",
                X_URL: "crimping-tool-main",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Crimping tool",
                X_Description:
                  "Mechanical crimping tools for professional results when crimping various types of contacts.",
                X_Description_SK:
                  "Mechanick\u00e9 krimpovacie n\u00e1stroje pre profesion\u00e1lne v\u00fdsledky pri krimpovan\u00ed r\u00f4znych typov kontaktov.",
                items: [
                  {
                    id: "S2J0000101",
                    parent_id: "R2J0000101",
                    text: "Crimping tool",
                    X_fullName: "Crimping tool",
                    X_fullName_SK: "Lisovac\u00ed n\u00e1stroj",
                    X_URL: "crimping-tool",
                    FULLPATH:
                      "PhoenixContact\\Tools\\Hand tools\\Crimping tool\\Crimping tool",
                    X_Description:
                      "Rugged crimping tools for fast contact processing, plus corresponding connectors in various different versions.",
                    X_Description_SK:
                      "Robustn\u00e9 krimpovacie n\u00e1stroje pre r\u00fdchle spracovanie kontaktov, plus zodpovedaj\u00face konektory v r\u00f4znych verzi\u00e1ch.",
                  },
                  {
                    id: "T2J0000101",
                    parent_id: "R2J0000101",
                    text: "Connectors",
                    X_fullName: "Connectors",
                    X_fullName_SK: "Konektory",
                    X_URL: "tools-connectors",
                    FULLPATH:
                      "PhoenixContact\\Tools\\Hand tools\\Crimping tool\\Connectors",
                    X_Description:
                      "The connector product range offers a comprehensive range of different versions for conductor cross sections from 0.5 to 240 mm\u00b2.",
                    X_Description_SK:
                      "Sortiment konektorov pon\u00faka rozsiahly sortiment r\u00f4znych verzi\u00ed pre prierezy vodi\u010dov od 0,5 do 240 mm\u00b2.",
                  },
                ],
              },
              {
                id: "V2J0000101",
                parent_id: "O2J0000101",
                text: "Electronics tools",
                X_fullName: "Electronics tools",
                X_fullName_SK: "N\u00e1stroje pre elektroniku",
                X_URL: "electronics-tools",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Electronics tools",
                X_Description:
                  "Tools for precise gripping, positioning, bending, and cutting in the field of electronics.",
                X_Description_SK: "",
              },
              {
                id: "X2J0000101",
                parent_id: "O2J0000101",
                text: "Riveting tools",
                X_fullName: "Riveting tools",
                X_fullName_SK: "N\u00e1stroje na nitovanie",
                X_URL: "riveting-tools",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Riveting tools",
                X_Description:
                  "Riveting tools for quick and safe joining using plastic body-bound rivets or metal blind rivets.",
                X_Description_SK: "",
              },
              {
                id: "Z2J0000101",
                parent_id: "O2J0000101",
                text: "Profile and DIN-rail cutters",
                X_fullName: "Profile and DIN-rail cutters",
                X_fullName_SK: "Fr\u00e9zy na profily a li\u0161ty DIN",
                X_URL: "profile-and-din-rail-cutters",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Profile and DIN-rail cutters",
                X_Description:
                  "Profile and DIN-rail cutters with various stamping bits that are user friendly and require minimum effort.",
                X_Description_SK: "",
              },
              {
                id: "13J0000101",
                parent_id: "O2J0000101",
                text: "Cutting tool",
                X_fullName: "Cutting tool",
                X_fullName_SK: "Rezn\u00fd n\u00e1stroj",
                X_URL: "cutting-tool",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Cutting tool",
                X_Description:
                  "Cutting tools that require minimum effort for cutting conductors and cables in a range of diameters cleanly and easily.",
                X_Description_SK: "",
              },
              {
                id: "33J0000101",
                parent_id: "O2J0000101",
                text: "Screwdriver tools",
                X_fullName: "Screwdriver tools",
                X_fullName_SK: "Skrutkovacie n\u00e1stroje",
                X_URL: "screwdriver-tools",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Screwdriver tools",
                X_Description:
                  "Various screwdrivers in a range of different sizes. A torque that can be finely adjusted and cordless screwdrivers complete the product range.",
                X_Description_SK: "",
              },
              {
                id: "53J0000101",
                parent_id: "O2J0000101",
                text: "Universal tools",
                X_fullName: "Universal tools",
                X_fullName_SK: "Univerz\u00e1lne n\u00e1stroje",
                X_URL: "universal-tools",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Universal tools",
                X_Description:
                  "Various universal tools with combined functions for standard and special applications.",
                X_Description_SK: "",
              },
              {
                id: "73J0000101",
                parent_id: "O2J0000101",
                text: "Tool sets",
                X_fullName: "Tool sets",
                X_fullName_SK: "Sady n\u00e1strojov",
                X_URL: "tool-sets",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Tool sets",
                X_Description:
                  "Rugged and heavy-duty bags and cases with tools for various different jobs.",
                X_Description_SK: "",
              },
              {
                id: "93J0000101",
                parent_id: "O2J0000101",
                text: "Testing and measuring tools",
                X_fullName: "Testing and measuring tools",
                X_fullName_SK: "Testovacie a meracie n\u00e1stroje",
                X_URL: "testing-and-measuring-tools",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Testing and measuring tools",
                X_Description:
                  "Compact multifunctional tools for error-free testing and measurement.",
                X_Description_SK: "",
              },
              {
                id: "A3J0000101",
                parent_id: "O2J0000101",
                text: "Portable stripping and crimping device",
                X_fullName: "Portable stripping and crimping device",
                X_fullName_SK:
                  "Prenosn\u00e9 odizolovacie a lisovacie zariadenie",
                X_URL: "portable-stripping-and-crimping",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Portable stripping and crimping device",
                X_Description:
                  "This tool makes treating conductors fun \u2013 stripping and crimping in less than 2 seconds.",
                X_Description_SK:
                  "Tento n\u00e1stroj rob\u00ed o\u0161etrovanie vodi\u010dov z\u00e1bavou \u2013 odizolovanie a krimpovanie za menej ako 2 sekundy.",
                items: [
                  {
                    id: "B3J0000101",
                    parent_id: "A3J0000101",
                    text: "Stripping and crimping hand-held machine",
                    X_fullName: "Stripping and crimping hand-held machine",
                    X_fullName_SK:
                      "Ru\u010dn\u00fd stroj na odizolovanie a lisovanie",
                    X_URL: "stripping-and-crimping-machine",
                    FULLPATH:
                      "PhoenixContact\\Tools\\Hand tools\\Portable stripping and crimping device\\Stripping and crimping hand-held machine",
                    X_Description:
                      "Mobile and battery-powered hand-held device for processing taped ferrules",
                    X_Description_SK:
                      "Mobiln\u00e9 a bat\u00e9riovo nap\u00e1jan\u00e9 ru\u010dn\u00e9 zariadenie na spracovanie lepen\u00fdch obj\u00edmok",
                  },
                ],
              },
              {
                id: "C3J0000101",
                parent_id: "O2J0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "hand-tools-accessories",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Accessories",
                X_Description:
                  "Accessories for hand tools, for example, replacement blade for stripping tools or replacement retaining springs for crimping pliers.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo pre ru\u010dn\u00e9 n\u00e1radie, napr\u00edklad n\u00e1hradn\u00e1 \u010depe\u013e na odizolovacie n\u00e1stroje alebo n\u00e1hradn\u00e9 pr\u00eddr\u017en\u00e9 pru\u017einy pre lisovacie klie\u0161te.",
              },
            ],
          },
        ],
      },
      {
        id: "D3J0000101",
        parent_id: "HFI0000101",
        text: "Lighting and signaling",
        X_fullName: "Lighting and signaling",
        X_fullName_SK: "Osvetlenie a signaliz\u00e1cia",
        X_URL: "lighting-and-signaling",
        FULLPATH: "PhoenixContact\\Lighting and signaling",
        X_Description:
          "Whether you are dealing with the system state or product quality \u2013 with intelligent lighting and signaling technology, you always have everything within view.",
        X_Description_SK:
          "\u010ci u\u017e ide o stav syst\u00e9mu alebo kvalitu produktu \u2013 s inteligentnou svetelnou a signaliza\u010dnou technikou m\u00e1te v\u017edy v\u0161etko pod kontrolou.",
        items: [
          {
            id: "E3J0000101",
            parent_id: "D3J0000101",
            text: "Machine lights",
            X_fullName: "Machine lights",
            X_fullName_SK: "Svetl\u00e1 stroja",
            X_URL: "machine-lights",
            FULLPATH: "PhoenixContact\\Lighting and signaling\\Machine lights",
            X_Description:
              "Make the most of your machine lighting and increase the efficiency of your system.",
            X_Description_SK:
              "Vyu\u017eite maximum osvetlenia svojho stroja a zv\u00fd\u0161i\u0165 efekt\u00edvnos\u0165 v\u00e1\u0161ho syst\u00e9mu.",
            items: [
              {
                id: "F3J0000101",
                parent_id: "E3J0000101",
                text: "PLD machine lighting",
                X_fullName: "PLD machine lighting",
                X_fullName_SK: "Osvetlenie PLD stroja",
                X_URL: "pld-lighting",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Machine lights\\PLD machine lighting",
                X_Description:
                  "LED machine lights in various lengths and with different emission angles for individual solutions.",
                X_Description_SK:
                  "LED strojov\u00e9 svietidl\u00e1 v r\u00f4znych d\u013a\u017ekach a s r\u00f4znymi uhlami vy\u017earovania pre individu\u00e1lne rie\u0161enia.",
              },
              {
                id: "G3J0000101",
                parent_id: "E3J0000101",
                text: "Accessories for PLD machine lights",
                X_fullName: "Accessories for PLD machine lights",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo pre svetl\u00e1 stroja PLD",
                X_URL: "pld-accessories",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Machine lights\\Accessories for PLD machine lights",
                X_Description:
                  "Accessories for installation and startup of PLD machine lights.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo pre in\u0161tal\u00e1ciu a spustenie osvetlenia strojov PLD.",
              },
            ],
          },
          {
            id: "H3J0000101",
            parent_id: "D3J0000101",
            text: "Signal towers",
            X_fullName: "Signal towers",
            X_fullName_SK: "Sign\u00e1lne ve\u017ee",
            X_URL: "signal-towers",
            FULLPATH: "PhoenixContact\\Lighting and signaling\\Signal towers",
            X_Description:
              "Optical, audible, and wireless signaling for machine and system states.",
            X_Description_SK:
              "Optick\u00e1, zvukov\u00e1 a bezdr\u00f4tov\u00e1 signaliz\u00e1cia stavu stroja a syst\u00e9mu.",
            items: [
              {
                id: "J3J0000101",
                parent_id: "H3J0000101",
                text: "Audible elements",
                X_fullName: "Audible elements",
                X_fullName_SK: "Zvukov\u00e9 prvky",
                X_URL: "audible-elements",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Signal towers\\Audible elements",
                X_Description:
                  "Audible signal elements such as buzzers, sirens or voice output to increase awareness of alarms in the event of machine and system errors.",
                X_Description_SK: "",
              },
              {
                id: "L3J0000101",
                parent_id: "H3J0000101",
                text: "Connection and mounting elements",
                X_fullName: "Connection and mounting elements",
                X_fullName_SK: "Pripojovacie a mont\u00e1\u017ene prvky",
                X_URL: "connection-and-mounting-elements",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Signal towers\\Connection and mounting elements",
                X_Description:
                  "Connection and mounting elements for the individual installation of signal towers.",
                X_Description_SK: "",
              },
              {
                id: "N3J0000101",
                parent_id: "H3J0000101",
                text: "Optical elements",
                X_fullName: "Optical elements",
                X_fullName_SK: "Optick\u00e9 prvky",
                X_URL: "optical-elements",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Signal towers\\Optical elements",
                X_Description:
                  "Optical elements for color-coded status indication of machine states.",
                X_Description_SK: "",
              },
              {
                id: "O3J0000101",
                parent_id: "H3J0000101",
                text: "Accessories",
                X_fullName: "Accessories",
                X_fullName_SK: "Pr\u00edslu\u0161enstvo",
                X_URL: "signal-towers-accessories",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Signal towers\\Accessories",
                X_Description:
                  "Accessories for installing and operating signal towers, such as labeling fields, light bulbs, and cable glands.",
                X_Description_SK:
                  "Pr\u00edslu\u0161enstvo na in\u0161tal\u00e1ciu a prev\u00e1dzku sign\u00e1lnych sto\u017eiarov, ako s\u00fa \u0161t\u00edtkov\u00e9 polia, \u017eiarovky a k\u00e1blov\u00e9 priechodky.",
              },
            ],
          },
          {
            id: "Q3J0000101",
            parent_id: "D3J0000101",
            text: "Signal lamps for marine applications",
            X_fullName: "Signal lamps for marine applications",
            X_fullName_SK:
              "Sign\u00e1lne svetl\u00e1 pre n\u00e1morn\u00e9 aplik\u00e1cie",
            X_URL: "signal-lamps-marine-applications",
            FULLPATH:
              "PhoenixContact\\Lighting and signaling\\Signal lamps for marine applications",
            X_Description:
              "Maritime signal lights for inland and maritime navigation.",
            X_Description_SK: "",
          },
          {
            id: "R3J0000101",
            parent_id: "D3J0000101",
            text: "Enclosure lights",
            X_fullName: "Enclosure lights",
            X_fullName_SK: "Svetl\u00e1 krytu",
            X_URL: "enclosure-lights",
            FULLPATH:
              "PhoenixContact\\Lighting and signaling\\Enclosure lights",
            X_Description:
              "Ensure optimum lighting of your control cabinet, right down to the bottom and save time during installation.",
            X_Description_SK:
              "Zabezpe\u010dte optim\u00e1lne osvetlenie va\u0161ej rozvodnej skrine a\u017e po spodok a u\u0161etrite \u010das pri in\u0161tal\u00e1cii.",
            items: [
              {
                id: "S3J0000101",
                parent_id: "R3J0000101",
                text: "Class 600 LED enclosure lights",
                X_fullName: "Class 600 LED enclosure lights",
                X_fullName_SK: "Svetl\u00e1 krytu LED triedy 600",
                X_URL: "class-600-led-enclosure-lights",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Enclosure lights\\Class 600 LED enclosure lights",
                X_Description:
                  "Class 600 LED control cabinet lights with various different socket types and AC wide range input for worldwide use.",
                X_Description_SK:
                  "Svetl\u00e1 rozv\u00e1dza\u010dov LED triedy 600 s r\u00f4znymi typmi z\u00e1suviek a \u0161irok\u00fdm AC vstupom pre celosvetov\u00e9 pou\u017eitie.",
              },
            ],
          },
        ],
      },
      {
        id: "T3J0000101",
        parent_id: "HFI0000101",
        text: "Charging technology for E-Mobility",
        X_fullName: "Charging technology for E-Mobility",
        X_fullName_SK:
          "Nab\u00edjacia technol\u00f3gia pre elektronick\u00fa mobilitu",
        X_URL: "charging-technology-e-mobility",
        FULLPATH: "PhoenixContact\\Charging technology for E-Mobility",
        X_Description:
          "We offer charging technology that is setting new standards in electromobility.",
        X_Description_SK:
          "Pon\u00fakame nab\u00edjaciu technol\u00f3giu, ktor\u00e1 stanovuje nov\u00e9 \u0161tandardy v elektromobilite.",
        items: [
          {
            id: "U3J0000101",
            parent_id: "T3J0000101",
            text: "Charging systems",
            X_fullName: "Charging systems",
            X_fullName_SK: "Nab\u00edjacie syst\u00e9my",
            X_URL: "charging-systems",
            FULLPATH:
              "PhoenixContact\\Charging technology for E-Mobility\\Charging systems",
            X_Description:
              "AC charging cables, DC charging cables, and infrastructure socket outlet with corresponding hinged covers and holders.",
            X_Description_SK:
              "AC nab\u00edjacie k\u00e1ble, DC nab\u00edjacie k\u00e1ble a z\u00e1suvka infra\u0161trukt\u00fary so zodpovedaj\u00facimi sklopn\u00fdmi krytmi a dr\u017eiakmi.",
            items: [
              {
                id: "W3J0000101",
                parent_id: "U3J0000101",
                text: "DC charging cable",
                X_fullName: "DC charging cable",
                X_fullName_SK: "DC nab\u00edjac\u00ed k\u00e1bel",
                X_URL: "dc-charging-cable",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging systems\\DC charging cable",
                X_Description:
                  "DC vehicle connectors in accordance with Type 1, Type 2 and GB/T standard from Phoenix Contact for connecting to fast charging stations",
                X_Description_SK: "",
              },
              {
                id: "Y3J0000101",
                parent_id: "U3J0000101",
                text: "AC charging cable",
                X_fullName: "AC charging cable",
                X_fullName_SK: "Nab\u00edjac\u00ed k\u00e1bel AC",
                X_URL: "ac-charging-cable",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging systems\\AC charging cable",
                X_Description:
                  "You can find the right AC charging cable with vehicle connector and infrastructure plug for every global standard in the product lineup from Phoenix Contact.",
                X_Description_SK: "",
              },
              {
                id: "04J0000101",
                parent_id: "U3J0000101",
                text: "AC Infrastructure Socket Outlets",
                X_fullName: "AC Infrastructure Socket Outlets",
                X_fullName_SK: "Z\u00e1suvky infra\u0161trukt\u00fary AC",
                X_URL: "ac-infrastructure-socket-outlets",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging systems\\AC Infrastructure Socket Outlets",
                X_Description:
                  "Infrastructure Socket Outlets from Phoenix Contact feature a modular design and can be used for indoor and outdoor charging points.",
                X_Description_SK: "",
              },
              {
                id: "24J0000101",
                parent_id: "U3J0000101",
                text: "Accessories for Charging system",
                X_fullName: "Accessories for Charging system",
                X_fullName_SK:
                  "Pr\u00edslu\u0161enstvo pre nab\u00edjac\u00ed syst\u00e9m",
                X_URL: "accessories-for-charging-system",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging systems\\Accessories for Charging system",
                X_Description:
                  "Accessories and replacement materials for your charging systems.",
                X_Description_SK: "",
              },
            ],
          },
          {
            id: "34J0000101",
            parent_id: "T3J0000101",
            text: "Charging controllers",
            X_fullName: "Charging controllers",
            X_fullName_SK: "Nab\u00edjacie ovl\u00e1da\u010de",
            X_URL: "charging-controllers",
            FULLPATH:
              "PhoenixContact\\Charging technology for E-Mobility\\Charging controllers",
            X_Description:
              "Charging controllers for residential, commercial, and public charging, as well as residual current monitoring modules.",
            X_Description_SK:
              "Regul\u00e1tory nab\u00edjania pre bytov\u00e9, komer\u010dn\u00e9 a verejn\u00e9 nab\u00edjanie, ako aj moduly na monitorovanie zvy\u0161kov\u00e9ho pr\u00fadu.",
            items: [
              {
                id: "54J0000101",
                parent_id: "34J0000101",
                text: "Residual current monitoring",
                X_fullName: "Residual current monitoring",
                X_fullName_SK: "Monitorovanie zvy\u0161kov\u00e9ho pr\u00fadu",
                X_URL: "residual-current-monitoring",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging controllers\\Residual current monitoring",
                X_Description:
                  "Universal residual current monitoring: RCM modules make it possible to detect AC and DC differential currents inside a charging point using a measuring sensor.",
                X_Description_SK: "",
              },
              {
                id: "74J0000101",
                parent_id: "34J0000101",
                text: "AC charging controllers",
                X_fullName: "AC charging controllers",
                X_fullName_SK:
                  "Ovl\u00e1da\u010de nab\u00edjania striedav\u00fdm pr\u00fadom",
                X_URL: "ac-charging-controllers",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging controllers\\AC charging controllers",
                X_Description:
                  "E-mobility charging controllers for any AC applications.",
                X_Description_SK: "",
              },
              {
                id: "94J0000101",
                parent_id: "34J0000101",
                text: "DC charging controllers",
                X_fullName: "DC charging controllers",
                X_fullName_SK:
                  "Regul\u00e1tory nab\u00edjania jednosmern\u00fdm pr\u00fadom",
                X_URL: "dc-charging-controllers",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging controllers\\DC charging controllers",
                X_Description:
                  "E-Mobility charging controllers for semi-public and public applications.",
                X_Description_SK: "",
              },
            ],
          },
        ],
      },
      {
        id: "A4J0000101",
        parent_id: "HFI0000101",
        text: "null",
        X_fullName: "Others",
        X_fullName_SK: "Ostatn\u00e9",
        X_URL: "others",
        FULLPATH: "PhoenixContact\\null",
        X_Description: "",
        X_Description_SK: "",
      },
    ],
  },
];
