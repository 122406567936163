import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function saleProducts(
  state = initialState.saleProducts,
  action
) {
  switch (action.type) {
    case types.SALE_PRODUCTS:
      return { ...state, ...action.payload };
    case types.DEFAULT_SALE_PRODUCTS:
      return initialState.saleProducts;
    default:
      return state;
  }
}
