import { Box, Button, Typography } from "@mui/material";
import { DEFAULT_API_DATA, SEARCH } from "../../../redux/actions/actionTypes";
import { DEV_URL, INDEX_TOKEN } from "../../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import CustomHits from "./CustomHits";
import { MeiliSearch } from "meilisearch";
import NotFoundSearch from "../NotFoundSearch";
import ServerConnectionError from "../../pages/defaultPages/ServerConnectionError";
import { conversionsApi } from "../../../api/apiCalls";
import crypto from "crypto-js";
import { useQuery } from "react-query";

export default function ResultList() {
  const dispatch = useDispatch();
  const expression = useSelector((state) => state?.search?.expression);
  const resultData = useSelector((state) => state?.search?.result);
  const isError = useSelector((state) => state?.search?.isError);
  const noResults = useSelector((state) => state?.search?.noResults);
  const sessionInfo = useSelector((state) => state?.sessionInfo);
  const [conversionApiPayload, setConversionApiPayload] = useState("");

  const getFbp = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.startsWith("_fbp=")) {
        return cookie.substring("_fbp=".length, cookie.length);
      }
    }
    return null;
  };

  const getFbc = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.startsWith("_fbc=")) {
        return cookie.substring("_fbc=".length, cookie.length);
      }
    }
    return null;
  };

  // eslint-disable-next-line
  const conversionsApiCall = useQuery(
    ["conversionsApi_SearchDialog", conversionApiPayload],
    () => conversionsApi(conversionApiPayload),
    {
      enabled: !!conversionApiPayload,
      cacheTime: 5,
    }
  );

  const meilisearchClient = new MeiliSearch({
    host: DEV_URL?.PROD_INDEX,
    apiKey: INDEX_TOKEN?.API_KEY,
  });

  const search = async () => {
    // TODO
    // setLoading(true);

    try {
      const index = meilisearchClient.index("cards");
      const searchResults = await index.search(expression, {
        limit: 10,
        offset: 0,
      });

      const fbp = getFbp();
      const fbc = getFbc();
      setConversionApiPayload({
        data: [
          {
            event_name: "Search",
            event_time: Math.floor(Date.now() / 1000),
            action_source: "website",
            event_source_url: window.location.href,
            user_data: {
              em: [
                sessionInfo?.email
                  ? crypto.SHA256(sessionInfo?.email.toLowerCase()).toString()
                  : "",
              ],
              client_user_agent: window.navigator.userAgent,
              fbp: fbp,
              fbc: fbc,
            },
            custom_data: {
              search_string: expression,
            },
          },
        ],
      });

      if (searchResults?.hits.length === 0) {
        dispatch({
          type: SEARCH,
          payload: {
            noResults: true,
          },
        });
      } else {
        dispatch({
          type: SEARCH,
          payload: {
            result: searchResults?.hits,
            noResults: false,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH,
        payload: {
          isError: true,
        },
      });
      console.error("Search error:", err);
    }
    // TODO
    // finally {
    // setLoading(false);
    // }
  };

  useEffect(() => {
    if (expression.length > 0) {
      dispatch({
        type: SEARCH,
        payload: {
          isError: false,
        },
      });
      const timer = setTimeout(() => {
        search();
      }, 500);

      return () => clearTimeout(timer);
    } else {
      dispatch({
        type: SEARCH,
        payload: {
          result: [],
          isError: false,
          noResults: false,
        },
      });
    }
    // eslint-disable-next-line
  }, [expression]);

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({
      type: DEFAULT_API_DATA,
    });

    dispatch({
      type: SEARCH,
      payload: {
        isSubmited: true,
        isOpenDialog: false,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          scrollBehavior: "smooth",
          overflowY: "auto",
          flexGrow: 1,
        }}
      >
        {isError && <ServerConnectionError />}
        {expression?.length > 0 ? (
          noResults ? (
            <NotFoundSearch />
          ) : (
            resultData?.length > 0 && <CustomHits />
          )
        ) : (
          <Typography textAlign={"center"} variant="subtitle1">
            Zadajte niečo, čo chcete vyhľadať
          </Typography>
        )}
      </Box>
      {!noResults && expression?.length > 0 && resultData?.length > 0 && (
        <Button
          sx={{ width: 200, mx: "auto", mt: 1 }}
          onClick={handleSubmit}
          variant="outlined"
        >
          Zobraziť viac
        </Button>
      )}
    </>
  );
}
