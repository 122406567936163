import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function currentUrl(state = initialState.currentUrl, action) {
  switch (action.type) {
    case types.CURRENT_URL:
      return { ...state, ...action.payload };
    case types.DEFAULT_CURRENT_URL:
      return initialState.currentUrl;
    default:
      return state;
  }
}
